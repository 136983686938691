<template>
<div>

<b-container>

<b-row>
    <b-col class="col-5 mt-3"><h5>{{ sub.title }}</h5></b-col>  
    <b-col class="col-7 mt-3">
        <div class="rtmarkerexpiry" :style="{ backgroundColor:sub.color}">
                {{ sub.status}} 
        </div>
    </b-col>
    <b-col class="col-6 mt-3"><h6>Starts : {{ sub.start }}</h6></b-col>
    <b-col class="col-6 mt-3"><h6>Expiry : {{ sub.end }}</h6></b-col>

    <b-col class="col-12 mt-3" style="border-top:1px solid rgba(255,255,255,0.7)"></b-col>

</b-row>            


<b-row>
    
        <b-col class="col-12 mt-3">    
                <label>Reference</label>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="sub.purchase_order"
                    name="sub.purchase_order"
                    v-validate="{required:true}"
					:class="{'darkinput': true, 'is-danger': errors.has('sub.purchase_order') }"
                    class="darkinput"
                    />
                    <span style="color:red" v-show="errors.has('sub.purchase_order')">
                    {{ errors.first('sub.purchase_order') }}</span>
            </b-col>
       
</b-row>

<b-row> 
         
         <b-col class="col-12 mt-3" >
              
              <b-form-checkbox
                          inline
                          v-model="sendsubemail"
                          size="lg"
                          value=1
                          unchecked-value=0
              />
              <label style="margin-right:15px">Send email </label> 
          </b-col> 
          
          <b-col class="col-12 mt-3" v-if="sendsubemail == 1">
                          <label>Email Address</label>
                          <b-form-input 
                          type="search"
                          placeholder=""
                          autocomplete="off" 
                          v-model="emailto" 
                          class="darkinput"  
                          />
              </b-col> 
</b-row>

<b-row>
        <b-col class="col-12 mt-5 mb-3" >
            <b-button variant="outline-danger" @click.stop="validateData()" block>Cancel scheduled subscription</b-button>
        </b-col>
</b-row>

<b-modal ref="renew-sub"  size="lg" title="Cancelling Subscription" hide-footer centered content-class="darkmodal">
    <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row>

   <b-row>
        <b-col class="col-12 mt-5">
            <b-button variant="outline-success" @click.stop="finishRenewal()" block>Finish</b-button>
       </b-col>   
   </b-row>   

</b-modal>

<SendEmail 
        :sendmail="sendmail"
        :emailid="emailid"   
        :emailto="emailto" 
        :subid="subid"
        :showdiag="false"
        v-on:emailsent="emailSendStatus"
        />

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-container>
	
</div> 
</template>

<script>
    import SendEmail from './SendEmail'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
    import { Validator } from 'vee-validate'

	export default {
        props:['subid'],
        name:'CasterSubscriptionCancelRenew',
        components:{
            SendEmail,
            ErrorLogging
        },
		data(){
		return {
            roleid:0,
			token:'',
            sub:{
                id:0,
                color:'',
                title:'',
                status:'',
                expirydays:0,
                start:'',
                end:'',
            },
            date:0,
            datenow:'',
            expire:'',
            expirydays:0,
            user:{},
            modaltitle:'',
            errorresponse:{},
            sendsubemail:1,
            sendmail:false,
            sent:99,
            emailto:'',
            emailid:8,  // sub scheduled for activation cancelled.
            sending:false,
            stagemessages:[],
        };
		
		},

        mounted(){

            Validator.localize('en',{ 
                    messages: {
                    required: '** required.',
                    },
            })   
            this.user = this.$store.getters.user
            this.roleid = this.$store.getters.roleid
            this.token = this.$store.getters.token
            this.datenow = moment().format('DD-MM-Y HH:mm:ss')
            this.fetchSub()
            
        },

      
		methods: {
            fetchSub(){
                axios.get('/api/casterntripsubscriptions',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:this.subid
                        }
                    })
				.then((response) => {

                    if(response.data.code == 999){
                            this.errorresponse = response.data
                    } else {
                        let renewsub = response.data.CasterSubs[0].renewalsub
                        this.sub.useremail = response.data.CasterSubs[0].companyemail
                        this.getRenewal(renewsub)
                        this.emailto = this.sub.useremail
                    }
                    
                    });
            },

            getRenewal(renewsub){

            axios.get('/api/casterntripsubscriptionsstock3',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:renewsub
                        }
                    })
				.then((response) => {

                    if(response.data.code == 999){
                            this.errorresponse = response.data
                    } else {
                        let sub = response.data.CasterSubs[0]

                        this.sub.id = this.subid 
                        this.sub.subid = sub.value
                        this.sub.color = sub.color
                        this.sub.rover = sub.rover
                        this.sub.title = sub.subscription[0].title
                        this.sub.expirydays = sub.days
                        this.sub.status = sub.statustext
                        this.sub.start = sub.startdate2
                        this.sub.end = sub.enddate2
                        this.sub.user = this.user.id

                    }
                    
                    });
            },

        
            validateData(){
                this.$validator.validateAll().then(result => {
                        if (result) { this.cancelScheduledSub()}
                });        
             },

             cancelScheduledSub(){

                
                this.$refs['renew-sub'].show()
               
                axios({
                    method: 'post',   
                    headers: {"Authorization" : "Bearer " + this.token},
                    url: '/api/rovercancelscheduledsubscription',
                    data: {
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            roverdata:this.sub,
                            country:this.sub.country,
                            reseller:this.sub.reseller,
                            dealer:this.sub.dealer,
                        }
                    })
                    .then((response)=> {
                        this.stagemessages = response.data.messages

                            if(response.data.code == 999){
                                    this.errorresponse = response.data
                                    this.saving = false
                                } else if(this.sendsubemail == 1){this.sendEmail()}
                                
                            });	
            },

           
            finishRenewal(){
                    this.$refs['renew-sub'].hide()
                    this.$emit('update')
            },

            sendEmail(){
                this.sending = true
                this.sendmail = !this.sendmail // trigger to SendEmail
            },

            emailSendStatus(data){
                    this.stagemessages.push(...data.messages)
                    this.sending = false
                    this.sent = data.sent
                }, 

            }
	}
</script> 