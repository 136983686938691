<script>
  
import { Bar } from 'vue-chartjs'

export default {
    extends: Bar,
    props:['data','update','yspacing'],

    data(){
      return {
        ds:[],
        chartdata: {
                 labels: [],
                 datasets: [
                    {
                        data: []
                    }
                    ]
            },
      chartoptions: {
          
            maintainAspectRatio: false,
            responsive:true,
            
            plugins: {
                datalabels: {
                    display: false,
                },
            },
            legend: {
                position: 'bottom',
                labels: {
                    fontColor: 'rgba(255,255,255,0.5)'
                    }
            },
             scales: {
                 yAxes: [{
                    stacked:true,
                    scaleLabel: {
                        display: true,
                        labelString: '',
                        fontColor:"#ccc"
                    },       
                    ticks: {
                        fontColor: "rgba(255, 255, 255, 0.65)",   
                        stepSize: this.yspacing,
                        reverse: false,
                        beginAtZero: true,
                    },
                    gridLines: { 
                        color:  "#666",
                        zeroLineColor: "#666" },
					}],
                xAxes: [{
                    stacked: true,    
                    ticks: {
                        fontColor: "rgba(255, 255, 255, 0.65)", 
                    }
          }]
             },

         },
  };

},

  mounted () {
     
        this.buildChart()  
    
  },

  watch:{
          data: function() {
                let sum1 = this.data.datasets.data[0].reduce((a, b) => {return a + b;}, 10);
                let sum2 = this.ds[0].data.reduce((a, b) => {return a + b;}, 10);
              
                if(sum1 != sum2){
                    this.ds = []
                    this.buildChart()
                }
          },
          
          update: function() {
                    this.ds = []
                    this.buildChart()
          }
  },


  methods:{

    buildChart(){

                    this.chartoptions.scales.yAxes[0].scaleLabel.labelString = this.data.datasets.label2
                    
                    for (let i = 0; i < this.data.datasets.data.length; i++) {
                        this.ds.push({
                                label: this.data.datasets.textlabels[i],
                                backgroundColor: this.data.datasets.colors[i],
                                data: this.data.datasets.data[i]
                                })
                    }

                    this.renderChart({
                        labels: this.data.labels,
                        datasets: this.ds
                    },this.chartoptions)
                    
    },

  },
}

</script> 