<template>
    <div class="scroll">
        <!-- Loading Text -->
        <b-row>
            <b-col class="mt-3 mb-3"  cols=10 v-if="!messages">
                <h6>Getting Key</h6>
            </b-col>
            <b-col class="mt-3 mb-3" cols=2 v-if="!messages">
                <b-icon icon="three-dots" animation="cylon" />
            </b-col>     
            <b-col v-if="messages">
                <h6>KEY</h6>
            </b-col>
        </b-row>

        <!-- Simple Key -->
        <b-row v-if="messages">
            <b-col>
                <b-table
                class="noScroll"
                striped 
                responsive 
                :items="simpleKeyItems" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                sort-by="type"
                dark
                >
                    <template #cell(color)="data">
                        <div class="statuscircle" :style="{ backgroundColor: data.item.color }" />
                    </template>
                    <template #cell(type)="data">
                        <div>{{convertTypeValueToString(data.item.type)}}</div>
                    </template>
                </b-table>
            </b-col>  
        </b-row>      

        <!-- Showing you can see the Full Message List-->
        <b-row class="mt-3 mb-3" v-if="messages" v-b-toggle.collapsFullList>
            <b-col cols=10>
                <h6>Full Message List:</h6>
            </b-col>
            <b-col cols=2>
                <b-icon class="mr-1" icon="arrows-collapse" v-b-tooltip.hover title="Open/Collapse"/>
            </b-col>
        </b-row>

        <!-- Collapsed Full Message List-->
        <b-row class="mt-3 mb-3" v-if="messages">
            <b-col>
                <b-collapse id="collapsFullList">
                    <b-table
                    title="Message List Key" 
                    striped 
                    responsive 
                    :items="messages" 
                    :fields="fields"
                    tbody-tr-class="rowClass"
                    thead-tr-class="rowHClass"
                    sort-icon-left
                    sort-by="type"
                    dark
                    >
                        <template #cell(color)="data">
                            <div class="statuscircle" :style="{ backgroundColor: data.item.color }" />
                        </template>
                        <template #cell(type)="data">
                            <div>{{convertTypeValueToString(data.item.type)}}</div>
                        </template>
                    </b-table>
                        <!-- Close Full Message List-->
                        <b-row id="closeFullMessageList" class="mt-3 mb-3" v-if="messages" v-b-toggle.collapsFullList>
                            <b-col cols=10>
                                <h6>Close Message List:</h6>
                            </b-col>
                            <b-col cols=2>
                                <b-icon class="mr-1" icon="arrows-collapse" v-b-tooltip.hover title="Open/Collapse"/>
                            </b-col>
                        </b-row>  
                </b-collapse>
            </b-col>
        </b-row>



    </div> 
</template>

<style scoped>
.scroll{
    max-height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    scrollbar-color: transparent transparent; /* Firefox*/
}
.scroll::-webkit-scrollbar{
    display: none; /* Chrome Safari */
}

.noScroll{
    overflow-x: hidden;
}
</style>

<script>
    import axios from 'axios'
	export default {
        name:'CasterLastEventsCardKey',
        data(){
            return {
                token:'',
                messages:null,//[]
                simpleKeyItems:[],
                fields:[
                    { key: 'color', label: 'Col', sortable: true },
                    { key: 'type', label: 'Type', sortable: true },
                    { key: 'text', label: 'Meaning', sortable: true },
                ],
            }
        },
		created(){
            this.token = this.$store.getters.token
            this.fetchLogMessages()
        },

		methods: {
            createSimpleKeyItems(){
                //First get all the message colours and filter to unique colors.
                let messageColors = []; 
                this.messages.forEach(element => {
                    messageColors.push(element.color);
                });
                const uniqueMessageColors = [...new Set(messageColors)]

                //next map what the colors mean 
                const knownColorMeanings = [
                    {color: "#dce1e3b2", type: "1", text: "New Connection"},
                    {color: "#3cd2a5b2", type: "1", text: "Connected/Transfered to Refstation"},
                    {color: "#ff6060b2", type: "1", text: "Connection Refused"},
                    {color: "#101416b2", type: "1", text: "Disconnected"},
                    {color: "#4e9cffb2", type: "2", text: "Disconnected"},
                    {color: "#ff9f0cb2", type: "2", text: "Connection Refused"},
                    {color: "#bba4ffb2", type: "3", text: "Settings, sources and users rehashed"},
                    {color: "#f9e80db2", type: "3", text: "Caster Restart"}
                ]


                //Now using the map we need to make a new table using it, and handling unknow colors
                const uniqueMessageColorsObj = uniqueMessageColors.map( (colorString) => {
                    //set a default row incase no match.
                    let tableEntry = {color:colorString, type:"unknown", text:"color meaning Needs adding."};

                    //remove blank space, just in case
                    colorString = colorString.replace(/\s+/g, "");

                    //check map for a match if so use that row for our table
                    knownColorMeanings.forEach( (knownMeaning) => {
                        if(knownMeaning.color === colorString){
                            tableEntry = knownMeaning;
                        }
                    })                
                    return (tableEntry)
                })
                this.simpleKeyItems = uniqueMessageColorsObj;
            },
            convertTypeValueToString(value){
                const map = {
                    1:"Client",
                    2:"Source",
                    3:"Other"
                }
                return map[value] || `unknown`
            },
            expandFullMessageList(){

            },
            fetchLogMessages(){
                
				axios.get('/api/getlogmessages',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                    }
                })
				.then((response) => {
                    this.messages = response.data.Messages
                    this.createSimpleKeyItems();
                    })
                    .catch(err => {
                        console.error(err);
                    });
			},
           
        }	   
	
	};
</script>