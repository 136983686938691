<template>
<div>
<b-navbar type="dark" variant="dark" style="position:absolute;left:0;width:100%;z-index:998">
    <b-navbar-brand href="/" style="height:75px;"> 
    <img :src="logo" style="height:65px;padding:5px;" v-if="branding.logo == undefined">
    <img :src="brandlogo" style="height:65px;padding:5px;" v-else> 
    </b-navbar-brand>
</b-navbar>
</div>
</template> 

<script>

import { mapState } from 'vuex'
import logo from '@/assets/images/branding/cloudbaselogo.png'

export default { 
  data(){
    return{
      logo,
      brandlogo:''   
    }
  },

 
    computed: mapState(['branding','countryid','resellerid','dealerid','companyid']), 

     watch: {
				branding() {
                    this.brandlogo = 'https://ip-rtk-aws.com'+this.branding.logo  
        },
    },
}
</script>