<template>
<div class="imageBorder">

<b-row>
        <b-col class="col-9 boxtext">DATA USAGE</b-col>
        <b-col class="col-3 text-right" >
                <b-icon-fullscreen @click.stop="fullScreen()" v-b-tooltip.hover title="Expand" />
        </b-col>
</b-row>        
      
<b-row class="mt-2" v-if="display">
        <b-col class="col-12">
        
                <b-row class="mt-1"  >
                        <b-col class="col-6 mt-2"><h6 style="font-size:0.9em">{{ simpackages[0].text}}</h6></b-col>
                        <b-col class="col-6 col-lg-6 text-center">
                                <BarChartH :datavalue="simpackages[0].data_used" :totalvalue="simpackages[0].data_allowance" unitvalue=' GB' />
                        </b-col>  
                </b-row>

                <b-row class="mt-1"  >
                        <b-col class="col-6 mt- mt-2"><h6 style="font-size:0.9em">{{ simpackages[1].text}}</h6></b-col>
                        <b-col class="col-6 col-lg-6 text-center">
                                <BarChartH :datavalue="simpackages[1].data_used" :totalvalue="simpackages[1].data_allowance" unitvalue=' GB' />
                        </b-col>  
                </b-row>

        </b-col>        

        </b-row>

 <b-row v-else class="mt-1">
                <b-col class="col-12 text-center pt-3 pb-3">
                        <h5 style="color:#48A1BA;">No SIM Data Found</h5>
                </b-col>   
</b-row>        

<b-modal ref="full-screen" size="md"  title="DATA USAGE" hide-footer centered content-class="darkmodal" v-if="display">

         <b-row class="mt-1" v-for="simpackage in simpackages" :key="simpackage.id">
        
        <b-col class="col-12">
                
                <b-row class="mt-3"  >

                         <b-col class="col-12 col-md-8 col-lg-9" style="font-size:0.9em">
                                <b-row> 
                                <b-col class="col-12"><h6>{{ simpackage.text}}</h6></b-col>
                                <b-col class="col-12">Used {{ simpackage.data_used }} GB</b-col>
                                <b-col class="col-12">Allowance {{ simpackage.data_allowance }} GB</b-col>
                                <b-col class="col-12">GB Usage {{ simpackage.data_usage }}%</b-col>
                                <!-- <b-col class="col-12"># Sims {{ simms }}</b-col> -->
                                </b-row>
                        </b-col>        
                
                        <b-col class="col-12 col-md-4 col-lg-3"> 
                                <SubsChart :data="data" v-if="simpackage.dataset == 0" />
                                <SubsChart :data="data1" v-else />
                        </b-col>
                </b-row>

         </b-col>       

        </b-row>

</b-modal>        

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div>
</template>


<script>
        import SubsChart from './SubsChart'
        import BarChartH from './BarChartH'
        import ErrorLogging from './ErrorLogging'
        import { mapState } from 'vuex'
        import axios from 'axios'
        export default {
                name:'CasterSimmUsageCardAdmin',
                components:{
                SubsChart,
                BarChartH,
                ErrorLogging
                },     
        data(){
		return {
                token:'',
                data:[],
                data1:[],
                simpackages:[],
                total:0,
                allowance:0,
                used:0,
                simms:0,
                display:false,
                errorresponse:{}
                };
                
                },
        
        created(){
                this.token = this.$store.getters.token
                if(this.$store.getters.user.country == 1){
                        this.fetchPackages()
                }
        },

        computed: mapState(['resellerid']),
                
                watch: {
                    
                        resellerid: function () {
                        
                        },    
                },     
        
        methods: {
            
            fetchPackages(){

			axios.get('/api/castersimmpackages',{
			headers: {"Authorization" : "Bearer " + this.token},
                params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token 
                }
                })
                        .then((response) => {

                        if(response.data.code == 999){
                        this.errorresponse = response.data
                      } 
                   
                        else {
                        this.simpackages = response.data.CasterSimmPackage
                        this.simpackages[0].dataset = 0
                        this.simpackages[1].dataset = 1
                        this.data.push({label:'used',color:'#ff6060',value:this.simpackages[0].data_usage})
                        this.data.push({label:'remaining',color:'#3cd2a5',value:100 - this.simpackages[0].data_usage})

                        this.data1.push({label:'used',color:'#ff6060',value:this.simpackages[1].data_usage})
                        this.data1.push({label:'remaining',color:'#3cd2a5',value:100 - this.simpackages[1].data_usage})
                    
                        this.display = true

                        } 
		
                    });
                },

                 fullScreen(){
                      this.$refs['full-screen'].show()
                },

        }
	
	};

</script> 