<template>
<div>
    <b-container>

    <b-row>

        <b-col class="col-12 mt-3">
            <label>Dealer</label>
            <h5>{{ dealer.dealer }}</h5>
        </b-col>     

       <b-col class="col-12 mt-3"> 
            <label>Subscription Type</label>
				<b-form-select
                    v-model="sub.id"
                    :options="subs"
                    @change="changeSub($event)"
                    name="sub.type"
                    v-validate="{min_value:1}"
					:class="{'b-form-select': true, 'is-danger': errors.has('sub.type') }"
                    
                    />
                </b-col>

        <b-col class="col-12 mt-3">
            <label>Number of subscriptions</label>
				<b-form-select
                    v-model="sub.number"
                    :options="numbers"
                    name="sub.number"
                    v-validate="{min_value:1}"
					:class="{'b-form-select': true, 'is-danger': errors.has('sub.number') }"
                    :disabled="sub.id == 0"  
                    />
            </b-col>

        <b-col class="col-12 mt-3"> 

        <label>Purchase Order</label>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="sub.po"
                    name="sub.po"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('sub.po') }"  
                    class="darkinput"

                    />
         </b-col>
   
</b-row>
   
      
    <b-row class="mt-5 pb-5">
         <b-col class="col-12">
            <b-button variant="outline-success" block  @click="updateStock()">ADD DEALER STOCK</b-button>
        </b-col>  
    </b-row> 


</b-container>

		
</div> 
</template>

<script>
    import axios from 'axios'
	export default {
        props:['reseller','dealer'],
        data(){
		return {
                token:'',
                sub:{},
                subs:[],
                number:0,
                numbers:[],
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.sub.reseller =  this.reseller
            this.sub.dealer =  this.dealer.id
            this.sub.user = this.$store.getters.user.id
            this.sub.id = 0
            this.sub.number = this.numbers[0]
            this.fetchResellerSubs()
        },

		methods: {

           fetchResellerSubs(){
            axios.get('/api/casterntripsubscriptionsstocklist',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         reseller:this.reseller,
                         admin:1
                     }
                })
				.then((response) => {
                    this.subs.push({text:'Select',value:0})
                    this.subs.push(...response.data)
                    console.log(this.subs)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

            changeSub(event){
                let subdata = this.subs.filter(sub => sub.value == event)
                let number = subdata[0]['count']
                let count = 1
                this.numbers = ['Select']
                while(count < number + 1){
                    this.numbers.push(count)
                    count++
                }
                
                
            },

            updateStock(){

             
                this.$validator.validateAll().then(result => {
                    if (!result) {

					this.$swal({
						title: "Fields Missing",
						text: "Please complete all required fields",
						icon: "warning",
						timer: 3000,
						showCloseButton: false
						})
					
				} else {

                         axios({
                                method: 'post',     //   New Stock Sub
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/addcasterntripsubscriptionstock',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    subdata:this.sub
                                    }
                                })
                            .then((response)=> {
                       
                            if(response.data.error){
                                this.$swal({
                                                title: "Update Error",
                                                text: response.data.error,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                } else {

                                    this.$swal({
                                                title: "",
                                                text: 'Subscriptions Added',
                                                icon: "success",
                                                showCloseButton: true,
                                                showConfirmButton: false,
                                                timer:3000
                                                })
                                                .then(() => {
                                                   this.$emit('updatemodal')
                                                    }) 

                                }

                        
                            }).catch(err => {
                                        this.$swal({
                                                title: "Update Error",
                                                text: err,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                        });	

                }   

                })      

            },

          validateField(field){
               this.$validator.validate(field)
           },   
      
         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script> 


