<template>
<div>
<b-container class="mainpagecont">

<b-row class="mb-3">
    <b-col class="col-12 col-md-4 mt-3">
        <h3>Subscriptions</h3>
    </b-col>
    <b-col class="col-12 col-md-5 offset-md-3 mt-3">
        <CasterSearchSuggestions 
            placeholder="search subscription logs"
            :clearsearch="clearsearch"
            suggestionType = "3"
            :suggestionFetchResponse="fetchResponse" 
            v-on:textsearch=updateSearch 
            v-on:clearsearch=clearFilter
            />
    </b-col>
</b-row>      

<b-row>
    <b-col class="col-12">
        <CasterDateSelect v-on:datefilter="updateDates" />
        <CasterOptionsFilter 
            :resellerid="resellerid" 
            :dealerid="dealerid" 
            :companyid="companyid" 
            :roverid=0
            :clearsearch="clearsearch" 
            :companysshow="filterCompanyShow"
            v-on:dealersel=updateDealerFilter  
            v-on:companysel=updateCompanyFilter
            v-on:resellersel=updateResellerFilter
            v-on:roversel=updateRoverFilter 
            />  
    </b-col>
</b-row>  

<b-row class="mt-3">
     <b-col class="col-12">
        <CasterSubscriptionsLogTable 
            :start="start" 
            :end="end" 
            :resellerid="resellerid" 
            :dealerid="dealerid" 
            :companyid="companyid" 
            :roverid="roverid"
            :subtype="subtype" 
            :stext="searchtext"
            :clearsearch="clearsearch" 
            v-on:clearstext="clearSearch"
            v-on:fetchresponse=setFetchResponse 
            />
    </b-col>

</b-row> 

</b-container>
</div> 
</template>

<script>
    import moment from 'moment'
    //import CasterSearch2 from './CasterSearch2'
    import CasterSearchSuggestions from './CasterSearchSuggestions'
    import CasterDateSelect from './CasterDateSelect'
    import CasterOptionsFilter from './CasterOptionsFilter'
    import CasterSubscriptionsLogTable from './CasterSubscriptionsLogTable'
    export default {
        name:'CasterSubsLog',
        components:{
            //CasterSearch2,
            CasterSearchSuggestions,
            CasterSubscriptionsLogTable,
            CasterOptionsFilter,
            CasterDateSelect
        },  
        data(){
            return {
                resellerid:99, // All UK
                dealerid:0,
                companyid:0,
                roverid:0,
                subtype:0,
                start:'',
                end:'',
                searchtext:'',
                fetchResponse:'',
                clearsearch:true,
                filterCompanyShow: true,
                };
                
                },

        beforeDestroy () {
            this.$store.commit('setsystemcheckpoll',1)
        },         

		created(){
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setbasesdatapoll',0)
            this.$store.commit('setsystemcheckpoll',0)
            this.start = this.firstDayOfMonth()
            this.end = moment().format('DD/MM/YYYY') 
            console.log(this.$store.getters.searchvalues)
            if(this.$store.getters.searchvalues.length > 0){
                this.resellerid = this.$store.getters.searchvalues[0]
                this.dealerid = this.$store.getters.searchvalues[1]
                this.companyid = this.$store.getters.searchvalues[2]
                this.roverid = this.$store.getters.searchvalues[3]
            } else {
                if(this.user.role == 1){
                    this.resellerid = 99 // All uk
                    this.dealerid = 0
                    this.companyid = 0
                    this.roverid = 0
                } else {
                this.resellerid = this.$store.getters.resellerid
                this.dealerid = this.$store.getters.dealerid
                this.companyid = this.$store.getters.companyid
                this.roverid = 0
                }
            } 
            },
            
        methods: {

            firstDayOfMonth(date = null) {
                if (date) {
                    return moment(date).startOf('year').format('DD/MM/YYYY')
                } else {
                    return moment().startOf('year').format('DD/MM/YYYY')
                }
            },

            updateDates(data){
                this.start = data[0]
                this.end = data[1]
            },

            updateResellerFilter(id){
                this.resellerid = id
                this.dealerid = 0
                this.companyid = 0
            },

            updateDealerFilter(id){
                this.dealerid = id
                this.companyid = 0
            },

            updateCompanyFilter(id){
                this.companyid = id
            },

            updateRoverFilter(id){
                this.roverid = id
            },

            updateSearch(text){
                this.searchtext = text
            },

            setFetchResponse(text){
                this.fetchResponse = text
            },

            clearSearch(){
                this.resellerid = 1
                this.dealerid = 0
                this.companyid = 0
                this.roverid = 0
                this.subtype = 0
                this.clearsearch = !this.clearsearch
            },

            clearFilter(){
                this.searchtext = ''
            }
	}	   
	
	};

</script>