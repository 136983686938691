<template>
<div>

<b-container>

    <b-row class="mb-3">

		<b-col class="col-12 mt-3">

				<label>Manufacturer</label>
				<b-form-input 
                    name="Manufacturer"
                    type="search" 
                    placeholder="required" 
                    autocomplete="off"
                    v-model="maker.text"
                    :disabled="lockfield" 
                    v-validate="'required|alreadyExists|min:3|max:32'"                    
                />
                <div class="text-danger">{{ errors.first('Manufacturer') }}</div>
		
			
		</b-col>

	</b-row>	 
      
    <b-row class="mt-5 pt-3 border-top">
         <b-col class="col-12">
            <b-button variant="success"  @click="updateMaker()" block>SAVE</b-button>
        </b-col>   

        <b-col class="col-12 mt-3" v-if="maker.value !== 0">
				<b-button variant="danger" block @click="deleteMaker(maker.value)">DELETE</b-button>
		</b-col>
    </b-row> 


</b-container>

		
</div> 
</template>


<script>
    import { Validator } from 'vee-validate';
    import axios from 'axios'
    export default {

        props:['id','makers'],
        data(){
		return {
    
                token:'',
                maker:{},
                lockfield:false
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            if(this.id == 0){this.newMaker()} else this.fetchMaker()
        
        },
        
        mounted(){
            this.addAlreadyExistsRule();
        }, 

        methods: {
            addAlreadyExistsRule(){
                //adding a rule to vee validator to check if the manufacturer already exists
                Validator.extend('alreadyExists', {
                    getMessage:"Manufacturer already exists.",
                    validate: (value) => {
                        const makersText = this.makers.map( makerObj => makerObj.text.toLowerCase());
                        if(makersText.includes(value.toLowerCase()))return false;
                        return true;
                    }
                })
            },

            fetchMaker(){
                axios.get('/api/machinemakers',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,  
                        id:this.id
                        }
                    })
				.then((response) => {
                    this.maker = response.data.data
                  
                   })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
           },

          
           newMaker(){
               
                this.maker.value = 0
                this.maker.text = ''
             
           },

       
            updateMaker(){
                if(this.lockfield)return;
                this.lockfield = true;
    
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.lockfield = false;
                        return;
                    }

                    if(this.maker.value !== 0){
                        axios({
                                method: 'put',
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/machinemaker',
                                data:{
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,  
                                    makerdata:this.maker
                                    }
                                })
                            .then((response)=> {
                       
                            if(response.data.error){
                                this.$swal({
                                                title: "Update Error",
                                                text: response.data.error,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                } else {

                                    this.$swal({
                                                title: "Updated",
                                                text: 'Machine Maker updated',
                                                icon: "success",
                                                showCloseButton: true,
                                                showConfirmButton: false,
                                                timer:3000
                                                })
                                                .then(() => {
                                                    this.$emit('updatemakers',response.data.value)
                                                    }) 

                                }

                        
                            }).catch(err => {
                                        this.$swal({
                                                title: "Update Error",
                                                text: err,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                            }).finally( () => {
                                this.lockfield = false;
                            });
                    } else {


                         axios({
                                method: 'post',     //   New Machine Maker
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/machinemaker',
                                data:{
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,  
                                    makerdata:this.maker
                                    }
                                })
                            .then((response)=> {
                       
                          
                            if(response.data.error){
                                this.$swal({
                                                title: "Update Error",
                                                text: response.data.error,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                } else {

                                    this.$swal({
                                                title: "Updated",
                                                text: 'New Machine Maker Created',
                                                icon: "success",
                                                showCloseButton: true,
                                                showConfirmButton: false,
                                                timer:3000
                                                })
                                                .then(() => {
                                                    this.$emit('updatemakers',response.data)
                                                    }) 
                                }
                        
                            }).catch(err => {
                                        this.$swal({
                                                title: "Update Error",
                                                text: err,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                            }).finally( () => {
                                this.lockfield = false;
                            });	
                    }                

                });      

            },

             deleteMaker(id){

                    this.$swal({
                    title: "Delete?",
                    text: "This will remove this manufacturer",
                    icon: "question",
                    showCloseButton: true,
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
					showCancelButton: true,
					cancelButtonText: 'No',
						})
						.then(() => {

                    axios({
                        method: 'delete',
						headers: { "Authorization" : "Bearer " + this.token },
                        url: '/api/machinemaker/'+id,
                        data:{
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,             
                            }
						})
					.then(
						this.$emit('updatemodal')
						)
					.catch(err => {
								this.$swal({
										title: "Update Error",
										text: err,
										icon: "error",
										showCloseButton: true,
                                        showConfirmButton: false
										})
								});	

                        })
               
			},

         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script> 


