<template>
<div>

<b-container v-if="display">

 <b-row class="mt-3 mb-3">
                <b-col class="col-12 mb-3" v-if="roleid == 5"><h5>{{ user.resellertitle }}</h5></b-col>
                <b-col class="col-12 mb-3" v-if="roleid == 10"><h5>{{ user.dealertitle }}</h5></b-col>
                <b-col class="col-12 mb-3" v-if="roleid == 20"><h5>{{ user.companytitle }}</h5></b-col>
                <b-col class="col-5 mt-1">Name</b-col>
                <b-col class="col-7 mt-1">{{ user.fname }} {{ user.lname }}</b-col>
                <b-col class="col-5 mt-1">Phone</b-col>
                <b-col class="col-7 mt-1">{{ user.mobile }}</b-col>
                <b-col class="col-5 mt-1">Email</b-col>
                <b-col class="col-7 mt-1">{{ user.email }}</b-col>
                <b-col class="col-5 mt-1">Password</b-col>
                <b-col class="col-7 mt-1">*******</b-col>
                <b-col class="col-5 mt-3">Role</b-col>
                <b-col class="col-7 mt-3">{{ user.roletitle }}</b-col>
                <b-col class="col-6 offset-5" v-if="user.readonly == 1">Read Only Access</b-col>
    </b-row> 
   
    <b-row class="mt-5 mb-3">
         <b-col class="col-12">
           <b-button variant="outline-danger" block  @click="deleteUser()">DELETE</b-button>
         </b-col>
    </b-row> 

</b-container>

		
</div> 
</template>


<script>
    import axios from 'axios'
	export default {
        props:['id'],
        data(){
		return {
                roleid:0,
                token:'',
                user:{},
                display:false
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            this.fetchUser()
        },

      
		methods: {

          
            fetchUser(){
                axios.get('/api/casterusers',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         id:this.id
                    }
                })
				.then((response) => {
                    this.user = response.data.users[0]
                    console.log(this.user)
                    this.display = true
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
            },

             deleteUser(){

              this.$swal({
                    title: "Delete?",
                    text: "This will remove this user",
                    icon: "question",
                    showCloseButton: true,
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
					showCancelButton: true,
					cancelButtonText: 'No',
						})
						.then((result) => {

                        if (result.isConfirmed) {

                       axios({
                        method: 'post',
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/casteruserarchive',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    id:this.id
                                    }
                                })
                                .then(
                                this.$emit('updatemodal')
                                //this.userEvent(2)
                        )
                        .catch(err => {
                                    this.$swal({
                                            title: "Delete Error",
                                            text: err,
                                            icon: "error",
                                            showCloseButton: true,
                                            showConfirmButton: false
                                            })
                                    });	

                            }
                        })  
                },
            
             userEvent(action){
                 let type = 0
                 if(action == 1){ //  User Create
                    
                    switch(this.roleid){
                        case 10: // dealer admin create
                        type = 30
                        break
                        case 11: // dealer user create
                        type = 31
                        break
                        case 20: // company admin create
                        type = 36
                        break
                        case 39: // company user create
                        type = 38
                        break
                    }

                 } 

                 if(action == 2){ // User Archive

                 switch(this.roleid){
                        case 10: // dealer admin archive
                        type = 33
                        break
                        case 11: // dealer user archive
                        type = 32
                        break
                        case 20: // company admin archive
                        type = 37
                        break
                        case 39: // company user archive
                        type = 37
                        break
                 }

                 }
                
          
                 axios({
                        method: 'post',  
                        headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                        url: '/api/userevent',
                        data: {
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            user:this.$store.getters.user.id,type:type,text:this.user.fname+' '+this.user.lname} // Archive User event
                        })
                    .then((response)=> {
                
                    if(response.data.error){
                        this.$swal({
                                        title: "Update Error",
                                        text: response.data.error,
                                        icon: "error",
                                        showCloseButton: true,
                                        showConfirmButton: false
                                        })

                        } else this.$emit('updatemodal')

                                        
                    }).catch(err => {
                                this.$swal({
                                        title: "Update Error",
                                        text: err,
                                        icon: "error",
                                        showCloseButton: true,
                                        showConfirmButton: false
                                        })
                                });	
            },

       
         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script>