<template>
<div class="loginpage" :style="{ backgroundImage:'url(' +backgroundimage+ ')' }" v-if="backgroundimage !== ''">

<b-container>

    <b-row class="vh-100" align-v="center">
      <b-col class="col-12 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

          <b-card
            title="Reset Password"
            class="mb-2 loginbox"
            >
         
             <form autocomplete="off" @submit.prevent="resetPassword" method="post">
              <div class="form-group">
                  <label for="email">E-mail</label>
                  <input type="email" id="email" class="form-control" placeholder="user@example.com" v-model="useremail">
              </div>
              <div class="form-group">
                  <label for="email">Password</label>
                  <input type="search" id="password" class="form-control" placeholder="enter" autocomplete="off" v-model="password" required>
                   <span class="invalid-feedback" v-if="has_error_short">Password must be minimum 6 characters long</span>
                   <span class="invalid-feedback" v-if="has_error">* Password must include uppercase, lowercase and number characters</span>
              </div>
              <div class="form-group">
                  <label for="email">Confirm Password</label>
                  <input type="search" id="password_confirmation" class="form-control" placeholder="verify" autocomplete="off" v-model="password_confirmation" required @focus="checkPW()">
                 <span class="invalid-feedback" v-if="has_error_match">Passwords do not match</span>
              </div>
              <b-button variant="success" type="submit">Update</b-button>
            </form>

        </b-card>

        </b-col>
      </b-row>

<div class="fluid-container footer">
    <p class="text-center">Nick Abbey Digital Agriculture Ltd &copy; 2023</p>
</div>

</b-container>

</div>
</template>

<script>
import backgroundimage from '@/assets/images/branding/cloudbasebgimage.jpeg'
import axios from 'axios'
export default {


    data() {
      return {
        useremail:'',
        usertoken:'',
        userdealer:'',
        password: '',
        dealer:{},
        password_confirmation: '',
        has_error: false,
        has_error_short: false,
        has_error_match: false,
        getbackgroundimage:'',
        backgroundimage,
      }
    },

    mounted(){
       
        if(this.$route.query['token'] == undefined){
            this.useremail = this.$route.params.email
            this.usertoken = this.$route.params.token
            this.userdealer = this.$route.params.dealer
            this.rewriteURL()
  
        } else {
        this.usertoken = this.$route.query['token']
        this.useremail = this.$route.query['email']
        this.userdealer = this.$route.query['dealer']
        }
       
        this.fetchDealer()
    },

    watch: {
        getbackgroundimage: function() {
            this.backgroundimage = this.getbackgroundimage
        }
    },     

    methods: {

   rewriteURL() {
        history.pushState(
            {},
            null,
            this.$route.path + '?email=' + this.useremail + '&token=' + this.usertoken + '&dealer=' + this.userdealer
        )
    },     

    fetchDealer(){
			axios.get('/api/casterdealerbranding',{
                params:{name:this.userdealer}
                    })
				.then((response) => {
                   
                    if(response.data.CasterDealers.length == 0){
                        this.backgroundimage = '/images/branding/cloudbasebgimage.jpeg'
                        this.dealer.logo = undefined
                        this.$store.commit('setbranding',this.dealer) 
               
                    } else {
                        if(this.$route.params.dealer !== 'reset'){
                        this.dealer = response.data.CasterDealers[0]
                        this.loginrole = response.data.Role    
                        this.$store.commit('setbranding',this.dealer)
                        this.brandtitle = this.dealer.text
                        this.getbackgroundimage ='https://ip-rtk-aws.com'+this.dealer.background
                   
                        }

                         
                    }    
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
    
    checkPW(){

        this.has_error_match = false  

        if(this.password.length < 6){
            this.has_error_short = true
            return 
            } else
            
        {
        this.has_error_short = false      
     
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})')
        if(strongPassword.test(this.password)) {
            this.has_error = false
            }
            else{
            this.has_error = true
            }
        }     
    },

        resetPassword() {

            if(this.password == '' || this.password != this.password_confirmation){
                this.has_error_match = true
                return
            } else {

        
            axios({
							method: 'post',
                            url: '/api/resetpasswordsubmit',
                            data: {
                                token:this.usertoken,
                                email:this.useremail,
                                password: this.password,
                              }
                            })
                            .then((response) => {
                             
                                if(!response.data.error){

                                    //this.dealer = response.data.dealer
                                    this.$swal({
                                    title: "Password Changed",
                                    text: "Your password has been updated",
                                    icon: "success",
                                    timer: 3000,
                                    showCloseButton: false,
                                    showConfirmButton: false
                                    })
                                    .then(() => {
                                    //console.log(this.dealer)    
                                    this.$router.push({ name: 'systemlogin', params: {dealer:this.dealer.logintitle} }).catch(()=>{})
                                    }) 
                                } else {
                                    this.$swal({
                                    title: "Error",
                                    text: response.data.message,
                                    icon: "error",
                                    timer: 3000,
                                    showCloseButton: false,
                                    showConfirmButton: false
                                    })
                                    }
                            })
                            .catch(err => alert(err))
        }

        }

    
    }
}
</script>