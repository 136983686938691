<template>
<div>
    <b-modal ref="email-monitor" title="Email Diag" size="lg" hide-footer centered content-class="darkmodal">
    
     <!-- sysadmin email diagnosis show -->
    
   <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-4">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-6">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row> 

   <b-row v-if="emailsent">
         <b-col class="col-12 mt-5">
        <b-button variant="outline-success"  block @click="finish()">Finish</b-button>
       </b-col>   
   </b-row>          

</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
	
</div> 
</template>


<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    export default {
    props:['sendmail','emailid','emailto','subid','id','companyid','pw','showdiag'], //  Type xxx    // Email id 1 - Welcome
    name:'SendEmail',
    components:{
            ErrorLogging
        },
    data(){
		return {
            token:'',
            stagemessages:[],
            emailsent:false,
            errorresponse:{},
            };
        },
	
    created(){
            this.token = this.$store.getters.token
    },

    watch:{
            sendmail: function () {
                this.sendEmail()
            }
       },
       
    methods: {   

        sendEmail(){
                    if(this.showdiag == true){this.$refs['email-monitor'].show()}

                    axios({
                        method: 'post',   
                        headers: {"Authorization" : "Bearer " + this.token},
                        url: '/api/newsendmailrod',
                        data: {
                                userid:this.$store.getters.user.id,
                                token:this.token,
                                emailid:this.emailid,           
                                useremail:this.emailto, 
                                sub:this.subid,
                                reseller:this.$store.getters.resellerid,
                                dealer:this.$store.getters.dealerid,
                                user:this.$store.getters.user.id,
                                pw:this.pw,
                                id:this.id // Dealer or company ID
                        }
                        })
                            .then((response)=> {

                                this.stagemessages = response.data.messages

                                if(response.data.code == 999){
                                    console.log(response.data)
                                    alert('except test')
                                    //this.errorresponse = response.data
                                  } else {
                                    this.emailsent = !response.data.error
                                    this.$emit('emailsent',{sent:this.emailsent,messages:this.stagemessages})  
                                }

                            })     
            },

            finish(){
                this.$refs['email-monitor'].hide()
                this.$emit('emailfinish')
            }

            }
	}
</script> 