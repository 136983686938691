<template>
<div class="imageBorder">

    <CasterLoaderGettingData :dataupdate="dataupdate"/>

        <b-row class="mt-1" v-if="warningmessage != ''">
            <b-col class="col-12">
                <b-button variant="danger" block disabled>{{ warningmessage }}</b-button>
            </b-col>
        </b-row>

        <b-row >

          <b-col class="col-12 col-sm-6">
            
            <b-row>

            <b-col class="col-6">Username</b-col>
            <b-col class="col-6">{{ casta.Username }}</b-col>
            <b-col class="col-6">Password</b-col>
            <b-col class="col-6">{{ casta.Password }}</b-col>
            <b-col class="col-6">Expiry_date</b-col>
            <b-col class="col-6" >{{ casta.Expiry_date }}</b-col>
            <b-col class="col-6">Allowed</b-col>
            <b-col class="col-6" >{{ casta.Allowed }}</b-col>
            <b-col class="col-6">Needs GGA</b-col>
            <b-col class="col-6" >{{ casta.Needs_GGA }}</b-col>
            <b-col class="col-6">Disconnect on change base</b-col>
            <b-col class="col-6" >{{ casta.Disconnect_on_change_base }}</b-col>
            <b-col class="col-6">Manual position only</b-col>
            <b-col class="col-6" >{{ casta.Manual_position_only }}</b-col>
            <b-col class="col-6">Ignore initial GGA</b-col>
            <b-col class="col-6" >{{ casta.Ignore_initial_GGA }}</b-col>
            <b-col class="col-6">Test user</b-col>
            <b-col class="col-6" >{{ casta.Test_user }}</b-col>
           

            </b-row>

        </b-col>
        
        <b-col class="col-12 col-sm-6">
            <b-row>
            <b-col class="col-6">In use</b-col>
            <b-col class="col-6" >{{ casta.In_use }}</b-col>
            <b-col class="col-6">Last RTK status</b-col>
            <b-col class="col-6" >{{ casta.Last_status }}</b-col>
            <b-col class="col-6">Current session id</b-col>
            <b-col class="col-6" >{{ casta.Current_id }}</b-col>
            <b-col class="col-6">Session starttime</b-col>
            <b-col class="col-6" >{{ casta.Session_start_time }}</b-col>
            <b-col class="col-6">Last_lat</b-col>
            <b-col class="col-6" >{{ casta.Last_lat }}</b-col>
            <b-col class="col-6">Last_lon</b-col>
            <b-col class="col-6" >{{ casta.Last_lon }}</b-col>
            <b-col class="col-6" v-if="casta.Home_lat != null">Home_lat</b-col>
            <b-col class="col-6" v-if="casta.Home_lat != null">{{ casta.Home_lat }}</b-col>
            <b-col class="col-6" v-if="casta.Home_long != null">Home_long</b-col>
            <b-col class="col-6" v-if="casta.Home_long != null">{{ casta.Home_long }}</b-col>
            
            
            <b-col class="col-6">Base threshold</b-col>
            <b-col class="col-6" v-if="casta.Change_base_threshold == null">1.1</b-col>
            <b-col class="col-6" v-else>{{ casta.Change_base_threshold }}</b-col>

            <b-col class="col-6">Rover Casta</b-col>
            <b-col class="col-6" >{{ casta.casta}}</b-col>

            <b-col class="col-6">Cloudbase Rover id</b-col>
            <b-col class="col-6" >{{ casta.Cloudbase_rover_id}}</b-col>
            
            </b-row>

        </b-col>
        
        </b-row>

        <b-row>
            <b-col class="col-12 col-md-6 mt-3">
                <b-button variant="outline-success" block @click.stop="editCasta()">Edit Casta subscription</b-button>
            </b-col>

            <b-col class="col-12 col-md-6 mt-3">
                <label style="margin-right:10px;top:-5px;position: relative;">Auto refresh</label>
                    
                    <b-form-checkbox
                    inline
                    v-model="auto"
                    size="md"
                    value=1
                    unchecked-value=0
                    @change="fetchCasta()"
                    />  
            </b-col>     
        </b-row>

        <b-modal ref="edit-casta" @hidden="modalClose()" size="md" title="Edit Casta Subscription"  hide-footer centered content-class="darkmodal">
            <b-row>
                <b-col class="col-12 col-sm-6">
                <b-row>

                <b-col class="col-12 mt-3">
                    <label>Username</label>
                    <b-form-input 
                        type="search" 
                        placeholder="" 
                        autocomplete="off"
                        v-model="castaedit.Username"
                        class="darkinput"
                        :disabled="role != 1"
                />
                </b-col>

                <b-col class="col-12 mt-3">
                    <label>Password</label>
                    <b-form-input 
                        type="search" 
                        placeholder="" 
                        autocomplete="off"
                        v-model="castaedit.Password"
                        class="darkinput"
                        :disabled="role != 1"
                />
                </b-col>

                <b-col class="col-12 mt-3">
                    <label>Expiry Date</label>
                    <VueCtkDateTimePicker
                            v-model="datesel1"
                            format='DD-MM-YYYY HH:mm'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="datesel1" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput"
                                name="datesel1"
                                v-validate="{required:true}"
                                :class="{'b-form-select': true, 'is-danger': errors.has('datesel1') }"
                                :disabled="role != 1"
                                />
                            </VueCtkDateTimePicker>    
                </b-col> 

                <b-col class="col-12 mt-3">
                    <label>Allowed</label>
                        <b-form-select
                            v-model="castaedit.Allowed"
                            :options="options"
                            :disabled="role != 1"
                        /> 
                </b-col>

                <b-col class="col-12 mt-3">
                    <label>Test User</label>
                        <b-form-select
                            v-model="castaedit.Test_user"
                            :options="options"
                            :disabled="role != 1"
                        /> 
                </b-col>

                <b-col class="col-12 mt-3">
                    <label>Rover Casta</label>
                        <b-form-select
                            v-model="castaedit.castaid"
                            :options="castaoptions"
                            :disabled="role != 1"
                        /> 
                </b-col>

                </b-row>
                </b-col>

                <b-col class="col-12 col-sm-6">
                <b-row>

                <b-col class="col-12 mt-3">
                    <label>Needs GGA</label>
                        <b-form-select
                            v-model="castaedit.Needs_GGA"
                            :options="options"
                            :disabled="adminlock"
                        /> 
                </b-col>    

                <b-col class="col-12 mt-3">
                    <label>Disconnect on change base</label>
                        <b-form-select
                            v-model="castaedit.Disconnect_on_change_base"
                            :options="options"
                            :disabled="adminlock"
                        /> 
                </b-col>

                <b-col class="col-12 mt-3">
                    <label>Manual position only</label>
                        <b-form-select
                            v-model="castaedit.Manual_position_only"
                            :options="options"
                            :disabled="adminlock"
                        /> 
                </b-col>

                <b-col class="col-12 mt-3">
                    <label>Ignore initial GGA</label>
                        <b-form-select
                            v-model="castaedit.Ignore_initial_GGA"
                            :options="options"
                            :disabled="adminlock"
                        /> 
                </b-col>

                <b-col class="col-12 mt-3">
                    <label>Base threshold</label>
                        <b-form-input
                                type="search"
                                v-model="castaedit.Change_base_threshold"
                                autocomplete = "off"
                                placeholder="1.1"
                                class="darkinput"
                                :disabled="adminlock"
                        /> 
                </b-col>


                </b-row>
                </b-col>

            </b-row>

            <b-row class="mt-5">
                <b-col class="col-12" v-if="!adminlock">
                    <b-button variant="outline-success" block @click.stop="updateCasta()">Update Casta subscription</b-button>
                </b-col>
                <b-col class="col-12" v-else>
                    <b-button variant="danger" disabled block>Account cannot change Casta subscription</b-button>
                </b-col>
            </b-row>

        </b-modal> 



        <b-modal ref="update-casta" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
        <b-row v-for="message in stagemessages " :key="message.id">
                <b-col class="col-12 col-sm-5">
                    {{ message.message }}
            </b-col>
            <b-col class="col-12 col-sm-5">
                    {{ message.text }}
            </b-col>
            <b-col class="col-12 col-sm-2 text-right">
                    <b-icon-check-square v-if="message.error == false" />
                    <b-icon-x-square v-else />
            </b-col>
        </b-row> 

        <b-row>
                <b-col class="col-12 mt-5">
                <b-button variant="outline-success" @click.stop="finish()" block>Finish</b-button>
            </b-col>   
        </b-row>          

        </b-modal>  
   

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
	
</div> 
</template>

<script>
    import CasterLoaderGettingData from './CasterLoaderGettingData'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
    export default {
    props:['id'],    
    name:'CasterRoverNTRIPCastaDetails',
    components:{
        CasterLoaderGettingData,
        ErrorLogging
        },
    data(){
		return {
                casta:{
                    Username:'-',
                    Title:'-',
                    Password:'-',
                    Expiry_date:'-',
                    In_use:'-',
                    Allowed:'-',
                    Change_base_threshold:'-',
                    Disconnect_on_change_base:'-',
                    Home_lat:'-',
                    Home_long:'-',
                    Last_lat:'-',
                    Last_long:'-',
                    Ignore_initial_GGA:'-',
                    Last_status:'-',
                    Manual_position_only:'-',
                    Needs_GGA:'-',
                    Session_start_time:'-',
                    Test_user:'-',
                    castaid:0,
                    casta:''
                },
                castaedit:{},
                options:[
                    {value:1,text:'Yes'},
                    {value:0,text:'No'},
                    {value:null,text:'null'}
                ],
                castaoptions:[
                    {value:1,text:'UK'},
                    {value:2,text:'Denmark'}
                ],
                stagemessages:[],
                modaltitle:'',
                warningmessage:'',
                timeout:false,
                dataupdate:false,
                datesel1:'',
                auto:0,
                role:0,
                adminlock:true,
                errorresponse:{}
            }
        },

	mounted(){
            this.role = this.$store.getters.roleid
            if(this.role < 10 || this.$store.getters.change_username == true){this.adminlock = false}
            this.fetchCasta()
       },

    beforeDestroy () {
        clearInterval(this.timeout)
    },
    
    watch:{
          auto: function() {
            if(this.auto == 1){
                this.timeout = setInterval(this.fetchCasta,3000)
            } else clearInterval(this.timeout)
          }
    },
    
    methods: {

            fetchCasta(){
               
                this.dataupdate = !this.dataupdate
                
                axios.get('/api/getrovercastavalues',{
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        roverid:this.id
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                            this.errorresponse = response.data
                    } else {
                            if(response.data.error){
                                this.warningmessage = response.data.message
                            } else {
                                this.casta = response.data.casta[0]
                                this.datesel1 = this.formatDate2(this.casta.Expiry_date)
                                this.casta.Expiry_date = this.formatDate(this.casta.Expiry_date)
                                this.casta.Session_start_time = this.formatDate(this.casta.Session_start_time)
                                this.casta.Title = this.casta.Username
                            }
                        }
                
                })
            },

            editCasta(){
                clearInterval(this.timeout)
                this.castaedit = JSON.parse(JSON.stringify(this.casta)) // decouple object
                this.$refs['edit-casta'].show()
            },

            updateCasta(){
                this.modaltitle = 'Updating '+this.castaedit.Title
                this.$refs['update-casta'].show()
                let date = moment(this.datesel1, "DD-MM-YYYY HH:mm")
                date =  moment(date).format('YYYY-MM-DD HH:mm:ss') 
                this.castaedit.Expiry_date = date

                axios({
                                method: 'put',   
                                headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                                url: '/api/updaterovercastavalues',
                                data: {
                                     userid:this.$store.getters.user.id,
                                     token:this.$store.getters.user.token,  
                                     data:this.castaedit
                                    }
                                })
                            .then((response)=> {
                                if(response.data.code == 999){
                                    this.stagemessages = response.data.messages
                                    this.errorresponse = response.data
                            } else this.stagemessages = response.data.messages
                            
                            });
            },

            finish(){
                this.$refs['update-casta'].hide()
                this.$refs['edit-casta'].hide()
                this.$emit('updatemodal')
            },

            modalClose(){
                this.timeout = false // restart casta get data
                this.fetchCasta()
            },

            formatDate(timedate) {
               if(timedate == null){return} 
               return moment(timedate).format('DD MMM y HH:mm')   
            },

            formatDate2(timedate) {
                return moment(timedate).format('DD-MM-YYYY HH:mm')   
            },
           

        }
	}
</script> 