<template>
<div>

<b-row>

<b-col class="col-12 col-md-6 col-lg-3 mt-3">
    <label>RTK Fix Status</label>
    <b-form-select
                v-model="statussel"
                :options="statustypes"
                class="darkinput2"
                size="sm"
                @change = "setStatus" 
                />
</b-col>

<b-col class="col-2 mt-3">
        <b-button variant="outline-success" size="sm" @click="resetSearch()" style="margin-top:32px"> reset search </b-button>
    </b-col>

</b-row>

<b-row class="mt-3">
    <b-col class="col-12 col-md-5 mt-3" v-if="total > limit">1 - {{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-12 col-md-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>
</b-row>    


<b-row class="mt-3 mb-5" >

      <b-col>
             <b-table
                class ="nowrap"
                ref = "rovers-table" 
                responsive 
                hover 
                sticky-header="600px"
                :items="displayrovers" 
                :fields="conditionalFields"
                @row-clicked="showRover($event)"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @sort-changed="onSortChanged"
                :busy="isBusy"
                sort-icon-left
                dark
                >
                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteRover(data.item.value)" class="mt-1 mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <!-- <b-button size="sm" @click="editRover(data.item)" class="mt-1 mr-2 xsbutton" variant="outline-success" v-if="role=1">
                        <b-icon-pencil />
                    </b-button> -->
                </template>    

                </b-table>
            
            <b-button v-if="more" variant="outline-success" block @click.stop="showMore()">Show more results?</b-button>
            <b-button v-if="loadingmore" variant="outline-warning" disabled block>Loading results</b-button>
</b-col>
</b-row>	

<b-modal v-model="isBusy" hide-header hide-footer centered content-class="loadingmodal">
        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <b-spinner variant="success" class="mr-2"/>
                <h5>loading results</h5>
            </b-col>
        </b-row> 
</b-modal> 

<b-modal ref="delete-rover" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterRoverDelete
    :id="roversel" 
    v-on:closemodal="closemodal()"
    v-on:updatemodal="updatemodal()"
    />
</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style scoped>
.nowrap td {
    white-space: nowrap !important;
}

.modal-content{
    border:none !important;
}
</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
    export default {
        props:['resellerid','dealerid','companyid','roverid','clearsearch','stext','status','refresh'],
        name:'CasterRoversTable2',
        components:{
            ErrorLogging
        },
        data(){
            return {
                test:true,
                id:0,
                token:'',
                rovers:[],
                displayrovers:[],
                roversel:0,
                statussel:0,
                statustypes:[
                    { value:0, text:'All'},
                    { value:4, text:'Fix'},
                    { value:5, text:'Float'},
                    { value:2, text:'DGPS'},
                    { value:1, text:'GPS'},
                    { value:6, text:'EST'},
                    { value:9, text:'Connected'},
                ],
                orderby:'rover',
                sortdesc:false,
                isBusy:false,
                display:false,
                errorresponse:{},
                nosubs:false,
                searchtext:'',
                page:0,
                limit:50,
                more:false,
                loadingmore:false,
                total:0,
                rstart:1,
                rend:0,
                role:0,
                fields:[
                    { key: 'text', label: 'Rover', sortable: true  },
                    { key: 'resellername', label: 'Reseller', sortable: true  },  
                    { key: 'dealername', label: 'Dealer', sortable: true  },    
                    { key: 'companyname', label: 'Company', sortable: true  },
                    { key: 'statustext', label: 'RTK Status', sortable: true  },
                    { key: 'lastconnect', label: 'Last Connect', formatter: 'formatDate', sortable: false  },   
                    { key: 'subscription[0].status', label: 'Subscription',  sortable: false  },
                    { key: 'subscription[0].subscription[0].text', label: 'Type', sortable: false  },
                    { key: 'subscription[0].enddate2', label: 'Expire', formatter: 'formatDate',sortable: false  },
                    { key: 'subscription[0].nosimm', label: 'Sim', formatter: 'formatNoSim', sortable: false  },
                    { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                    ],
                observer: null,
                modaltitle:'',
                filtertype:1 // Rover RTK Status
                };
                
                },

		created(){
            this.role = this.$store.getters.roleid
            this.token = this.$store.getters.token

            if(this.$store.getters.prevroute == 'casterrovermanage'){
                this.searchtext = this.$store.getters.searchtext
            } else {
                this.$store.commit('setprevroute',this.$route.name)
                this.searchtext = ''
                this.$store.commit('setsearchtext','')
                }

            this.statussel = this.getTableFilter(this.filtertype)
            this.fetchRovers()
        },

        mounted(){
            this.addTableEventList()
        },

        computed: {
            
            filterdata() {
                return { ...this.resellerid, ...this.dealerid, ...this.companyid, ...this.roverid };
            },

            conditionalFields() {

                if(this.resellerid > 0 && this.dealerid == 0 && this.companyid == 0){
                    return this.fields.filter(field => field.key !== 'resellername')
                } 

                if(this.dealerid > 0 && this.companyid == 0){
                    return this.fields.filter(field => field.key !== 'dealername' && field.key !== 'resellername')
                }

                if(this.companyid > 0 && this.roverid == 0){
                    return this.fields.filter(field => field.key !== 'companyname' && field.key !== 'dealername' && field.key !== 'resellername')
                }

                return this.fields

                }
        },

         watch: {
                    filterdata: function () {
                        this.page = 0
                        this.fetchRovers()
                    },

                    refresh: function () {
                        this.page = 0
                        this.fetchRovers()
                    },

                    clearsearch: function () {
                        this.page = 0
                        this.fetchRovers()
                    },
                   
                    stext: function () {
                        if(this.stext !== this.searchtext){
                            this.searchtext = this.stext
                            this.page = 0
                            this.fetchRovers()
                        }
                         },
                         
                    rovers: function() {
                        if(this.page > 0){
                            this.readdTableEventList
                        }
                    }
        }, 
        
		methods: {

            deleteRover(id){
               this.roversel = id
               this.modaltitle = 'Delete Rover'
               this.$refs['delete-rover'].show()
           },    
           
            showRover(event){
               this.$router.push({ name: 'casterrovermanage', params: {id:event.value} }).catch(()=>{})
            },

            setStatus(){
                this.page = 0
                this.storeTableFilter(this.statussel,this.filtertype)
                this.fetchRovers()
            },

            storeTableFilter(value,type){
                let searchvalues = this.$store.getters.searchvalues2.filter(value=>value.type != type) // clear previous value
                searchvalues.push({type:type,value:value})
                this.$store.commit('setsearchvalues2',searchvalues)
          },

            getTableFilter(type){
                let searchvalues = this.$store.getters.searchvalues2.filter(value=>value.type == type)
                if(searchvalues.length > 0){
                    return searchvalues[0].value
                } else return 0
            },

        
            fetchRovers(){
                this.isBusy = true
                axios.get('/api/companyroverstestrod',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.token,
                        roleid:this.role,
                        reseller:this.resellerid,
                        dealer:this.dealerid,
                        company:this.companyid,
                        rover:this.roverid,
                        status:this.statussel,
                        stext:this.searchtext,
                        page:this.page,
                        start:this.start,
                        end:this.end,
                        limit:this.limit,
                        orderby:this.orderby,
                        sortdesc:this.sortdesc
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        } else {
                                this.more = false
                                this.loadingmore = false 
                                if(this.page == 0){
                                    this.rovers = response.data.rovers
                                } else this.rovers.push(...response.data.rovers)

                                this.displayrovers = this.rovers

                                this.updatePaging()
                                
                                this.total = response.data.total
                                if(this.total < this.limit){this.more = false}

                                if(this.total != 0 && this.stext != ''){
                                    this.$emit('fetchresponse',this.stext)
                                }

                                this.isBusy = false
                                this.display = true
                        }
                    
                    });
			},

            updatePaging(){
                if(this.page == 0){
                    this.rstart = 1
                    this.rend = this.limit
                } else {
                this.rend = ((this.page + 1) * this.limit)
                if(this.rend > this.total){this.rend = this.total}
                } 
            },

            addTableEventList(){
                const tableScrollBody = this.$refs["rovers-table"].$el
                tableScrollBody.addEventListener("scroll", this.onScroll)
            },

            removeTableEventList(){
                const tableScrollBody = this.$refs["rovers-table"].$el
                tableScrollBody.removeEventListener("scroll", this.onScroll)
            },

            readdTableEventList(){
                this.$nextTick(() => {    
                    this.removeTableEventList()
                    this.addTableEventList()
                })    
            },

            showMore(){
                this.page++
                this.more = false
                this.loadingmore = true
                this.fetchRovers()
            },

            onScroll(event) {
                if (
                    event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 200
                ) {
                    if (!this.isBusy) {
                        if(this.total > this.limit){
                            this.more = true
                        } else this.removeTableEventList()
                    }
                }
            },

            // handle column sorting events to only call API when there is more results
            onSortChanged(event){
                if(this.total > this.limit && this.total !== this.rend){
                    this.orderby = event.sortBy
                    this.sortdesc = event.sortDesc
                    this.fetchRovers()
                }
            },

            setDefaultSort(){
                if(this.resellerid > 0){this.orderby = 'dealer'}
                this.fetchRovers()
            },

            resetSearch(){
                this.searchtext = ''
                this.$store.commit('setsearchtext','')
                this.fetchRovers()
                this.statussel = 0
                this.$emit('clearstext')
            },

            formatDate(timedate) {
                let date =  moment(timedate).format('DD-MM-YY HH:mm') 
                return date
            },

            formatNoSim(nosimm){
                if(nosimm == 1){return '-'} else return 'Y'
            }
        }	
    };

</script>