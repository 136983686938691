<template>
<div>
 <b-container class="mainpagecont">

<b-row class="mt-5 pt-3">

        <b-col class="col-6">
            <h4>Caster GGA Messages</h4>
        </b-col>

        <b-col class="col-3 offset-3">
            <b-button variant="info" block size="sm" @click="autoRefresh()" v-if="auto"><b-icon-clock class="icon" />  AUTO REFRESH</b-button>
            <b-button variant="outline-info" size="sm" block  @click="autoRefresh()" v-else><b-icon-clock class="icon" />  AUTO REFRESH</b-button>      
        </b-col>               

 </b-row>  


<b-row class="mt-5"> 

        <b-col class="col-3">
                <label>Username</label>
                  <CasterSearch :setsearchtext="usernamesel" v-on:textsearch=updateSearch v-on:clearsearch=clearSearch />
                <div class="choiceholder" v-if="showrovers">
                        <ul class="makerlist">
                        <li v-for="rover in rovers" :key="rover.value" @click="roverSel(rover)">{{ rover.text }}</li>
                        </ul>
                    </div>
                </b-col>  

        <b-col class="col-3">
                        <label>Start Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterstart"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterstart" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2"
                                size="sm" 
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

            <b-col class="col-3">
                        <label>End Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterend"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterend" 
                                autocomplete = "off"
                                placeholder="select" 
                                class="darkinput2"
                                size="sm" 
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

            <b-col class="col-2" style="margin-top:30px">
                <b-button variant="outline-success" size="sm"  @click="filterDates()">filter dates</b-button>
                <b-icon-x-circle style="margin-left:10px;font-size:1.6em" v-if="filterstart !== ''"  @click="clearDates()"  /> 
            </b-col>       

</b-row>              

<b-row class="mt-3 mb-3" >
     <b-col class="col-6">
            <strong v-if="isBusy">Loading...</strong>
     </b-col>

     <b-col class="col-6 text-right">
         {{ requests.length }} Results
     </b-col> 

      <b-col>
             <b-table 
                striped 
                responsive 
                hover 
				:items="requests" 
                :fields="fields"
                tbody-tr-class="rowWClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :sort-by.sync="sortBy"
                :sort-desc="sortDesc"
                dark
                fixed
            
                >
        
                </b-table>
       </b-col>             
</b-row> 


                        

</b-container>
		
</div> 
</template>

<style scoped>
.table-dark td{
    word-break: normal !important;
}
</style>

<script>
    import CasterSearch from './CasterSearch'
    import moment from 'moment'
    import axios from 'axios'
	export default {
        name:'CasterLogGGA',
        components:{
            CasterSearch
        }, 
        data(){
            return {
    
                token:'',
                requests:[],
                rovers:[],
                showrovers:false,
                usernamesel: null,
                auto:false,
                polldata:null,
                currentmessage:0,
                filterstart:'',
                filterend:'',
                fields:[
                { key: 'Timestamp', label: 'Date/Time', formatter:'setTime', sortable: true, style:'break' },
                { key: 'Username', label: 'Name', sortable: true },
                { key: 'Basestation', label: 'Reference Station', sortable: true },
                { key: 'Session_id', label: 'Session id', sortable: true },
                { key: 'Distance', label: 'Distance', formatter: 'formatDistance', sortable: true },
                { key: 'User_agent', label: 'User Agent', sortable: true },
                { key: 'Bytes_sent', label: 'Bytes Sent', formatter: 'formatData', sortable: true },
                { key: 'GGA', label: 'Message', sortable: false },
               ],
                sortBy: 'value',
                sortDesc: false,
                isBusy: false,
                timeout:false
                };
                
                },

        mounted(){
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setbasesdatapoll',0)
        },
        
        beforeDestroy () {
            clearInterval(this.timeout)
        },

		created(){
            this.token = this.$store.getters.token
            this.fetchRequests()
        },

		methods: {

            fetchRequests(){

				this.isBusy = true
				axios.get('/api/casterrequests',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                        rover:this.usernamesel,
                        start:this.filterstart,
                        end:this.filterend
                         }
                })
				.then((response) => {
                    this.requests = response.data
                    this.isBusy = false
                    if(this.auto == true){
                                clearTimeout(this.timeout)
                                this.timeout = setTimeout(this.fetchRequests, 5000)
                            }
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });

			},

           fetchRovers(){
                
				axios.get('/api/indexrovers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                        username:this.usernamesel
                    }
                })
				.then((response) => {
                    this.rovers = response.data.rovers
                    if(this.rovers.length > 0){this.showrovers = true} else this.showrovers = false
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

           roverSel(rover){
                this.usernamesel = rover.username
                this.showrovers = false
                this.timeout = false
                this.fetchRequests()
           }, 
            
           updateSearch(data){
                this.usernamesel = data
                this.fetchRovers()
           }, 
           
           clearSearch(){
                this.usernamesel = null
                this.rovers = []
                this.showrovers = false
                this.timeout = false
                this.fetchRequests()
           },

           filterDates(){
                this.timeout = false
                this.fetchRequests()
           },

            clearDates(){
                this.timeout = false
                this.filterstart = ''
                this.filterend = ''
                this.fetchRequests()
           },
          
            autoRefresh(){
                this.auto = !this.auto
                 if(this.auto == true){
                    this.fetchRequests()
                    } else {
                        clearTimeout(this.timeout)
                        }
            },

            formatDistance(distance){
                return (distance/1000).toFixed(1)+' km'
            },

            formatData(data){
                return (data/1024).toFixed(0)+' kB'
            },

            setTime(datetime){
                return  moment.utc(datetime).local().format('DD-MM-YY HH:mm:ss') 
            },

		}	   
	
	};

</script> 