<template>
<div class="imageBorder">

        <h3>Not in use</h3>
        <!-- <b-row v-if="display">

             <b-col class="col-4 mt-3"></b-col>

             <b-col class="col-4 mt-3 text-center">
                {{ timeperiod }}
            </b-col>             
            <b-col class="col-4 mt-3 text-right">
               <b-button size="sm" variant="outline-success" @click="setPeriod('week')">WEEK</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('month')">MONTH</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('year')">YEAR</b-button>

            </b-col>            
            <b-col class="col-12 mt-3">
                 <BarChartSingle :chartdata="data" :height="200" />
            </b-col>

        </b-row>

         <b-row class="mt-3" v-else>
                <b-col class="col-12 text-center pt-3 pb-3">
                        <h5 style="color:#48A1BA;">No Data</h5>
                </b-col>   
        </b-row> -->
	
</div>
</template>

<script>
    import axios from 'axios'
    import moment from 'moment'
   // import BarChartSingle from './BarChartSingle'
	export default {
        name:'CasterSubsChartCard',
        data(){
		return {
                data:[],
                token:'',
                display:false,
                dealer:0,
                period:3600, // 1 hour
                timeout:'',
                timeperiod:'',
                start:'',
                end:'',
                steps:0,
                activepages:['casterstatssubs']
               };
                
                },

	created(){
                // this.token = this.$store.getters.token
                // this.dealer = this.$store.getters.dealerid
                // this.setPeriod('week')
             },

	methods: {

                getNetworkLogins(){

                     axios.get('/api/subsstats',{
                        headers: {"Authorization" : "Bearer " + this.token},
                        params:{
                                userid:this.$store.getters.user.id,
                                token:this.$store.getters.user.token,
                                start:this.start,
                                end:this.end,
                                steps:this.steps,
                                dealer:this.dealer
                         }
                        })
			.then((response) => {

                    if(response.data.error == 'Unauthorized'){    
                     
                      this.$swal({
                            title: "System Error",
                            text: response.data.error,
                            icon: "error",
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                   }
                   
                   else {
                           this.data = response.data
                           this.display = true
                           if(this.activepages.includes(this.$route.name)){
                                this.timeout = setTimeout(this.getNetworkLogins, 300000) // poll every 5 mins
                            } else clearTimeout(this.timeout)
                        }

                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            })
                    });
                },
                
                setPeriod(value){
                    
                    switch(value){
                        case 'day':
                        this.start = moment().format("YYYY-MM-DD")
                        this.end = moment().format("YYYY-MM-DD")    
                        this.timeperiod = moment().format("DD-MM-YYYY")
                        this.steps = 24
                        break;
                        case 'week':
                        this.start = moment().startOf('isoweek').format("YYYY-MM-DD")
                        this.end = moment().endOf('isoweek').format("YYYY-MM-DD")
                        this.timeperiod = moment().startOf('isoweek').format("DD-MM-YYYY")+' - '+moment().endOf('isoweek').format("DD-MM-YYYY")
                        this.steps = 7
                        break;
                        case 'month':
                        this.start = moment().startOf('month').format("YYYY-MM-DD")
                        this.end = moment().endOf('month').format("YYYY-MM-DD")
                        this.timeperiod =  moment().startOf('month').format("DD-MM-YYYY")+' - '+ moment().endOf('month').format("DD-MM-YYYY")
                        this.steps = moment().daysInMonth() 
                        break;
                        case 'year':
                        this.start = moment().startOf('year').format("YYYY-MM-DD")
                        this.end = moment().endOf('year').format("YYYY-MM-DD")
                        this.timeperiod =  moment().startOf('year').format("DD-MM-YYYY")+' - '+ moment().endOf('year').format("DD-MM-YYYY")
                        this.steps = 12
                        break;                        
                    }    
                        this.$emit('periodchange',{start:this.start,end:this.end,period:value})
                        this.getNetworkLogins()    
                }
            }
        };
</script> 