<template>
<div>

<b-container class="mainpagecont">

<b-row class="mb-3">
    <b-col class="col-12 col-md-3 mt-3">
        <h3>Dealers</h3>
    </b-col>

    <b-col class="col-12 col-md-4 offset-md-1 mt-3">
            <CasterSearchSuggestions
            placeholder="search dealers"
            :clearsearch="clearsearch"
            suggestionType = "4"
            :suggestionFetchResponse="fetchResponse" 
            v-on:textsearch=updateSearch 
            v-on:clearsearch=clearFilter
            />
    </b-col>

    <b-col class="col-12 col-md-3 offset-md-1 mt-3 text-right">
        <b-button size="sm" variant="outline-success" block @click="newDealer()">
            <b-icon-plus-circle class="bicon" /> Add Dealer
        </b-button>
    </b-col>

</b-row>      

<CasterOptionsFilter v-if="searchtext == ''"
            :resellerid="resellerid" 
            :dealerid="dealerid" 
            :companyid="companyid"
            :dealersshow="true"
            :companysshow="false"
            :roverid=0
            :clearsearch="clearsearch"
            v-on:resellersel=updateResellerFilter 
            v-on:dealersel=updateDealerFilter  
            />

<b-row class="mt-1 mb-5" >
    <b-col class="col-12">
        <CasterDealersTable2 
            :stext="searchtext"
            :resellerid="resellerid"  
            :dealerid="dealerid" 
            :companyid="companyid"
            :key="componentKey"
            :clearsearch="clearsearch" 
            v-on:clearstext="clearSearch"
            v-on:fetchresponse=setFetchResponse 
            v-on:setdealer="editDealer" 
            />
    </b-col>
</b-row>

<b-modal ref="edit-dealer" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterDealerAdd
    :dealerid="dealerid"
    nosave=1
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    v-on:updatedealer=updatemodal()
    />
</b-modal>

</b-container>
	
</div> 
</template>

<script>
    import CasterSearchSuggestions from './CasterSearchSuggestions'
    import CasterOptionsFilter from './CasterOptionsFilter'
    import CasterDealersTable2 from './CasterDealersTable2'
    import CasterDealerAdd from './CasterDealerAdd'
    export default {
        name:'CasterDealers',
        components:{
            CasterSearchSuggestions,
            CasterOptionsFilter,
            CasterDealersTable2,
            CasterDealerAdd
        }, 
      data(){
        return {
                resellerid:0,
                dealerid:0,
                companyid:0,
                fetchResponse:'',
                clearsearch:true,
                modaltitle:'',
                componentKey:0,
                searchtext:'',
                roleid:0
                };
                
        },

        created(){
            this.roleid = this.$store.getters.roleid // 1 - System Admin 5 - Reseller Admin 10 - Dealer Admin 
            // Are stored search filters use them
            if(this.$store.getters.searchvalues.length > 0){
                switch(this.roleid){
                    case 1:
                    this.resellerid = this.$store.getters.searchvalues[0]
                    this.dealerid = this.$store.getters.searchvalues[1]
                    break;
                    case 5:
                    this.resellerid = this.$store.getters.resellerid
                    this.dealerid = this.$store.getters.searchvalues[1]
                    break;
                    case 10:
                    this.resellerid = this.$store.getters.resellerid
                    this.dealerid = this.$store.getters.dealerid
                    break;
                }
                
            } else this.setDefaults()
            
        },

        mounted(){
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setbasesdatapoll',0)
           
        },

        methods: {

            setDefaults(){ 
                
                 // If system admin set to all Resellers
                 if(this.roleid == 1){
                    this.resellerid = 0 
                    this.dealerid = 0
                } else {
                // All other users go with stored login values    
                this.resellerid = this.$store.getters.resellerid
                this.dealerid = this.$store.getters.dealerid
                }
                
            },

            updateResellerFilter(id){
                this.resellerid = id
                this.dealerid = 0
                this.companyid = 0
            },

            updateDealerFilter(id){
                this.dealerid = id
                this.companyid = 0
            },

            updateSearch(text){
                this.searchtext = text
            },

            setFetchResponse(text){
                this.fetchResponse = text
            },

            clearSearch(){
                this.searchtext = ''
                this.clearsearch = !this.clearsearch
                this.setDefaults()
            },

            clearFilter(){
                this.searchtext = ''
            },

            newDealer(){
                this.dealerid = 0 
                this.modaltitle = 'Add Dealer'
                this.$refs['edit-dealer'].show() 
            },

            editDealer(id){
                this.modaltitle = 'Edit Dealer'
                this.dealerid = id
                this.$refs['edit-dealer'].show() 

            },

            updatemodal(){
                 this.$refs['edit-dealer'].hide() 
                 this.componentKey++
            },

         
            closemodal(){
                this.$refs['edit-dealer'].hide()
            },
    },     

       
	};

</script>