<template>
<div>

<b-container v-if="simms.length > 0 && !gettingdata">

    <b-row class="mt-1">
        <b-col class="col-12 col-sm-8">
        <label>Dealer</label>
        <b-form-select
                    v-model="dealersel"
                    :options="dealers"
                    class="darkinput2" 
                    size="sm"
                    />
        </b-col>
    </b-row>
    
    <b-row class="mt-3">
        <b-col class="col-2">
             select
        </b-col>
         <b-col class="col-5">
             iccid
        </b-col>
        <b-col class="col-5" v-if="role == 1">
             endpoint (sysadmin)
        </b-col>
    </b-row>

             
    <b-row class="mt-1" v-for="(item, index) in simms" :key="item.simId" >
         <b-col class="col-2">
            <b-form-checkbox
                        v-model="simms[index].value"
                        size="lg"
                        value=1
                        unchecked-value=0
                        @change="selectedSim()"
                        /> 
        </b-col>  
        <b-col class="col-5">{{ simms[index].number }}</b-col>
        <b-col class="col-5" v-if="role == 1">{{ simms[index].endpoint }}</b-col>
       
    </b-row> 

    <b-row class="mt-3" v-if="selectwarning">
         <b-col class="col-12 text-center">
            <b-button variant="danger" disabled>{{  warningmessage }}</b-button>
         </b-col>
    </b-row>
   
    <b-row class="mt-5 pt-3 border-top">
         <b-col class="col-12 col-sm-10 offset-1">
            <b-button variant="outline-success"  @click="validateInputs()" block>ADD SELECTED TO DEALER STOCK</b-button>
        </b-col>   

    </b-row> 

</b-container>

<b-container v-if="gettingdata">
     <b-row class="mt-5 mb-5">
         <b-col class="col-12 text-center">
                    <b-spinner class="align-middle"></b-spinner>
                    <h5 style="margin-top:10px"><strong>Getting available sims</strong></h5>
        </b-col>  
      </b-row> 
</b-container>  

<b-container v-if="!gettingdata && simms.length == 0">
     <b-row class="mt-5 mb-5">
         <b-col class="col-12 text-center">
                    <h5 style="margin-top:10px"><strong>No available Sims</strong></h5>
        </b-col>  
      </b-row> 
</b-container> 

<b-modal ref="status-messages" size="lg" :title="dealername" hide-footer centered content-class="darkmodal">
   <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row> 

   <b-row>
         <b-col class="col-12 mt-5">
        <b-button variant="outline-success" @click.stop="finishAdd()" block>Finish</b-button>
       </b-col>   
   </b-row>          

</b-modal>    

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
		
</div> 
</template>

<script>
     import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        props:['reseller','dealer','type'],
        name:'CasterSimmAddBicsNew',
        components:{
            ErrorLogging
        }, 
        data(){
        return {
                token:'',
                simms:[],
                dealers:[],
                dealersel:0,
                dealername:'',
                totalsel:0,
                selectwarning:false,
                gettingdata:false,
                errorresponse:{},
                stagemessages:[],
                status:0,
                role:0,
                warningmessage:''
                };
                
                },

		mounted(){
            this.token = this.$store.getters.token
            this.role = this.$store.getters.roleid
            this.fetchDealers()
            this.fetchSimms()
       },

       watch:{
                dealersel: function() {
                    if(this.dealersel > 0){
                        this.dealername = this.dealers.filter(dealer=>dealer.value == this.dealersel)[0]['text']
                        this.selectwarning = false
                    }
                }
        },         
       
       methods: {

            fetchSimms(){
                this.simms = []
                this.gettingdata = true
				axios.get('/api/castersimms',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.reseller,
                        stock:true,
                        type:this.type  
                    }
                    })
				.then((response) => {
                    if(response.data.code == 999){
                       this.errorresponse = response.data
                      } else {
                        this.simms = response.data.CasterSimmss
                        this.gettingdata = false
                      }
                    })
                    
			},

            fetchDealers(){

                axios.get('/api/casterdealers',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.reseller,
                        list:1
                    }
                })
                .then((response) => {
                    if(response.data.code == 999){
                       this.errorresponse = response.data
                      } else {
                        this.dealers.push({value:0,text:'Select'})
                        this.dealers.push(...response.data.CasterDealers)
                        this.dealersel = this.dealer
                      }
                    })
                    
                },

                validateInputs(){
                    this.selectwarning = false
                    this.warningmessage = ''
                    if(this.totalsel == 0){this.warningmessage = 'No sims selected'}
                    if(this.dealersel == 0){this.warningmessage = 'No dealer selected'}
                    if(this.warningmessage == ''){this.update()} else this.selectwarning = true
                },
             
                update(){

                    this.stagemessages.push({message:'Processing',text:'',error:false})
                    this.$refs['status-messages'].show()   
                  
                    let simms = this.simms.filter(simm=>simm.value == true)
                  

                   axios({ 
                                method: 'post',     
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/castersimmsassigndealer',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    reseller:this.reseller,
                                    dealer:this.dealersel,
                                    dealername:this.dealername,
                                    simms:simms,
                                    }
                                }).then((response) => {

                                if(response.data.code == 999){
                                    this.errorresponse = response.data
                                } else {
                                    console.log(response.data.messages)
                                    if(response.data.messages !== undefined){
                                    this.stagemessages = response.data.messages  
                                    }  else console.log(response.data)
                                }
                    })
                      
              },

              selectedSim(){
                this.totalsel = this.simms.filter(simm=>simm.value == true).length
                if(this.totalsel > 0){this.selectwarning = false}
              },

              finishAdd(){
                    this.$emit('updatemodal')
              }
      
		}	   
	
	};
</script> 