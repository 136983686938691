<template>
<div>

    <h6 v-show="!ready">
        Loading
        <b-icon icon="arrow-clockwise" animation="spin"/>
    </h6>
    <div v-show="ready">
        <Editor
            ref="tinyReadOnly"
            :init="{
                base_url: '/tinymce', 
                suffix: '.min',
                skin_url: `/tinymce/skins/ui/Cloudbase`,
                body_class: 'tinyReadOnlyBody',
                content_css: 'CloudbaseReadOnlyDisplay',
                plugins: 'link lists',
                toolbar: false,
                menubar: false,
                statusbar:false,
                license_key: 'gpl',


                setup(editor) {
                    editor.on('init', function() {
                        editor.mode.set('readonly');
                        editorContainer = editor.getContainer();
                        editor.getContainer().classList.add('tinyReadOnlyContainer');
                        editor.getContainer().style.maxHeight = '50px';
                        editorIframe = editor.iframeElement.contentDocument;
                        editorInitalised = true;
                    });
                }, 
            }"
            v-model="content"
        ></Editor>
    </div>

</div>
</template>

<style>
/* note styling for the text window is its own html page and has its own css page, as well as a skin 
ie to change the background color change it in body within: /skins/content/CloudbaseReadOnlyDisplay/content.min.css
I to overwite some aspects ive gotten the specific selectors needed
*/
.tinyReadOnlyContainer{
    transition: max-height 0.5s ease-in-out;
}

.tox-tinymce.tinyReadOnlyContainer{
    border: none !important;
}
.tox .tox-edit-area__iframe.tinyReadOnly{
    background-color: rgba(0, 0, 0, 0) !important;
}

</style>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
    name:'TinyReadOnlyDisplay',
    components:{Editor},
    props:{
        value:String,
        minHeight:[String,Number],
        maxHeight:[String,Number],
        id:Number,
        hoverID:Number,
    },
    data() {
        return {
            editorContainer:null,
            editorInitalised:false,
            content:"",
            domObserver:null,
            ready:false,

            usedMaxHeight:"400",
            editorIframe:null,
        }
    },
    created(){
        this.content = '<pre>' + this.value + '</pre>';
        this.observeDOM(); //need to wait till DOM has finished adding "tiny" elements, mounted doesnt work.
        setTimeout( () => { //have a backup timeout for ready, it's just for a cleaner loading experience.
            this.endObserver;
            this.ready = true; 
        },(1000*10)); 
        this.usedMaxHeight = this.maxHeight;
    },
    watch:{
        value(newVal){
            this.content = '<pre>' + newVal + '</pre>'
        },
        ready(state){
            if(!state)return;
            setTimeout( () => {
                this.scrollContent();    
            },100)
        },
        hoverID(value){
            if(value !== this.id || value === null){
                this.editorContainer.style.maxHeight = this.minHeight + 'px';
                return
            }
            this.editorContainer.style.maxHeight = this.maxHeight + 'px';
        }
    },
    methods:{
        observeDOM(){
            this.domObserver = new MutationObserver( () => {
                this.readyCheck();
            })
            this.domObserver.observe(document.body, {childList:true, subtree:true})
        },

        readyCheck(){
            //get iframe within tiny editor, which contains the template
            if(!this.$refs.tinyReadOnly?.element?.nextElementSibling)return;
            const iframe = this.$refs.tinyReadOnly?.element?.nextElementSibling?.querySelector('iframe'); 

            //get content from within iframe
            if(!iframe?.contentDocument?.body) return;
            this.bodyTest = iframe.contentDocument
            const iframeBody = iframe.contentDocument.body

            if(!iframeBody?.firstChild || iframeBody.firstChild?.innerHTML?.length < 1)return;

            //set ready
            this.ready = true;    
        },

        endObserver(){
            this.domObserver.disconnect();
        },

        scrollContent(){
            //scrolling conten passed "dear customer" to be a little more useful
            const scrollValue = 40;
            this.editorIframe.documentElement.scrollTop = scrollValue;
            this.editorIframe.body.scrollTop = scrollValue;
        }

    }  
} 
</script>