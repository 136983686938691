<template>
<div>
<b-container>

<transition name="slide"> 

   <div v-show="isPanelOpen" class="sidebar-panel" :style="{backgroundColor:color}">

    <b-row>
        <b-col class="col-12">  
            <h6>SYSTEM ADMIN</h6> 
             
        </b-col>
    </b-row>        


    <b-row class="mt-2">

        <b-col class="col-12">  
            
    <ul class='nav'>
            <li v-bind:class="[{ submenuactive: route === 'casteradminhome'},'dropdown_item-1']" @click="menuLink('casteradminhome')">
                <b-icon-house v-bind:class="[{ sidemenuiconsel: homeActive }, 'sidemenuicon']" />Home
            </li>
            <li class="dropdown">
            <a class="dropdown-toggle"  data-toggle="dropdown" href="#" id="network" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleSubMenuIcons(1)">
            <b-icon-diagram-2 v-bind:class="[{ sidemenuiconsel: networkActive }, 'sidemenuicon']" />Network
            </a>
            <ul class='dropdown-menu'>
                <li v-bind:class="[{ submenuactive: route === 'casternetworkdisplay'},'dropdown_item-1']" @click.stop="menuLink('casternetworkdisplay')">Display</li>
                <li v-bind:class="[{ submenuactive: route === 'casterroverslogged'},'dropdown_item-2']" @click.stop="menuLink('casterroverslogged')">Logged Rovers</li>
                <li v-bind:class="[{ submenuactive: route === 'casterreferencestations'},'dropdown_item-3']" @click.stop="menuLink('casterreferencestations')">Reference Stations</li>
            </ul>
            </li>

             <li class="dropdown">
            <a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleSubMenuIcons(2)">
            <b-icon-people v-bind:class="[{ sidemenuiconsel: usersActive }, 'sidemenuicon']" />Manage</a>
            <ul class='dropdown-menu'>
                <li v-bind:class="[{ submenuactive: route === 'castersubscriptionsstockreselle'},'dropdown_item-5']" @click.stop="menuLink('castersubscriptionsstockreseller')">Stocking</li>
                <li v-bind:class="[{ submenuactive: route === 'castersubscriptions'},'dropdown_item-1']" @click.stop="menuLink('castersubscriptions')">Subscriptions</li>
                <li v-bind:class="[{ submenuactive: route === 'castersimms'},'dropdown_item-2']" @click.stop="menuLink('castersimms')">SIM's</li>
                <li v-bind:class="[{ submenuactive: route === 'casterresellers'},'dropdown_item-3']" @click.stop="menuLink('casterresellers')">Resellers</li>
                <li v-bind:class="[{ submenuactive: route === 'casterdealers'},'dropdown_item-4']" @click.stop="menuLink('casterdealers')">Dealers</li>
                <li v-bind:class="[{ submenuactive: route === 'castercompanies'},'dropdown_item-5']" @click.stop="menuLink('castercompanies')">Companies</li>
                <li v-bind:class="[{ submenuactive: route === 'casterrovers'},'dropdown_item-5']" @click.stop="menuLink('casterrovers')">Rovers</li>
                <li v-bind:class="[{ submenuactive: route === 'casterusers'},'dropdown_item-5']" @click.stop="menuLink('casterusers')">Users</li>
                <li v-bind:class="[{ submenuactive: route === 'casterorders'},'dropdown_item-5']" @click.stop="menuLink('casterorders')">Orders</li>
                <li v-bind:class="[{ submenuactive: route === 'casterbases'},'dropdown_item-5']" @click.stop="menuLink('casterbases')">Basestations</li>
              </ul>
            </li>


            <li class="dropdown">
            <a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleSubMenuIcons(3)">
            <b-icon-list-task v-bind:class="[{ sidemenuiconsel: logsActive }, 'sidemenuicon']" />Logs</a>
            <ul class='dropdown-menu'>
                <li v-bind:class="[{ submenuactive: route === 'castersubslog'},'dropdown_item-1']" @click.stop="menuLink('castersubslog')">Subscription Log</li>
                <li v-bind:class="[{ submenuactive: route === 'casterlogmessages'},'dropdown_item-1']" @click.stop="menuLink('casterlogmessages')">Log Messages</li>
                <li v-bind:class="[{ submenuactive: route === 'casterloggga'},'dropdown_item-2']" @click.stop="menuLink('casterloggga')">GGA Messages</li>
                <li v-bind:class="[{ submenuactive: route === 'casterlogrtcm3'},'dropdown_item-3']" @click.stop="menuLink('casterlogrtcm3')">RTCM3 Messages</li>
                 <li v-bind:class="[{ submenuactive: route === 'castereventlog'},'dropdown_item-4']" @click.stop="menuLink('castereventlog')">System Events</li>
                 <li v-bind:class="[{ submenuactive: route === 'casteremails'},'dropdown_item-5']" @click.stop="menuLink('casteremails')">Email Log</li>
                <li v-bind:class="[{ submenuactive: route === 'castergdpr'},'dropdown_item-6']" @click.stop="menuLink('castergdpr')">GDPR O/S</li>
                </ul>
            </li>

            <li class="dropdown">
            <a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleSubMenuIcons(4)">
            <b-icon-graph-up v-bind:class="[{ sidemenuiconsel: statsActive }, 'sidemenuicon']" />Statistics</a>
            <ul class='dropdown-menu'> 
                <li v-bind:class="[{ submenuactive: route === 'casterstatsnetworklogins'},'dropdown_item-1']" @click.stop="menuLink('casterstatsnetworklogins')">Network Logins</li>
                <li v-bind:class="[{ submenuactive: route === 'casterstatsntripclients'},'dropdown_item-2']" @click.stop="menuLink('casterstatsntripclients')">NTRIP Clients</li>
                <li v-bind:class="[{ submenuactive: route === 'casterstatssubs'},'dropdown_item-3']" @click.stop="menuLink('casterstatssubs')">Subscriptions</li>
                </ul>
            </li>

             <li class="dropdown">
            <a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleSubMenuIcons(5)">
            <b-icon-gear-fill v-bind:class="[{ sidemenuiconsel: sysadminActive }, 'sidemenuicon']" />System Admin</a>
            <ul class='dropdown-menu'>
                <li v-bind:class="[{ submenuactive: route === 'casterusersadmin'},'dropdown_item-1']" @click.stop="menuLink('castersystemstatus')">System Status</li>
                <li v-bind:class="[{ submenuactive: route === 'casterreports'},'dropdown_item-1']" @click.stop="menuLink('casterreports')">System Reports</li>
                <li v-bind:class="[{ submenuactive: route === 'casterusersadmin'},'dropdown_item-2']" @click.stop="menuLink('casterusersadmin')">Admin Users</li>
                <li v-bind:class="[{ submenuactive: route === 'casteraudit'},'dropdown_item-3']" @click.stop="menuLink('casteraudit')">Audit Trail</li>
                <li v-bind:class="[{ submenuactive: route === 'casteremailtemplates'},'dropdown_item-4']" @click.stop="menuLink('casteremailtemplates')">Email Templates</li>
            </ul>

            </li>
            <li class='logout' @click.stop="userprofile()"><b-icon-person class="sidemenuicon" />User Profile</li>
            <li class='logout' @click.stop="logout()"><b-icon-box-arrow-right class="sidemenuicon" />Logout</li>
          </ul>

        </b-col>
        </b-row>

        <b-row>
            <b-col class="col-12">
            <CasterAlarms />
            </b-col>
        </b-row>    

        <b-row>
            <b-col class="col-12">
            <CasterAlerts @setActiveIcon="menuLink" @alertsButtonEl="updateAlertsButtonEl"/>
            </b-col>
        </b-row>    

         <b-row class="mt-3" v-show="!hideQuickLinks">
             <b-col class="col-12 mt-3"><h6>QUICK LINKS</h6></b-col>
            
             <b-col class="col-12 mt-3">
                <b-button size="sm" variant="outline-success" block @click="newReseller()" class="quicklinkbutton">
                    <b-icon-plus-circle style="margin-right:10px" />  Add Reseller
                </b-button>
            </b-col>

            <b-col class="col-12 mt-3">
                  <b-button ref="stockingQLBtn" size="sm" variant="outline-success" block  @click.stop="menuLink('castersubscriptionsstockreseller')" class="quicklinkbutton">
                    <b-icon-bar-chart-fill style="margin-right:10px" /> Stocking
                  </b-button>
            </b-col>

            <b-col class="col-12 mt-3" v-if="user.role == 1">
                  <b-button size="sm" variant="outline-success" block  @click.stop="LoginAsCloudbase()" class="quicklinkbutton">
                    <b-icon-person style="margin-right:10px" /> Cloudbase <span style="font-size:smaller;">{{"(Reseller)"}}</span>
                  </b-button>
            </b-col>

        </b-row>     
            
   </div>

</transition>


<b-modal ref="edit-reseller" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterResellerAdd
    :resellerid=0
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    />
</b-modal>


<b-modal ref="edit-profile" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
     <CasterUserProfile
    :userid="user.id"
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal() 
    />
</b-modal> 

</b-container>

</div>
</template>

<script>
    import CasterUserProfile from './CasterUserProfile'
    import CasterAlerts from './CasterAlerts'
    import CasterAlarms from './CasterAlarms'
    import CasterResellerAdd from './CasterResellerAdd'
    import axios from 'axios'
    import { mapState } from 'vuex'
    export default {
        name:'SideMenuCaster',
        components:{
            CasterUserProfile,
            CasterAlerts,
            CasterAlarms,
            CasterResellerAdd
        },    
        props: ['width','color'],
        data() {
            return {
                isPanelOpen: true,
                route:'',
                homesel:true,
                networksel:false,
                userssel:false,
                statssel:false,
                settingssel:false,
                adminsel:false,
                sysadminsel:false,
                logssel:false,
                hideQuickLinks:false,
                menuopen:0,
                menulast:0,
                menuBodyListener:false,
                menuActiveIcon:null,
                modaltitle:'',
                user:{},
                alertsButtonElement:null,
            }
        },     
        created(){
            this.user = this.$store.getters.user
            this.mobile = this.$store.getters.mobile
            this.isPanelOpen = !this.mobile     //  start with closed menu if mobile
            this.route =  this.$route.name
        },

        mounted(){
            this.setActiveIcon(this.route)
        },

        computed:{
           homeActive(){
            if(this.homesel){
                return 'sidemenuiconsel'
                } else return null 
            },  
            usersActive(){
            if(this.userssel){
                return 'sidemenuiconsel'
                }  else return null 
            }, 
            networkActive(){
            if(this.networksel){
                return 'sidemenuiconsel'
                }  else return null    
            },
            statsActive(){
            if(this.statssel){
                return 'sidemenuiconsel'
                }   else return null    
            },
            settingsActive(){
            if(this.settingssel){
                return 'sidemenuiconsel'
                }   else return null   
            },  
            adminActive(){
            if(this.adminsel){
                return 'sidemenuiconsel'
                }   else return null   
            },
            sysadminActive(){
            if(this.sysadminsel){
                return 'sidemenuiconsel'
                }  else return null    
            },
            logsActive(){
            if(this.logssel){
                return 'sidemenuiconsel'
                }   else return null   
            },    
            
            ...mapState(['openmenu','alarms'])
        },

        
        watch: {
            openmenu: function () {
                this.swidth = window.innerWidth  
                if(this.swidth < 1200){
                    this.isPanelOpen = this.openmenu
                } else this.isPanelOpen = true  
                
                //Disable scrolling if menu is in small screen size with open panel
                if(this.isPanelOpen && this.swidth <= 800){
                    document.body.style.overflow = 'hidden';
                    document.body.style.height = '100%';
                    document.body.style.width = '100%';
                }else{
                    document.body.style.overflow = '';
                    document.body.style.height = '';
                    document.body.style.width = '';
                 }
            },

        }, 
        
        methods: {
            //JM - Icon highlighting & quick link logic-------------------------------------------//
            subMenuBodyClose(){          
                //only act if there is a listener
                if(this.menuBodyListener){
                    //remove the listener and reset the flag
                    document.body.removeEventListener("click", this.subMenuBodyClose);
                    this.menuBodyListener = false;

                    //flips icons to corret highlight
                    this.subMenuIconReset(this.menuActiveIcon);
                }
            },

            subMenuAddBodyListnerForClosing(){  
                //we need to turn the icon back to grey if the user closed the menu by clicking outside the dropdown
                //flag to prevent multiple listeners being added
                
                if(!this.menuBodyListener){
                    //time out for debounce the first time you use, the click event from the opening menu triggers this event which we dont want
                    setTimeout( () => { 
                        document.body.addEventListener("click", this.subMenuBodyClose);
                    },0)

                    this.menuBodyListener = true;  
                }
            },

            subMenuIconReset(activeTracker = this.menuActiveIcon, ){
                //turn all icons grey
                this.homesel = false;
                this.networksel = false;
                this.userssel = false;  //manage
                this.logssel = false;
                this.statssel = false;
                this.sysadminsel = false;

                //reset menulast used when user clicks to open and to close
                this.menulast = 0;

                //enable Active Icon
                switch(activeTracker){
                    case 0:
                    this.homesel = true;
                    break;  
                    case 1:
                    this.networksel = true;
                    break;  
                    case 2:
                    this.userssel = true;
                    break; 
                    case 3:
                    this.logssel = true;
                    break;  
                    case 4:
                    this.statssel = true;
                    break; 
                    case 5:
                    this.sysadminsel = true;
                    break; 
                }   

                //Show quick lings again
                this.hideQuickLinks = false;
            },

            toggleSubMenuIcons(menu){
                //if listener exists, remove it
                if(this.menuBodyListener){
                    document.body.removeEventListener("click", this.subMenuBodyClose);
                    this.menuBodyListener = false;
                }

                //if user opens and closes menu by clicking the same dropdown
                if(this.menulast === menu){
                    this.subMenuIconReset();
                    return;

                //if user clicks another menu or the page, closing the first menu
                }else{
                    //listen for closing the submenu by clicking elsewhere
                    this.subMenuAddBodyListnerForClosing();
                    
                    //reset usinng the menu clicked
                    this.subMenuIconReset(menu);
                    this.menulast = menu;
                    this.hideQuickLinks = true;
                }
            },
            
            setActiveIcon(route){
                //active icon is the icon that returns to green when you are on its page,
                //ie if on home and no menu is open home icon will be green.

                this.homesel = false
                this.networksel = false
                this.userssel = false
                this.statssel = false 
                this.settingssel = false   
                this.adminsel = false
                this.sysadminsel = false
                this.logssel = false
                
                if(this.isPanelOpen && this.$refs.stockingQLBtn){
                    this.$refs.stockingQLBtn.classList.remove("quicklinkbuttonFakeActive");
                }
                if(this.alertsButtonElement){
                    this.alertsButtonElement.classList.remove("alertsBtnFakeActive");
                }

                switch(route){
                    case 'casternetworkdashboard':
                    case 'casteradminhome':            
                        this.homesel = true
                        this.menuActiveIcon = 0;
                        break
                    case 'casternetworkdisplay':      
                    case 'casterroverslogged':
                    case 'casterreferencestations':     
                        this.networksel = true 
                        this.menuActiveIcon = 1;   
                        break
                    case 'casterresellers':
                    case 'casterdealers':
                    case 'castercompanies':
                    case 'casterrovers':
                    case 'castersubscriptions':
                    case 'castersimms': 
                    case 'casterusers': 
                    case 'casterorders':
                    case 'casterbases':
                        this.userssel = true;
                        this.menuActiveIcon = 2;    
                        break
                    case 'casterlogmessages':
                    case 'casterlogrtcm3':
                    case 'casterloggga':    
                    case 'casterlogsats':  
                    case 'castereventlog':
                    case 'casteremails':     
                        this.logssel = true;
                        this.menuActiveIcon = 3;
                        break;
                    case 'castergdpr':     
                        this.logssel = true;
                        this.menuActiveIcon = 3;
                        if(this.isPanelOpen && this.alertsButtonElement){
                            this.alertsButtonElement.classList.add("alertsBtnFakeActive");
                        }
                        break;
                    case 'stats':
                    case 'casterstatsnetworklogins':
                    case 'casterstatsntripclients':
                    case 'casterstatssubs':         
                        this.statssel = true;
                        this.menuActiveIcon = 4;    
                        break
                    case 'settings':
                        this.settingssel = true;
                        this.menuActiveIcon = 5;    
                        break
                    case 'sysadmin':
                    case 'castersystemstatus':
                    case 'users':
                    case 'systemadmin/audittrail':
                    case 'casterusersadmin':
                    case 'casteraudit':
                    case 'casteremailtemplates':
                        this.sysadminsel = true;
                        this.menuActiveIcon = 5;     
                        break
                    case 'castersubscriptionsstockreseller':
                        if(this.isPanelOpen && this.$refs.stockingQLBtn){
                            this.menuActiveIcon = null;
                            this.$refs.stockingQLBtn.classList.add("quicklinkbuttonFakeActive");
                        }
                        break;
                    default:
                        this.menuActiveIcon = null;                  
                }
            },

            menuLink(name){
                //caled when you click a link in a menu
                if(name == 'casternetworkdisplay'){
                    this.networksel = true;
                    this.menuopen = 0;
                    this.hideQuickLinks = true; 
                }

                if(name == 'casteradminhome'){
                    this.homesel = true;
                    this.menuopen = 0;
                    this.hideQuickLinks = false;
                }    

                if(this.$store.getters.mobile){
                    this.$store.commit('setopenmenu',false) //  close menu on item select if mobile    
                }   
            
                this.$emit('linkname',name)
                this.route = name
                this.setActiveIcon(name)
              
            },

            updateAlertsButtonEl(element){
                this.alertsButtonElement = element;
            },

            //JM - Icon & quicklink logic end-----------------------------------------------------//

            userprofile(){
                this.modaltitle = 'Edit Profile'
                this.$refs['edit-profile'].show()
            },    

            newReseller(){
                this.modaltitle = 'New Reseller'
                this.$refs['edit-reseller'].show()
            },

            updatemodal(){
                if(this.$refs['edit-reseller']) this.$refs['edit-reseller'].hide(); 
                this.componentKey++
            },

         


         
            logout(){

                axios({
                    method: 'post',    
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    url: '/api/userevent',
                    data: {
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        user:this.$store.getters.user.id,
                        type:2,
                        text:this.$store.getters.user.fname+' '+this.$store.getters.user.lname
                        }
                    })
                    .then(()=> {this.$store.commit('setloggedin',false)

                });	
            },

            LoginAsCloudbase(){
                let branding = this.$store.getters.branding
                branding.logintitle = 'cloudbase'
                this.$store.commit('setbranding',branding)
                this.$store.commit('setloggedin',false)
            }     
           
		}	   
	
	};
</script>