<template>
<div>

<b-row class="mt-3" v-if="showfilters">
 
    <b-col class="col-12 col-md-4 mt-3" v-if="roleid == 1">
        <label>Reseller</label>
        <b-form-select
                    v-model="resellersel"
                    :options="resellers"
                    v-on:change="changeReseller()"
                    class="darkinput2"
                    size="sm" 
                    />
    </b-col>

    <b-col class="col-12 col-md-4 mt-3" v-if="roleid < 10">
        <label>Dealer</label>
        <b-form-select
                    v-model="dealersel"
                    :options="dealers"
                    v-on:change="changeDealer()"
                    class="darkinput2"
                    size="sm"  
                    />
    </b-col>

    <b-col class="col-12 col-md-4 mt-3">
        <label>Company</label>
        <b-form-select
                    v-model="companysel"
                    :options="companies"
                    v-on:change="changeCompany()"
                    class="darkinput2" 
                    size="sm"  
                    />
    </b-col>

    </b-row>

    <b-row>

        <b-col class="col-5 col-sm-3 mt-3">
                <label>Start Date</label>
                <VueCtkDateTimePicker
                            v-model="start"
                            format='DD/MM/YYYY'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="true"
                            >
                            <b-form-input 
                                type="search"
                                v-model="start" 
                                autocomplete = "off"
                                placeholder="automatic"
                                class="darkinput2"
                                size="sm"
                                />
                            </VueCtkDateTimePicker>    

        </b-col>  
        
        <b-col class="col-5 col-sm-3 mt-3">
            <label>End Date</label>
                <VueCtkDateTimePicker
                            v-model="end"
                            format='DD/MM/YYYY'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="true"
                            >
                            <b-form-input 
                                type="search"
                                v-model="end" 
                                autocomplete = "off"
                                placeholder="automatic"
                                class="darkinput2"
                                size="sm"
                                />
                            </VueCtkDateTimePicker>    

        </b-col>
        
        <b-col class="col-2 col-sm-3 mt-3">
            <b-button variant="outline-success" size="sm" @click="setDates()" style="margin-top:32px"> set dates </b-button>
        </b-col>
   
    
  </b-row>
	
</div> 
</template>



<script>
    import axios from 'axios'
    export default {
        props:['startdate','enddate','resellerid','dealerid','companyid','showfilters'],
		data(){  
		return {
            roleid:0,
			token:'',
			resellers:[],
            dealers:[],
            companies:[],
            resellersel:0,
            dealersel:0,
            companysel:0,
            start:'',
            end:'',
            update:false,
            display:false
       };
		
		},
		created(){
			this.token = this.$store.getters.token
            this.userid = this.$store.getters.user.id
            this.roleid = this.$store.getters.roleid
            this.resellersel = this.resellerid
            this.dealersel = this.dealerid
            this.companysel = this.companyid
            this.start = this.startdate
            this.end = this.enddate
            this.fetchOptions()
            
            
		},

        methods: {

            fetchOptions(){
                axios.get('/api/getfilterselectoptions',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.userid,
                        token:this.token,
                        reseller:this.resellersel,
                        dealer:this.dealersel,
                        company:this.companysel
                    }
                })
				.then((response) => {
                    
                        this.display = true
                        this.resellers = []
                        this.resellers.push({text:'All Resellers',value:0})
                        this.resellers.push({text:'All UK Resellers',value:99})
                        this.resellers.push(...response.data.resellers)
                        this.dealers = []
                        this.dealers.push({text:'All Dealers',value:0})
                        this.dealers.push(...response.data.dealers)
                        this.companies = []
                        this.companies.push({text:'All Dealers',value:0})
                        this.companies.push(...response.data.companys)
               
                    })
                 
            },

            
            changeReseller(){
                this.dealersel = 0
                this.companysel = 0
                this.fetchOptions()
                this.$store.commit('setresellerid',this.resellersel)
                this.$store.commit('setsearchvalues',[this.resellersel,this.dealersel,this.companysel,1])
                this.$emit('resellersel',this.resellersel)
            },

            changeDealer(){
                this.companysel = 0
                this.fetchOptions()
                this.$store.commit('setsearchvalues',[this.resellersel,this.dealersel,this.companysel,1])
                this.$emit('dealersel',this.dealersel)
            },

            changeCompany(){
                this.$store.commit('setsearchvalues',[this.resellersel,this.dealersel,this.companysel,1])
                this.$emit('companysel',this.companysel)
            },

            setDates(){
                this.$emit('datesel',[this.start,this.end])
            },

        }
	
	}

</script> 