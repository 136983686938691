<template>
<div>

<b-container class="mainpagecont" v-if="notauth">
    <b-row>
        <b-col class="col-12 col-sm-8 offset-sm-2 p-5">
            <b-button variant="danger" block disabled>UNAUTHORISED TO VIEW THIS RESOURCE</b-button>
        </b-col>
    </b-row>
</b-container> 

<div class="mainpagecont" v-if="display">

<b-row>
        <b-col class="col-12 col-sm-6"><h3>Company Overview<br/><span style="font-size:0.7em;color:rgba(255,255,255,0.7)"> {{ company.text }}</span></h3></b-col>
</b-row>

<b-row>
        <b-col class="col-12">
            <span v-if="company.address1 != ''">{{  company.address1 }}</span>
            <span v-if="company.address2 != ''">{{  company.address2 }}</span>
            <span v-if="company.address3 != ''">{{  company.address3 }}</span>
            {{  company.towncity }} {{  company.county }} {{  company.postcode }}
        </b-col>
        <b-col class="col-12">{{  company.mobile }} {{  company.email }}</b-col>
</b-row>


<b-row>
    <b-col class="col-12 col-sm-6 col-md-4 col-lg-4 mt-1 p-2 boxclickable" @click="menuLink('casterroverslogged')" >
        <CasterRoversLoggedCard :companyid="companyid"  />
    </b-col>

    <b-col class="col-12 col-sm-6 col-md-4 col-lg-4 mt-1 p-2 boxclickable" @click="menuLink('castersubscriptions')">
        <CasterSubsCard :dealerid="company.dealer" :companyid="companyid" />
    </b-col>

    <b-col class="col-12 col-sm-6 col-md-4 col-lg-4 mt-1 p-2" >
        <CasterSimmUsageCard  />
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterLastEventsCard  />
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterShowLeafletRovers  :border="true" v-on:fullscreen="fullScreen"/>
    </b-col>

    <b-col class="col-12 mt-1 p-2"><h6>ROVERS</h6></b-col>
    <b-col class="col-12 mt-0 p-2">
    <div class="imageBorder"> 
        <CasterRoversTable  :reseller="company.business" :dealer="company.dealer" :company="companyid" :key="componentKey" />
    </div>
    </b-col>

    <b-col class="col-12 mt-1 p-2"><h6>USERS</h6></b-col>
    <b-col class="col-12 mt-0 p-2"  v-if="company.value > 0">
        <div class="imageBorder"> 
        <CasterUsersTable :resellerid="company.business" :dealerid="company.dealer" :companyid="companyid" :search='false' :key="componentKey" />
         </div> 
    </b-col>

</b-row>
  
</div>

<b-modal ref="full-screen" size="xl"  title="ROVERS MAP" @show="waitSetWidth" @hide="closeMap" hide-footer centered content-class="darkmodal2">
   <div ref="fullscreendiv" :style="{width:100+'%',height:500+'px'}">
        <CasterShowLeafletRovers v-if="modalmapwidth != 0"  :fsmapheight="modalmapheight" :fsmapwidth="modalmapwidth" :border="false"/>   
   </div>
</b-modal>


<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>


<script>
    import CasterSubsCard from './CasterSubsCard'
    import CasterRoversLoggedCard from './CasterRoversLoggedCard'
    import CasterSimmUsageCard from './CasterSimmUsageCard'
    import CasterLastEventsCard from './CasterLastEventsCard'
    import CasterShowLeafletRovers from './CasterShowLeafletRovers'
    import CasterRoversTable from './CasterRoversTable'
    import CasterUsersTable from './CasterUsersTable'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    export default {
        name:'CasterCompany',
        components:{
            CasterSubsCard,
            CasterRoversLoggedCard,
            CasterSimmUsageCard,
            CasterLastEventsCard,
            CasterShowLeafletRovers,
            CasterRoversTable,
            CasterUsersTable,
            ErrorLogging
        },   
        data(){
        return {
                companyid:0,
                company:{},
                modaltitle:'',
                componentKey:0,
                rovers:[],
                searchvalues:[0,0,0,0], // 0 - Reseller 1 - Dealer 2- Company 3 - values set
                errorresponse:{},
                display:false,
                notauth:false,
                showmap:false,
                modalmapwidth:0,
                modalmapheight:485,
                roleid:0,
                };
                
                },

		created(){
            this.companyid = this.$route.params.id
            this.$store.commit('setcompanyid',this.companyid) 
            this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            this.$store.commit('setbasesdatapoll',0)
            this.$store.commit('setroversdatapoll',1)
            this.$store.commit('setprevroute',this.$route.name)
            this.fetchCompany()
        },

        beforeDestroy(){
            //RB 2208 this.$store.commit('setcompanyid',0)
            //RB 2208 if(this.roleid == 1 || this.roleid == 5){this.$store.commit('setdealerid',0)} // reset dealer if sysadmin or reseller admin
        }, 
        
        methods: {

            fetchCompany(){
                axios.get('/api/castercompany',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         reseller:this.$store.getters.resellerid,
                         //dealer:this.$store.getters.dealerid,
                         id:this.companyid
                     }
                }).then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        return;
                    }

                    this.company = response.data.CasterCompanies[0]
                    this.$store.commit('setdealerid',this.company.dealer)
                    if(this.company == undefined){
                        this.notauth = true
                    } else this.display = true
                }).catch( err => {
                    console.error("CasterCompany, fetchCompany :",err)
                })
			},

          
            updatemodal(){
                this.$refs['edit-company'].hide()
                this.$refs['rover-add'].hide()
            },

            menuLink(name){
                
                this.searchvalues[0] = this.company.business
                this.searchvalues[1] = this.company.dealer
                this.searchvalues[2] = this.company.value
                this.searchvalues[3] = 1
                this.$store.commit('setsearchvalues',this.searchvalues)
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },

            showMap(){
                this.showmap = true
            },

            fullScreen(){
                this.modalmapwidth = 0
                this.$refs['full-screen'].show()
            },
            
            waitSetWidth(){
                setTimeout(this.setModalWidth,150)
            },

            setModalWidth(){
                window.addEventListener("resize", this.setModalWidth)
                this.modalmapwidth = this.$refs['fullscreendiv'].clientWidth -20
            },

            closeMap(){
                window.removeEventListener("resize", this.setModalWidth)
                this.$refs['full-screen'].hide()
            },
            

            
		}	   
	
	};

</script>