<template>
<div class="imageBorder">

        <b-row v-if="display">

            <b-col class="col-9 boxtext">NETWORK LOGINS</b-col>
            <b-col class="col-3 text-right" >
                <b-icon-fullscreen @click.stop="fullScreen()" v-b-tooltip.hover title="Expand" />
            </b-col>           
             <!-- <b-col class="col-4 mt-3 text-center">
                {{ timeperiod }}
            </b-col>             
            <b-col class="col-4 mt-3 text-right">
               <b-button size="sm" variant="outline-success" @click="setPeriod('day')">DAY</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('week')">WEEK</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('month')">MONTH</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('year')">YEAR</b-button>

            </b-col>             -->
            <b-col class="col-12 mt-3">
                 <LineChartSingle :chartdata="data" :height="230" />
            </b-col>

        </b-row>

         <b-row class="mt-3" v-else>
                <b-col class="col-12 text-center pt-3 pb-3">
                        <h5 style="color:#48A1BA;">No Data</h5>
                </b-col>   
        </b-row>

<b-modal ref="full-screen" size="lg"  title="NETWORK LOGINS" hide-footer centered content-class="darkmodal" v-if="display">

    <b-row style="min-height:300px">
        <b-col class="col-12 mt-3">
            <LineChartSingle  :chartdata="data" :height="300" :width="300" />
        </b-col>
    </b-row>

</b-modal>    

</div>
</template>

<script>
    import LineChartSingle from './LineChartSingle'
    import { mapState } from 'vuex'
    import axios from 'axios'
    import moment from 'moment'
	export default {
        name:'CasterNetworkLoginsChartCard',
        components:{
            LineChartSingle
        },        
        data(){
		return {
                data:[],
                token:'',
                resellid:0,
                dealerid:0,
                display:false,
                period:3600, // 1 hour
                timeout:'',
                timeperiod:'',
                start:'',
                end:'',
                steps:0,
               };
                
                },

	created(){
                this.token = this.$store.getters.token
                this.resellid = this.$store.getters.resellerid
                this.dealerid = this.$store.getters.dealerid
                this.setPeriod('day')
                },

    beforeDestroy () {
            clearTimeout(this.timeout)
        }, 
        
        
    computed: mapState(['resellerid']),
                
                watch: {
                    
                        resellerid: function () {
                        this.resellid = this.resellerid    
                        this.getNetworkLogins()
                        },    
                },            

	methods: {

                getNetworkLogins(){

                axios.get('/api/networklogins',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                    userid:this.$store.getters.user.id,
                    token:this.$store.getters.user.token,    
                    start:this.start,
                                end:this.end,
                                steps:this.steps,
                                reseller:this.resellid,
                                dealer:this.dealerid,
                                timezone:this.$store.getters.timezone
                         }
                        })
			.then((response) => {

                    if(response.data != 'NOT AUTHORISED'){    
                     
                           this.data = response.data
                           this.display = true
                           if(!this.timeout){
                                this.timeout = setTimeout(this.getNetworkLogins, 300000) // poll every 5 mins
                            } else clearTimeout(this.timeout)
                        } else this.display = false

                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            })
                    });
                },
                
                setPeriod(value){
                    
                    switch(value){
                        case 'day':
                        this.start = moment().format("YYYY-MM-DD")
                        this.end = moment().format("YYYY-MM-DD")    
                        this.timeperiod = moment().format("DD-MM-YYYY")
                        this.steps = 24
                        break;
                        case 'week':
                        this.start = moment().startOf('isoweek').format("YYYY-MM-DD")
                        this.end = moment().endOf('isoweek').format("YYYY-MM-DD")
                        this.timeperiod = moment().startOf('isoweek').format("DD-MM-YYYY")+' - '+moment().endOf('isoweek').format("DD-MM-YYYY")
                        this.steps = 7
                        break;
                        case 'month':
                        this.start = moment().startOf('month').format("YYYY-MM-DD")
                        this.end = moment().endOf('month').format("YYYY-MM-DD")
                        this.timeperiod =  moment().startOf('month').format("DD-MM-YYYY")+' - '+ moment().endOf('month').format("DD-MM-YYYY")
                        this.steps = moment().daysInMonth() 
                        break;
                        case 'year':
                        this.start = moment().startOf('year').format("YYYY-MM-DD")
                        this.end = moment().endOf('year').format("YYYY-MM-DD")
                        this.timeperiod =  moment().startOf('year').format("DD-MM-YYYY")+' - '+ moment().endOf('year').format("DD-MM-YYYY")
                        this.steps = 12
                        break;                        
                    }    
                        this.$emit('periodchange',{start:this.start,end:this.end,period:value})
                        this.getNetworkLogins()    
                },

                 fullScreen(){
                      this.$refs['full-screen'].show()
                      this.getNetworkLogins()
                      
                },
            }	 

	};
</script> 