<template> 
<div>

<b-row>
        <b-col class="col-12">TEST</b-col> 
      <b-col class="col-6 mt-3">{{emails.length }} Result<span v-if="emails.length != 1">s</span></b-col>
</b-row>    

<b-row class="mt-3">
      <b-col>

             <b-table 
                responsive 
                hover 
                :items="emails" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @row-clicked="showEmail($event)"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                sort-icon-left
                :busy="isBusy"
                dark
                >

                <template #table-busy>
                    <div class="text-center text-orange my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong style="margin-left:10px"> Loading...</strong>
                    </div>
                </template>

                </b-table>
            
    
</b-col>
</b-row>	

<b-modal ref="edit-email" size="lg" :title="email.sent_email" hide-footer centered content-class="darkmodal">
   <b-row>
       <b-col class="col-12">Subject : {{ email.subject }}</b-col>
       <b-col class="col-12 mt-3">
           <div v-html="email.text" /> 
       </b-col>
   </b-row>

   <b-row>
       <b-col class="col-12 mt-5">
           <b-button variant="outline-success" block @click="sendEmail1()">NEXT</b-button>
       </b-col>
   </b-row>

</b-modal>

<b-modal ref="send-email" size="md" title="Resend Email" hide-footer centered content-class="darkmodal">

     <b-col class="col-12 mt-3">
        <label>Email</label>
                <b-form-input 
                    type="search"
                    placeholder="optional"
                    autocomplete="off" 
                    v-model="email.sent_email" 
                    name="email.sent_email"
                     v-validate="{required:true}"
					:class="{'darkinput': true, 'is-danger': errors.has('email.sent_email') }"
                    class="darkinput"
                    />
		</b-col>  

         <b-col class="col-12 mt-5">
           <b-button variant="outline-success" block @click="resendEmail()">SEND EMAIL</b-button>
       </b-col>        

</b-modal>    

<b-modal ref="send-email" size="md" title="Resend Email" hide-footer centered content-class="darkmodal">

     <b-col class="col-12 mt-3">
        <label>Email</label>
                <b-form-input 
                    type="search"
                    placeholder="optional"
                    autocomplete="off" 
                    v-model="email.sent_email" 
                    name="email.sent_email"
                     v-validate="{required:true}"
					:class="{'darkinput': true, 'is-danger': errors.has('email.sent_email') }"
                    class="darkinput"
                    />
		</b-col>  

         <b-col class="col-12 mt-5">
           <b-button variant="outline-success" block @click="resendEmail()">SEND EMAIL</b-button>
       </b-col>        

</b-modal>    
<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
</div> 
</template>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
	export default {
        props:['company'], 
        name:'CasterGDPREmails',
        components:{
            ErrorLogging
        },
        data(){
            return {
                email:{},
                emails:[],
                fields:[
                { key: 'subject', label: 'Email', sortable: true  },
                { key: 'sent_email', label: 'To', sortable: true  },
                { key: 'date', label: 'Date/Time', sortable: true, formatter: 'formatDate'  }
                ],
                sortBy:'date',
                sortDesc:true,
                display:false,
                perPage: 100,
                currentPage:1,   
                token:'',
                user:{},
                isBusy:false,
                errorresponse:{}
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.user = this.$store.getters.user
            this.fetchEmails()
        },

		methods: {

            fetchEmails(){

                this.isBusy = true
				axios.get('/api/castergdpremails',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         company:this.company
                  
                     }
                })
				.then((response) => {

                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {

                    this.emails = response.data.CasterEmails
                    this.isBusy = false
                    this.display = true
                    }
                   
                    });
			},

            
             showEmail(email){
                //this.email = email
                
                this.modaltitle = 'Email'

                axios.get('/api/casteremaillogs',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         id:email.value                  
                     }
                })
				.then((response) => {
                    this.email = response.data.CasterEmails[0]
                    this.email.text = this.email.text.replace("ip-rtk-uk.com/images","ip-rtk-aws.com/images")
                    this.$refs['edit-email'].show()
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });

                
            },

             sendEmail1(){
                 this.$refs['send-email'].show()
            },


            resendEmail(){
                   
                        axios({
                                method: 'post',   
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/resendemail',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    email: this.email,
                                    user: this.user
                                    }
                                })
                            .then((response)=> {

                            if(response.data.error){
                                this.$swal({
                                                title: "Email not sent",
                                                text: response.data.error,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                } else {
                                        this.$swal({
                                                title: "Email resent",
                                                text: '',
                                                icon: "success",
                                                showCloseButton: true,
                                                showConfirmButton: false,
                                                timer:3000
                                                })
                                                .then(() => {
                                                   this.$refs['send-email'].hide() 
                                                   this.$refs['edit-email'].hide()
                                                   this.fetchEmails()
                                                    }) 
                                }
                        
                            }).catch(err => {
                                        this.$swal({
                                                title: "Email not sent",
                                                text: err,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                        });	
            },
 

            formatDate(timedate) {
                let date =  moment(timedate).format('DD-MM-YY HH:mm') 
                return date
            }

		}	   
	
	};
</script>