<template>
<div>
<div class="mainpagecont" >

<b-row class="mt-3">
    <b-col class="col-9 mt-3">
        <h4>NTRIP Clients</h4>
    </b-col>
    <b-col class="col-3 text-right mt-3">
        <b-button variant="outline-success" @click="$router.go(-1)" >BACK</b-button>
    </b-col>    
</b-row>


<b-row class="mt-0 pb-5">
 
    <b-col class="col-12 mt-3">
       <CasterNTRIPClientsChartCard :dealer="dealer" v-on:periodchange="changePeriod"/>
    </b-col>

     <!-- <b-col class="col-12 mt-3" v-if="start !== ''">
       <casternetworkloginstable :start="start" :end="end" :period="period" />
    </b-col>     -->

</b-row> 



</div>
</div> 
</template>

<script>
    import CasterNTRIPClientsChartCard from './CasterNTRIPClientsChartCard'
    export default {
        name:'CasterStatsNTRIPClients',
        components:{
            CasterNTRIPClientsChartCard
        },
        data(){
		return {
                dealer:0,
                start:'',
                end:'',
                period:''
                };
                
                },

    
		created(){
           if(this.$store.getters.roleid == 1){this.dealer = 0} else this.dealer = this.$store.getters.dealerid
          
        },
	
		methods: {

           changePeriod(value){
               this.start = value.start
               this.end = value.end
               this.period = value.period
           }

		}	   
	
	};

</script>