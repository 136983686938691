<template>
<div>

<b-container>

    <b-row class="mb-3">

		<b-col class="col-12 mt-3">

            <label>Model</label>
            <b-form-input 
                name="Model"
                type="search" 
                placeholder="required" 
                autocomplete="off"
                v-model="model.text"
                :disabled="lockfield" 
                v-validate="'required|alreadyExists|min:3|max:32'"                  
            />
            <div class="text-danger">{{ errors.first('Model') }}</div>
		
			
		</b-col>

       
	</b-row>	
      
    <b-row class="mt-5 pt-3 border-top">
         <b-col class="col-12">
            <b-button variant="success"  @click="updateModel()" block>SAVE</b-button>
        </b-col>   

        <b-col class="col-12 mt-3" v-if="model.value !== 0">
				<b-button variant="danger" block @click="deleteModel(model.value)">DELETE</b-button>
		</b-col>
    </b-row> 


</b-container>

		
</div> 
</template>


<script>
    import { Validator } from 'vee-validate';
    import axios from 'axios'
	export default {

        props:['id','maker', 'models'],
        data(){
		return {
    
                token:'',
                model:{},
                searchtext:'',
                lockfield:false
                };
                
                },

		created(){
      
            this.token = this.$store.getters.token
            if(this.id == 0){this.newModel()} else this.fetchModel()

        
        },
        mounted(){
            this.addAlreadyExistsRule();
        },
        methods: {
            
            addAlreadyExistsRule(){
                //adding a rule to vee validator to check if the model already exists
                Validator.extend('alreadyExists', {
                    getMessage:"Model already exists.",
                    validate: (value) => {
                        const modelText = this.models.map( modelObj => modelObj.text.toLowerCase());
                        if(modelText.includes(value.toLowerCase()))return false;
                        return true;
                    }
                })
            },
            

            fetchModel(){
                axios.get('/api/machinemodels',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,  
                        id:this.id
                        }
                })
                .then((response) => {
                    this.model = response.data.data

                  
                   })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
           },

      
           newModel(){
               
                this.model.value = 0
                this.model.text = ''
                this.model.maker = this.maker
             
           },

       
            updateModel(){
                if(this.lockfield) return;
                this.lockfield = true;

                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.lockfield = false; 
                        return;
                    }

                    if(this.model.value !== 0){
                        axios({
                                method: 'put',
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/machinemodel',
                                data:
                                        {
                                            userid:this.$store.getters.user.id,
                                            token:this.$store.getters.user.token,
                                            modeldata: this.model 
                                        }
                                
                                })
                            .then((response)=> {
                       
                            if(response.data.error){
                                
                                this.$swal({
                                                title: "Update Error",
                                                text: response.data.error,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                } else {

                                    this.$swal({
                                                title: "Updated",
                                                text: 'Machine Model updated',
                                                icon: "success",
                                                showCloseButton: true,
                                                showConfirmButton: false,
                                                timer:3000
                                                })
                                                .then(() => {
                                                    this.$emit('updatemodels',response.data)
                                                    }) 

                                }

                        
                            }).catch(err => {
                                        this.$swal({
                                                title: "Update Error",
                                                text: err,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                            }).finally( () => {
                                this.lockfield = false; 
                            });	
                    } else {


                         axios({
                                method: 'post',     //   New Machine
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/machinemodel',
                                 data:
                                        {
                                            userid:this.$store.getters.user.id,
                                            token:this.$store.getters.user.token,
                                            modeldata: this.model 
                                        }
                                })
                            .then((response)=> {
                       
                            if(response.data.error){
                                this.$swal({
                                                title: "Update Error",
                                                text: response.data.error,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                } else {

                                    this.$swal({
                                                title: "Updated",
                                                text: 'New Machine Model Created',
                                                icon: "success",
                                                showCloseButton: true,
                                                showConfirmButton: false,
                                                timer:3000
                                                })
                                                .then(() => {
                                                    this.$emit('updatemodels',response.data)
                                                    }) 
                                }
                        
                            }).catch(err => {
                                        this.$swal({
                                                title: "Update Error",
                                                text: err,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                            }).finally( () => {
                                this.lockfield = false; 
                            });	

                    }                   

                })      

            },

             deleteModel(id){

                    this.$swal({
                    title: "Delete?",
                    text: "This will remove this model",
                    icon: "question",
                    showCloseButton: true,
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
					showCancelButton: true,
					cancelButtonText: 'No',
						})
						.then(() => {

                    axios({
                        method: 'delete',
						headers: { "Authorization" : "Bearer " + this.token },
                        url: '/api/machinemodel/'+id,
                        data:{
                             userid:this.$store.getters.user.id,
                             token:this.$store.getters.user.token,
                            }
						})
					.then(
						this.$emit('updatemodal')
						)
					.catch(err => {
								this.$swal({
										title: "Update Error",
										text: err,
										icon: "error",
										showCloseButton: true,
                                        showConfirmButton: false
										})
								});	

                        })
               
			},

         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script> 


