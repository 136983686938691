<template>
<div class="imageBorder">

<b-row v-if="numrovers > 0">
        <b-col class="col-9 boxtext">LOGGED ROVERS</b-col>
        <b-col class="col-3 text-right" >
                <b-icon-fullscreen @click.stop="fullScreen()" v-b-tooltip.hover title="Expand" />
        </b-col>
</b-row>

<b-row v-if="numrovers > 0" style="min-height:80px">
       
        <div class="charttextholder">
                <h2 class="boxtext_md">{{ numrovers }}</h2>
                {{ numfix }}/{{ numrovers }} <span style="font-size:0.7em"> RTK FIX</span>
        </div>

        <div class="pieholderlg" v-if="singlebox">
                <SubsChart :data="rovers" />
        </div>  

        <div class="pieholder" v-else>
                <SubsChart :data="rovers" />
        </div>  
     
  
</b-row>  


 <b-row class="mt-3" v-else>
        <b-col class="col-12 text-center pt-3 pb-3">
                <h5 style="color:#48A1BA;">No Logged Rovers</h5>
        </b-col>   
</b-row>


<b-modal ref="full-screen" size="md"  title="LOGGED ROVERS" hide-footer centered content-class="darkmodal">
  <b-row class="mt-3" v-if="this.numrovers != 0">
     <b-col class="col-12" >
      
        <b-row class="mt-3">
                <b-col class="col-2 col-sm-8 col-lg-3">
                <h2 class="boxtext_md">{{ numrovers }}</h2>
                </b-col>

                <b-col class="col-7 col-lg-6 text-center d-none d-lg-block">
                <BarChartH :datavalue="numfix" :totalvalue="numrovers" unitvalue=' RTK FIX' />
                </b-col>  

                <b-col class="col-12 col-sm-4 col-lg-3">
                        <SubsChart :data="rovers" /> 
                </b-col>

        </b-row>
    </b-col>
   
    <b-col class="col-12">
            <b-row>

                <b-col class="col-6">
                                <b-row class="mt-3"> 
                                        <b-col class="col-12">{{ rovers[3].value }} {{ rovers[3].label }}</b-col>     
                                        <b-col class="col-12" >{{ rovers[4].value }} {{ rovers[4].label }}</b-col>
                                        <b-col class="col-12" >{{ rovers[5].value }} {{ rovers[5].label }}</b-col>   
                                </b-row>  
                </b-col>   

                <b-col class="col-6">
                                <b-row class="mt-3"> 
                                <b-col class="col-12">{{ rovers[2].value }} {{ rovers[2].label }}</b-col>
                                <b-col class="col-12">{{ rovers[1].value }} {{ rovers[1].label }}</b-col>
                                <b-col class="col-12">{{ rovers[6].value }} {{ rovers[6].label }}</b-col>
                                </b-row>  
                </b-col>  

            </b-row>
</b-col>     

</b-row>
</b-modal> 

</div>
</template>

<script>
        import SubsChart from './SubsChart'
        import BarChartH from './BarChartH'
        import { mapState } from 'vuex'
        export default {
        props:['singlebox'],        
        name:'CasterRoversLoggedCard',
        components:{
        SubsChart,
        BarChartH
        },     
        data(){
        return {
        display:false,
        rovers:[],
        numrovers:0,
        numfix:0,
        };
                
                },

        mounted(){
                if(this.$store.getters.chartdata != undefined){
                this.setData()
                }    
        },

        computed: mapState(['chartdata']),

        watch: {
                  chartdata: function () {
                        this.setData()      
              }
       },

	methods: {

                fullScreen(){
                      this.$refs['full-screen'].show()
                },

                setData(){
                        if(this.chartdata.length != 0){
                        this.rovers = this.chartdata.data  
                        this.numfix = this.chartdata.data[3].value
                        this.numrovers = this.chartdata.total
                        }
                }
        }	   
};
</script> 