<template>
<div>
<b-row>

<b-col class="col-12 col-sm-3 mt-3">
        <label>Start Date</label>
        <VueCtkDateTimePicker
                    v-model="start"
                    format='DD/MM/YYYY'
                    :dark="true"
                    :no-value-to-custom-elem="true"
                    :only-date="true"
                    >
                    <b-form-input 
                        type="search"
                        v-model="start" 
                        autocomplete = "off"
                        placeholder="automatic"
                        class="darkinput2"
                        size="sm"
                        />
                    </VueCtkDateTimePicker>    

</b-col>  

<b-col class="col-12 col-sm-3 mt-3">
    <label>End Date</label>
        <VueCtkDateTimePicker
                    v-model="end"
                    format='DD/MM/YYYY'
                    :dark="true"
                    :no-value-to-custom-elem="true"
                    :only-date="true"
                    >
                    <b-form-input 
                        type="search"
                        v-model="end" 
                        autocomplete = "off"
                        placeholder="automatic"
                        class="darkinput2"
                        size="sm"
                        />
                    </VueCtkDateTimePicker>    

</b-col>

<b-col class="col-2 mt-3">
    <b-button variant="outline-success" size="sm" @click="setDates()" style="margin-top:32px"> set dates </b-button>
</b-col>

</b-row>

</div> 
</template>

<script>
    import moment from 'moment'
	export default {
        name:'CasterDateSelect',
        components:{},
        data(){
            return {
                start:'',
                end:''
                };
                
                },

		created(){
            this.start = this.firstDayOfMonth()
            this.end = moment().format('DD/MM/YYYY')
            this.setDates()
        },

        methods: {

            firstDayOfMonth(date = null) {
                if (date) {
                    return moment(date).startOf('year').format('DD/MM/YYYY')
                } else {
                    return moment().startOf('year').format('DD/MM/YYYY')
                }
            },

            setDates(){
                this.$emit('datefilter',[this.start,this.end])
            },

        }	
    };

</script>