<template> 
  <div >
    <b-row class="">
      <b-col class="col-8 mx-auto d-flex">
        <b-form-select v-model="countryselected" :options="countryoptions"></b-form-select>
        <b-button @click="getData" class="w-25">Get Data</b-button>        
      </b-col>
    </b-row>
    <b-row >
      <b-col class="col-10 mx-auto">
        <div class="table-wrpr mt-3 mb-3  ">
          <b-table 
            striped 
            responsive 
            hover
            :items="bases" 
            :fields="fields"
            />
        </div>
      </b-col>
    </b-row>
  
  </div>
</template>

<style scoped>
.base-rows{
  background-color: rgb(89, 177, 177);
  border-bottom: dashed grey 0.1em;
}
.table-wrpr{
  background-color: azure;
  border: dashed 0.1em grey;
  overflow-x:auto;
  max-height: 80vh;
}
</style>


<script>
import axios from 'axios'
export default {
  name: 'GetBasestationData',
  data() {
    return {
    
    bases:[],
    countryselected:0,
  
    countryoptions:[
        {value:0, text:"All"},
        {value:1, text:"United Kingdom"},
        {value:2, text:"Denmark"}
        ], 
    
    fields: [
        { key: 'text', label: 'Base', sortable: true  },
        { key: 'mount', label: 'Mountpoint', sortable: true  },
        { key: 'station_id', label: 'Station Id', sortable: true  },
        { key: 'latitude', label: 'Lat', sortable: true  },
        { key: 'longitude', label: 'Long', sortable: true  }
    ],
      
    }
  },
  created(){
    console.log(`created test`)
  
  },
  methods: {
    getData() {
     
      axios.get('https://ip-rtk-aws.com/api/testapijordan',{ 
        params:{
            country:this.countrySelected
            }
        })
      .then(response => {
        this.bases = response.data.basestations
      }).catch(error => {
        console.log(`Issue getting data: `,error)
      });
    },
  }
}
</script>