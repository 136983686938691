<template>
<div>
<b-container>
     
    <b-row>
        <b-col class="col-12 pt-1 pb-2 text-center flashbg" :key="animationtrigger"></b-col>    
    </b-row>
</b-container>      
</div> 
</template>


<script>
   export default {
        props:['dataupdate'],
        data(){
        return {
                animationtrigger:false
                };
                
                },
		
        watch:{
            dataupdate: function() {
            this.animationtrigger = !this.animationtrigger;
          }
    },

	};
</script> 