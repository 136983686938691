<template>
<div class="imageBorder">

        <b-row>
                <b-col class="col-12">CONNECTION TIME (HRS)</b-col>
        </b-row>

        <b-row  v-if="display">
            <b-col class="col-12 mt-3">
                 <BarChartSingle :chartdata="data" :height="200" />
            </b-col>

        </b-row>

         <b-row class="mt-3" v-else>
                <b-col class="col-12 text-center pt-3 pb-3">
                        <h5 style="color:#48A1BA;">No Data</h5>
                </b-col>   
        </b-row>

</div>
</template>

<script>
    import BarChartSingle from './BarChartSingle'
    import axios from 'axios'
	export default {
        props:['roverid'],
        name:'CasterRoverConnectionTimeChartCard',
        components:{
           BarChartSingle
        },      
        data(){
            return {
                id:0,
                data:[],
                token:'',
                display:false
               };
                
                },

	created(){
                this.token = this.$store.getters.token
                this.getData()
        },
        
        methods: {

          
            getData(){

                    axios.get('/api/roversessiondata',{
                        headers: {"Authorization" : "Bearer " + this.token},
                        params:{
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,  
                            rover:this.roverid,
                            type:2
                         }
                        })
			.then((response) => {

                    if(response.data.error == 'Unauthorized'){    
                     
                      this.$swal({
                            title: "System Error",
                            text: response.data.error,
                            icon: "error",
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                   }
                   
                   else {
                           this.data = response.data
                           this.display = true
                        }

                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            })
                    });
                },
            }	 

	};
</script> 