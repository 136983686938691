<template>
<div>
<b-container class="mainpagecont">

<b-row class="mb-3">
    <b-col class="col-12 col-md-4 mt-3" style="color:rgba(255,255,255,1)">
        <h4>Orders</h4>
    </b-col>

    <b-col class="col-12 col-md-4 mt-3">
            <CasterSearch v-on:textsearch=updateSearch v-on:clearsearch=clearFilter />
    </b-col>

    <b-col class="col-12 col-md-4 mt-3 text-right">
            <b-button variant="outline-success" size="sm" @click.stop="refreshOrders()">
                <b-icon-arrow-clockwise />
                 Refresh
                </b-button>
    </b-col>

</b-row>      

<b-row>       
    <b-col class="col-12">
        <CasterOrdersTable 
            :stext="searchtext"
            :reseller="resellerid"  
            :dealer="dealerid" 
            :company="companyid" 
            :key="componentKey"
            :refresh="refreshorders" 
            />
    </b-col>
</b-row>

</b-container>
</div> 
</template>

<script>
    import CasterSearch from './CasterSearch'
    import CasterOrdersTable from './CasterOrdersTable'
    export default {
        name:'CasterOrders',
        components:{
            CasterSearch,
            CasterOrdersTable
        }, 
        data(){
            return {
                resellerid:0,
                companyid:0,
                dealerid:0,
                user:{},
                componentKey:0,
                display:false,
                searchtext:'',
                dealerfilter:false,
                refreshorders:false,
                };
                
                },

		created(){
            this.$store.commit('setbasesdatapoll',0)
            this.display = true
            },

		methods: {

            refreshOrders(){
                this.refreshorders = !this.refreshorders
            },

            menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },

            updateTable(filter){
                this.resellerid = filter.reseller
                this.dealerid = filter.dealer
                this.companyid = filter.company
            },

            updateSearch(text){
                this.searchtext = text
            },

            clearFilter(){
                this.searchtext = ''
            },


		}	   
	
	};

</script> 
