<template>
<div>

    
<b-row class="mt-3">
        <b-col class="col-12 text-right">{{ results }} Result<span v-if="results != 1">s</span></b-col>
        <b-col class="col-12 mt-2">
             <b-table
                class="orders" 
                responsive 
                hover 
                :items="orders" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :busy="isBusy"
                
                @row-clicked="showOrder($event)"
                dark
                >

                <template #table-busy>
                    <div class="text-center text-warning my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                    </div>
                </template>

                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteRover(data.item.value)" class="mt-1 mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <!-- <b-button size="sm" @click="editRover(data.item)" class="mt-1 mr-2 xsbutton" variant="outline-success">
                        <b-icon-pencil />
                    </b-button> -->
                </template>    

                </b-table>
       </b-col>
</b-row>

<b-modal ref="edit-order"  :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterOrder
    :order="ordersel" 
    v-on:closemodal="closeModal()"
    v-on:updatemodal="updatemodal()"
    />
</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style>

.nowrap td {
    white-space: nowrap !important;
}
</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import CasterOrder from './CasterOrder'
    import axios from 'axios'
    import moment from 'moment'
	export default {
        name:'CasterOrdersTable',
        components:{
            ErrorLogging,
            CasterOrder
            }, 
        props:['stext','company','refresh'], 
        data(){
        return {
                id:0,
                token:'',
                orders:[],
                ordersel:{},
                results:0,
                fields:[
                    { key: 'company', label: 'Company', sortable: true  },
                    { key: 'name', label: 'Name', sortable: true  },    
                    { key: 'amount', label: 'Amount', sortable: true  },
                    { key: 'order', label: 'Order #', sortable: true  },
                    { key: 'statustext', label: 'Status', sortable: true  },
                    { key: 'datetime', label: 'Date/Time', formatter: 'formatDate',sortable: true  },
                    { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                    ],
                sortBy:'datetime',
                isBusy: false,
                modaltitle:'',
                errorresponse:{}
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.fetchOrders()
        },

           watch: {
                    stext: function () {
                         this.fetchOrders()
                         },
                         
                    refresh: function() {
                        this.fetchOrders()
                    }     
          },

		methods: {

           
            fetchOrders(){
                
            
                this.isBusy = true
                axios.get('/api/payments',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         company:this.company,
                         stext: this.stext,
                     }
                })
				.then((response) => {

                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        } else {
                   
                            this.orders = response.data.payments
                            this.results = this.orders.length
                            this.display = true
                            this.isBusy = false

                        }    

                    })
                
			},

       
            showOrder(item){
               this.ordersel = item
               this.modaltitle = 'Process Order'
               this.$refs['edit-order'].show() 
            },

             updatemodal(){
                this.$refs['edit-order'].hide() 
                this.fetchOrders()
            },

             formatDate(timedate) {
                return  moment.utc(timedate).local().format('DD-MM-YY HH:mm:ss')
            },

            closeModal(){
                this.$refs['edit-order'].hide() 
            }

		}	   
	
	};

</script>