<template>
<div>
<b-row class="mt-1 pb-3" style="border-bottom:1px solid rgba(255,255,255,0.5)">
    <div class="stageselectmain">
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(1)" v-bind:class="companyclass "><b-icon-building /></div>details</div>
        <div class="stageselectline" v-bind:class="line1class"></div>
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(2)" v-bind:class="userclass"><b-icon-person /></div>admin</div>
        <div class="stageselectline" v-bind:class="line2class"></div>
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(3)" v-bind:class="brandclass"><b-icon-image /></div>branding</div>
        <div class="stageselectline" v-bind:class="line2class"></div>
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(4)" v-bind:class="createclass"><b-icon-check /></div><span v-if="companyid == 0">create</span><span v-else>update</span></div>
    
    </div>
                
</b-row>

<b-container class="addrowpage" v-if="page == 1 && display">
    
<b-row class="mt-3">
    <b-col class="col-12" v-if="roleid == 1">
        <label>Reseller</label>
            <b-form-select
                v-model="company.business"
                :options="resellers"
                @change="changeReseller($event)"
                class="darkinput"
                />
    </b-col>

    <b-col class="col-12 mt-3" >  
            <label>Dealer</label>
                <b-form-select
                v-model="company.dealer"
                :options="dealers"
                class="darkinput"
                :disabled="roleid > 5" 
                />
    </b-col>
</b-row>

<b-row>

    <b-col class="col-12 mt-3">
            <label>Company Name</label>
            <b-form-input 
                type="search"
                placeholder=""
                autocomplete="off" 
                v-model="company.text" 
                name="company.text"
                v-validate="{required:true}"
                :class="{'darkinput': true, 'is-danger': errors.has('company.text') }" 
                @input = "validateField('company.text')"
                @change = "validateField('company.text')"
                />
                <span style="color:red;">{{ errors.first('company.text') }}</span>
    </b-col>

    <b-col class="col-12 mt-3">
            <label>Address</label>
            <b-form-input 
                type="search"
                placeholder=""
                autocomplete="off" 
                v-model="company.address1" 
                name="company.address1"
                v-validate="{required:true}"
                :class="{'darkinput': true, 'is-danger': errors.has('company.address1') }"
                @input = "validateField('company.address1')"
                @change = "validateField('company.address1')"  
                />
                <span style="color:red;">{{ errors.first('company.address1') }}</span>
    </b-col>

    <b-col class="col-12 mt-3">
        <b-form-input 
                type="search"
                placeholder="optional"
                autocomplete="off" 
                v-model="company.address2" 
                name="company.address2"
                class="darkinput"
                />
    </b-col>

    <b-col class="col-12 mt-3">
        <b-form-input 
                type="search"
                placeholder="optional"
                autocomplete="off" 
                v-model="company.address3" 
                name="company.address3"
                class="darkinput"
                />
    </b-col>        

    <b-col class="col-12 mt-3">
            <label>Town / City</label>
            <b-form-input 
                type="search"
                placeholder=""
                autocomplete="off" 
                v-model="company.towncity" 
                name="company.towncity"
                v-validate="{required:true}"
                :class="{'darkinput': true, 'is-danger': errors.has('company.towncity') }"
                @input = "validateField('company.towncity')" 
                @change = "validateField('company.towncity')"  
                />
                <span style="color:red;">{{ errors.first('company.towncity') }}</span>
    </b-col>

    <b-col class="col-12 col-md-6 mt-3" v-if="country == 1">
            <label>County</label>
            <b-form-input 
                type="search"
                placeholder=""
                autocomplete="off" 
                v-model="company.county" 
                name="company.county"
                class="darkinput"
                />
    </b-col>


    <b-col class="col-12 col-md-6 mt-3">
            <label>Postcode</label>
            <b-form-input 
                type="search"
                placeholder=""
                autocomplete="off" 
                v-model="company.postcode" 
                name="company.postcode"
                class="darkinput"
                />
    </b-col>
 
    <b-col class="col-12 mt-3">
            <label>Account #</label>
            <b-form-input 
                type="search"
                placeholder="optional"
                autocomplete="off" 
                v-model="company.account" 
                class="darkinput"
                />
    </b-col>

    <b-col class="col-12 mt-3">
          
            <b-form-checkbox
                inline
                v-model="company.send_emails"
                size="lg"
                value=1
                unchecked-value=0
              />
              <label style="margin-right:15px">Send subscription emails </label> 
    </b-col>

</b-row>

   
<b-row class="mt-5">
    <b-col class="col-12">
        <b-button variant="outline-success" block ref="next" size="md" @click="checkcompanydetails()">Next</b-button>
     </b-col>  
</b-row>     

</b-container>

<CasterAddSetAdmin 
        v-if="page == 2"
        :passadmin="company"
        v-on:adminset="updateAdminUser"
        />

<b-container class="addrowpage" v-if="page == 3">


<b-row class="mt-5">

    <b-col class="col-12">
           <label>Company logo</label>
    </b-col>

    <b-col class="col-12" v-if="logoimage == '' || logoimage == null">
        <ImageUpload 
        type="branding/logos"
        v-on:upload="LogofileUploaded"
        />
    </b-col>

    <b-col class="col-12" v-else>
            <img :src="'https://ip-rtk-aws.com/images/branding/logos/'+logoimage"  style="width:100%" />
            <b-button class="mt-1" size="sm" variant="outline-success" @click="resetLogo()"><b-icon-arrow-clockwise /></b-button> 
    </b-col>        

</b-row>


<b-row class="mt-5">
         <b-col class="col-12 mt-3">         
            <b-button variant="outline-success" block size="md"  @click="brandingCheck()">Next</b-button>
        </b-col>  
</b-row>          


</b-container>

<b-container class="addrowpage" v-if="page == 4" style="font-size:1em">

    <b-row class="mt-2">
        <b-col class="col-12 mt-3"><h5>{{ company.text }}</h5></b-col>

        <b-col class="col-12 mt-3">
            <b-row>
                <b-col class="col-2"><b-icon-geo-alt class="icon1" /></b-col>
                <b-col class="col-10">
                    <b-row>
                        <b-col class="col-12">{{ company.address1}}</b-col>
                        <b-col class="col-12" v-if="company.address2">{{ company.address2}}</b-col>
                        <b-col class="col-12" v-if="company.address3">{{ company.address3}}</b-col>
                        <b-col class="col-12">{{ company.towncity}}</b-col>
                        <b-col class="col-12">{{ company.county}}</b-col>
                        <b-col class="col-12">{{ company.postcode}}</b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>

        <b-col class="col-12">
            <b-row class="mt-3" v-if="company.mobile">
                <b-col class="col-2"><b-icon-phone  class="icon1" /></b-col>
                <b-col class="col-10">{{ company.mobile}}</b-col>
            </b-row>
            <b-row class="mt-3" v-if="company.tel">
                <b-col class="col-2"><b-icon-telephone  class="icon1" /></b-col>
                <b-col class="col-10">{{ company.tel}}</b-col>
            </b-row>
        </b-col>

        <b-col class="col-12 mt-3">
            <b-row>
                <b-col class="col-2"><b-icon-person  class="icon1" /></b-col>
                <b-col class="col-10">
                    <b-row>
                        <b-col class="col-12">{{ company.fname}} {{ company.lname }}</b-col>
                        <b-col class="col-12">{{ company.email }}</b-col>
                        <b-col class="col-12">{{ company.password }}</b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>  

        <b-col class="col-12 mt-3" v-if="this.company.country == 1">
            <b-row>
                <b-col class="col-2"><b-icon-globe  class="icon1"/></b-col>
                <b-col class="col-10" >https://portal.cloudbase-rtk.net</b-col>
            </b-row>
        </b-col>

    </b-row>  
   
    
    <b-row class="mt-3"> 
         
         <b-col class="col-12 mt-3" >
              <b-form-checkbox
                          inline
                          v-model="sendwelcomeemail"
                          size="lg"
                          value=1
                          unchecked-value=0
              />
              <label style="margin-right:15px">{{  emailtypetext }} </label> 
          </b-col> 
          
          <b-col class="col-12 mt-3" v-if="sendwelcomeemail">
                          <label>Email Address</label>
                          <b-form-input 
                          type="search"
                          placeholder=""
                          autocomplete="off" 
                          v-model="emailto" 
                          class="darkinput"  
                          />
              </b-col> 
      </b-row>

    <b-row class="mt-5">
        <b-col class="col-12">
            <b-button variant="outline-success" block size="md" @click="updateCompany()"><span v-if="companyid==0">Create</span><span v-else>Update</span> Company</b-button>
        </b-col>  
    </b-row>
   

</b-container>

<b-modal ref="create-company" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row> 

   <b-row>
         <b-col class="col-12 mt-5">
        <b-button variant="outline-success" @click.stop="finishCompany()" block>Finish</b-button>
       </b-col>   
   </b-row>          

</b-modal> 

 
<SendEmail
        :sendmail="sendmail"
        :emailid="emailid"
        :emailto="emailto" 
        :id="addedcompany"
        :pw="company.password"
        :showdiag=false
        v-on:emailsent="emailSendStatus"
        />

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
		
</div> 
</template>

<style scoped>
.adminselect:hover{
    background-color: gray;
    cursor:pointer;
}
.icon1{
    font-size:1.4em;
}

</style>

<script>
    import CasterAddSetAdmin from './CasterAddSetAdmin'
    import ImageUpload from './ImageUpload'
    import SendEmail from './SendEmail'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import { Validator } from 'vee-validate'
    import './customRule';
	export default {
        props:['companyid'],
        name:'CasterCompanyAdd',
        components:{
            CasterAddSetAdmin,
            ImageUpload,
            SendEmail,
            ErrorLogging
        },
        data(){
        return {
                token:'',
                roleid:0,
                resellerid:0,
                company:{
                    value:0,
                    text:'',
                    address1:'',
                    address2:'',
                    address3:'',
                    towncity:'',
                    county:'',
                    postcode:'',
                    country:0,
                    tel:'',
                    mobile:'',
                    website:'',
                    fname:'',
                    lname:'',
                    email:'',
                    password:'',
                    business:0,
                    dealer:0,
                    adminuser:0
                },
                dealers:[],
                companyusers:[],
                user:{},
                resellers:[],
                stagemessages:[],
                logintitle:'',
                logoimage:'',
                page:1,
                validate:0,
                userpass:{},
                usersel:0,
                index:0,
                useredit:0,
                modaltitle:'',
                country:1,
                companydetail:false,
                userdetail:false,
                branddetail:false,
                companyclass:'stageactive',
                userclass:'',
                brandclass:'',
                createclass:'',
                line1class:'stageselectline',
                line2class:'stageselectline',
                line3class:'stageselectline',
                saving:false,
                display:false,
                currentemail:'',
                sent:99,
                sendmail:false,
                emailto:'',
                emailid:20, // New company email
                addedcompany:0,
                emailtypetext:'',
                sendwelcomeemail:0,
                sendgdpremail:0,
                sending:false,
                errorresponse:{},
                userfields:[
                { key: 'fname', label: 'Forename', sortable: true  },
                { key: 'lname', label: 'Surname', sortable: true  },
                { key: 'roletitle', label: 'Role', sortable: true  },
                { key: 'selected_admin', label: ' Selected', tdClass: 'text-left' }
                ],
                };
                
                },

		created(){

            this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            this.resellerid = this.$store.getters.resellerid
            this.user = this.$store.getters.user
            this.fetchResellers()
            this.fetchDealers()

            if(this.companyid == 0){
                this.newCompany()
            } else {    //Edit company
                this.companyclass = 'stagecomp'
                this.userclass = 'stagecomp'
                this.brandclass = 'stagecomp'
                this.createclass = 'stagecomp'
                this.line1class = 'stageselectlinecomp'
                this.line2class = 'stageselectlinecomp'
                this.line3class = 'stageselectlinecomp'
                this.emailtypetext = 'Re-send login details'
                this.display = true
                this.fetchCompany()
            }
          },

          mounted() {
            Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   
        },
          
          watch:{
            
            companydetail: function () {
                         if(this.companydetail){this.companyclass = 'stagecomp',this.line1class = 'stageselectlinecomp'} else {this.companyclass = 'stageactive',this.line1class = 'stageselectline'}
                         },
            branddetail: function () {
                         if(this.branddetail){this.brandclass = 'stagecomp'} else this.brandclass = 'stageactive'
                         },
       },
       methods: {

        newCompany(){
            this.company.business = this.$store.getters.resellerid
            this.company.dealer = this.$store.getters.dealerid
            if(this.company.business == 11){this.company.country = 2} else this.company.country = 1
            this.companyclass = 'stagecomp'
            this.sendwelcomeemail = 1
            this.sendgdpremail = 1
            this.emailtypetext = 'Send login details'
            this.display = true
        },

        fetchCompany(){
            axios.get('/api/castercompanies',{
                headers: {"Authorization" : "Bearer " + this.token},
                params:{
                    userid:this.$store.getters.user.id,
                    token:this.$store.getters.user.token,
                    id:this.companyid,
                }
            })
            .then((response) => {
                if(response.data.code == 999){
                    this.errorresponse = response.data
                } else {
                    this.company = response.data.CasterCompanies[0]
                    this.company.validated = true
                    this.companyusers = this.company.users
                    this.resellerid = this.company.business
                    this.countryid = this.company.country
                    this.currentemail = this.company.email
                    this.emailto = this.company.email
                    this.display = true
                }  
            }).catch( err => {
                console.error("CasterCompanyAdd, fetchCompany :",err)
            });
        },

            checkcompanydetails(){
                this.$validator.validateAll().then(result => {
                if(result){
                    this.line1class = 'stageselectlinecomp'
                    this.userclass = 'stagecomp'
                    this.page = 2
                    }
                })
            },

            updateAdminUser(details){
                    this.company.fname = details.fname
                    this.company.lname = details.lname
                    this.company.email = details.email
                    this.company.mobile = details.mobile
                    this.company.tel = details.tel
                    this.company.password = details.password
                    this.company.adminuser = details.adminuser
                    this.company.validated = true
                    this.emailto = this.company.email
                    this.line2class = 'stageselectlinecomp'
                    this.brandclass = 'stagecomp'
                    this.page = 3
                  
                },

            brandingCheck(){
                //if(this.logoimage == ''){return}
                this.line2class = 'stageselectlinecomp'
                this.createclass = 'stagecomp'
                this.page = 4
            },

            resetLogo(){
                this.logoimage = ''
            },

            validateField(field){
                this.$validator.validate(field)
                if(field == 'company.email'){this.emailCheck()}
            },

           emailCheck(){	
                if(this.company.email === this.currentemail) return;

                axios.get('/api/checkuserexists',{
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        email:this.company.email
                    }
                })
                .then((response) => {

                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        return;
                    }
                
                    this.emailerror2 = false    
                    if(response.data.exists == true){
                        this.emailerror = true
                    } else {
                        this.emailerror = false 
                        this.company.validated = false
                    }
                })
                .catch( err => {
                    console.error("CasterCompanyAdd, emailCheck :",err)
                })
            },

            setPage(id){
                
                switch(id){
                    case 1:
                    this.page = id
                    break
                    case 2:
                     if(this.company.validated){
                        this.page = id
                        } 
                    break    
                    case 3:
                     if(this.company.validated){
                        this.page = id
                        } 
                    break     
                    case 4:
                    if(this.brandclass == 'stagecomp'){
                        this.page = id
                        }
                    break
                   
                }
            }, 

          
            LogofileUploaded(file){
                this.logoimage = file
            },

            fetchResellers(){

                axios.get('/api/casterbusinesses',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                        }
                })
                .then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                    } else {
                        this.resellers.push({text:'Select',value:0})
                        this.resellers.push(...response.data.CasterBusiness)
                        }
                    })
                },

            fetchDealers(){
                axios.get('/api/casterdealers',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellerid,
                        dropdown:1
                    }
                })
                .then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        return;
                    }
                    this.dealers = []
                    this.dealers.push({text:'Select',value:0})
                    this.dealers.push(...response.data.CasterDealers)
                })
                .catch(err => {
                    console.error("CasterCompanyAdd, fetchDealers :",err)
                });
            },

            changeReseller(value){
                if(value == 11 ){this.company.country = 2} else this.company.country = 1
                this.company.business = value 
                this.company.dealer = 0
                this.resellerid = value 
                this.fetchDealers()
            },

            finishCompany(){
                    this.$refs['create-company'].hide()
                    this.$emit('updatemodal')
            },

            updateCompany(){
                if(this.company.value == null){this.modaltitle = 'Creating Company'} else this.modaltitle = 'Updating Company'
                this.$refs['create-company'].show()
                this.company.logo = this.logoimage
                this.company.logintitle = this.logintitle  

                axios({
                    method: 'post',     //   New Company
                    headers: {"Authorization" : "Bearer " + this.token},
                    url: '/api/castercompanyaddtest',
                    data: {
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        companydata:this.company
                        }
                })
                .then((response)=> {

                    if(response.data.code == 999){
                            this.errorresponse = response.data
                    } else {
                        this.stagemessages = response.data.messages
                        this.company.value = response.data.value
                        this.addedcompany = this.company.value
                        if(this.sendwelcomeemail == 1){this.sendEmail()}
                                    if(this.sendgdpremail == 1){
                                        setTimeout(this.sendGDPREmail, 3000);
                                    }
                    }
            
                })
                .catch( err => {
                    console.error("CasterCompanyAdd, updateCompany :",err)
                })
                .finally( () => {
                    this.saving = false;
                })
            },
         
            sendEmail(){
                this.sendmail = !this.sendmail // trigger to SendEmail
            },

            sendGDPREmail(){
                this.emailid = 16 // Initial GDPR compliance email
                this.sendmail = !this.sendmail // trigger to SendEmail
            },

            emailSendStatus(data){
                    this.stagemessages.push(...data.messages)
            }, 

            closemodal(){
                this.$emit('closemodal')
            },

		}	   
	
	};

</script>