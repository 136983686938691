<script>
  
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: ['chartdata','datasetsel'],

  data(){
		return {

    test:0,
    tempdata:[],

    cdata:{
        labels:[],
        datasets:[],
    },

    ylabel:'testme',
    
    chartoptions: {
            responsive: true,
            maintainAspectRatio: false,
            
            plugins: {
                datalabels: {
                display: false,
                },
            },
            legend:{
                position: 'bottom',
                labels: {
                    fontColor: 'rgba(255,255,255,0.5)'
                    }
            },
           
            scales: {
                yAxes: [{
                scaleLabel: {
                  display: true,
                  labelString: '',
                  fontColor: "rgba(255,255,255,0.7)", 
                },       
                ticks: {
                fontColor: "rgba(255,255,255,0.7)", 
                stepSize: 10,
                reverse: false,
                beginAtZero: true,
                },
                gridLines: { 
                    color:  "#666",
                    zeroLineColor: "#666" },
                }],
                xAxes: [{
                ticks: {
                    fontColor: "rgba(255,255,255,0.7)", 
                    }
                }]
            },
        },
    };
                
  },


    mounted () {
        this.createChart()
    },

    watch: {
        chartdata: function() {
            this.createChart()
        },
        datasetsel: function(){
             this.createChart()
        }
    },

    methods:{

        createChart(){

          
            let dsoptions = {
                label:'',
                pointBackgroundColor:'#3cd2a5',
                fill:false,
                borderColor:'#3cd2a5',
                data:[],
            }

            let ds2 = JSON.stringify(dsoptions)
            this.cdata.labels = this.chartdata.labels
            this.tempdata = []
            
                    let dataset = JSON.parse(ds2)
                   
                    switch(this.datasetsel){
                        case 0:
                        case undefined:    
                        dataset.data = this.chartdata.datasets.data
                        dataset.label = this.chartdata.datasets.label
                        break;
                        case 1:
                        dataset.data = this.chartdata.datasets.data2
                        dataset.label = this.chartdata.datasets.label2
                        break;
                        case 2:
                        dataset.data = this.chartdata.datasets.data3
                        dataset.label = this.chartdata.datasets.label3
                        break;
                    }

                    this.tempdata.push(dataset)
                    this.cdata.datasets = this.tempdata
            
            this.chartoptions.scales.yAxes[0].scaleLabel.labelString = dataset.label
            
            this.renderChart(this.cdata, this.chartoptions)
        
        }
    }
}

</script> 