<template>
<div>
<b-container class="mainpagecont">

<b-row class="mt-5 pt-3">

        <b-col class="col-4">
            <h4>Event Log Admin</h4>
        </b-col>

         <b-col class="col-3 offset-2" v-if="showdatefilter">
                        <label>Start Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterstart"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterstart" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2"
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

            <b-col class="col-3" v-if="showdatefilter">
                        <label>End Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterend"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterend" 
                                autocomplete = "off"
                                placeholder="select" 
                                class="darkinput2"
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

</b-row>  

<b-row class="mt-3"> 

        <b-col class="col-3">
            <label>Event Group</label>
            <b-form-select class="darkinput2"  v-model="groupsel" :options="groups"  size="sm"  @change="filterGroup($event)" ></b-form-select>     
        </b-col>

         <b-col class="col-3">
            <label>Event Type</label>
            <b-form-select class="darkinput2"  v-model="typesel" :options="types" size="sm" @change="filterType($event)" ></b-form-select>     
        </b-col>
</b-row>

<b-row class="mt-3">
            <b-col class="col-6">
                <b-button variant="outline-success" size="sm"  @click="fetchLogs()"><b-icon-arrow-clockwise /></b-button>
                <b-button variant="outline-success" size="sm"  @click="clearFilter()"><b-icon-x-circle /></b-button>
            </b-col>   

            <b-col class="col-6 text-right">
                <b-button variant="outline-success" size="sm"  @click="showDateFilter()"><b-icon-calendar-date /></b-button>
            </b-col>           
</b-row>  


<b-row>

    
<b-col v-if="pages.length >  0" class="col-5 mt-3">{{ rstart }}-{{ rend }} of {{ total }} Results</b-col>
<b-col v-else class="col-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>


 <b-col class="col-7 text-right mt-3" v-if="total > limit">
      <b-btn v-for="page in pages" :key="page.value" size="sm" @click="gotoPage(page.value)" style="min-width:30px;margin-right:1px">{{ page.text }}</b-btn>      
</b-col>

</b-row>

<b-row class="mt-3">

      <b-col>
             <b-table 
                striped 
                responsive 
                hover
                :items="events" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :busy="isBusy"
                dark
                >

                 <template #cell(typetext)="data">
                    {{ data.item.typename }} : {{ data.item.text }} 
                </template>
            
                <template #table-busy>
                    <div class="text-center text-warning my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong style="margin-left:15px">Loading...</strong>
                    </div>
                </template>

                </b-table>
       </b-col>             
</b-row>            
  
</b-container>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style>

.errorevent{background-color:rgb(194, 82, 82) !important;font-size:0.8em !important;}
.successevent{background-color:rgb(53, 135, 53) !important;font-size:0.8em !important;}
.adminevent{background-color:rgb(65, 65, 171) !important;font-size:0.8em !important;}

</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        name:'CasterEventLog',
        components:{
            ErrorLogging
            },
        data(){
		return {
    
                token:'',
                events:[],
                dealers:[],
                companies:[],
                basestations:[],
                types:[],
                groups:[
                    {value:0,text:'All'},
                    {value:1,text:'User'},
                    {value:2,text:'Admin'},
                    {value:3,text:'System'},
                    {value:4,text:'Basestations'},
                    {value:5,text:'GDPR'},
                    {value:6,text:'Subscriptions'},
                    {value:7,text:'SIMS'}
                ],
                companysel:0,
                dealersel:0,
                typesel:0,
                groupsel:1,
                basesel:0,
                showdatefilter:false,
                filterstart:'',
                filterend:'',
              
               fields: [
                    { key: 'datetime', label: 'date/time', sortable: false}, 
                    { key: 'typename', label: 'action', sortable: false},
                    { key: 'detail', label: 'detail', sortable: false},
                    { key: 'username', label: 'user', sortable: false},
                ],
                page:0,
                total:0,
                limit:100,
                rstart:1,
                rend:0,
                pages:[],
                
                sortBy: 'value',
                sortDesc: true,
                isBusy: false,
                errorresponse:{}
            
                };
                
                },

     
		created(){
            this.token = this.$store.getters.token
            this.fetchTypes()
            this.fetchLogs()
        },


       
		methods: {

            gotoPage(page){
                this.page = page
                this.fetchLogs()
            },

            updatePaging(){
                this.rstart = (this.page * this.limit) + 1
                this.rend = (this.rstart + this.limit) - 1
                if(this.rend > this.total){this.rend = this.total} 
            },

            fetchLogs(){
                this.isBusy = true

                axios.get('/api/events',{
                        headers: {"Authorization" : "Bearer " + this.$store.getters.user.token},
                        params:{
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            limit:this.limit,
                            page:this.page,
                            dealer:this.dealersel,
                            company:this.companysel,
                            group:this.groupsel,
                            type:this.typesel
                            //text:this.rovername
                            }
                        })
				.then((response) => {

                    this.isBusy = false
                    if(response.data.code == 999){
                            this.errorresponse = response.data
                    } else {
                        this.events = response.data.CasterEvents
                        this.total = response.data.Total
                        this.pages = []
                                let pages = Math.ceil(this.total/this.limit)
                                let count = 0
                                while(count < pages){
                                    this.pages.push({value:count,text:count+1})
                                    count++
                                }
                        this.updatePaging()
                         //if(!this.timeout){this.timeout = setInterval(this.fetchEvents, 5000)}
                        }
                    });
            },

            fetchTypes(){

				axios.get('/api/castereventtypes',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        group:this.groupsel
                        }
                })
				.then((response) => {
                    this.types = []
                    this.types.push({value:0,text:'All'}) 
                    this.types.push(...response.data.CasterEventTypes)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
            
           filterType(event){
               this.typesel = event
               this.currentpage = 1
               this.fetchLogs()
           },

           filterGroup(event){
               this.groupsel = event
               this.currentpage = 1
               this.fetchLogs()
               this.fetchTypes() 
           },

        
           showDateFilter(){
               this.showdatefilter = !this.showdatefilter
           },

           clearFilter(){
               this.dealersel = 0
               this.companysel = 0
               this.typesel = 0
               this.basesel = 0
               this.groupsel = 0
               this.start = ''
               this.end = ''
               this.offset = 0
               this.fetchTypes()
               this.fetchLogs()
           },

           setPaging(number){
               this.perPage = number
           },
    
		}	   
	
	};

</script> 


