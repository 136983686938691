<template>
<div>
    <b-row class="mt-1">
             <b-col class="col-10 mt-3"><h6>CASTA STATUS</h6></b-col>
             <b-col class="col-2 mt-3 text-right"><b-icon-chevron-expand style="cursor:pointer" @click.stop="setExpand()" /></b-col>
             <b-col class="col-12 mt-3" v-if="systemalarms.length == 0  && expand">
                <b-button variant="outline-warning" size="sm" disable block>Waiting for sync data</b-button>
             </b-col>
             <b-col class="col-12 mt-3" v-if="systemalarms.length != 0  && expand">
                <b-button v-for="alarm in systemalarms" :key="alarm.id" size="sm" @click.stop="menuLink('castersystemstatus')" :style="{backgroundColor:setstatus(alarm)}" block>{{ alarm.description }} : Last sync {{ settime(alarm) }}s</b-button> 
             </b-col>
    </b-row>

</div>
</template>


<script>
    
    import { mapState } from 'vuex'
    import moment from 'moment'
    export default {
        data() {
            return {
                timeout:false,
                systemalarms:[],
                errorresponse:{},
                expand:true
            }
        },     
        created(){
           
        },

        beforeDestroy () {
            clearTimeout(this.timeout)
        }, 
        
        computed: mapState(['maint','alarms']),

        watch: {
                alarms(){
                    this.systemalarms = this.alarms
                }
        },
                
        methods: {

            setExpand(){
                this.expand = !this.expand
            },

            setstatus(alarm){
                let date1 = moment()
                let date2 = moment(alarm.lastcasterpoll) 
                let diff = date1.diff(date2, 'seconds');

                if(diff > 30){return 'rgba(255, 96, 96, 0.7)'} else return 'rgba(60, 210, 165, 0.7)'
            },

            settime(alarm){
                let date1 = moment()
                let date2 = moment(alarm.lastcasterpoll) 
                let diff = date1.diff(date2, 'seconds')
                return diff
            },

            menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },
       
		}	   
	
	};
</script> 