<template>
<div class="imageBorder">

<b-row class="mt-1">
                <b-col class="col-12 col-sm-6 col-md-4 mt-3">

                   <VueCtkDateTimePicker
                            v-model="startdate"
                            format='DD-MM-YYYY HH:mm'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-input-group>  
                            <b-input-group-prepend is-text>
                                <b-icon-calendar3 class="icon" style="color:white" />
                            </b-input-group-prepend>    
                            <b-form-input
                                type="search"
                                v-model="startdate" 
                                autocomplete = "off"
                                placeholder="start date/time"
                                class="darkinput"

                                />
                             </b-input-group>   
                            </VueCtkDateTimePicker>    
                </b-col>

                <b-col class="col-12 col-sm-6 col-md-4 mt-3">
             
                 <VueCtkDateTimePicker
                            v-model="enddate"
                            format='DD-MM-YYYY HH:mm'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-input-group>  
                            <b-input-group-prepend is-text>
                                <b-icon-calendar3 class="icon" style="color:white" />
                            </b-input-group-prepend>    
                            <b-form-input
                                type="search"
                                v-model="enddate" 
                                autocomplete = "off"
                                placeholder="end date/time"
                                class="darkinput"
                                />
                             </b-input-group>   
                            </VueCtkDateTimePicker>    
                </b-col>
           
</b-row>    

<b-row class="mt-3">
      <b-col class="col-12 mt-2">
           
            <div v-if="sessions.length > 0">    
             <b-table
                ref="sessionstable" 
                responsive 
                hover
                :items="sessions" 
                :fields="fields"
                select-mode="single"
                selectable
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                @row-clicked="showSession($event)"
                dark
                />

            </div>

            <div v-else>

                <b-row class="mt-3">
                    <b-col class="col-12 text-center pt-3 pb-3">
                        <h5>No Data For Rover</h5>
                    </b-col>   
                </b-row>

            </div>         

             
       </b-col>
</b-row>

<b-modal ref="datesel-modal-1" size="sm" :title="modaltitle" hide-footer hide-header centered body-class="p-0">
              <VueCtkDateTimePicker
              v-model="startdate"
              format='DD-MM-YYYY'
              :dark="true"
              :inline="true"
              :only-date="true"
              />
</b-modal>

<b-modal ref="datesel-modal-2" size="sm" :title="modaltitle" hide-footer hide-header centered body-class="p-0">
              <VueCtkDateTimePicker
              v-model="enddate"
              format='DD-MM-YYYY'
              :dark="true"
              :inline="true"
              :only-date="true"
              />
</b-modal>

</div> 
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
    props:['id','sessionid'],
    data(){
        return {
                token:'',
                startdate:'',
                enddate:'',
                sessions:[],
                sessionsel:0,
                currentsession:0,
                fields:[
                { key: 'datetime', label: 'Date/Time', formatter:'formatTime2',sortable: true  },  
                { key: 'session_id', label: 'Session ID', sortable: true  },    
                { key: 'basestation', label: 'Basestation', sortable: true  },   
                { key: 'time_to_fix', label: 'RTK Fix Time', sortable: true  },
                { key: 'connection_time', label: 'Connection Time', formatter:'formatTime', sortable: true  },
                { key: 'quality', label: 'Quality', sortable: true  },
                { key: 'bytessent', label: 'Bytes Sent', sortable: true  },
                { key: 'ggas', label: '#GPGGA', sortable: true  },
                ],
                modaltitle:'',
                timer:false,
                start:true
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.fetchSessions()
        },

         beforeDestroy () {
            clearInterval(this.timer)
        },

           watch: {
                        id: function(){
                            this.fetchSessions()
                        },

                        sessionsel: function(){
                            this.fetchSessions()
                        },

                        startdate: function () {
                            this.fetchSessions()
                         },
                        enddate: function () {
                            this.fetchSessions()
                         } 
          },

		methods: {

            setStartDate(){
                this.modaltitle = 'Start Date'
                this.$refs['datesel-modal-1'].show()
            },  
            
            setEndDate(){
                this.modaltitle = 'End Date'
                this.$refs['datesel-modal-2'].show()
            },
            
            setDate(){
                this.startdate = moment().format('DD-MM-YYYY')
                this.enddate = moment().add(1, 'days').format('DD-MM-YYYY')
            },

            fetchSessions(){

              axios.get('/api/roversessionstest',{
                headers: {"Authorization" : "Bearer " + this.token},
                        params:{
                              userid:this.$store.getters.user.id,
                              token:this.$store.getters.user.token,
                              id:this.id,
                              start:this.startdate,
                              end:this.enddate,
                              sessiondata:0
                          }
                      })
              .then((response) => {        
              if(response.data.code == 999){
                        console.log(response.data)
                      } else {
                            this.sessions = response.data.Sessions
                            if(this.sessionsel == 0){this.sessionsel = this.sessionid}
                            let session = this.sessions.filter(session => session.session_id == this.sessionsel)
                            this.$emit('session',session[0])
                            if(!this.timer){
                               this.timer = setInterval(this.fetchSessions, 5000) 
                            }
                          }
              })          
            },

            showSession(event){
                this.sessionsel = event.session_id
                let session = this.sessions.filter(session => session.session_id == this.sessionsel)
                this.$emit('session',session[0])
            },

            formatTime(start){
                    let days = parseInt(start/86400)
                    let hours = Math.trunc(parseInt(start - (days * 86400))/3600) 
                    let minutes = Math.trunc(parseInt((start - (days * 86400) -(hours * 3600))/60)) 
                    let secs = Math.trunc((start - (days * 86400) - (hours * 3600) - (minutes * 60))) 
                    let duration = days+'d '+ hours +'h '+ minutes +'m '+ secs+'s'
                    return duration
            },

             formatTime2(datetime){  
                    let time =  moment.utc(datetime).local().format('DD-MM-YY HH:mm:ss')
                    return time
                },

		}	   
	
	};

</script> 


