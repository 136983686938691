<template>
<div>
    <b-container >

    <b-row v-if="response == ''">
           

        <b-col class="col-12 mt-3">
              
            <b-form-checkbox
                    inline
                    v-model="confirm"
                    size="lg"
                    value=1
                    unchecked-value=0
              />
              <label style="margin-left:5px;">Yes I definately want to restart Connecta </label>
        </b-col>

        <b-col class="col-12 mt-3" v-if="confirm == 1">
            <b-button variant="danger" block @click="restartConnecta()">RESTART CONNECTA</b-button>
        </b-col>

        <b-col class="col-12 mt-3 mb-1" v-if="response != ''">
            <b-button variant="success" block disabled v-if="success">{{ response }} <b-icon-check2-circle /></b-button>
            <b-button variant="danger" block disabled v-else>{{ response }}  <b-icon-x-circle /></b-button>
        </b-col>
    </b-row>

    <b-row v-else>
        <b-col class="col-12">
            <b-button variant="success" block disabled v-if="success">{{ response }} <b-icon-check2-circle /></b-button>
            <b-button variant="danger" block disabled v-else>{{ response }}  <b-icon-x-circle /></b-button>
        </b-col>
    </b-row>    

</b-container>
		
</div> 
</template>

<script>
    import axios from 'axios'
	export default {
        data(){
		return {
                castaid:0,
                response:'',
                success:false,
                confirm:false,
                };
                
                },

	
		methods: {

           restartConnecta(){
                    this.response = ''
                    this.success = false
                    axios.get('/api/restartconnecta',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.token,
                        id:this.castaid
                    }
                })

                .then((response)=> {
                    console.log(response)
                    if(response.data.code == 999){
                        this.response = 'Restart Failed'
                        this.success = false
                    } else {
                            this.response = 'Restart Successful'
                            this.success = true
                        }
                })
              
            },

       
         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script> 