<template>
<div>

    <b-container>
        <b-row>       
            <b-col class="col-12">
                <b-form-file 
                id="imageUploadInput"
                ref="file-input"
                single
                v-model="image" 
                placeholder="Choose image.."
                drop-placeholder="Drop file here..."
                accept= ".jpeg, .jpg, .png"
                class="darkinput"   
                />
            </b-col>
        </b-row>
    </b-container>

    <b-modal v-model="uploading" hide-header hide-footer centered content-class="darkmodal">
        <b-row class="p-3">
            <b-col class="col-12 text-center">
            <h3>Uploading Image <b-icon-upload animation="cylon-vertical"/> </h3>
            </b-col>
        </b-row> 
    </b-modal> 

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
        
</div> 
</template>

<style>
    #imageUploadInput__BV_file_outer_ > label{
        background-color:#212529 !important;
        font-family:'Chakra Petch' !important;
        border-color: rgb(206, 212, 218) !important;
        color: rgba(255,255,255,0.5) !important;
    }
</style>

<script>

    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    export default {
        props:['type'],
        components:{ErrorLogging},
        data(){
            return {
                token:'',
                image:null,
                lockfield:false,
                errorresponse:{},
                uploading:false,
            };
        },

        created(){
            this.token = this.$store.getters.token
        },

        watch: {
            image: function () {
                if(this.image !== null){
                    this.uploadImage(this.image) 
                }
            }
        },
        
        methods: {

            uploadImage(image){
                this.uploading = true;

                const formData = new FormData
                formData.append('files', image);
                formData.append('type',this.type)
                formData.append('userid',this.$store.getters.user.id)
                formData.append('token',this.$store.getters.user.token)
                
                axios({
                    method: 'post',
                    headers: {"Authorization" : "Bearer " + this.token},
                    url: '/api/photoupload',
                    data: formData

                })
                .then((response)=>{
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        return;
                    }
                    this.$refs['file-input'].reset()
                    this.$emit('upload',response.data.filename)

                })
                .catch(err => {
                    console.error("ImageUpload: ",err);
                })
                .finally( () => {
                    this.uploading = false;
                });
            }, 

            updatemodal(){
                this.$emit('updatemodal')
            },
   
        }       
    
    };

</script> 


