<template>
<div>
<b-container class="mainpagecont">

    <b-row class="mb-3" >
    <b-col class="col-12 col-md-4 mt-1 p-2">
        <b-button size="sm" variant="outline-success" block @click.stop="checkCasta()">Casta Connection Check</b-button>
    </b-col>
    <!-- <b-col class="col-12 col-md-4 mt-1 p-2" v-if="!denmarkonly">    
        <b-button size="sm" variant="outline-danger" block @click.stop="restartConnecta()">Restart CONNECTA</b-button>
    </b-col> -->
    <b-col class="col-12 col-md-4  mt-1 p-2 offset-md-4" v-if="!denmarkonly">    
        <b-button size="sm" variant="outline-danger" block @click.stop="restartCasta()">Restart CASTA</b-button>
    </b-col>
</b-row>

<b-row class="mb-3" >
    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterSystemCheckCard />
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterSystemAlarmcard />
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2" v-if="!denmarkonly">
        <CasterSystemChartCard2 :type=2 :system=1 :sum="charttotals[2]" :update="updatechart" v-on:datasum="storeSum"/>
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterSystemChartCard2 :type=2 :system=2 :sum="charttotals[2]" :update="updatechart" v-on:datasum="storeSum"/>
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2" v-if="!denmarkonly">
        <CasterSystemChartCard3 :type=3 :system=1 :sum="charttotals[3]" :update="updatechart" v-on:datasum="storeSum"/>
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterSystemChartCard3 :type=3 :system=2 :sum="charttotals[3]" :update="updatechart" v-on:datasum="storeSum"/>
    </b-col>

     <b-col class="col-12 col-md-6 mt-1 p-2" v-if="!denmarkonly">
        <CasterSystemChartCard1 :type=1 :system=1 :sum="charttotals[1]" :update="updatechart" v-on:datasum="storeSum"/>
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterSystemChartCard1 :type=1 :system=2 :sum="charttotals[1]" :update="updatechart" v-on:datasum="storeSum"/>
    </b-col>

    <!-- <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterSystemChartCardRT  :system=1  :update="updatechart" />
    </b-col> -->

    <!-- <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterSystemChartCardRT  :system=2  :update="updatechart" />
    </b-col> -->

    <b-col class="col-12 col-md-6 mt-1 p-2" v-if="!denmarkonly">
        <CasterSystemChartCard4 :type=4 :sum="charttotals[4]" :system=1 :update="updatechart" v-on:datasum="storeSum"/>
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterSystemChartCard4 :type=4 :sum="charttotals[4]" :system=2 :update="updatechart" v-on:datasum="storeSum"/>
    </b-col>

     <b-col class="col-12 mt-3 mt-1 p-2">
            <CasterRoverEventsCard  :update="true" :roverid=0 />
    </b-col> 
    
<b-modal ref="casta-check" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
        <CasterCheck
            :denmarkonly="denmarkonly"
            v-on:updatemodal="updateModal"
            />
</b-modal>

<b-modal ref="casta-restart" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
        <CasterRestart
            v-on:updatemodal="updateModal"
            />
</b-modal>

<b-modal ref="connecta-restart" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
        <ConnectaRestart
            v-on:updatemodal="updateModal"
            />
</b-modal>

</b-row>

</b-container>
</div> 
</template>

<script>
    import CasterSystemAlarmcard from './CasterSystemAlarmcard'
    import CasterSystemCheckCard from './CasterSystemCheckcard'
    import CasterSystemChartCard1 from './CasterSystemChartCard1'
    import CasterSystemChartCard2 from './CasterSystemChartCard2'
    import CasterSystemChartCard3 from './CasterSystemChartCard3'
    import CasterSystemChartCard4 from './CasterSystemChartCard4'
    import CasterCheck from './CasterCheck'
    import CasterRestart from './CasterRestart'
    import ConnectaRestart from './ConnectaRestart'
   // import CasterSystemChartCardRT from './CasterSystemChartCardRT'
    import CasterRoverEventsCard from './CasterRoverEventsCard'
    export default {
        name:'CasterAdminStatus',
        components:{
            CasterSystemAlarmcard,
            CasterSystemCheckCard,
            CasterSystemChartCard1,
            CasterSystemChartCard2,
            CasterSystemChartCard3,
            CasterSystemChartCard4,
           // CasterSystemChartCardRT,
            CasterRoverEventsCard,
            CasterCheck,
            CasterRestart,
            ConnectaRestart
        },
        data(){
            return {
                    timeout:false,
                    charttotals:[0,0,0,0,0],
                    updatechart:false,
                    modaltitle:'',
                    denmarkonly:false,
                };
                
                },

		created(){
            if(this.$store.getters.user.business == 11){
                this.denmarkonly = true
            }
            this.$store.commit('setbasesdatapoll',0)
            this.autoUpdate()
            },

        beforeDestroy () {
            clearTimeout(this.timeout)
        },    

       
		methods: {

        autoUpdate(){
                this.updatechart = !this.updatechart
                if(!this.timeout){
                this.timeout = setTimeout(this.autoUpdate,3000)
                } 
        },

        storeSum(data){
            if(this.charttotals[data.value] != data.value){
                this.charttotals[data.type] = data.value
             }
        },

        checkCasta(){
            this.modaltitle = 'Check Casta Connection'
            this.$refs['casta-check'].show()
        },

       restartCasta(){
            this.modaltitle = 'Restart Casta'
            this.$refs['casta-restart'].show()
        },

        restartConnecta(){
            this.modaltitle = 'Restart Connecta'
            this.$refs['connecta-restart'].show()
        },

        updateModal(){
            //this.$refs['casta-check'].hide()
        }

		}	   
	
	};

</script>