<template>
<div>

    <b-container>

        <b-row>
        
            <b-col class="col-12 col-sm-6 mt-3">
                <label>Name</label>
                    <b-form-input 
                        type="search" 
                        placeholder="required" 
                        autocomplete="off"
                        v-model="base.text"
                        name="base.title"
                        v-validate="{required:true}"
                        :class="{'input': true, 'is-danger': errors.has('base.title') }"  
                        class="darkinput"
                        @input = "validateField('base.title')"
                        />
                        <span style="color:red;">{{ errors.first('base.title') }}</span>
            </b-col>

            <b-col class="col-12 col-sm-6 mt-3">
                <label>Mount Point</label>
                    <b-form-input 
                        type="search"
                        placeholder="required"
                        autocomplete="off" 
                        v-model="base.mount" 
                        name="base.mount"
                        v-validate="{required:true}"
                        :class="{'input': true, 'is-danger': errors.has('base.mount') }"
                        class="darkinput"
                        @input = "validateField('base.mount')"  
                        />
                        <span style="color:red;">{{ errors.first('base.mount') }}</span>
            </b-col>

            <b-col class="col-12 col-sm-6 mt-3">
                <label>Station ID</label>
                    <b-form-input 
                        type="search"
                        placeholder="optional"
                        autocomplete="off" 
                        v-model="base.station_id"
                        class="darkinput" 
                    />
            </b-col>

        </b-row>

        <b-row>
        
            <b-col class="col-12 col-sm-6 mt-3">
                <label>Type</label>
                    <b-form-select
                        v-model="base.type"
                        :options="types"
                        class="darkinput"
                        size="sm"
                        name="base.type"
                        v-validate="{min_value:1}"
                        :class="{'input': true, 'is-danger': errors.has('base.type') }"
                        @change = "validateField('base.type')"
                        :disabled="baselock"  
                        />
                        <span style="color:red;">{{ errors.first('base.type') }}</span>
                        
            </b-col>

            <b-col class="col-12 col-sm-6 mt-3">
                <label>Country</label>
                    <b-form-select
                        v-model="base.country"
                        placeholder=""
                        :options="countries"
                        name="base.country"
                        v-validate="{min_value:1}"
                        :class="{'input': true, 'is-danger': errors.has('base.country') }"
                        class="darkinput"
                        @input = "validateField('base.country')" 
                        size="sm"
                        :disabled="baselock" 
                        />
                        <span style="color:red;">{{ errors.first('base.country') }}</span>
                        
            </b-col>

            <b-col class="col-12 mt-3">
                <label>Latitude</label>
                    <b-form-input 
                        type="search"
                        placeholder="required"
                        autocomplete="off" 
                        v-model="base.latitude" 
                        name="base.latitude"
                        v-validate="{required:true}"
                        :class="{'input': true, 'is-danger': errors.has('base.latitude') }"
                        class="darkinput"
                        @input = "validateField('base.latitude')"  
                        />
                        <span style="color:red;">{{ errors.first('base.latitude') }}</span>
            </b-col>

            <b-col class="col-12 mt-3">
                <label>Longitude</label>
                    <b-form-input 
                        type="search"
                        placeholder="required"
                        autocomplete="off" 
                        v-model="base.longitude" 
                        name="base.longitude"
                        v-validate="{required:true}"
                        :class="{'input': true, 'is-danger': errors.has('base.longitude') }"
                        class="darkinput"
                        @input = "validateField('base.longitude')"  
                        />
                        <span style="color:red;">{{ errors.first('base.longitude') }}</span>
            </b-col>

            <b-col class="col-12 col-sm-6 mt-3">
                <label>Altitude</label>
                    <b-form-input 
                        type="search"
                        placeholder="optional"
                        autocomplete="off" 
                        v-model="base.altitude" 
                        class="darkinput"
                        />
                        
            </b-col>

            <b-col class="col-12 mt-3">
                <label>Base Serial No.</label>
                    <b-form-input 
                        type="search"
                        placeholder="optional"
                        autocomplete="off" 
                        v-model="base.client_serial" 
                        class="darkinput"
                        />
                        
            </b-col>

            <b-col class="col-12 mt-3">
                <label>GNSS Serial No.</label>
                    <b-form-input 
                        type="search"
                        placeholder="optional"
                        autocomplete="off" 
                        v-model="base.gnss_serial" 
                        class="darkinput"
                        />
                        
            </b-col>

        
    </b-row>
    
    <b-row class="mt-5 mb-3"  v-if="!edit">
            <b-col class="col-12">
            <b-button variant="outline-success" block  @click.stop="updateBase()"><b-icon-check-circle class="mr-2" />SAVE</b-button>
            </b-col>
    </b-row>

    <b-row class="mt-5 mb-3"  v-else>
        <b-col class="col-12 col-sm-5  mt-3">
                <b-button variant="outline-success" block  @click.stop="updateBase()"><b-icon-check-circle class="mr-2" />SAVE</b-button>
            </b-col>
            <b-col class="col-12 col-sm-5 offset-sm-2 mt-3">
                <b-button variant="outline-danger" block  @click.stop="DeleteBaseModalVisibility()"><b-icon-x-circle  class="mr-2" />DELETE</b-button>
            </b-col>
    </b-row> 

    </b-container>

    <!-- Update/Create Base Confirmation Modal -->
    <b-modal v-model="updateCreateModal" hide-header hide-footer centered content-class="darkmodal">

        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <h3>Base: "{{base.text}}" {{updateCreateMessage}} <b-icon icon="check-lg" variant="success" animation="throb"/> </h3>
            </b-col>
        </b-row>

    </b-modal> 

    <!-- Delete Base Confirmation Modal -->
    <b-modal v-model="deleteModal" hide-header hide-footer centered content-class="darkmodal">

        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <h4>Delete Reference Station?</h4>
            </b-col>
        </b-row>

        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <h4 ref="deleteModalMessage">Remove "{{base.text}}" permanently</h4>
            </b-col>
        </b-row> 

        <b-row class="mt-5 mb-3">
        <b-col class="col-12 col-sm-5  mt-3">
                <b-button variant="outline-danger" block  @click.stop="removeBase()"><b-icon-check-circle class="mr-2" />DELETE</b-button>
            </b-col>
            <b-col class="col-12 col-sm-5 offset-sm-2 mt-3">
                <b-button variant="outline-warning" block  @click.stop="DeleteBaseModalVisibility()"><b-icon-x-circle  class="mr-2" />CANCEL</b-button>
            </b-col>
    </b-row> 

    
    </b-modal> 

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
                
</div> 
</template>

<script>
    import ErrorLogging from './ErrorLogging'
    import { Validator } from 'vee-validate';
    import './customRule';
    import axios from 'axios'

    export default {
        props:['id','country'],
        name:'CasterBaseEdit',
        components:{
            ErrorLogging
        },
        data(){
            return {
                base:{text:""},
                edit:false,
                baselock:false,
                countries:[
                    { value:0,text:'Select'},
                    { value:1,text:'UK'},
                    { value:2,text:'DENMARK'},
                ],
                types:[
                    { value:0,text:'Select'},
                    { value:1,text:'Cloudbase Mk 1'},
                    { value:2,text:'Cloudbase Mk 2'},
                    { value:3,text:'Cloudbase Mk 3'},
                    { value:4,text:'Cloudbase Mk 4'}
                ],
                errorresponse:{},
                deleteModal:false,
                updateCreateModal:false,
                updateCreateMessage:"",
            };
        },

        created(){
            
            if(this.id > 0){
                this.edit = true
                this.fetchBase()
            }  else {
                if(this.$store.getters.user.business == 11){
                    this.base.country = 2
                    this.base.type = 4
                    this.baselock = true
                } else {
                    this.base.country = 0
                    this.base.type = 0
                }
            }
          
        },

        mounted() {

            Validator.localize('en',{
                messages: {
                    required: '** required.',
                    min_value: '** select value.'
                },
            })  

        },
        
        methods: {

            fetchBase(){
                axios.get('/api/basestations',{
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:this.id
                    }
                })
                .then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        return;
                    }
                    this.base = response.data.basestations[0]
                })
                .catch(err => {
                    console.error("CasterBaseEdit, fetchBase :",err)
                });
            },

            DeleteBaseModalVisibility(){
               this.deleteModal = !this.deleteModal; 
            },

            removeBase(){
                axios({
                    method: 'delete',
                    headers: { "Authorization" : "Bearer " + this.$store.getters.token },
                    url: '/api/basestation/'+this.base.value,
                    data:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,             
                    }
                })
                .then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        return;
                    }
                    //updatamodal will close all modals
                    this.$emit('updatemodal');
                })
                .catch( err => {
                    console.error("CasterBaseEdit, removeBase :",err)
                    this.$refs.deleteModalMessage.innerText = "Delete Failed";
                    this.$refs.deleteModalMessage.style.color = "red";
                    setTimeout(() => {
                        this.$emit('updatemodal');
                    },5000)
                });    
           },

         
            updateBase(){
                this.$validator.validateAll().then(result => {
               
                    if(!result) {
                        console.log('failed validation');
                        return;
                    }


                    if(this.edit == false){    

                        axios({
                            method: 'post',  
                            headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                            url: '/api/basestation',
                            data: {
                                userid:this.$store.getters.user.id,
                                token:this.$store.getters.user.token,
                                basedata:this.base
                            }
                        })
                        .then((response)=> {
                        
                            if(response.data.code == 999){
                                    this.errorresponse = response.data
                            } else {
                                this.updateCreateModal = true;
                                this.updateCreateMessage = "created."
                                setTimeout(() => {
                                    this.updateCreateModal = false;
                                    this.$emit('updatemodal');
                                },5000)
                            }
                        });

                    } else {

                        axios({
                            method: 'put',  
                            headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                            url: '/api/basestation',
                            data: {
                                userid:this.$store.getters.user.id,
                                token:this.$store.getters.user.token,
                                basedata:this.base
                                }
                        })
                        .then((response)=> {
                            if(response.data.code == 999){
                                    this.errorresponse = response.data
                            } else {

                                this.updateCreateModal = true;
                                this.updateCreateMessage = "updated."
                                setTimeout(() => {
                                    this.updateCreateModal = false;
                                    this.$emit('updatemodal');
                                },5000)
                            }
                        });
                    }
                });         
            },

          
            validateField(field){
                this.$validator.validate(field)
            },

        }           
        
    };

</script>