<template>
  <div>

    <b-container class="mainpagecont">

      <b-row>
        <b-col class="col-12 col-sm-4 mt-3">
          <h4>SIM MANAGEMENT</h4>
        </b-col>

        <b-col class="col-12 col-sm-4 mt-3">
          <CasterSearchSuggestions 
            placeholder="search sims"
            :clearsearch="clearsearch"
            suggestionType = "4"
            :suggestionFetchResponse="fetchResponse" 
            v-on:textsearch=updateSearch 
            v-on:clearsearch=clearFilter
            />
        </b-col>

        <b-col class="col-12 col-sm-4 mt-3 text-right">
          <b-button variant="outline-success" size="sm" block @click="addSimms()"
            v-if="dealerid != 0 && isadmin"><b-icon-plus-circle /> ASSIGN TO DEALER</b-button>
          <b-button variant="outline-success" size="sm" block @click="refreshSimms()"
            v-if="isadmin"><b-icon-plus-circle /> ADD RESELLER STOCK</b-button>
        </b-col>
      </b-row>

      <b-row>
       

  </b-row>
  
  <CasterOptionsFilter v-if="searchtext == ''"
            :resellerid="resellerid" 
            :dealerid="dealerid" 
            :companyid="companyid"
            :roversshow="true" 
            :companysshow="true" 
            :roverid=0
            :clearsearch="clearsearch"
            v-on:resellersel=updateResellerFilter 
            v-on:dealersel=updateDealerFilter  
            v-on:companysel=updateCompanyFilter
            v-on:roversel=updateRoverFilter 
            /> 
            
            <b-row>       

<b-col class="col-12">
    <CasterSimmsTable2 
        :stext="searchtext"
        :resellerid="resellerid"  
        :dealerid="dealerid" 
        :companyid="companyid"
        :roverid="roverid"
        :simsupplier="simsupplier" 
        :key="componentKey"
        :refresh="refresh"
        :clearsearch="clearsearch" 
        v-on:clearstext="clearSearch"
        v-on:fetchresponse=setFetchResponse  
        />
</b-col>
</b-row>            

<b-modal ref="add-stock" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
  <CasterSimmAddResellerStock :reseller="resellerid" v-on:updatemodal=updateModal() />
</b-modal>

<b-modal ref="assign-simms" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
  <CasterSimmsAssignDealer :active="active" :reseller="resellerid" :dealer="dealerid" :type="simsupplier"
    v-on:updatemodal=updateModal() />
</b-modal>

<b-modal ref="error-modal" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
        <b-row>
          <b-col class="col-12 text-center">
            <h5>{{ errortext }}</h5>
          </b-col>
        </b-row>
      </b-modal>

</b-container>

  </div>
</template>

<script>
import CasterSearchSuggestions from './CasterSearchSuggestions'
import CasterOptionsFilter from './CasterOptionsFilter'
import CasterSimmsTable2 from './CasterSimmsTable2'
import CasterSimmsAssignDealer from './CasterSimmsAssignDealer'
import CasterSimmAddResellerStock from './CasterSimmsAddResellerStock'

export default {
  name: 'CasterSimms',
  components: {
    CasterSearchSuggestions,
    CasterOptionsFilter,
    CasterSimmsTable2,
    CasterSimmAddResellerStock,
    CasterSimmsAssignDealer
  },
  data() {
    return {
      resellerid:0,
      dealerid:0,
      companyid:0,
      roverid:0,
      isadmin: false,
      simsupplier:2,
      active: 0,
      isBusy: false,
      token: '',
      sortBy: 'companydetail[0].text',
      modaltitle: '',
      modaltitlemain: "",
      errortext:'',
      searchtext:'',
      refresh:false,
      fetchResponse:'',
      clearsearch:true,
      componentKey:0
    };

  },
  created() {

    this.token = this.$store.getters.token
    this.roleid = this.$store.getters.roleid // 1 - System Admin 5 - Reseller Admin 10 - Dealer Admin 
            // Are stored search filters use them
            if(this.$store.getters.searchvalues.length > 0){
                switch(this.roleid){
                    case 1:
                    this.resellerid = this.$store.getters.searchvalues[0]
                    this.dealerid = this.$store.getters.searchvalues[1]
                    this.companyid = this.$store.getters.searchvalues[2]
                    break;
                    case 5:
                    this.resellerid = this.$store.getters.resellerid
                    this.dealerid = this.$store.getters.searchvalues[1]
                    this.companyid = this.$store.getters.searchvalues[2]
                    break;
                    case 10:
                    this.resellerid = this.$store.getters.resellerid
                    this.dealerid = this.$store.getters.dealerid
                    this.companyid = this.$store.getters.searchvalues[2]
                    break;
                }
                
                this.roverid = this.$store.getters.searchvalues[3]
            } else this.setDefaults()
    
      if (this.roleid == 1 || this.roleid == 5) { this.isadmin = true }
  },
  
  mounted() {
    this.$store.commit('setroversdatapoll', 0)
    this.$store.commit('setbasesdatapoll', 0)
    this.searchtext = this.$store.getters.searchtext
  },


  methods: {

    setDefaults(){ 
                
                // If system admin set to all Resellers
                if(this.roleid == 1){
                   this.resellerid = 0 
                   this.dealerid = 0
                   this.companyid = 0
                   this.roverid = 0
                   this.subtype = 0
               } else {
               // All other users go with stored login values    
               this.resellerid = this.$store.getters.resellerid
               this.dealerid = this.$store.getters.dealerid
               this.companyid = this.$store.getters.companyid
               this.roverid = 0
               this.subtype = 0
               }
               
           },

    addSimms() {
      this.modaltitlemain = 'Add Sims'
      this.$refs['assign-simms'].show()
    },

    refreshSimms() {
      if (this.resellerid == 0) {
        this.modaltitle = 'Update Reseller Stock'
        this.errortext = 'Please select a Reseller first'
        this.$refs['error-modal'].show()
      } else {
        switch(this.resellerid){
          case 1:
          this.resellerselname = 'Cloudbase'
          break;
          case 2:
          this.resellerselname = 'CW Agritech'
          break;
          case 7:
          this.resellerselname = 'SDF'
          break;
          case 11:
          this.resellerselname = 'RTK Danmark'
          break;

        }
        this.modaltitle = 'Update ' + this.resellerselname + ' Stock'
        this.$refs['add-stock'].show()
      }
    },

    
    // new 

    updateResellerFilter(id){
        this.resellerid = id
        this.dealerid = 0
        this.companyid = 0
        this.roverid = 0
    },

    updateDealerFilter(id){
        this.dealerid = id
        this.companyid = 0
        this.roverid = 0
    },

    updateCompanyFilter(id){
        this.companyid = id
        this.roverid = 0
    },

    updateRoverFilter(id){
        this.roverid = id
    },

    updateSearch(text){
        this.searchtext = text
    },

    setFetchResponse(text){
        this.fetchResponse = text
    },

    clearSearch(){
        this.subtype = 0
        this.clearsearch = !this.clearsearch
        this.setDefaults()
    },

    clearFilter(){
        this.searchtext = ''
    },

    //        


    updateModal() {
      this.$refs['assign-simms'].hide()
      this.$refs['add-stock'].hide()
      this.componentKey += 1
    },

  }
}
</script>