var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ready),expression:"!ready"}]},[_vm._v(" Loading "),_c('b-icon',{attrs:{"icon":"arrow-clockwise","animation":"spin"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ready),expression:"ready"}]},[_c('Editor',{ref:"tinyRichText",attrs:{"init":{
                base_url: '/tinymce', 
                suffix: '.min',
                skin_url: `/tinymce/skins/ui/Cloudbase`,
                plugins: 'link lists',
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
                license_key: 'gpl',
                body_class: 'editArea',
                content_css: 'CloudbaseModal',
            }},on:{"input":_vm.emitContent},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }