<template>
<div>

<b-container class="mainpagecont" v-if="display">

<b-row>
        <b-col class="col-12 col-sm-6 mt-1 p-2"><h3>Network Overview <br/><span style="font-size:0.7em;color:rgba(255,255,255,0.7)"> {{ reseller.text }}</span></h3></b-col>
        <b-col class="col-12 col-sm-6 p-2 text-right" v-if="role == 1">
            <b-btn variant="outline-success" size="sm" @click="LoginAsReseller">LOGIN AS RESELLER</b-btn>
        </b-col>
</b-row>

<b-row>
    <b-col class="col-12 col-md-6 col-lg-3 mt-1 p-2 boxclickable" @click="menuLink('casterroverslogged')" >
        <CasterRoversLoggedCard  />
    </b-col>

    <b-col class="col-12 col-md-6 col-lg-3 mt-1 p-2 boxclickable" @click.stop="menuLink('casterreferencestations')">
        <CasterBasestationsCard  />
    </b-col>

    <b-col class="col-12 col-md-6 col-lg-3 mt-1 p-2 boxclickable" @click="menuLink('castersubscriptions')">
        <CasterSubsCard  />
    </b-col>

    <b-col class="col-12 col-md-6 col-lg-3 mt-1 p-2" >
        <CasterSimmUsageCardAdmin />  
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterLastEventsCard  />
   </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterShowLeafletRovers  :border="true" v-on:fullscreen="fullScreen"/>
    </b-col>

     <b-col class="col-12 col-sm-6 mt-1 p-2">
        <CasterNetworkRTKChartCard  />
    </b-col>

    <b-col class="col-12 col-sm-6 mt-1 p-2">
        <CasterNetworkLoginsChartCard /> 
    </b-col>

    <b-col class="col-12 mt-1 p-2 boxclickable" @click.stop="menuLink('castersubscriptionsstockreseller')">
        <CasterStockingCard  :height="320" />
    </b-col>

    <b-col class="col-12 mt-1 p-2" ><h5>Action History</h5></b-col>
    <b-col class="col-12 p-2">
        <CasterRoverEventsCard :update="true" />
    </b-col>  

</b-row>

<b-modal ref="full-screen" size="xl"  title="ROVERS MAP" @show="waitSetWidth" @hide="closeMap" hide-footer centered content-class="darkmodal2">
   <div ref="fullscreendiv" :style="{width:100+'%',height:500+'px'}">
        <CasterShowLeafletRovers v-if="modalmapwidth != 0"  :fsmapheight="modalmapheight" :fsmapwidth="modalmapwidth" :border="false"/>   
   </div>
</b-modal> 

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-container>

</div> 
</template>

<script>
    import CasterRoversLoggedCard from './CasterRoversLoggedCard'
    import CasterBasestationsCard from './CasterBasestationsCard'
    import CasterSimmUsageCardAdmin from './CasterSimmUsageCardAdmin'
    import CasterLastEventsCard from './CasterLastEventsCard'
    import CasterShowLeafletRovers from './CasterShowLeafletRovers'
    import CasterNetworkRTKChartCard from './CasterNetworkRTKChartCard'
    import CasterNetworkLoginsChartCard from './CasterNetworkLoginsChartCard'
    import CasterStockingCard from './CasterStockingCard'
    import CasterRoverEventsCard from './CasterRoverEventsCard'
    import CasterSubsCard from './CasterSubsCard'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        name:'CasterReseller',
        components:{
            CasterRoversLoggedCard,
            CasterBasestationsCard,
            CasterSimmUsageCardAdmin,
            CasterShowLeafletRovers,
            CasterLastEventsCard,
            CasterNetworkRTKChartCard,
            CasterNetworkLoginsChartCard,
            CasterStockingCard,
            CasterRoverEventsCard,
            CasterSubsCard,
            ErrorLogging
        },    
        data(){
		return {
                    token:'',
                    role:0,
                    rid:0,
                    reseller:{},
                    title:'',
                    errorresponse:{},
                    display:false,
                    showmap:false,
                    modalmapwidth:0,
                    modalmapheight:485
                };
                
                },

		created(){
            this.rid = this.$route.params.id
            this.token = this.$store.getters.token
            this.role = this.$store.getters.roleid
            this.$store.commit('setbasesdatapoll',1)
            this.$store.commit('setroversdatapoll',1)
           
            if(this.rid !== 0){
                this.fetchReseller()
            } else  {
                this.title = 'All Resellers'
                this.resetValues(0,0)
                }
        },

        beforeDestroy () {
            window.removeEventListener("resize",this.setModalWidth)
        },

        
		methods: {

            resetValues(resellerid,countryid){
                    this.$store.commit('setresellerid',resellerid) 
                    this.$store.commit('setdealerid',0)
                    this.$store.commit('setcompanyid',0)
                    this.$store.commit('setroversdata','')
                    this.$store.commit('setcountryid',countryid)
                    this.display = true
            },

            fetchReseller(){
            
				axios.get('/api/casterbusinesses',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:this.rid
                     }
                })
				.then((response) => {
                     if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                    this.reseller = response.data.CasterBusiness[0]    
                    this.rid = this.reseller.value
                    this.resetValues(this.reseller.value,this.reseller.country)

                      }

                    });
			},
            
            menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },

            showMap(){
                    this.showmap = true
                },

            fullScreen(){
                this.modalmapwidth = 0
                this.$refs['full-screen'].show()
            },

            waitSetWidth(){
                setTimeout(this.setModalWidth,150)
            },

            setModalWidth(){
                window.addEventListener("resize", this.setModalWidth)
                this.modalmapwidth = this.$refs['fullscreendiv'].clientWidth -20
            },

            closeMap(){
                window.removeEventListener("resize",this.setModalWidth)
                this.$refs['full-screen'].hide()
            },

            LoginAsReseller(){
                let branding = this.$store.getters.branding
                branding.logintitle = this.reseller.logintitle
                this.$store.commit('setbranding',branding)
                this.$store.commit('setloggedin',false)
            }
		}	   
	
	};

</script>