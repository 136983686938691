<template>
<div class="imageBorder" style="font-size:0.8em" v-if="online">

        
        <b-row class="mt-5">
            <b-col class="col-6">NO. SATELLITES</b-col>
            <b-col class="col-6" v-if="rover.connected == 1">{{ rover.num_sats}}</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col class="col-6">REF STATION ID</b-col>
            <b-col class="col-6" v-if="rover.connected == 1">{{ rover.basestationid }}</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col class="col-6">REF STATION</b-col>
            <b-col class="col-6" v-if="rover.connected == 1">{{ rover.basestation}}</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col class="col-6">ALTITUDE</b-col>
            <b-col class="col-6" v-if="rover.connected == 1">{{ rover.altitude }}m</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

        <b-row class="mt-1">  
            <b-col class="col-6">TIME TO FIRST FIX</b-col>
            <b-col class="col-6" v-if="rover.connected == 1">{{ rover.time_to_fix }}s</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

</div>

<div class="imageBorder" style="font-size:0.8em" v-else>
    <b-row class="mt-3 pl-3">   
            <b-col class="col-12">Rover Offline</b-col>
    </b-row>
</div>    


</template>


<script>
   
    export default {
        props:['rover'],
        data(){
		return {
                    online:true
            
                };
                
                },

		created(){

                
        },

           watch: {
                    rover: function () {
                            if(this.rover == undefined){this.online = false} else this.online = true
                         }, 
        },


		methods: {


        }	   
	
	};

</script> 