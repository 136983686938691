<template>
<div>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import { mapState } from 'vuex'
	export default {
        name:'CasterPollDatabases',
        components:{
            ErrorLogging
        },
        data(){
        return {
                token:'',
                timeout:false,
                timeout2:false,
                errorresponse:{},
                };
                
                },

		created(){
                
                this.token = this.$store.getters.token
                //if(this.$store.getters.basesdatapoll == 1){this.getBasesData()}
                //if(this.$store.getters.roversdatapoll == 1){this.getRoversData()}

                
         },

         mounted(){

            // Run and start Rovers data pollling timer
                this.checkRoversPolling('start')
                this.timeout2 = setInterval(this.checkRoversPolling, 4000) // poll every 4 secs
          
             // Run and start Rovers data pollling timer
                this.checkRoversPolling('start')
                this.timeout = setInterval(this.checkBasesPolling, 5000) // poll every 5 secs
          
         },

        beforeDestroy () {
            clearTimeout(this.timeout)
            clearTimeout(this.timeout2)
        },   

         computed: mapState(['roversdatapolling','basesdatapoll','roversdatapoll','basesdatapoll','resellerid','dealerid','companyid']),
                
                watch: {
                       basesdatapoll: function () { 
                            this.checkBasesPolling()
                        },

                        roversdatapoll: function () {
                            this.checkRoversPolling()
                        },
                        
                        resellerid: function () { 
                            this.checkRoversPolling()
                            this.checkBasesPolling()
                        },
                        
                        dealerid: function () { 
                            this.checkRoversPolling()
                        },
                        
                        companyid: function () { 
                            this.checkRoversPolling()
                        },
                },        

      
		methods: {

            checkRoversPolling(){
                // If already getting rover data or rover poll switched off do nothing
                if(this.roversdatapolling || this.roversdatapoll == 0){
                    return
                } else this.getRoversData()
            },

            checkBasesPolling(){
                // If already getting base data or base poll switched off do nothing
                if(this.basesdatapolling || this.basesdatapoll == 0){
                    return
                } else this.getBasesData()
            },

            getRoversData(){ 
                this.$store.commit('setroversdatapolling',true)
                this.rovers = []
                axios.get('/api/roversdata',{
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                          userid:this.$store.getters.user.id,
                          token:this.$store.getters.user.token,
                          reseller:this.$store.getters.resellerid,
                          dealer:this.$store.getters.dealerid,
                          company:this.$store.getters.companyid,
                          test:'getroverspoll'
                    }
                })
				.then((response) => {

                    if(response.data == 'NOT AUTHORISED'){this.$store.commit('setloggedin',false)}
                   
                        if(response.data.code == 999){
                                console.log(response.data)
                                this.$store.commit('setroversdatapolling',false)
                                //this.errorresponse = response.data
                        } else {
                                if(response.data.rovers !== undefined){
                                    this.$store.commit('setroversdata', response.data.rovers)
                                    this.$store.commit('setchartdata', response.data.chartdata)
                                    this.$store.commit('setlasteventsdata', response.data.lastevents)
                                    this.$store.commit('setsubsdata', response.data.subdata)
                                    this.$store.commit('setroversdatapolling',false)
                                    }
                                }    
                    });

         },

            getBasesData(){
                    this.$store.commit('setbasesdatapolling',true)
                    axios.get('/api/basesdata',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:0,
                        timezone:this.$store.getters.timezone,
                        country:this.$store.getters.countryid,
                        test:'getbasespoll',
                        }
                })
				.then((response) => {

                    if(response.data == 'NOT AUTHORISED'){this.$store.commit('setloggedin',false)} 

                    if(response.data.code == 999){
                            console.log(response.data)
                            //this.errorresponse = response.data
                            this.$store.commit('setbasesdatapolling',false)
                    } else {
                                this.$store.commit('setbasesdata',response.data)
                                this.$store.commit('setbasesdatapolling',false)
                            }

                    });

            },
            
		}	   
	
	};

</script> 