<template>
<div>

<b-container v-if="display">

    <b-row class="mt-3">

        <b-col class="col-4">    
            <h6>ICCID</h6>
        </b-col>

        <b-col class="col-8">    
            <h6>{{ simm.number }}</h6>
        </b-col>

        <b-col class="col-4" v-if="isadmin">    
            <h6>Supplier</h6>
        </b-col>

        <b-col class="col-8" v-if="isadmin">     
            <h6>{{ simm.simsupplier }}</h6>
        </b-col>

        <b-col class="col-4" v-if="isadmin">     
            <h6 v-if="simm.supplier == 2">End Point</h6>
            <h6 v-else>Connection ID</h6>
        </b-col>

        <b-col class="col-8" v-if="isadmin">     
            <h6 v-if="simm.supplier == 2">{{ simm.endpoint}}</h6>
            <h6 v-else>{{ simm.connection_id }}</h6>
        </b-col>

        <b-col class="col-4">    
            <h6>Package</h6>
        </b-col>

        <b-col class="col-8">    
            <h6>{{ simm.package }}</h6>
        </b-col>

        <b-col class="col-4">    
            <h6>Reseller</h6>
        </b-col>

        <b-col class="col-8">    
            <h6>{{ simm.resellername }}</h6>
        </b-col>

		<b-col class="col-4">    
            <h6>Dealer</h6>
        </b-col>

        <b-col class="col-8">    
            <h6>{{ simm.dealername }}</h6>
        </b-col>

        <b-col class="col-4">    
            <h6>SIM Status</h6>
        </b-col>

        <b-col class="col-8">    
            <h6>{{ simm.status }}</h6>
        </b-col>
    </b-row>

     <b-row class="mt-3" v-if="simm.base == 0 && isadmin">

        <b-col class="col-12  text-right" v-if="roleid == 1"> 
            <b-button variant="outline-success" size="sm" @click.stop="refreshSim()">refresh sim</b-button>
        </b-col>    

        <b-col class="col-12  mt-3" v-if="roleid == 1"> 
            <label>Reseller</label>
				<b-form-select
                    v-model="resellersel"
                    :options="resellers"
                    v-on:change="changeReseller"
                    />
        </b-col>

         <b-col class="col-12  mt-3"> 
            <label>Dealer</label>
				<b-form-select
                    v-model="dealersel"
                    :options="dealers"
                    v-on:change="changeDealer"
                    />
        </b-col>

        <b-col class="col-12  mt-3"> 
            <label>Company</label>
				<b-form-select
                    v-model="companysel"
                    :options="companies"
                    v-on:change="changeCompany"
                    />
        </b-col>

        <b-col class="col-12  mt-3"> 
            <label>Rover</label>
				<b-form-select
                    v-model="roversel"
                    :options="rovers"
                    v-on:change="changeRover"
                    />
        </b-col>


         <b-col class="col-12 mt-3">
            <label>Update SIM status</label>
            <b-form-select
                    v-model="simmstatus"
                    :options="actions"
                    v-on:change="changeStatus"
                    name="simm.statusid"
                    v-validate="{min_value:1}"
					:class="{'b-form-select': true, 'is-danger': errors.has('simm.statusid') }"
                    />
    </b-col>

    <b-col class="col-12 mt-3 text-right">
            <b-button variant="outline-success" size="sm" @click.stop="setData()">reset form</b-button>
    </b-col> 

    <b-col class="col-12 mt-3"><h5>Stock Status</h5></b-col>
    
    <b-col class="col-12 col-sm-4 mt-3">
          <b-form-checkbox v-model="rstock" inline disabled size="md" value="1" unchecked-value="0" />
          <label style="position: absolute;"> Reseller</label>
        </b-col>

        <b-col class="col-12 col-sm-4 mt-3">
          <b-form-checkbox v-model="dstock" inline disabled size="md" value="1" unchecked-value="0" />
          <label style="position: absolute;"> Dealer</label>
        </b-col>

        <b-col class="col-12 col-sm-4 mt-3">
          <b-form-checkbox v-model="cstock" inline disabled size="md" value="1" unchecked-value="0" />
          <label style="position: absolute;"> Company</label>
        </b-col>
    </b-row>    
      
    <b-row class="mt-5 mb-3" v-if="simm.base == 0 && isadmin">
         <b-col class="col-12">
            <b-button variant="outline-success" block  @click="checkData()">REVIEW CHANGES</b-button>
        </b-col>  
    </b-row> 

<b-modal ref="message-display" size="lg" title="Actions" hide-footer centered content-class="darkmodal">
   <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row> 

   <b-row>
        <b-col class="col-12 mt-5">
            <b-button variant="outline-success" @click.stop="finish()" block>Finish</b-button>
       </b-col>   
   </b-row>    
</b-modal>

<b-modal ref="show-dialog" size="md" :title="simdata.number" hide-footer centered content-class="darkmodal">
   
    <b-row>
        <b-col class="col-12 mt-3" v-if="actionstext.length > 0"><h5>Actions</h5></b-col>
        <b-col class="col-12 mt-1" v-for="action in actionstext" :key="action.id">
            {{ action }}
        </b-col>
    </b-row>
    
    <b-row>
        <b-col class="col-12 mt-3" v-if=fromtext.length ><h5>Move from</h5></b-col>
        <b-col class="col-12 mt-1" v-for="ftext in fromtext" :key="ftext.id">
            {{ ftext }}
        </b-col>
    </b-row>

    <b-row>
        <b-col class="col-12 mt-3" v-if=totext.length ><h5>Move to</h5></b-col>
        <b-col class="col-12 mt-1" v-for="ttext in totext" :key="ttext.id">
            {{ ttext }}
        </b-col>
    </b-row>

    <b-row>
        <b-col class="col-12 mt-5">
            <b-button variant="outline-success" block  @click.stop="updateSimm()">UPDATE SIM</b-button>
        </b-col> 
    </b-row>
</b-modal>


</b-container>
</div> 
</template>

<script>
    import axios from 'axios'
	export default {
        props:['isadmin','id','reseller','simdata'],
        data(){
		return {
                token:'',
                roleid:0,
                user:{},
                simm:{},
                rstock:0,
                dstock:0,
                cstock:0,
                resellers:[],
                resellersel:1,
                resellername:'',
                dealers:[],
                dealersel:0,
                dealername:'',
                companies:[{value:0,text:'Select'}],
                companysel:0,
                companyname:'',
                rovers:[{value:0,text:'Select'}],
                roversel:0,
                rovername:'',
                rovericcid:'',
                roverid:0,
                backtostocklist:[],
                simmstatus:0,
                statusname:'',
                display:true,
                dialogtext:'',
                fromtext:[],
                totext:[],
                actionstext:[],
                actionsbetterconnect:[
                    {value:0,text:'Select'},
                    {value:1,text:'Suspend'},
                    {value:2,text:'Activate'},
                    {value:3,text:'Unsuspend'},
                ],
                actionsbics:[
                    {value:0,text:'Select'},
                    {value:6,text:'Suspend'},
                    {value:12,text:'Activate'},
                    {value:7,text:'Unsuspend'},
                ],
                stagemessages:[]
                };
                },

		created(){
      
            this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            this.setData()
        },
        
		methods: {

            setData(){

                this.simm = this.simdata  // simdata is original values for SIM - simm is the updated values
                this.resellersel = this.simm.reseller
                this.dealersel = this.simm.dealer
                this.companysel = this.simm.company
                this.roversel = this.simm.rover

                this.resellername = this.simm.resellername
                this.dealername = this.simm.dealername
                this.companyname = this.simm.companyname

                this.rstock = this.simm.rstock
                this.dstock = this.simm.dstock
                this.cstock = this.simm.cstock

                this.resetText()

                if(this.roleid == 1){ // Sysadmins
                    this.fetchResellers()
                }
                this.fetchDealers()
                if(this.simm.dealer != 0){this.fetchCompanies()}
                if(this.simm.company != 0){this.fetchRovers()}
                
                if(this.simm.supplier == 1){this.actions = this.actionsbetterconnect} else this.actions = this.actionsbics
                this.actions = this.actions.filter(action => action.value == this.simm.statusid || action.value == 0)

            },

            resetText(){
                this.actionstext = []
                this.fromtext = []
                this.totext = []
            },

            checkData(){
               
                let toreseller = this.simdata.resellername
                let todealer = this.simdata.dealername
                let tocompany = this.simdata.companyname
                let torover = this.simdata.rovername
                let change = ''
                this.backtostocklist = []
                
                if(this.simdata.rstock == 1){this.fromtext.push(this.simdata.resellername+' - Reseller stock')}
                else if(this.simdata.dstock == 1){this.fromtext.push(this.simdata.dealername+' - Dealer stock')}
                else if(this.simdata.cstock == 1){this.fromtext.push(this.simdata.companyname+' - Company stock')}
                else {
                    this.fromtext.push(this.simdata.resellername)
                    this.fromtext.push(this.simdata.dealername)
                    this.fromtext.push(this.simdata.companyname)
                    this.fromtext.push(this.simdata.rovername)
                    }

                if(this.simdata.company != this.companysel){
                    if(this.companysel != 0){
                        tocompany = this.companyname
                        change = 'company'
                    } else tocompany = ''
                }    

                if(this.simdata.dealer != this.dealersel){
                        if(this.dealersel != 0){
                            change = 'dealer'
                            todealer = this.dealername
                        } else todealer = ''
                    }

                if(this.simdata.reseller != this.resellersel){
                            change = 'reseller'
                            toreseller = this.resellername
                        }
                 
                   
                if(this.simdata.rover != this.roversel){
                    // if a destination Rover selected     
                    if(this.roversel !== 0){
                            torover = this.rovername
                            let added = false
                            // Current sim is assigned to another rover so unassign from orginal and assign to new
                            if(this.simdata.rover != this.roversel && this.simdata.rover != 0){
                                this.actionstext.push('Remove '+this.simdata.number+' from '+this.simdata.rovername)
                                this.actionstext.push('Add '+this.simdata.number+' to '+this.rovername)
                                this.backtostocklist.push(this.simdata.rover) // old rover sim back to stock
                                added = true
                                } 

                            // If there is an existing sim on selected Rover must be put back in stock
                             if(this.rovericcid !== null){
                                this.actionstext.push('Remove '+this.rovericcid+' from '+this.rovername)
                                this.backtostocklist.push(this.roverid) // selected rover old sim back to stock
                                if(!added){
                                    this.actionstext.push('Add '+this.simdata.number+' to '+this.rovername)
                                }
                                } 

                        // no destination rover selected so just unassign from current Rover        
                        } else {
                            torover = ''
                            this.actionstext.push('Remove '+this.simdata.number+' from '+this.simdata.rovername)
                            this.backtostocklist.push(this.simdata.rover) // selected rover old sim back to stock
                            }
                        change = 'rover'
                    }  
                
                let stock = ''    
                if(this.rstock == 1){stock = 'Reseller stock'}
                if(this.dstock == 1){stock = 'Dealer stock'}
                if(this.cstock == 1){stock = 'Company stock'}

                this.totext.push(toreseller)
                this.totext.push(todealer)
                this.totext.push(tocompany)
                this.totext.push(torover)
                this.totext.push(stock)          
                        
                 if(change == ''){
                    if(this.statusname == ''){
                        this.actionstext.push('No Changes')
                        this.fromtext = []
                    } else this.actionstext.push(this.simdata.status+' to '+this.statusname)
                    this.totext = []
                    }
                
                this.$refs['show-dialog'].show()
                
            },

            updateSimm(){

            // Set current selected sim new values
            let simdata = {}
            simdata.id = this.simm.value
            simdata.resellerorig = this.simdata.reseller // orginal sim reseller
            simdata.reseller = this.resellersel
            simdata.dealer = this.dealersel
            simdata.company = this.companysel
            simdata.rover = this.roversel
            simdata.rstock = this.rstock
            simdata.dstock = this.dstock
            simdata.cstock = this.cstock
            simdata.status = this.simmstatus
            simdata.backtostocklist = this.backtostocklist // list of rovers to remove sims from
           
            axios({
                 method: 'post',
                 headers: {"Authorization" : "Bearer " + this.token},
                 url: '/api/updatesimmtest',
                 data: {
                     userid:this.$store.getters.user.id,
                     token:this.$store.getters.user.token,
                     simmdata:simdata,
                     }
                 })
             .then((response)=> {

             if(response.data.code == 999){
                 //this.errorresponse = response.data
                 alert('exception')
                 
                 } else {
                         this.$refs['message-display'].show()
                         this.stagemessages = response.data.messages
                     }
                })
                
            },

            changeReseller(){
                this.setResellerStock()
                this.resetText()
                this.resellername = this.resellers.filter(reseller => reseller.value == this.resellersel)[0]['text']
                this.dealername = ''
                this.companyname = ''
                this.rovername = ''
                this.dealersel = 0
                this.companysel = 0
                this.roversel = 0
                this.simmstatus = 0
                this.companies= [{value:0,text:'Select'}]
                this.rovers= [{value:0,text:'Select'}]
                this.fetchDealers()
            },  
            
            changeDealer(){
                if(this.dealersel > 0){this.setDealerStock()} else this.setResellerStock()
                this.resetText()
                this.dealername = this.dealers.filter(dealer => dealer.value == this.dealersel)[0]['text']
                this.companyname = ''
                this.rovername = ''
                this.companysel = 0
                this.roversel = 0
                this.simmstatus = 0
                this.companies= [{value:0,text:'Select'}]
                this.rovers= [{value:0,text:'Select'}]
                this.fetchCompanies()
            },  

            changeCompany(){
                if(this.companysel > 0){this.setCompanyStock()} else this.setDealerStock()
                this.resetText()
                this.companyname = this.companies.filter(company => company.value == this.companysel)[0]['text']
                this.rovername = ''
                this.roversel = 0
                this.simmstatus = 0
                this.rovers= [{value:0,text:'Select'}]
                this.fetchRovers()
            },
            
            changeRover(){
                if(this.roversel != 0){this.removeFromStock()} else this.setCompanyStock()
                this.resetText()
                let rover = this.rovers.filter(rover => rover.value == this.roversel)[0]
                this.rovername = rover['text']
                this.rovericcid = rover['iccid'] // current iccid for selected rover
                this.roverid = rover['value']   // id of selected rover 
                this.simmstatus = 0
            },  

            changeStatus(){
                if(this.simmstatus != 0){
                let status = this.actions.filter(action => action.value == this.simmstatus)[0]
                this.statusname = status['text']
                }
            },  

        removeFromStock(){
            this.rstock = 0
            this.dstock = 0
            this.cstock = 0
        },

        setResellerStock(){
            this.rstock = 1
            this.dstock = 0
            this.cstock = 0
        },  

        setDealerStock(){
            this.rstock = 0
            this.dstock = 1
            this.cstock = 0
        },  

         setCompanyStock(){
            this.rstock = 0
            this.dstock = 0
            this.cstock = 1
         },
         
       

            fetchResellers(){

				axios.get('/api/casterbusinesses',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                    }
                })
				.then((response) => {
                    if(response.data.CasterBusiness != undefined){
                        this.resellers = [{value:0,text:'Select'}]
                        this.resellers.push(...response.data.CasterBusiness)
                        
                    }
                    })
           },

            fetchDealers(){
                if(this.resellersel == 0){return}
                this.dealers = []
				axios.get('/api/casterdealers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellersel,
                        dropdown:1
                    }
                })
				.then((response) => {
                    if(response.data.CasterDealers != undefined){
                        
                        this.dealers = [{value:0,text:'Select'}]
                        this.dealers.push(...response.data.CasterDealers)
                    }
                    })
                    
			},

            fetchCompanies(){
                   if(this.dealersel == 0){return}
                   axios.get('/api/castercompanieslist',{
                   headers: {"Authorization" : "Bearer " + this.token},
                   params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellersel,
                        dealer:this.dealersel,
                    }
               })
               .then((response) => {
                   if(response.data.code == 999){
                       this.errorresponse = response.data
                       return
                   }
                   this.companies =[{value:0,text:'Select'}]
                   this.companies.push(...response.data.CasterCompanies)
               })
           },

           fetchRovers(){
                    if(this.companysel == 0){return}
                   axios.get('/api/indexrovers',{
                   headers: {"Authorization" : "Bearer " + this.token},
                   params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellersel,
                        dealer:this.dealersel,
                        company:this.companysel,
                        withsimm:1
                    }
               })
               .then((response) => {
                   if(response.data.code == 999){
                       this.errorresponse = response.data
                       return
                   }
                   this.rovers = [{value:0,text:'Select'}]
                   this.rovers.push(...response.data.rovers)
               })
           },

           

        refreshSim(){
             //prepare data
             let dataObj = {
                userid:this.$store.getters.user.id,
                token:this.$store.getters.user.token,
                reseller:this.simdata.reseller,
                endpoint:this.simdata.endpoint_id,
                connectionid:this.simdata.connection_id,
                type:this.simdata.supplier
            }                   
           
            //post
            axios.post('api/simrefresh', dataObj,
            {
                headers: {"Authorization" : "Bearer " + this.token},        
            })
            .then((response) => {
            if (response.data.code == 999) {
                this.errorresponse = response.data
            } else {
                this.simm.number = response.data.iccid
                this.simm.status = response.data.status
            }
        })
        },  
         
         finish(){
            this.$refs['message-display'].hide()
            this.$emit('updatemodal')
         },

         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script>