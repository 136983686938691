<template>
<div>

<b-container class="mainpagecont">

  <b-row class="mb-3">
    <b-col class="col-12 col-md-4 mt-3" style="color:rgba(255,255,255,1)">
        <h3>Resellers</h3>
    </b-col>

    <b-col class="col-12 col-md-4 mt-3">
            <CasterSearch v-on:textsearch=updateSearch v-on:clearsearch=clearFilter />
    </b-col>
        

    <b-col class="col-12 col-md-3 offset-md-1 mt-3">
        <b-button size="sm" variant="outline-success" block @click="newReseller()">
            <b-icon-plus-circle class="bicon" /> Add Reseller
        </b-button>
    </b-col>

</b-row> 

<b-row>
      <b-col class="col-12 mt-3"> 
            <CasterResellersTable :stext="searchtext" :key="componentKey"/>
       </b-col> 
</b-row>

<b-modal ref="edit-reseller" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterResellerAdd
    :resellerid=0
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    />
</b-modal>

</b-container>
	
</div> 
</template>

<script>
import CasterSearch from './CasterSearch'
import CasterResellerAdd from './CasterResellerAdd'
import CasterResellersTable from './CasterResellersTable'
export default {
    name:'CasterResellers',
        components:{
            CasterSearch,
            CasterResellerAdd,
            CasterResellersTable
        }, 
      data(){
        return {
                  businessid:0,
                  modaltitle:'',
                  searchtext:'',
                  componentKey:0
                };
                
                },

		mounted(){
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setbasesdatapoll',0)
        },

    methods: {

             newReseller(){
                this.modaltitle = 'Add Reseller'
                this.$refs['edit-reseller'].show() 
            },

            updateSearch(text){
                this.searchtext = text
            },

            clearFilter(){
                this.searchtext = ''
            },

             updatemodal(){
                 this.$refs['edit-reseller'].hide() 
                 this.componentKey++
            },

         
            closemodal(){
                this.$refs['edit-reseller'].hide()
            },
    },     

       
	};

</script>