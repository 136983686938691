<template>
<div>
<b-row>
    <b-col classs="col-12">
        <h6 style="font-size:0.8em">{{ datavalue }}/{{ totalvalue }} <span style="font-size:0.7em">{{ unitvalue }}</span></h6> 

    <div class="meter">
        <span :style="{ width: plotvalue + '%' }">
            <span class="progress"></span>
        </span>    
    </div>

    </b-col>
</b-row>    
</div>
</template>

<style scoped>
.meter { 
    height: 5px;
    position: relative;
    background: #81807e;
    overflow: hidden;
}

.meter span {
    display: block;
    height: 100%;
}

.progress {
    background-color: #5bc89c;
    animation: progressBar 1.5s ease-in-out;
    animation-fill-mode:both; 
}

@keyframes progressBar {
  0% { width: 0; }
  100% { width: 100%; }
}
</style>

<script>
  


export default {
    props:['datavalue','totalvalue','unitvalue'],

    data(){
      return {
        plotvalue:0
    };

    },

    mounted () {
        this.plotvalue = (this.datavalue/this.totalvalue) * 100
    },

}
</script> 