<template>
<div>
<b-container>    

    <b-row class="mt-1">
            <b-col class="col-6">REF STATION</b-col>
            <b-col class="col-6" v-if="roversel.connected == 1">{{ roversel.basestation}}</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

    <b-row class="mt-1">
            <b-col class="col-6">REF STATION ID</b-col>
            <b-col class="col-6" v-if="roversel.connected == 1">{{ baseid }}</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

    <b-row class="mt-1">
        <b-col class="col-6">DISTANCE</b-col>
        <b-col class="col-6" v-if="roversel.connected == 1">{{ roversel.distance }} km</b-col>
        <b-col class="col-6" v-else> - </b-col>
    </b-row> 

    <b-row class="mt-1">
        <b-col class="col-6">SESSION ID</b-col>
        <b-col class="col-6" v-if="roversel.connected == 1">{{ sessionid }} </b-col>
        <b-col class="col-6" v-else> - </b-col>
    </b-row> 
    
    <b-row class="mt-5 pb-3" v-if="kickerror == null">
        <b-col class="col-12">
            <b-button size="md" variant="outline-danger" block @click="resetRover()" v-if="sessionid > 0">
                <b-icon-x-circle class="bicon" /> DISCONNECT ROVER
            </b-button>
            <b-button size="md" disabled variant="outline-warning" block v-else>
                <b-icon-x-circle class="bicon" /> NO LIVE SESSION
            </b-button>
        </b-col>
    </b-row>

    <b-row class="mt-5 pb-3" v-else>
        <b-col class="col-12" v-if="kickerror">
            <b-button size="md" disabled variant="danger" block>
                <b-icon-x-circle class="bicon" /> {{ message }}
            </b-button>
        </b-col>
        <b-col class="col-12" v-else>
            <b-button size="md" disabled variant="success" block>
                <b-icon-x-circle class="bicon" /> {{ message }}
            </b-button>
        </b-col>
    </b-row>    

</b-container>
</div>
</template>

<script>
import axios from 'axios'
export default {
    props:['rover'],
    data(){
    return {
               roversel:{
                connected:0,
                basestation:'',
                basestationid:0,
               },
               sessionid:0,
               castaid:0,
               baseid:0,
               message:'',
               kickerror:null,
               errorresponse:{}
            };
            
    },

    created(){
        this.roversel = this.rover
        this.fetchRover()    
    },

    methods:{
        fetchRover(){

            axios.get('/api/getrovercastasessionid',{
                headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                params:{
                    userid:this.$store.getters.user.id,
                    token:this.$store.getters.token,
                    rover:this.roversel.value,
                }
            }).then((response) => {
                if(response.data.code == 999){
                        alert('Exception Error')
                        this.errorresponse = response.data
                } else {
                    this.sessionid = response.data.sessionid
                    this.castaid = response.data.castaid
                    this.baseid = response.data.castaid
                } 
            })    
        },

        resetRover(){ 

            axios.get('/api/disconnectroverfromcasta',{
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.token,
                        casta:this.castaid,
                        session:this.sessionid
                    }
                }).then((response) => {
                    if(response.data.code == 999){
                            alert('Exception Error')
                            this.errorresponse = response.data
                    } else {
                        if(response.data.error == true){
                            this.message = response.data.message
                            this.kickerror = true
                        } else {
                            this.message = response.data.message
                            this.kickerror = false
                        }
                    } 
                })   
        },

    }    

}; 
</script>    