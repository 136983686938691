<!-- Version 281022/1430 -->
<template>
<div>

<b-container v-if="display">

 <b-row class="mt-3 mb-3">
     <b-col class="col-12 col-sm-4">
         <b-row>
                <b-col class="col-12"><h5>{{ reseller.text }}</h5></b-col>
                <b-col class="col-12">{{ reseller.contact}}</b-col>
                <b-col class="col-12">{{ reseller.address1}}</b-col>
                <b-col class="col-12" v-if="reseller.address2">{{ reseller.address2}}</b-col>
                <b-col class="col-12" v-if="reseller.address3">{{ reseller.address3}}</b-col>
                <b-col class="col-12">{{ reseller.towncity}}</b-col>
                <b-col class="col-12">{{ reseller.postcode}}</b-col>
         </b-row>
     </b-col>

     <b-col class="col-12 col-sm-4">
          <b-row>
                <b-col class="col-12"><h6>Dealers</h6></b-col>
         </b-row>        
         <b-row v-for="dealer in dealers" :key="dealer.value">
                <b-col class="col-12">{{ dealer.text }}</b-col>
         </b-row>
      </b-col>

      <b-col class="col-12 col-sm-4">
          <b-row>
                <b-col class="col-12"><h6>Companies</h6></b-col>
         </b-row>        
         <b-row v-for="company in companies" :key="company.id">
                <b-col class="col-12">{{ company.text }}</b-col>
         </b-row>
      </b-col>
        

</b-row> 

    <b-row class="mt-5 mb-5">
        <b-col class="col-12 text-center" style="border:1px solid white;padding:10px">
                <h6>WARNING : DELETING THIS RESELLER WILL DELETE ALL <br/>ASSOCIATED DEALERS, COMPANIES, ROVERS AND USERS</h6>
        </b-col>
    </b-row>     
   
    <b-row class="mt-5 mb-3">
         <b-col class="col-12">
           <b-button variant="outline-danger" block  @click="deleteReseller()">DELETE</b-button>
         </b-col>
    </b-row> 

</b-container>

		
</div> 
</template>


<script>
    import axios from 'axios'
	export default {
        props:['id'],
        data(){
		return {
                token:'',
                reseller:{},
                dealers:[],
                companies:[],
                user:{},
                display:false
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.user = this.$store.getters.user
            this.fetchReseller()
        },

      
		methods: {

              fetchReseller(){

				axios.get('/api/casterbusinesses',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         id:this.id
                     }
                })
				.then((response) => {
                    this.reseller = response.data.CasterBusiness[0]
                    this.fetchDealers()
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
          
             fetchDealers(){

				axios.get('/api/casterdealers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.id
                     }
                })
				.then((response) => {
                    this.dealers = response.data.CasterDealers
                    this.fetchCompanies()
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

            fetchCompanies(){

				axios.get('/api/castercompanies',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        resellers:this.id
                     }
                })
				.then((response) => {
                    this.companies = response.data.CasterCompanies
                    this.display = true
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},


               deleteReseller(){

                let text = "This will archive "+this.reseller.text+" , all associated dealers, companies, users and subscriptions.<br/><br/>Are you sure?"

                this.$swal({
                    title: "Delete Reseller",
                    html: text,
                    icon: "warning",
                    showCloseButton: true,
                    showConfirmButton: true,
                    confirmButtonText: 'Yes',
					showCancelButton: true,
					cancelButtonText: 'No',
						})
						.then((result) => {

                        if (result.isConfirmed) {

                       axios({
                        method: 'post',
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/casterresellerarchive',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    id:this.id
                                    }
                                })
                            .then(
                                this.userEvent()
                        )
                        .catch(err => {
                                    this.$swal({
                                            title: "Delete Error",
                                            text: err,
                                            icon: "error",
                                            showCloseButton: true,
                                            showConfirmButton: false
                                            })
                                    });	

                            }
                        })            
				
            }, 

            
             userEvent(){   //  Set log event - RESELLER ARCHIVE
                 
          
                 axios({
                        method: 'post',  
                        headers: {"Authorization" : "Bearer " + this.token},
                        url: '/api/userevent',
                        data: {
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            user:this.user.id,type:20,text:this.reseller.text} 
                        })
                    .then((response)=> {
                
                    if(response.data.error){
                        this.$swal({
                                        title: "Update Error",
                                        text: response.data.error,
                                        icon: "error",
                                        showCloseButton: true,
                                        showConfirmButton: false
                                        })

                        } else this.$emit('updatemodal')

                                        
                    }).catch(err => {
                                this.$swal({
                                        title: "Update Error",
                                        text: err,
                                        icon: "error",
                                        showCloseButton: true,
                                        showConfirmButton: false
                                        })
                                });	
            },

       
         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script>