<template>
<div>
 <b-container class="mainpagecont">

<b-row class="mt-5 pt-3">

        <b-col class="col-6">
            <h4>Caster Satellite Messages</h4>
        </b-col>

        <b-col class="col-3 offset-3">
            <b-button variant="info" block size="sm" @click="autoRefresh()" v-if="auto"><b-icon-clock class="icon" />  AUTO REFRESH</b-button>
            <b-button variant="outline-info" size="sm" block  @click="autoRefresh()" v-else><b-icon-clock class="icon" />  AUTO REFRESH</b-button>      
        </b-col>               

 </b-row>    

<b-row class="mt-5"> 

        <b-col class="col-3">
            <label>Basestations</label>
            <b-form-select class="darkinput2"  v-model="basestationsel" :options="basestations" @change="filterBS($event)" ></b-form-select>     
        </b-col>

        <b-col class="col-3">
                        <label>Start Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterstart"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterstart" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2"  
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

            <b-col class="col-3">
                        <label>End Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterend"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterend" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2"  
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

            <b-col class="col-2" style="margin-top:30px">
                <b-button variant="info" block @click="fetchRequests()">filter</b-button>
            </b-col>       

</b-row>              

<b-row class="mt-3 mb-3" >
     <b-col class="col-12 text-right">
         {{ requests.length }} Results
     </b-col> 

      <b-col>
             <b-table 
                striped 
                responsive 
                hover 
				:items="requests" 
                :fields="fields"
                tbody-tr-class="rowWClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :busy="isBusy"
                @row-clicked="showSats($event)"
                dark
                fixed
                >
            
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                    </div>
                </template>

                </b-table>
       </b-col>             
</b-row>            
                        

<b-modal ref="sat-modal" size="xl" title="Satellites" hide-footer centered>
   <castersats
        :id="currentmessage"
   />     
</b-modal>        

</b-container>
		
</div> 
</template>

<script>
    import axios from 'axios'
	export default {
        data(){
		return {
    
                token:'',
                requests:[],
                basestations:[],
                basestationsel:0,
                auto:false,
                polldata:null,
                currentmessage:0,
                filterstart:'',
                filterend:'',
                fields:[
                { key: 'timestamp', label: 'Date/Time', sortable: true },
                { key: 'basestation', label: 'Reference Station', sortable: true },
                { key: 'session', label: 'Session ID', sortable: true },
                { key: 'gsats', label: 'GPS Satellites', sortable: true },
                { key: 'rsats', label: 'Glosnas Satellites', sortable: true },
                { key: 'esats', label: 'Galileo Satellites', sortable: true },
                { key: 'csats', label: 'CSNA Satellites', sortable: true },
               ],
                sortBy: 'value',
                sortDesc: false,
                isBusy: false,
              
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.fetchBaseStations()
            this.fetchRequests()
        },

		methods: {

            fetchRequests(){

				axios.get('/api/castersats',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         basestation:this.basestationsel,
                         start:this.filterstart,
                         end:this.filterend
                         }
                })
				.then((response) => {
                    this.requests = response.data.sats
                    if(this.auto == true){this.polldata = setTimeout(this.fetchRequests, 5000)}
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });

			},

           fetchBaseStations(){

				axios.get('/api/basestations',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{}
                })
				.then((response) => {

                    this.basestations.push({value:0,text:'All'}) 
                    this.basestations.push(...response.data.basestations)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			}, 

           filterBS(event){
               this.basestationsel = event
               this.fetchRequests()
           },

        
           showSats(request){
               this.currentmessage = request.id
               this.$refs['sat-modal'].show()
           },
          
            updatemodal(){
                this.$refs['sat-modal'].hide() 
                
            },

               autoRefresh(){
                this.auto = !this.auto
                if(this.auto == true){
                    this.fetchRequests()
                    } else {
                        clearTimeout(this.polldata)
                        }
            },

		}	   
	
	};

</script> 


