<template>
<div>
    <b-row class="mt-1" v-if="systemalerts.length > 0">
             <b-col class="col-12 mt-3"><h6>SYSTEM ALERTS</h6></b-col>
             <b-col class="col-12 mt-3">
                <b-button v-for="alert in systemalerts" :key="alert.id" size="sm" variant="danger" block>{{ alert.text }}</b-button> 
             </b-col>
    </b-row>

    <b-row class="mt-1" v-if="gdprcount > 0">
        <b-col class="col-10 mt-3"><h6>ALERTS</h6></b-col>
        <b-col class="col-2 mt-3 text-right"><b-icon-chevron-expand style="cursor:pointer" @click.stop="setExpand()" /></b-col>
        <b-col class="col-12 mt-3" v-if="expand">

        <b-button v-if="gdprcount2 > 0" ref="alertsButton"  size="sm" variant="outline-danger" block @click.stop="menuLink('castergdpr')">
            <b-icon-bell style="margin-right:10px" />{{ gdprcount }} GDPR Waiting
        </b-button>

        <b-button v-else size="sm" ref="alertsButton" variant="outline-warning" block @click.stop="menuLink('castergdpr')">
            <b-icon-bell style="margin-right:10px" />{{ gdprcount }} GDPR Waiting
        </b-button>

        </b-col>
    </b-row>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div>
</template>

<style scoped>
    .alertsBtnFakeActive{
        background-color: #ffc107 !important;
        color: black !important;
    }
</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import { mapState } from 'vuex'
    export default {
        components:{ErrorLogging},
        data() {
            return {
                timeout:false,
                gdprcount:0,
                gdprcount2:0,
                gdprcount3:0,
                systemalerts:[],
                errorresponse:{},
                expand:true
            }
        },     
        created(){
            this.fetchAlarms()
            if(this.$store.getters.maint == 1){this.setMaint()}
        },

        beforeDestroy () {
            clearTimeout(this.timeout)
        }, 
        
        computed: mapState(['maint']),

        watch: {
                maint: function () { 
                        if(this.maint == 1){
                            this.setMaint()
                        } else this.systemalerts = []
                },
                gdprcount(){
                    this.emitAlertsButtonEl();
                }
        },
                
        methods: {

            setExpand(){
                this.expand = !this.expand
            },

            setMaint(){
                this.systemalerts.push({text:'Under Maintenance'})
                if(this.$store.getters.user.role != 1){
                    this.$store.commit('setloggedin',false)
                }
            },

            menuLink(name){
                if(this.$store.getters.mobile){
                    this.$store.commit('setopenmenu',false) //  close menu on item select if mobile    
                }  
                this.$router.push({ name: name, params: {} }).catch(()=>{})
                this.$emit('setActiveIcon',name)
            },
            emitAlertsButtonEl(){
                if(!(this.gdprcount > 0))return;
                this.$nextTick( () => {
                    if(!this.$refs.alertsButton)return;
                    //checks passed
                    this.$emit('alertsButtonEl', this.$refs.alertsButton)
                })
                
            },

            fetchAlarms(){
                this.fetchGDPR()
                if(!this.timeout){this.timeout = setInterval(this.fetchAlarms,120000)}
            },
          
            fetchGDPR(){
                axios.get('/api/casterindexcompliance',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         reseller:this.$store.getters.resellerid,
                         dealer:this.$store.getters.dealerid
                     }
                })
				.then((response) => {
                    if(response.data == 'NOT AUTHORISED'){
                        this.$store.commit('setloggedin',false)
                    }
                    else {
                        if(response.data.code == 999){
                            this.errorresponse = response.data
                        } else {
                                this.gdprcount = response.data.counts[0]
                                this.gdprcount2 = response.data.counts[1]
                                this.gdprcount3 = response.data.counts[2]
                            }
                        }       
                    
                    });
            },

		}	   
	
	};
</script> 