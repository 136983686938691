<template>
<div>
    <b-container>

    <b-row>


         <b-col class="col-12 mt-3">
            <label>Reseller</label>
            <b-form-select
                    v-model="sub.reseller"
                    :options="resellers"
                    disabled
                   
                />
        </b-col>     

      
       <b-col class="col-12 mt-3"> 
            <label>Subscription Type</label>
				<b-form-select
                    v-model="sub.type"
                    :options="subtypes"
                    name="sub.type"
                    v-validate="{min_value:1}"
					:class="{'b-form-select': true, 'is-danger': errors.has('sub.type') }"  
                    />
            </b-col>

        <b-col class="col-12 mt-3"> 
            <b-form-group label="Number of subscriptions">
            <b-form-input v-model="sub.number" name="number" class="darkinput" @input="convertToInt" type="number" min="1" max="500" v-validate="{ required: true, numeric: true, min_value: 1, max_value: 500 }"></b-form-input>
            <div v-if="errors.has('number')" class="invalid-feedback">{{ errors.first('number') }}</div>
            </b-form-group>
        </b-col>

        <b-col class="col-12 mt-3"> 

        <label>Purchase Order</label>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="sub.po"
                    name="sub.po"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('sub.po') }"  
                    class="darkinput"
                    />
         </b-col>
   
</b-row>
   
      
    <b-row class="mt-5 pb-5">
         <b-col class="col-12">
            <b-button variant="outline-success" block  @click="updateStock()">ADD TO RESELLER STOCK</b-button>
        </b-col>  
    </b-row> 


</b-container>

		
</div> 
</template>

<script>
    import axios from 'axios'
	export default {
        props:['reseller'],
        data(){
		return {
                token:'',
                sub:{},
                subtypes:[],
                dealers:[],
                resellers:[],
                updateStockLock:false,
                };
                
                },

		created(){
            
            this.token = this.$store.getters.token
            this.sub.reseller =  this.reseller
            this.sub.user = this.$store.getters.user.id
            this.sub.type = 0
            this.sub.number = 1
            this.fetchResellers()
            this.fetchSubTypes()
        },

		methods: {

           fetchSubTypes(){
			
				axios.get('/api/castersubscriptions',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                    }
                })
				.then((response) => {
                    this.subtypes.push({value:0,text:'Select'})
                    this.subtypes.push(...response.data.CasterSubscriptions)
  
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},


             fetchResellers(){

				axios.get('/api/casterbusinesses',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                    }
                    })
				.then((response) => {
                    this.resellers.push({text:'Select',value:0})
                    this.resellers.push(...response.data.CasterBusiness)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

           

            updateStock(){
                if(this.updateStockLock)return;
                this.updateStockLock = true;
               
                this.$validator.validateAll().then(result => {
                    if (!result) {
                        this.updateStockLock = false;

                    /* dont need this as text under the input informs the user
					this.$swal({
						title: "Fields Missing",
						text: "Please complete all required fields",
						icon: "warning",
						timer: 3000,
						showCloseButton: false
						})
                    */
					
                    } else {
                        
                        axios({
                            method: 'post',     //   New Stock Sub
                            headers: {"Authorization" : "Bearer " + this.token},
                            url: '/api/addcasterntripsubscriptionsreseller',
                            reseller:this.sub.reseller,
                            data: {
                                userid:this.$store.getters.user.id,
                                token:this.$store.getters.user.token,
                                subdata:this.sub
                            }
                        })
                        .then((response)=> {
                
                            if(response.data.error){
                                this.$swal({
                                    title: "Update Error",
                                    text: response.data.error,
                                    icon: "error",
                                    showCloseButton: true,
                                    showConfirmButton: false
                                });
                            } else {
                                this.$swal({
                                    title: "",
                                    text: 'Subscriptions Added',
                                    icon: "success",
                                    showCloseButton: true,
                                    showConfirmButton: false,
                                    timer:3000
                                    })
                                    .then(() => {
                                    this.$emit('updatemodal')
                                });
                            }

                    
                        }).catch(err => {
                            this.$swal({
                                title: "Update Error",
                                text: err,
                                icon: "error",
                                showCloseButton: true,
                                showConfirmButton: false
                            })
                        }).finally( () => {this.updateStockLock = false;});	
                    }   
                })      
            },

            validateField(field){
                this.$validator.validate(field)
            },   
      
            closemodal(){
                this.$emit('closemodal')
            },

            convertToInt(){
                try{
                    if(this.sub.number === "")return;

                    let intNumber = parseInt(this.sub.number);

                    if(isNaN(intNumber)) {
                        alert("number of subs is not a number")
                        return;
                    }
                    this.sub.number = intNumber;  
                }catch(err){
                    console.error(err)
                }
            }

		}	   
	
	};

</script> 


