<template>
<div class="loginpage">
    <NavbarDefault />
    <b-container>

    <b-row class="vh-100" align-v="center">
        
       <b-col class="col-12 col-sm-10 col-lg-8 offset-sm-1 offset-lg-2">

          <b-card
            class="mb-2 loginbox"
            >
            <b-row>
                <b-col class="col-12 text-center">
                    <h5>LOGIN PAGE NOT FOUND</h5>
                    <h6>Please use your portal address or contact support</h6>
                    <h6>V3.0 July 2023</h6>
                </b-col>
            </b-row>    
        </b-card>  

      </b-col>
   </b-row> 


<div class="fluid-container footer">
    <p class="text-center">Nick Abbey Digital Agriculture Ltd &#169; 2022</p>
</div>

</b-container>


</div>
</template>

<script>
import NavbarDefault from './NavBarDefault.vue'
export default {
    name:'NoLoginPage',
        components:{
            NavbarDefault 
    },    
    data(){
        return{
        dealer:{
            logo:'https://ip-rtk-aws.com/images/branding/logos/cloudbaselogo.png'
        }
        }
    },    

    mounted(){
                this.$store.commit('setloggedin',false)
                this.$store.commit('setbranding',this.dealer)
        }
    }
</script>