<template>
<div>
<b-row class="mt-1 pb-3" style="border-bottom:1px solid rgba(255,255,255,0.5)">
    <div class="stageselectmain">
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(1)" v-bind:class="dealerclass "><b-icon-building /></div>details</div>
        <div class="stageselectline" v-bind:class="line1class"></div>
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(2)" v-bind:class="userclass"><b-icon-person /></div>admin</div>
        <div class="stageselectline" v-bind:class="line2class"></div>
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(3)" v-bind:class="brandclass"><b-icon-image /></div>branding</div>
        <div class="stageselectline" v-bind:class="line2class"></div>
        <div class="stageselectbox"><div class="stageSelect" @click="setPage(4)" v-bind:class="createclass"><b-icon-check /></div><span v-if="dealerid == 0">create</span><span v-else>update</span></div>
    
    </div>
                
</b-row>

<b-container class="addrowpage" v-if="page == 1 && display">

   
    <b-row>

        <b-col class="col-12 mt-3">
                <h5>Dealer Details</h5>
        </b-col> 
        
        <b-col class="col-12 mt-3" v-if="roleid == 1">
            <label>Reseller</label>
                    <b-form-select
                            v-model="dealer.business"
                            :options="businesses"
                            @change = "changeReseller()"
                            name="dealer.business"
                            v-validate="{min_value:1}"
                            :class="{'input': true, 'is-danger': errors.has('dealer.business') }" 
                            class="darkinput"
                            @input = "validateField('dealer.business')"
                            :disabled = "dealer.business != 0 && roleid != 1" 
                        />
        </b-col> 
    
    
        <b-col class="col-12 mt-3">
                <label>Title</label>
                <b-form-input 
                    type="search"
                    placeholder="company name"
                    autocomplete="off" 
                    v-model="dealer.text" 
                    name="dealer.text"
                    v-validate="{required:true}"
					:class="{'darkinput': true, 'is-danger': errors.has('dealer.text') }" 
                    @input = "validateField('dealer.text')"
                    />
                    <span style="color:red" v-show="errors.has('dealer.text')">
                    {{ errors.first('dealer.text') }}</span>
		</b-col>

        <b-col class="col-12 mt-3">
                <label>Address</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="dealer.address1" 
                    name="dealer.address1"
                    v-validate="{required:true}"
					:class="{'darkinput': true, 'is-danger': errors.has('dealer.address1') }"
                    @input = "validateField('dealer.address1')" 
                    />
                    <span style="color:red;">{{ errors.first('dealer.address1') }}</span>
		</b-col>

        <b-col class="col-12 mt-3">
            <b-form-input 
                    type="search"
                    placeholder="optional"
                    autocomplete="off" 
                    v-model="dealer.address2" 
                    name="dealer.address2"
                    class="darkinput"
                    />
		</b-col>

        <b-col class="col-12 mt-3">
            <b-form-input 
                    type="search"
                    placeholder="optional"
                    autocomplete="off" 
                    v-model="dealer.address3" 
                    name="dealer.address3"
                    class="darkinput"
                    />
		</b-col>        

        <b-col class="col-12 mt-3">
                <label>Town / City</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="dealer.towncity" 
                    name="dealer.towncity"
                    v-validate="{required:true}"
					:class="{'input': true, 'is-danger': errors.has('dealer.towncity') }"
                    class="darkinput"
                    @input = "validateField('dealer.towncity')"
                    />
                    <span style="color:red;">{{ errors.first('dealer.towncity') }}</span>
		</b-col>

        <b-col class="col-12 col-md-6 mt-3" v-if="country == 1">
                <label>County</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="dealer.county" 
                    name="dealer.county"
                    class="darkinput"
                    />
		</b-col>


        <b-col class="col-12 col-md-6 mt-3">
            <label>Postcode</label>
                <b-form-input 
                    type="text"
                    placeholder=""
                    autocomplete="off" 
                    v-model="dealer.postcode" 
                    name="dealer.postcode"
                    class="darkinput"
                    />
		</b-col>
        
           <b-col class="col-12 mt-3">
                <label>Website</label>
                <b-form-input 
                    type="search"
                    placeholder="optional"
                    autocomplete="off" 
                    v-model="dealer.website" 
                    name="dealer.website"
                    class="darkinput"
                    />
		</b-col> 

        <b-col class="col-12 mt-3">
            <b-form-checkbox
                inline
                v-model="dealer.change_username"
                size="lg"
                value=1
                unchecked-value=0
              />
              <label style="margin-right:15px">Dealer can change NTRIP values</label> 
		</b-col> 

</b-row>
    
 
<b-row class="mt-5">
    <b-col class="col-12">
        <b-button variant="outline-success" block ref="next" size="md" @click="checkDealerDetails()">Next</b-button>
     </b-col>  
</b-row>     

</b-container>

<CasterAddSetAdmin 
        v-if="page == 2"
        :passadmin="dealer"
        v-on:adminset="updateAdminUser"
        />

<b-container class="addrowpage" v-if="page == 3">


<b-row class="mt-5">

    <b-col class="col-12 mt-3">
              <label>Login page name</label>
              <b-form-input 
                    type="search"
                    placeholder="eg: cwagritech"
                    autocomplete="off" 
                    v-model="dealer.logintitle" 
                    name="dealer.logintitle"
                    v-validate="'required:true|alpha_dash|max:40|min:3'"
					:class="{'input': true, 'is-danger': errors.has('dealer.logintitle') }"
                    class="darkinput"
                    size="md"
                    @input = "() => {
                        checkLoginTitle('dealer.logintitle');
                        validateField('dealer.logintitle');
                    }"
                    />
                    <span style="color:red;">{{ errors.first('dealer.logintitle') }}</span>
         </b-col> 

         <b-col class="col-12 col-md-8 offset-md-1 mt-3" v-if="logintitleerror">
            <b-button variant="danger" block disabled>Login page name unavailable</b-button>
        </b-col>

        <b-col class="col-12 mt-3">
           <label>Dealer logo</label>
        </b-col>

         <b-col class="col-12" v-if="logoimage == ''">
               <ImageUpload 
                type="branding/logos"
                v-on:upload="LogofileUploaded"
                />
         </b-col>

        <b-col class="col-10 offset-1" v-else>
              <img :src="'https://ip-rtk-aws.com/images/branding/logos/'+logoimage"  style="width:100%" />
              <b-button class="mt-1" size="sm" variant="outline-success" @click="resetLogo()"><b-icon-arrow-clockwise /></b-button> 
        </b-col>        

        <b-col class="col-12 mt-3">
            <label> Login page background image</label>
        </b-col>

         <b-col class="col-12"  v-if="bgimage == ''">
             <ImageUpload 
                type="branding/homebgs"
                v-on:upload="BGfileUploaded"
                />
         </b-col>   

        <b-col class="col-10 offset-1" v-else>
              <img :src="'https://ip-rtk-aws.com/images/branding/homebgs/'+bgimage"  style="width:100%" />
              <b-button class="mt-1" size="sm" variant="outline-success" @click="resetBg()"><b-icon-arrow-clockwise /></b-button>  
        </b-col>           
</b-row>

<b-row class="mt-5">
         <b-col class="col-12 mt-3">         
            <b-button variant="outline-success" block size="md"  @click="brandingCheck()">Next</b-button>
        </b-col>  
</b-row>          

</b-container>

<b-container class="addrowpage" v-if="page == 4" style="font-size:1em">

<b-row class="mt-2">
        <b-col class="col-12"><h5>{{ dealer.text }}</h5></b-col>

        <b-col class="col-12 mt-3">
            <b-row>
                <b-col class="col-2"><b-icon-geo-alt class="icon1" /></b-col>
                <b-col class="col-10">
                    <b-row>
                        <b-col class="col-12">{{ dealer.address1}}</b-col>
                        <b-col class="col-12" v-if="dealer.address2">{{ dealer.address2}}</b-col>
                        <b-col class="col-12" v-if="dealer.address3">{{ dealer.address3}}</b-col>
                        <b-col class="col-12">{{ dealer.towncity}}</b-col>
                        <b-col class="col-12">{{ dealer.postcode}}</b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>

        <b-col class="col-12">
            <b-row class="mt-3" v-if="dealer.mobile">
                <b-col class="col-2"><b-icon-phone  class="icon1" /></b-col>
                <b-col class="col-10">{{ dealer.mobile}}</b-col>
            </b-row>
            <b-row class="mt-3" v-if="dealer.tel">
                <b-col class="col-2"><b-icon-telephone  class="icon1" /></b-col>
                <b-col class="col-10">{{ dealer.tel}}</b-col>
            </b-row>
        </b-col>

        <b-col class="col-12 mt-3">
            <b-row>
                <b-col class="col-2"><b-icon-person  class="icon1" /></b-col>
                <b-col class="col-10">
                    <b-row>
                        <b-col class="col-12">{{ dealer.fname}} {{ dealer.lname }}</b-col>
                        <b-col class="col-12">{{ dealer.email }}</b-col>
                        <b-col class="col-12">{{ dealer.password }}</b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-col>  

        <b-col class="col-12 mt-3">
            <b-row>
                <b-col class="col-2"><b-icon-globe  class="icon1"/></b-col>
                <b-col class="col-10" v-if="this.dealer.country == 2">https://rtkdk.dk/login/{{ dealer.logintitle }}</b-col>
                <b-col class="col-10" v-else>https://ip-rtk-uk.com/login/{{ dealer.logintitle }}</b-col>
            </b-row>
        </b-col>

</b-row>  

<b-row class="mt-3">
                <b-col class="col-12 mt-1">
                    <label style="margin-right:10px;">{{  emailtypetext }} </label>
                    <b-form-checkbox
                    inline
                    v-model="sendwelcomeemail"
                    size="lg"
                    value=1
                    unchecked-value=0
                    />    
                </b-col>
                <b-col class="col-12 mt-1" v-if="sendwelcomeemail == 1">
                    <b-form-input 
                    type="search"
                    placeholder="email address"
                    autocomplete="off" 
                    v-model="emailto" 
                    class="darkinput"
                    />
                </b-col>
</b-row>      
   
<b-row class="mt-5">
     <b-col class="col-12">
            <b-button variant="outline-success" block size="lg" @click="createDealer()"><span v-if="dealerid==0">Create</span><span v-else>Update</span> Dealer</b-button>
        </b-col>  
</b-row>

</b-container>

<b-modal ref="create-dealer" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row> 

   <b-row>
         <b-col class="col-12 mt-5">
        <b-button variant="outline-success" size="md" @click.stop="finishDealer()" block>Finish</b-button>
       </b-col>   
   </b-row>          

</b-modal> 

 <SendEmail
        :sendmail="sendmail"
        :emailid="emailid"
        :emailto="emailto" 
        :id="addeddealer"
        :pw="dealer.password"
        :showdiag=false
        v-on:emailsent="emailSendStatus"
        />

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
		
</div> 
</template>

<style scoped>
.adminselect:hover{
    background-color: gray;
    cursor:pointer;
}
.icon1{
    font-size:1.4em;
}
</style>


<script>
    import CasterAddSetAdmin from'./CasterAddSetAdmin'
    import ImageUpload from './ImageUpload'
    import ErrorLogging from './ErrorLogging'
    import SendEmail from './SendEmail'
    import { Validator } from 'vee-validate'
    import axios from 'axios'
    import './customRule'
	export default {
        props:['dealerid'],
        name:'CasterDealerAdd',
        components:{
            CasterAddSetAdmin,
            ImageUpload,
            SendEmail,
            ErrorLogging
        },
        data(){
        return {
                token:'',
                dealer:{
                    value:0,
                    text:'',
                    address1:'',
                    address2:'',
                    address3:'',
                    towncity:'',
                    county:'',
                    postcode:'',
                    country:0,
                    tel:'',
                    mobile:'',
                    website:'',
                    fname:'',
                    lname:'',
                    email:'',
                    password:'',
                    business:0,
                    logintitle:'',
                    adminuser:0
                },
                dealerusers:[],
                user:{},
                stagemessages:[],
                businesses:[],
                bgimage:'',
                logoimage:'',
                dealerpassword:'',
                country:1,
                page:1,
                validate:0,
                currentlogintitle:'',
                currentemail:'',
                emailerror:false,
                emailerror2:false,
                emailto:'',
                emailid:30, // Dealer Welcome email
                sendmail:false,
                addeddealer:0,
                emailtypetext:'',
                sendwelcomeemail:1,
                userpass:{},
                roleid:0,
                usersel:0,
                index:0,
                useredit:0,
                modaltitle:'',
                dealerdetail:false,
                userdetail:false,
                branddetail:false,
                dealerclass:'stageactive',
                userclass:'',
                brandclass:'',
                createclass:'',
                line1class:'stageselectline',
                line2class:'stageselectline',
                line3class:'stageselectline',
                saving:false,
                display:false,
                logintitleerror:false,
                errorresponse:{},
              
                fields:[
                { key: 'title', label: 'Name', sortable: true  },
                { key: 'email', label: 'Login Email', sortable: true  },
                { key: 'password', label: 'Password', sortable: true  },
                { key: 'show_details', label: '' }
                ],
                userfields:[
                { key: 'fname', label: 'Forename', sortable: true  },
                { key: 'lname', label: 'Surname', sortable: true  },
                { key: 'roletitle', label: 'Role', sortable: true  },
                { key: 'selected_admin', label: ' Selected', tdClass: 'text-left' }
                ],
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            if(this.roleid == 1){this.fetchResellers()}
            if(this.dealerid == 0){ 
               this.newDealer()
            } else {    // Edit Dealer
                this.dealerclass = 'stagecomp'
                this.userclass = 'stagecomp'
                this.brandclass = 'stagecomp'
                this.createclass = 'stagecomp'
                this.line1class = 'stageselectlinecomp'
                this.line2class = 'stageselectlinecomp'
                this.line3class = 'stageselectlinecomp'
                this.sendwelcomeemail = 0
                this.emailtypetext = 'Re-send login details'
                this.fetchDealer()
            }
          },

          mounted() {
            Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   
        },

       watch:{

            dealerdetail: function () {
                         if(this.dealerdetail){this.dealerclass = 'stagecomp',this.line1class = 'stageselectlinecomp'} else {this.dealerclass = 'stageactive',this.line1class = 'stageselectline'}
                         },
            branddetail: function () {
                         if(this.branddetail){this.brandclass = 'stagecomp'} else this.brandclass = 'stageactive'
                         },
       },
       methods: {

            newDealer(){
                this.dealer.business = this.$store.getters.resellerid
                this.resellerid = this.$store.getters.resellerid
                this.dealerclass = 'stagecomp'
                if(this.dealer.business == 11){this.dealer.country = 2} else this.dealer.country = 1
                this.emailtypetext = 'Send welcome email'
                this.display = true
           }, 

           fetchDealer(){

				axios.get('/api/casterdealers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         id:this.dealerid,
                         list:1
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                            this.dealer = response.data.CasterDealers[0]
                            this.dealer.validated = true
                            this.currentemail = this.dealer.email
                            this.currentlogintitle = this.dealer.logintitle
                            this.bgimage = this.dealer.background
                            this.logoimage = this.dealer.logo
                            this.dealerusers = this.dealer.users
                            this.resellerid = this.dealer.business
                            this.display = true
                      }
                    });
			},
            
            checkDealerDetails(){
                this.$validator.validateAll().then(result => {
                if(result){
                    this.line1class = 'stageselectlinecomp'
                    this.userclass = 'stagecomp'
                    this.page = 2
                }
                })
            },

            updateAdminUser(details){
                    this.dealer.fname = details.fname
                    this.dealer.lname = details.lname
                    this.dealer.email = details.email
                    this.dealer.mobile = details.mobile
                    this.dealer.tel = details.tel
                    this.dealer.password = details.password
                    this.dealer.adminuser = details.adminuser
                    this.dealer.validated = true
                    // Auto set login title if new dealer
                    if(this.dealer.logintitle == ''){this.dealer.logintitle = this.dealer.text.replace(/\s/g, '').toLowerCase()}
                    if(this.currentlogintitle !== this.dealer.logintitle){this.checkLoginTitle()}
                    this.line2class = 'stageselectlinecomp'
                    this.brandclass = 'stagecomp'
                    this.page = 3
                },
        
            
            brandingCheck(){  
                this.$validator.validate('dealer.logintitle').then(result => {
                if(result){
                    if(this.dealer.logintitle != undefined && this.logintitleerror == false){ //} && this.bgimage != '' && this.logoimage != ''){
                        this.line2class = 'stageselectlinecomp'
                        this.createclass = 'stagecomp'
                        this.page = 4
                        this.emailto = this.dealer.email
                    }
                }
                })
            },

            resetLogo(){
                this.logoimage = ''
            },

             resetBg(){
                this.bgimage = ''
            },

           validateField(field){
                setTimeout(() => this.validateDebounced(field), 300);
            },

            validateDebounced(field){
                this.$validator.validate(field)
            },

        
            setPage(id){
            
                switch(id){
                    case 1:
                        this.page = id
                    break
                    case 2:
                     if(this.dealer.validated){
                        this.page = id
                        } 
                    break    
                    case 3:
                     if(this.dealer.validated){
                        this.page = id
                        } 
                    break     
                    case 4:
                    if(this.brandclass == 'stagecomp' && this.createclass == 'stagecomp'){
                        this.page = id
                        }
                    break
                }
            }, 

            BGfileUploaded(file){
                this.bgimage = file
            },

            LogofileUploaded(file){
                this.logoimage = file
            },

            selectAdmin(){
                this.modaltitle = "Select Main Administrator"
                this.$refs['select-admin'].show()
            },

            setAdmin(event){
                this.dealer.fname = event.fname
                this.dealer.lname = event.lname
                this.dealer.email = event.email
                this.dealer.adminuser = event.value
                this.$refs['select-admin'].hide()
            },

            changeReseller(){
                if(this.dealer.business == 11){this.dealer.country = 2} else this.dealer.country = 1
            },

            finishDealer(){
                    this.$refs['create-dealer'].hide()
                    this.$emit('updatemodal')
            },

            createDealer(){
                if(this.dealer.value == null){this.modaltitle = 'Creating Dealer'} else this.modaltitle = 'Updating Dealer'
                this.$refs['create-dealer'].show()
                this.saving = true
                this.dealer.logo = this.logoimage
                this.dealer.background = this.bgimage
               
                 axios({
                                method: 'post',     //   New Dealer
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/casterdealeraddtest',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    reseller:this.resellerid,
                                    country:this.dealer.country,
                                    dealerdata:this.dealer
                                    }
                                })
                            .then((response)=> {

                                if(response.data.code == 999){
                                    this.errorresponse = response.data
                                    this.saving = false
                                } else {
                                        this.stagemessages = response.data.messages
                                        this.dealer.value = response.data.dealerid
                                        this.addeddealer = this.dealer.value
                                        this.saving = false
                                        if(this.sendwelcomeemail == 1){this.sendEmail()}
                                    }
                            });	
            },

            sendEmail(){
                this.sendmail = !this.sendmail // trigger to SendEmail
            },

            emailSendStatus(data){
                    this.stagemessages.push(...data.messages)
            }, 

            fetchResellers(){

                axios.get('/api/casterbusinesses',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                        }
                })
                .then((response) => {
                    this.businesses.push({text:'Select',value:0})
                    this.businesses.push(...response.data.CasterBusiness)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
                },

            async checkLoginTitle(){
                await this.loginTitleCheck()
            },    

            async loginTitleCheck(){	

            return new Promise((resolve) => {

            axios.get('/api/casterdealers',{
                headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                params:{
                    userid:this.$store.getters.user.id,
                    token:this.$store.getters.user.token,
                    logintitle:this.dealer.logintitle
                }
            })
            .then((response) => {
                if(response.data.code == 999){
                    this.errorresponse = response.data
                } else {
                    if(response.data.count > 0){this.logintitleerror = true} else this.logintitleerror = false
                    }    
                resolve()
                });
            }) // End resolve promise
        },
            
         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script> 


