<template>
<div>

<b-container ref="cont" class="header" > 

<b-row>
  
    <b-col v-if="!phone" class="col-2 col-sm-3 text-left navicons">
        <b-icon-list @click.stop="toggle"  style="margin-left:5px;" />
        <b-icon-geo scale="0.8" :style="{color:dataind1,marginLeft:30+'px'}" />
        <b-icon-broadcast-pin scale="0.8" :style="{color:dataind2,marginLeft:5+'px'}" />

    </b-col>
    <b-col v-else class="p-1 pl-0 no-gutters col-2 col-sm-3 text-left navicons">
        <b-icon-list @click.stop="toggle"  style="margin-left:5px;" />
    </b-col>


    <b-col v-if="!phone" class="col-8 col-sm-6 text-center" >
        <img ref="brandingLogo" :src="logo" style="height:45px;padding:1px;">
    </b-col> 
    <b-col v-else class="p-1 no-gutters col-8 col-sm-6 text-center" >
        <img :src="logo" style="max-width:100%;max-height:45px;padding:1px;" >
    </b-col> 


    <b-col v-if="!phone" class="col-2 col-sm-3  text-left text-sm-right pt-1"> 
        <div  style="float:right;font-size:0.8em;color:rgba(255,255,255,0.7);margin-left:8px;margin-top:5px;">
            {{ user.fname }} {{ user.lname }}
        </div>
        <b-icon-person-circle class="navicons"  v-b-tooltip.hover title="Edit User Profile" style="margin-top:3px;margin-right:5px;" @click.stop="userprofile" />
    </b-col>
    <b-col v-else  class="p-1 pr-0 no-gutters col-2 col-sm-3 text-right "> 
        <b-icon-person-circle class="navicons"  v-b-tooltip.hover title="Edit User Profile" style="margin-top:3px;margin-right:5px;" @click.stop="userprofile" />
    </b-col>

</b-row>

<b-modal ref="edit-profile" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
    <CasterUserProfile
    :userid="user.id"
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal() 
    />
</b-modal> 

</b-container> 

<!-- <div class="navmenubutton navicons" v-if="networkdisplay">  
          <b-icon-list @click.stop="menuLink('casterdealer')"  style="margin-left:5px;" />
</div>   -->

</div>
</template>

<script>
import CasterUserProfile from './CasterUserProfile'
import { mapState } from 'vuex'
export default {
    name:'NavBarDashbord',
        components:{
            CasterUserProfile
        },   
    data(){
		return {
            isBurgerActive: true,
            user:{},
            logo:'',
            phone:false,
            networkdisplay:false,
        //   dealerlogin:'',
            business:0,
            dealer:0,
            company:0,
            role:0,
            modaltitle:'',
            debouncedResizeRoutine:null,
            dataind1:'#666666',
            dataind2:'#666666',       
        }
    },

    mounted(){
        this.logo = 'https://ip-rtk-aws.com'+this.$store.getters.branding.logo
        this.checkBrandingLogo();
        this.phoneScreenSizeHandler();

        if(this.$route.name == 'casternetworkdisplay'){this.networkdisplay = true} else this.networkdisplay = false
        this.user = this.$store.getters.user
        this.dealerlogin = this.$store.getters.branding.logintitle
    },

    beforeDestroy(){
        window.removeEventListener('resize', this.debouncedResizeRoutine);
    },

    computed: {
        ...mapState(['countryid','resellerid','dealerid','companyid','roleid','roversdatapolling','basesdatapolling']),
    },

    watch: {

        'countryid':function(){
            this.country = this.countryid
        },

        'resellerid':function(){
            this.business = this.resellerid
        },

        'dealerid':function(){
            this.dealer = this.dealerid
        },

        'companyid':function(){
            this.company = this.companyid
        },

        'roleid':function(){
            this.role = this.roleid
        },

       '$route': function () {
            if(this.$route.name == 'casternetworkdisplay'){this.networkdisplay = true} else this.networkdisplay = false
        },

        'roversdatapolling':function(){
            if(this.roversdatapolling){this.dataind1 = 'rgba(60, 210, 165, 0.698)' } else this.dataind1 = '#666666'
        },

        'basesdatapolling':function(){
            if(this.basesdatapolling){this.dataind2 = 'rgba(60, 210, 165, 0.698)' } else this.dataind2 = '#666666'
        },
    },

    methods:{

        toggle(){
            this.isBurgerActive = !this.isBurgerActive
            this.$store.commit('setopenmenu',!this.$store.getters.openmenu)
            if(this.networkdisplay){
                history.back()
            }
        },

        userprofile(){
            this.modaltitle = 'Edit Profile'
            this.$refs['edit-profile'].show()
        },       
            
        menuLink(name){
            this.$router.push({ name: name, params: {} }).catch(()=>{})
            this.$store.commit('setopenmenu',true)
        },

        closemodal(){
            this.$refs['edit-profile'].hide()
        },     

        updatemodal(){
            this.$refs['edit-profile'].hide()
        },

        checkBrandingLogo(){
            this.$refs.brandingLogo.addEventListener("error", () => {
                const defaultBrandingLogo = "https://ip-rtk-aws.com/images/branding/logos/cloudbaselogo.png";
                console.error(`NavBarDashboard: Branding Logo not found: ${this.logo}, using ${defaultBrandingLogo}`)
                this.logo = defaultBrandingLogo;
                this.$refs.brandingLogo = defaultBrandingLogo;
            });
        },

        debounce(funct, timer){
            let timeoutID
            return function debouncedFunct(...args){
                const doLater = () => {
                    clearInterval(timeoutID);
                    funct(...args);
                }
                clearInterval(timeoutID);
                timeoutID = setTimeout(doLater, timer);
            }
        },

        resizeRoutine(){
            const containerWidth = this.$refs.cont.clientWidth;
            if(!containerWidth)return;

            if(containerWidth <= 800){
                this.phone = true;
                return;
            }
            this.phone = false;
        },

        phoneScreenSizeHandler(){
            //setup
            if(this.$refs.cont.clientWidth <= 800){this.phone = true}

            //debounce does action {timer} after it hasnt been called again.
            this.debouncedResizeRoutine = this.debounce(this.resizeRoutine, 250);
            window.addEventListener('resize', this.debouncedResizeRoutine);
        },
    },

}
</script>