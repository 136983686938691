<template>
<div>
 <b-container class="mainpagecont">

<b-row class="mt-5 pt-3">

        <b-col class="col-12 col-md-4">
            <h4>Caster Audit</h4>
        </b-col>

        <b-col class="col-12 col-md-4 offset-md-4">
                <label>Rover</label>
                  <CasterSearch :setsearchtext="usernamesel"  v-on:textsearch=updateSearch v-on:clearsearch=clearSearch />
                <div class="choiceholder" v-if="showrovers">
                        <ul class="makerlist">
                        <li v-for="rover in rovers" :key="rover.value" @click="roverSel(rover)">{{ rover.text }}</li>
                        </ul>
                    </div>
        </b-col>  


 </b-row>  


<b-row class="mt-3">
    <b-col class="col-12">
        <CasterSubscriptionsFilter
            :resellerid="resellerid" 
            :dealerid="dealerid" 
            :companyid="companyid"
            v-on:dealersel=updateDealerFilter  
            v-on:companysel=updateCompanyFilter
            v-on:resellersel=updateResellerFilter 
        />
    </b-col>
</b-row>  

<b-row class="mt-3 mb-3" >
     <b-col class="col-6">
            <strong v-if="isBusy">Loading...</strong>
     </b-col>

     <b-col class="col-6 text-right">
         {{ items.length }} Results
     </b-col> 

      <b-col>
             <b-table 
                class="audit"
                striped 
                responsive 
                hover
                @row-clicked="showDetail($event)" 
				:items="items" 
                :fields="fields"
                tbody-tr-class="rowWClass"
                thead-tr-class="rowHClass"
                sort-icon-left
               
                dark
                fixed
            
                >
        
                </b-table>
       </b-col>             
</b-row> 

<b-modal ref="show-detail" size="md" :title="modaltitle"  hide-footer centered content-class="darkmodal">

    <b-row class="mt-1 mb-3"> 
        <b-col class="col-12">
                <h5>{{ dataitem.company }}</h5>
        </b-col>
    </b-row>

    <span v-if="dataitem.action == 'updated'">

   <b-row class="mt-0">
        <b-col class="col-12" v-if="dataitem.action == 'updated'">
            <h5>Old Values</h5>
        </b-col>
    </b-row>     
       
    <b-row class="mt-1"  v-if="dataitem.oldvalues.purchase_order">  
        <b-col class="col-4">
            Purchase Order
        </b-col>            
        <b-col class="col-8">
            {{ dataitem.oldvalues.purchase_order }}
        </b-col>
    </b-row>
    <b-row class="mt-1" v-if="dataitem.oldvalues.startdate"> 
        <b-col class="col-4">
            Start date
        </b-col>            
        <b-col class="col-8">
            {{ dataitem.oldvalues.startdate }}
        </b-col>
    </b-row>
    <b-row class="mt-1" v-if="dataitem.oldvalues.enddate">  
        <b-col class="col-4">
            End date
        </b-col>            
        <b-col class="col-8">
            {{ dataitem.oldvalues.enddate }}
        </b-col>
    </b-row>
    <b-row class="mt-1">  
        <b-col class="col-4">
            Stock Sub
        </b-col>            
        <b-col class="col-8">
            {{ dataitem.oldvalues.renewsub }}
        </b-col>
    </b-row>
    <b-row class="mt-1">  
        <b-col class="col-4">
            Renew Once
        </b-col>            
        <b-col class="col-8">
            <span v-if="dataitem.oldvalues.renew_once == 1">Yes</span>
            <span v-else>No</span>
        </b-col>    
    </b-row>
    
    <b-row class="mt-1">   
        <b-col class="col-4">
            SIM
        </b-col>            
        <b-col class="col-8">
            <span v-if="dataitem.oldvalues.nosimm == 0">Yes</span>
            <span v-else>No</span>
        </b-col>      
    </b-row> 

    <b-row class="mt-1" v-if="dataitem.oldvalues.status">  
        <b-col class="col-4">
            Status
        </b-col>            
        <b-col class="col-8">
            {{ getStatus(dataitem.oldvalues.status) }}
        </b-col>
    </b-row>

    </span>

   <b-row class="mt-3">
        <b-col class="col-12" v-if="dataitem.action == 'updated'">
            <h5>New Values</h5>
        </b-col>
    </b-row>
    <b-row class="mt-1"  v-if="dataitem.newvalues.purchase_order">  
        <b-col class="col-4">
            Purchase Order
        </b-col>            
        <b-col class="col-8">
            {{ dataitem.newvalues.purchase_order }}
        </b-col>
    </b-row>
    <b-row class="mt-1" v-if="dataitem.newvalues.startdate"> 
        <b-col class="col-4">
            Start date
        </b-col>            
        <b-col class="col-8">
            {{ dataitem.newvalues.startdate }}
        </b-col>
    </b-row>
    <b-row class="mt-1" v-if="dataitem.newvalues.enddate">  
        <b-col class="col-4">
            End date
        </b-col>            
        <b-col class="col-8">
            {{ dataitem.newvalues.enddate }}
        </b-col>
    </b-row>
    
    <b-row class="mt-1" v-if="dataitem.newvalues.renewsub">  
        <b-col class="col-4">
            Stock Sub
        </b-col>            
        <b-col class="col-8">
            {{ dataitem.newvalues.renewsub }}
        </b-col>
    </b-row>
    <b-row class="mt-1" v-if="dataitem.newvalues.stocksub">  
        <b-col class="col-4">
            Stock Sub
        </b-col>            
        <b-col class="col-8">
            {{ dataitem.newvalues.stocksub }}
        </b-col>
    </b-row>
    <b-row class="mt-1">  
        <b-col class="col-4">
            Renew Once
        </b-col>            
        <b-col class="col-8">
            <span v-if="dataitem.newvalues.renew_once == 1">Yes</span>
            <span v-else>No</span>
        </b-col>    
    </b-row>
    <b-row class="mt-1">   
        <b-col class="col-4">
            SIM
        </b-col>            
        <b-col class="col-8">
            <span v-if="dataitem.newvalues.nosimm == 0">Yes</span>
            <span v-else>No</span>
        </b-col>      
    </b-row>

    <b-row class="mt-1" v-if="dataitem.newvalues.status">  
        <b-col class="col-4">
            Status
        </b-col>            
        <b-col class="col-8">
            {{ getStatus(dataitem.newvalues.status) }}
        </b-col>
    </b-row>

    <b-row class="mt-5">   
        <b-col class="col-12">
            <b-button variant="outline-success" block size="sm" @click.stop="showRaw()">Show raw data</b-button>
        </b-col>      
    </b-row>    

</b-modal>

<b-modal ref="show-raw" size="md" :title="modaltitle"  hide-footer centered content-class="darkmodal">                        

    <span v-if="dataitem.action == 'updated'">
    <b-row class="mt-1"> 
        <b-col class="col-12">
                <h5>Old Values</h5>
        </b-col>
    </b-row>
    <b-row class="mt-3 mb-3"> 
        <b-col class="col-12">
            {{ dataitem.oldvalues }}
        </b-col>  
   </b-row>  
    </span>

     <b-row class="mt-1"> 
        <b-col class="col-12">
                <h5>New Values</h5>
        </b-col>
    </b-row>
    <b-row class="mt-3"> 
        <b-col class="col-12">
            {{ dataitem.newvalues }}
        </b-col>  
    </b-row>  

</b-modal>

</b-container>

    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
		
</div> 
</template>

<style>
    .audit .table-dark td{
        word-break: break-word !important;
    }
</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import CasterSearch from './CasterSearch'
    import CasterSubscriptionsFilter from './CasterSubscriptionsFilter'
    import axios from 'axios'
	export default {
        name:'CasterAudit',
        components:{
            CasterSearch,
            CasterSubscriptionsFilter,
            ErrorLogging
        },  
        data(){
            return {
                resellerid:0,
                dealerid:0,
                companyid:0,
                roverid:0,
                token:'',
                items:[],
                dataitem:{
                    newvalues:{
                        startdate:null,
                        enddate:null
                    },
                    oldvalues:{
                        startdate:null,
                        enddate:null
                    }
                },
                modaltitle:'',
                rovers:[],
                showrovers:false,
                usernamesel: null,
                filterstart:'',
                filterend:'',
                fields:[
                { key: 'reseller', label: 'Reseller', sortable: true },
                { key: 'dealername', label: 'Dealer', sortable: true , style:'break'},
                { key: 'company', label: 'Company', sortable: true , style:'break'},
                { key: 'rover', label: 'Rover', sortable: true },
                { key: 'username', label: 'Name', sortable: true },
                { key: 'action', label: 'Action', sortable: true },
                { key: 'datetime', label: 'Date/Time', sortable: true },  
                // { key: 'oldvalues', label: 'Old', sortable: false },
                // { key: 'newvalues', label: 'New', sortable: false },
               ],
                searchtext:'',
                sortBy: 'datetime',
                sortDesc: true,
                isBusy: false,
                timeout:false,
                errorresponse:{},
            };    
        },

        mounted(){
            
        },
        
        created(){
            this.$store.commit('setbasesdatapoll',0)
            this.token = this.$store.getters.token
            this.fetchAudits()
        },

		methods: {

             getStatus(id){
                let text = '-'
                switch(id){
                    case 2:
                    text = 'Pending'
                    break
                    case 3:                   
                    text = 'Active'
                    break
                    case 4:
                    text = 'Expired'
                    break
                    case 5:
                    text = 'Suspended'
                    break
                    case 6:
                    text = 'Cancelled'
                    break
                   }
                    return text
             },

            fetchAudits(){

				this.isBusy = true
				axios.get('/api/casteraudits',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellerid,
                        dealer:this.dealerid,
                        company:this.companyid,
                        rover:this.roverid
                    }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        return;
                    }
                    this.items = response.data.Results 
                }).catch(err => {
                    console.error("CasterAudit, fetchAudits: ",err)
                }).finally( () => {
                    this.isBusy = false;
                })

			},

            showDetail(event){
                this.dataitem = event
                this.modaltitle = this.dataitem.rover+' '+this.dataitem.sub[0].subscription[0].text+' '+this.dataitem.action
                this.$refs['show-detail'].show()
            },

            showRaw(){
                this.modaltitle = this.dataitem.rover+' '+this.dataitem.sub[0].subscription[0].text+' '+this.dataitem.action
                this.$refs['show-raw'].show()
            },

            updateResellerFilter(id){
                this.resellerid = id
                this.dealerid = 0
                this.companyid = 0
                this.fetchAudits()
            },

            updateDealerFilter(id){
                this.dealerid = id
                this.companyid = 0
                this.fetchAudits()
            },

            updateCompanyFilter(id){
                this.companyid = id
                this.fetchAudits()
            },

           
           fetchRovers(){
                
				axios.get('/api/indexrovers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        username:this.usernamesel
                    }
                }).then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        return;
                    }
                    this.rovers = response.data.rovers
                    if(this.rovers.length > 0){this.showrovers = true} else this.showrovers = false
                }).catch(err => {
                    console.log("CasterAudit, fetchRovers: ",err)
                });
			},

           roverSel(rover){
                this.usernamesel = rover.username
                this.roverid = rover.value
                this.showrovers = false
                this.resellerid = 0
                this.dealerid = 0
                this.companyid = 0
                this.fetchAudits()
           }, 

            updateSearch(data){
                this.usernamesel = data
                this.fetchRovers()
           }, 
            
            clearSearch(){
                this.rovers = []
                this.showrovers = false
                this.roverid = 0
                this.fetchAudits()
           },

		}	   
	
	};

</script> 