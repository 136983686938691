<template>
<div class="loginpage" :style="{ backgroundImage:'url(' +backgroundimage+ ')' }" v-if="backgroundimage !== ''">
<NavBarBlank  />
    <b-container>

    <b-row class="vh-100" align-v="center" >
        
      <b-col class="col-12 col-sm-10 col-lg-8 offset-sm-1 offset-lg-2" v-if="!loginpageerror">
            
          <b-card v-if="!undermaint"
            :title= "branding.text"
            class="mb-2 loginbox"
            >
             <form v-on:submit.prevent="login">
                <div class="form-group row">
                    <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>

                    <div class="col-md-6">
                        <b-form-input 
                            type="search"
                            placeholder=""
                            autocomplete="off" 
                            v-model="username" 
                            name="username"
                            v-validate="{required:true}"
                            :class="{'darkinput': true, 'is-danger': errors.has('username') }"
                            @change = "validateField('username')" 
                            @input = "validateField('username')"  
                            />
                            <span class="invalid-feedback" role="alert" v-if="loginerror">
                                <strong>Login error</strong>
                            </span>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                    <div class="col-md-6">
                        <b-form-input 
                            type="password"
                            placeholder=""
                            autocomplete="off" 
                            v-model="password" 
                            name="password"
                            v-validate="{required:true}"
                            :class="{'darkinput': true, 'is-danger': errors.has('password') }"
                            @change = "validateField('password')" 
                            @input = "validateField('password')"  
                            />

                            <span class="invalid-feedback" role="alert" v-if="loginerror==true">
                                <strong>Login error</strong>
                            </span>
                    </div>
                </div>

                <div class="form-group row mb-0">
                    <div class="col-md-8 offset-md-4">
                        <b-button variant="success" type="submit">Login</b-button>
                        <b-button variant="outline" style="color:rgba(255,255,255,0.7)" @click="forgotPassword()">Forgot your password?</b-button>
                        
                    </div>
                </div>
            </form>
        </b-card>  

        <b-card v-else
            title= "SYSTEM TEMPORARILY UNAVAILABLE"
            class="text-center mb-2 loginbox"
            >
            <p>{{ currentdatetime }}</p>
            <p>support access only</p>  

            <form v-on:submit.prevent="login">
           
                        <div class="form-group row">
                            <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>

                            <div class="col-md-6">

                                <b-form-input 
                                    type="search"
                                    placeholder=""
                                    autocomplete="off" 
                                    v-model="username" 
                                    name="username"
                                    v-validate="{required:true}"
                                    :class="{'darkinput': true, 'is-danger': errors.has('username') }"
                                    @change = "validateField('username')" 
                                    @input = "validateField('username')"  
                                    />

                                    <span class="invalid-feedback" role="alert" v-if="loginerror">
                                        <strong>Login error</strong>
                                    </span>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                            <div class="col-md-6">
                                <b-form-input 
                                    type="password"
                                    placeholder=""
                                    autocomplete="off" 
                                    v-model="password" 
                                    name="password"
                                    v-validate="{required:true}"
                                    :class="{'darkinput': true, 'is-danger': errors.has('password') }"
                                    @change = "validateField('password')" 
                                    @input = "validateField('password')"  
                                    />

                                    <span class="invalid-feedback" role="alert" v-if="loginerror==true">
                                        <strong>Login error</strong>
                                    </span>
                            </div>
                        </div>

                        <div class="form-group row mb-0">
                            <div class="col-md-8 offset-md-4">
                                <b-button variant="success" type="submit">Login</b-button>
                                <b-button variant="outline" style="color:white" @click="forgotPassword()">Forgot your password?</b-button>
                              
                            </div>
                        </div>
                    </form>
            </b-card> 

      </b-col>

      <b-col class="col-12 col-sm-10 col-lg-8 offset-sm-1 offset-lg-2" v-if="loginpageerror">

          <b-card
            class="mb-2 loginbox"
            >
            <b-row>
                <b-col class="col-12 text-center">
                    <h5>LOGIN PAGE NOT FOUND</h5>
                    <h6>Please check your login address or contact support</h6>
                    
                </b-col>
            </b-row>    
        </b-card>  

      </b-col>
   </b-row> 

</b-container>

<div class="fluid-container footer">
    <p class="text-center">Cloudbase Positioning Ltd &copy; 2024</p>
</div>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div>
</template>

<script>
import ErrorLogging from './ErrorLogging'
import NavBarBlank from './NavBarBlank.vue'
import axios from 'axios'
import moment from 'moment'
import backgroundimage from '@/assets/images/branding/cloudbasebgimage.jpeg'
import logo from '@/assets/images/branding/cloudbaselogo.png'


export default {
    name:'SystemLogin',
        components:{
            NavBarBlank,
            ErrorLogging
    },        
    data(){
        return{
        username:'',
        password:'',
        branding:{
            text:'',
            logintitle:'',
            logo:'',
            background:''
        },
        getbackgroundimage:'',
        backgroundimage,
        logo,
        loginerror:false,
        loginpageerror:false,
        undermaint:false,
        currentdatetime:'',
        errorresponse:{},
        link:'',
        token:'',
        timeout:false,
        systemadmin:false,
        }
  },

mounted(){
            this.link = this.$route.params.dealer
            this.token = ''
            this.currentdatetime =  moment().format('DD-MM-Y HH:mm:ss')
            if(this.link == 'system'){
                this.fetchSystemBranding()
            } else this.fetchBranding()
            
            this.maintCheck()
            
      },

beforeDestroy() {
               clearTimeout(this.timeout)
          },    

  watch: {
        getbackgroundimage: function() {
            this.backgroundimage = this.getbackgroundimage
        }
    },     

  methods:{

    fetchBranding(){
			axios.get('/api/casterbranding',{   // is unsecured route so no userid / token required
                params:{name:this.$route.params.dealer}
                    })
				.then((response) => {

                     if(response.data.code == 999){
                        this.errorresponse = response.data
                        } else {
                   
                    if(response.data.error){
                        this.branding.logo = response.data.logo
                        this.$store.commit('setbranding',this.branding)
                        this.loginpageerror = true
                    } else {
                        if(this.$route.params.dealer !== 'reset'){ // ???
                        this.branding = response.data.Branding[0]
                        this.undermaint = response.data.Maint
                        this.$store.commit('setcountryid',this.branding.country)
                        this.$store.commit('setresellerid',this.branding.reseller)
                        this.$store.commit('setdealerid',this.branding.dealer)
                        this.$store.commit('setcompanyid',0)    
                        this.$store.commit('setbranding',this.branding)
                        this.getbackgroundimage ='https://ip-rtk-aws.com'+this.branding.background
                        }
                    }
                }    
                  
                });
			},


        fetchSystemBranding(){
                    this.$store.commit('setcountryid',0)
                    this.$store.commit('setresellerid',0)
                    this.$store.commit('setdealerid',0)
                    this.$store.commit('setcompanyid',0)   
                    this.branding.text = 'System Management'
                    this.branding.logo = '/images/branding/logos/cloudbaselogo.png'
                    this.branding.background = '/images/branding/homebgs/cloudbasebgimage.jpeg'
                    this.$store.commit('setbranding',this.branding)
                    this.getbackgroundimage ='https://ip-rtk-aws.com'+this.branding.background
                    this.systemadmin = true
            },       

        login(){

          this.$validator.validateAll().then(result => { 
            if (result){
                axios({
                method: 'post',
                url: '/api/auth/vuelogin',
                data:{
                    email: this.username,
                    password: this.password,
                    systemadmin:this.systemadmin
                }
                })
            .then((response) => {
                if(response.data.status=='ok'){
                    let user = response.data.user
                    if(user.role == 1 || user.role == 5){ // Sysadmins and Resellers
                        user.change_username = true
                    } else user.change_username = response.data.dealer.change_username
                    let roleid = 0
                    
                    clearTimeout(this.timeout)

                    this.$store.commit('setuser',user)
                    this.$store.commit('settoken',response.data.token)
                    this.$store.commit('setloggedin',true)
                   
                   // Fix for sysadmins to not use user Reseller/Dealer from user record etc values 
                    // if(user.role != 1){
                    //     this.$store.commit('setcountryid',user.country)
                    //     this.$store.commit('setresellerid',user.business)
                    //     this.$store.commit('setdealerid',user.dealer)
                    //     this.$store.commit('setcompanyid',0)
                    //     roleid = user.role
                    // } else {
                    //     if(this.systemadmin){roleid = 1} else {
                    //         if(this.$store.getters.dealerid == 0){roleid = 5} // Reseller Admin
                    //         if(this.$store.getters.dealerid != 0){roleid = 10} // Dealer Admin
                    //     }
                    // }

                    if(user.role == 10){  // Dealer Admin
                        this.$store.commit('setcountryid',user.country)
                        this.$store.commit('setresellerid',user.business)
                        this.$store.commit('setdealerid',user.dealer)
                        this.$store.commit('setcompanyid',0)
                        roleid = user.role
                    }

                    if(this.systemadmin){roleid = 1} else {
                        if(this.$store.getters.dealerid == 0){roleid = 5} // Reseller Admin
                        if(this.$store.getters.dealerid != 0){roleid = 10} // Dealer Admin
                    }

                    this.$store.commit('setroleid',roleid)

                   
                    this.userEvent(1)   // create login event

                    switch (this.$store.getters.roleid){
                        case 1: // System admins
                        this.$router.push({ name: 'casteradminhome', params: {} }).catch(()=>{})
                        break;
                        case 5: // Reseller admins
                        this.$router.push({ name: 'casterresellerhome', params: {} }).catch(()=>{})
                        break;
                        case 10: // Dealer admins
                        this.$router.push({ name: 'casterdealer', params: {id:this.$store.getters.dealerid} }).catch(()=>{})
                        break;         
                    }

                    
                } else {
                    this.loginerror = true
                }
            })
            .catch(response => {
            alert('System login error'+response)
            }) 
            }
          })
        },

        forgotPassword(){
          this.$router.push({ name: 'reset-password', params: {} }).catch(()=>{})
         },

        validateField(field){
                this.$validator.validate(field)
        },

        userEvent(type){
           
            axios({
                method: 'post',  
                headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                url: '/api/userevent',
                data: {
                    userid:this.$store.getters.user.id,
                    token:this.$store.getters.user.token,
                    user:this.$store.getters.user.id,
                    type:type,
                    group:'user',
                    text:this.$store.getters.user.fname+' '+this.$store.getters.user.lname
                    } 
                })
            .then((response)=> {
        
            if(response.data.code == 999){
                    this.errorresponse = response.data
            } else {
                this.$emit('updatemodal')
                }
                                
            });	
    },
    
    maintCheck(){
              
              axios.get('/api/castersystemtest',{
              headers: {"Authorization" : "Bearer " + this.token},
              params:{
                  userid:this.$store.getters.user.id,
                  token:this.$store.getters.user.token,
                  page:'login'
              }
                  })
              .then((response) => {
                    this.$store.commit('setmaint',response.data.maintentance)
                    this.undermaint = response.data.maintentance
                    this.timeout = setTimeout(this.maintCheck, 30000)
              })
            },
  }            
}
</script>