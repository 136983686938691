<template>
<div class="imageBorder">

        <b-row v-if="display">
            <b-col class="col-12" v-if="system == 1">UK</b-col>
            <b-col class="col-12" v-else>Denmark</b-col>
             <b-col class="col-12 col-md-7 boxtext">{{ data.datasets.label }}</b-col>

             <b-col class="col-12 col-md-5 text-right"> 
                {{ timeperiod }}
            </b-col>             
            <!-- <b-col class="col-4 mt-3 text-right">
               <b-button size="sm" variant="outline-success" @click="setPeriod('day')">DAY</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('week')">WEEK</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('month')">MONTH</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('year')">YEAR</b-button>

            </b-col>             -->


            <b-col class="col-12 mt-3"  style="min-height:200px">
                 <BarChartHUD :data="data" :update="chartupdate" :height="200" :yspacing="10" />
            </b-col>

        </b-row>

         <b-row class="mt-3" v-else>
                <b-col class="col-12 text-center pt-3 pb-3">
                        <h5 style="color:#48A1BA;">No Data</h5>
                </b-col>   
        </b-row>

</div>
</template>

<script>
    import BarChartHUD from './BarChartHUD'
    import axios from 'axios'
    import moment from 'moment'
	export default {
        props:['type','sum','update','system'],
        name:'CasterSystemChartCard2',
        components:{
            BarChartHUD
        },  
        data(){
		return {
                data:[],
                token:'',
                display:false,
                period:3600, // 1 hour
                timeout:'',
                timeperiod:'',
                start:'',
                end:'',
                steps:0,
                chartupdate:0,
                lastupdate:0,
               };
                
                },

	mounted(){
                this.token = this.$store.getters.token
                this.setPeriod('day')
                this.getChartdata()
             },

     watch: {
        update: function() {
                this.getChartdata()
        }
    },           

	methods: {

                getChartdata(){

                axios.get('/api/createstatsgraphdata',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            //startday:'2023-05-12',
                            //starttime:'10:00:00',
                            steps:24,
                            type:this.type,
                            timezone:this.$store.getters.timezone,
                            system:this.system
                    }
                        })
			.then((response) => {

                    if(response.data.error == 'Unauthorized'){    
                     
                      this.$swal({
                            title: "System Error",
                            text: response.data.error,
                            icon: "error",
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                   }
                   
                   else {
                            let data = response.data
                            let sum= 0
                            
                         
                            data.datasets.data.forEach(element => {
                                sum = sum + element.reduce((a, b) => {return a + b;}, 10);
                            });

                            if(Date.now() - this.lastupdate > 300000){
                                 this.lastupdate  = Date.now()  
                                 sum = 0
                            }

                               
                            if(this.sum !== sum){ 
                                this.lastupdate  = Date.now()   
                                this.data = data
                                this.display = true
                                this.chartupdate++
                            }


                            this.$emit('datasum',{value:sum,type:this.type})
                        }

                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            })
                    });
                },
                
                setPeriod(value){
                    
                    switch(value){
                        case 'day':
                        this.start = moment().format("YYYY-MM-DD")
                        this.end = moment().format("YYYY-MM-DD")    
                        this.timeperiod = moment().format("DD-MM-YYYY")
                        this.steps = 24
                        break;
                        case 'week':
                        this.start = moment().startOf('isoweek').format("YYYY-MM-DD")
                        this.end = moment().endOf('isoweek').format("YYYY-MM-DD")
                        this.timeperiod = moment().startOf('isoweek').format("DD-MM-YYYY")+' - '+moment().endOf('isoweek').format("DD-MM-YYYY")
                        this.steps = 7
                        break;
                        case 'month':
                        this.start = moment().startOf('month').format("YYYY-MM-DD")
                        this.end = moment().endOf('month').format("YYYY-MM-DD")
                        this.timeperiod =  moment().startOf('month').format("DD-MM-YYYY")+' - '+ moment().endOf('month').format("DD-MM-YYYY")
                        this.steps = moment().daysInMonth() 
                        break;
                        case 'year':
                        this.start = moment().startOf('year').format("YYYY-MM-DD")
                        this.end = moment().endOf('year').format("YYYY-MM-DD")
                        this.timeperiod =  moment().startOf('year').format("DD-MM-YYYY")+' - '+ moment().endOf('year').format("DD-MM-YYYY")
                        this.steps = 12
                        break;                        
                    }    
                        this.$emit('periodchange',{start:this.start,end:this.end,period:value})
                        this.getChartdata()    
                }
            }	 

	};
</script> 