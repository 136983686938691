<template>
<div>
<div class="mainpagecont" >

<b-row class="mt-3">
    <b-col class="col-9 mt-3">
        <h4>Subscriptions</h4>
    </b-col>
    <b-col class="col-3 text-right mt-3">
        <b-button variant="outline-success" @click="$router.go(-1)" >BACK</b-button>
    </b-col>    
</b-row>


<b-row class="mt-0 pb-5">
 
    <b-col class="col-12 mt-3">
       <CasterSubsChartCard v-on:periodchange="changePeriod"/>
    </b-col>

   
</b-row> 



</div>
</div> 
</template>

<script>
    import CasterSubsChartCard from './CasterSubsChartCard'
    export default {
        name:'CasterStatsSubs',
        components:{
            CasterSubsChartCard
        },
        data(){
            return {
                start:'',
                end:'',
                period:''
                };
                
                },

    
		created(){
           
          
        },
	
		methods: {

           changePeriod(value){
               this.start = value.start
               this.end = value.end
               this.period = value.period
           }

		}	   
	
	};

</script>