<template>
<div>

<b-row>

<b-col class="col-2 mt-0">
        <b-button variant="outline-success" size="sm" @click="resetSearch()" style="margin-top:32px"> reset search </b-button>
    </b-col>

</b-row>

<b-row class="mt-3">
    <b-col class="col-12 col-md-5 mt-3" v-if="total > limit">1 - {{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-12 col-md-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>
</b-row>    


<b-row class="mt-3 mb-5" >

      <b-col>
             <b-table
                class ="nowrap"
                ref = "companys-table" 
                responsive 
                hover 
                sticky-header="600px"
                :items="displaycompanys" 
                :fields="conditionalFields"
                :sort-by="sortBy"
                @head-clicked="onSortChanged"
                :no-local-sorting="nosort"
                @row-clicked="showCompany($event)"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                :busy="isBusy"
                sort-icon-left
                dark
                >
                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteCompany(data.item.value)" class="mt-1 mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <b-button size="sm" @click="editCompany(data.item)" class="mt-1 mr-2 xsbutton" variant="outline-success" v-if="role==1">
                        <b-icon-pencil />
                    </b-button>
                </template>    

                </b-table>
            
            <b-button v-if="more" variant="outline-success" block @click.stop="showMore()">Show more results?</b-button>
            <b-button v-if="loadingmore" variant="outline-warning" disabled block>Loading results</b-button>
</b-col>
</b-row>	

<b-modal v-model="isBusy" hide-header hide-footer centered content-class="loadingmodal">
        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <b-spinner variant="success" class="mr-2"/>
                <h5>loading results</h5>
            </b-col>
        </b-row> 
</b-modal> 

<b-modal ref="edit-company" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterCompanyAdd
    :companyid="companysel"
    nosave=1
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    v-on:updatecompany=updatemodal()
    />
</b-modal> 


<b-modal ref="delete-company" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterCompanyDelete
    :id="companysel" 
    v-on:updatemodal=updatemodal()
    />
</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style scoped>
.nowrap td {
    white-space: nowrap !important;
}

.modal-content{
    border:none !important;
}
</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    export default {
        props:['resellerid','dealerid','companyid','clearsearch','stext'],
        name:'CasterCompaniesTable2',
        components:{
            ErrorLogging
        },
        data(){
            return {
                test:true,
                id:0,
                token:'',
                companys:[],
                displaycompanys:[],
                companysel:0,
                orderby:'company',
                nosort:false,
                sortdesc:false,
                sortBy:'text',
                isBusy:false,
                display:false,
                errorresponse:{},
                nosubs:false,
                searchtext:'',
                page:0,
                limit:50,
                more:false,
                loadingmore:false,
                total:0,
                rstart:1,
                rend:0,
                role:0,
                fields:[
                        { key: 'text', label: 'Name', sortable: true  },
                        { key: 'resellername', label: 'Reseller', sortable: true  },
                        { key: 'dealername', label: 'Dealer', sortable: true  },
                        { key: 'contact', label: 'Contact', sortable: false  },
                        { key: 'email', label: 'Email', sortable: false  },
                        { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                    ],
                observer: null,
                modaltitle:''
                };
                
                },

		created(){
            this.role = this.$store.getters.roleid
            this.token = this.$store.getters.token

            if(this.$store.getters.prevroute == 'castercompany'){
                this.searchtext = this.$store.getters.searchtext
            } else {
                this.$store.commit('setprevroute',this.$route.name)
                this.searchtext = ''
                this.$store.commit('setsearchtext','')
                }
           
            this.fetchCompanys()
        },

        mounted(){
            this.addTableEventList()
        },

        computed: {
            
            filterdata() {
                return { ...this.resellerid, ...this.dealerid, ...this.companyid };
            },

            conditionalFields() {

                if(this.resellerid > 0 && this.dealerid == 0 && this.companyid == 0){
                    return this.fields.filter(field => field.key !== 'resellername')
                } 

                if(this.dealerid > 0 && this.companyid == 0){
                    return this.fields.filter(field => field.key !== 'dealername' && field.key !== 'resellername')
                }

                return this.fields

                }
        },

         watch: {
                    filterdata: function () {
                        this.page = 0
                        this.fetchCompanys()
                    },

                    clearsearch: function () {
                        this.page = 0
                        this.fetchCompanys()
                    },
                   
                    stext: function () {
                        if(this.stext !== this.searchtext){
                            this.searchtext = this.stext
                            this.page = 0
                            this.fetchCompanys()
                            }
                         },
                         
                    companys: function() {
                        if(this.page > 0){
                            this.readdTableEventList
                        }
                    }
        }, 
        
		methods: {

           editCompany(company){
                this.modaltitle = 'Edit Company : '+company.text
                this.companysel = company.value
                this.$refs['edit-company'].show()
           },

           deleteCompany(id){
               this.companysel = id
               this.modaltitle = 'Delete Company'
               this.$refs['delete-company'].show()
           },
           
            showCompany(event){
                this.$router.push({ name: 'castercompany', params: {id:event.value} }).catch(()=>{})
            },

            setStatus(){
                this.page = 0
                this.fetchCompanys()
            },

        
            fetchCompanys(){
                this.isBusy = true
                axios.get('/api/castercompaniessort',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.token,
                        roleid:this.role,
                        reseller:this.resellerid,
                        dealer:this.dealerid,
                        company:this.companyid,
                        page:this.page,
                        limit:this.limit,
                        stext:this.searchtext,
                        orderby:this.orderby,
                        sortdesc:this.sortdesc
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        } else {
                                this.more = false
                                this.loadingmore = false 
                                if(this.page == 0){
                                    this.companys = response.data.CasterCompanies
                                } else this.companys.push(...response.data.CasterCompanies)

                                this.displaycompanys = this.companys

                                this.updatePaging()
                                
                                this.total = response.data.Total
                                if(this.total < this.limit){
                                    this.more = false
                                    this.nosort = false
                                } this.nosort = true

                                if(this.total != 0 && this.stext != ''){
                                    this.$emit('fetchresponse',this.stext)
                                }

                                this.isBusy = false
                                this.display = true
                        }
                    
                    });
			},

            updatePaging(){
                if(this.page == 0){
                    this.rstart = 1
                    this.rend = this.limit
                } else {
                this.rend = ((this.page + 1) * this.limit)
                if(this.rend > this.total){this.rend = this.total}
                } 
            },

            addTableEventList(){
                const tableScrollBody = this.$refs["companys-table"].$el
                tableScrollBody.addEventListener("scroll", this.onScroll)
            },

            removeTableEventList(){
                const tableScrollBody = this.$refs["companys-table"].$el
                tableScrollBody.removeEventListener("scroll", this.onScroll)
            },

            readdTableEventList(){
                this.$nextTick(() => {    
                    this.removeTableEventList()
                    this.addTableEventList()
                })    
            },

            showMore(){
                this.page++
                this.more = false
                this.loadingmore = true
                this.fetchCompanys()
            },

            onScroll(event) {
                if (
                    event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 200
                ) {
                    if (!this.isBusy) {
                        if(this.total > this.limit){
                            this.more = true
                        } else this.removeTableEventList()
                    }
                }
            },

             // handle column sorting events to only call API when there is more results
             onSortChanged(field,event){
              
              if(event.sortable){
                  if(this.nosort){
                      this.orderby = field
                      this.sortdesc = this.$refs['companys-table'].localSortDesc !== true
                      this.page = 0
                      this.fetchCompanys()
                  } 
              }
          },

            setDefaultSort(){
                if(this.resellerid > 0){this.orderby = 'dealer'}
                this.fetchCompanys()
            },

            resetSearch(){
                this.statussel = 0
                this.searchtext = ''
                this.fetchCompanys()
                this.$store.commit('setsearchtext','')
                this.$emit('clearstext')
            },

        }	
    };

</script>