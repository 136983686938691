<template>
<div>

<b-container class="mainpagecont">

<b-row>
    <b-col class="col-9 mt-3"><h4>Email Log</h4></b-col>
</b-row>

<b-row v-if="display">

        <b-col class="col-12 col-sm-3 mt-3" v-if="role == 1">
            <label>Reseller</label>
            <b-form-select class="darkinput2"  v-model="resellersel" size="sm" :options="resellers" @change="filterReseller($event)" ></b-form-select>     
        </b-col> 

         <b-col class="col-12 col-sm-3 mt-3" v-if="role == 1 || role == 5">
            <label>Dealer</label>
            <b-form-select class="darkinput2"  v-model="dealersel" size="sm" :options="dealers" @change="filterDealer($event)" ></b-form-select>     
        </b-col>

        <b-col class="col-12 col-sm-3 mt-3">
            <label>Company</label>
            <b-form-select class="darkinput2"  v-model="companysel" size="sm" :options="companies" @change="filterCompany($event)" ></b-form-select>     
        </b-col>

        <b-col class="col-12 col-sm-3 mt-3" v-if="role == 1">
            <label>Type</label>
            <b-form-select class="darkinput2"  v-model="typesel" size="sm" :options="types" @change="filterType($event)" ></b-form-select>     
        </b-col>

</b-row>

<b-row v-if="display">
     <b-col class="col-12">
        <CasterEmailsTable 
            :reseller="resellersel" 
            :dealer="dealersel" 
            :company="companysel" 
            :type="typesel"
            :key="componentKey"
            />
    </b-col>

</b-row>        

</b-container>
	
</div> 
</template>

<script>
    import CasterEmailsTable from './CasterEmailsTable'
    import axios from 'axios'
	export default {
        name:'CasterEmails',
        components:{
            CasterEmailsTable
        },
        data(){
		return {
            resellersel:0,
            dealersel:0,
            companysel:0,
            typesel:0,
            resellers:[],
            dealers:[],
            companies:[],
            types:[],
            componentKey:0,
            display:false,
            role:5
       };
		
		},
		created(){
            this.token = this.$store.getters.token
            this.role = this.$store.getters.roleid
            this.$store.commit('setbasesdatapoll',0)
            this.resellersel = this.$store.getters.resellerid
            this.dealersel = this.$store.getters.dealerid
            this.fetchResellers()
            this.fetchTypes()
            
       },
	
		methods: {

            menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },

            fetchResellers(){

				axios.get('/api/casterbusinesses',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                    }
                })
				.then((response) => {
                    if(response.data.CasterBusiness != undefined){
                        this.resellers = []
                        this.resellers.push({text:'All',value:0})
                        this.resellers.push(...response.data.CasterBusiness)
                        this.fetchDealers()
                        this.display = true
                        } else this.display = false
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

             fetchDealers(){

				axios.get('/api/casterdealers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellersel,
                        list:1
                        }
                })
				.then((response) => {
                    this.dealers = []
                    this.dealers.push({value:0,text:'All'}) 
                    this.dealers.push(...response.data.CasterDealers)
                    this.fetchCompanies()
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
            
            fetchCompanies(){

				axios.get('/api/castercompanies',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellersel, 
                        dealer:this.dealersel,
                        nousers:1
                        }
                })
				.then((response) => {
                    this.companies = []
                    this.companies.push({value:0,text:'All'}) 
                    this.companies.push(...response.data.CasterCompanies)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

            fetchTypes(){

                    axios.get('/api/casteremails',{
                        headers: {"Authorization" : "Bearer " + this.token},
                        params:{
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            reseller:this.resellersel, 
                            dealer:this.dealersel,
                            nousers:1
                            }
                    })
                    .then((response) => {
                        this.types = []
                        this.types.push({value:0,text:'All'}) 
                        this.types.push(...response.data.CasterEmails)
                        })
                        .catch(err => {
                            this.$swal({
                                title: "System Error",
                                text: err,
                                icon: "error",
                                timer: 3000,
                                showCloseButton: false,
                                showConfirmButton: false
                                
                                })
                        });
},

            filterReseller(event){
               this.resellersel = event
               this.dealersel = 0
               this.companysel = 0
               this.fetchDealers()
               this.componentKey++
           },

            filterDealer(event){
                this.dealersel = event
                this.companysel = 0
                this.fetchCompanies()
                this.componentKey++
           },

           filterCompany(event){
               this.companysel = event
               this.componentKey++
           },

           filterType(event){
               this.typesel = event
               this.componentKey++
               
           },
        }
    }
</script> 