<template>
<div class="" ref="mainDiv">


<b-row class="mt-3 mb-3" >
    <b-col class="col-12 col-sm-auto">
        <h3 class="mb-1" >{{ requests.length }} Results</h3>
    </b-col>

    <b-col class="col-12 col-sm mb-1">
        <CasterSearch :setsearchtext="usernamesel" v-on:textsearch=updateSearch v-on:clearsearch=clearSearch />
        <div class="choiceholder" v-if="showrovers">
            <ul class="makerlist">
                <li v-for="rover in rovers" :key="rover.value" @click="roverSel(rover)">{{ rover.text }}</li>
            </ul>
        </div>
    </b-col>
    <b-row class="col-12 col-sm-4 no-gutters">
        <b-col class="col-6 col-sm-12 col-lg-6">
            <b-button variant="outline-success" block size="sm" style="min-width:100px" @click.stop="autoRefresh()" v-if="auto">AUTO ON</b-button>
            <b-button variant="outline-success" block size="sm" style="min-width:100px" @click.stop="autoRefresh()" v-else >AUTO OFF</b-button> 
        </b-col> 
        <b-col class="col-6 col-sm-12 col-lg-6">
            <b-button variant="outline-success" block size="sm" style="min-width:100px"  v-b-toggle.lastEventsKeyCollapse >
                <b-icon class="mr-1" icon="key"/>
                KEY
            </b-button>  
        </b-col> 
    </b-row>
</b-row>

<!-- JM - Key, Collapsable -->
<b-row>
    <b-col>
        <b-collapse id="lastEventsKeyCollapse">
            <b-container>
                <CasterLastEventsCardKey/> 
            </b-container>
            <hr>
            <hr>
        </b-collapse>
    </b-col>
</b-row>

<b-row class="mt-3 mb-3" >

      <b-col v-if="!showgga">
            <b-table

                striped 
                responsive 
                hover 
				:items="requests" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                dark
                >
                 <template #cell(status)="data">
                    <div class="statuscircle" :style="{ backgroundColor: data.item.Color }" />
                </template>

            </b-table>
       </b-col>       

        <b-col v-else>
            <b-table
                striped 
                responsive 
                hover 
				:items="requests" 
                :fields="fields2"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                dark
                >

                <template #cell(status)="data">
                    <div class="statuscircle" :style="{ backgroundColor: data.item.Colour }" />
                </template>
            </b-table>
       </b-col>             
</b-row>            

</div> 
</template>

<script>

    import CasterSearch from './CasterSearch'
    import CasterLastEventsCardKey from './CasterLastEventsCardKey.vue'
    import moment from 'moment'
    import axios from 'axios'

	export default {
        name:'CasterLogMessagesComp',
        components:{
            CasterSearch,
            CasterLastEventsCardKey
        }, 
        data(){
            return {
                token:'',
                requests:[],
                rovers:[],
                showgga:false,
                showrovers:false,
                usernamesel: null,
                auto:true,
                timeout:false,
                fields:[
                    { key: 'status', label: '' },    
                    { key: 'Description', label: 'Message', sortable: true },
                    { key: 'Timestamp', label: 'Date/Time', formatter:'settime', sortable: true, style:'break' },
                    { key: 'Username', label: 'Rover', sortable: true },
                    { key: 'Basestation', label: 'Reference Station', sortable: true },
                    { key: 'Session_id', label: 'Session ID', sortable: true },
                ],
                fields2:[
                    { key: 'status', label: '' },    
                    { key: 'Timestamp', label: 'Date/Time', formater:'settime', sortable: true, style:'break' },
                    { key: 'Session_id', label: 'Session ID', sortable: true },
                    { key: 'Message', label: 'Message', sortable: true }, 
                ],
                sortBy: 'value',
                sortDesc: false,
                isBusy: false, 
            };
                
        },

		created(){
            this.$store.commit('setbasesdatapoll',0)
            this.token = this.$store.getters.token
            this.fetchRequests()
        },
        mounted(){
            this.setModalMaxHeight();
        },

        beforeDestroy () {
            clearInterval(this.timeout)
        },

        

		methods: {

            fetchRequests(){
				this.isBusy = true
				axios.get('/api/casterlog',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         rover:this.usernamesel,
                         reseller:this.$store.getters.resellerid,
                         dealer:this.$store.getters.dealerid
                         }
                })
				.then((response) => {
                    this.requests = response.data.Log
                    this.isBusy = false
                     if(this.auto == true){
                                clearTimeout(this.timeout)
                                this.timeout = setTimeout(this.fetchRequests, 5000)
                            }
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });

			},

             fetchRequestsGGA(){
                if(!this.showgga){return}
				this.isBusy = true
				axios.get('/api/casterloggga',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         rover:this.usernamesel
                         }
                })
				.then((response) => {
                    this.requests = response.data
                    this.isBusy = false
                     if(this.auto == true){
                                clearTimeout(this.timeout)
                                this.timeout = setTimeout(this.fetchRequestsGGA, 5000)
                            }
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });

			},

            fetchRovers(){
                
				axios.get('/api/indexrovers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         username:this.usernamesel,
                         reseller:this.$store.getters.resellerid,
                         dealer:this.$store.getters.dealerid
                    }
                })
				.then((response) => {
                    this.rovers = response.data.rovers
                    if(this.rovers.length > 0){
                        this.showrovers = true
                        this.showgga = false
                        } else this.showrovers = false
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
           
            roverSel(rover){
                this.showgga = true
                this.usernamesel = rover.username
                this.showrovers = false
                clearTimeout(this.timeout)
                this.fetchRequestsGGA()
           }, 
            
           updateSearch(data){
                this.usernamesel = data
                this.fetchRovers()
                if(data.length > 0){this.fetchRequests()}
           }, 
           
           clearSearch(){
                this.usernamesel = null
                this.rovers = []
                this.showgga = false
                this.showrovers = false
                this.timeout = false
                this.fetchRequests()
           },

            autoRefresh(){
                this.auto = !this.auto
                 if(this.auto == true){
                    this.fetchRequests()
                    } else {
                        clearTimeout(this.polldata)
                        }
            },

            settime(datetime){  
                return  moment.utc(datetime).local().format('DD-MM-YY HH:mm:ss')
            },

            setModalMaxHeight(){
                //modals cant just be given a class, so did it this way
                const modalBody = this.$refs["mainDiv"].parentElement;
                const modalBodyClasses = [...modalBody.classList];
                const bodyPos = modalBodyClasses.findIndex( listItem => {return listItem === "modal-body"});
                if(bodyPos === -1)return null;

                const modalContent = modalBody.parentElement;
                if(!modalContent)return null;
                const modalContentClasses = [...modalContent.classList];
                const contentPos = modalContentClasses.findIndex( listItem => {return listItem === "modal-content"});
                if(contentPos === -1)return null;

                //checks passed, we have a valid modal-body / modal-content
                modalContent.style.height = "90vh";
                modalBody.style.maxHeight = "90%";
                modalBody.style.overflowY = "auto";
                
            },
        }	   
	
	};
</script>