<template>
<div>
<b-container class="mainpagecont">

<b-row>
    <b-col class="col-12 p-2"><h3>Logged Rovers<br/><span style="font-size:0.7em;color:rgba(255,255,255,0.7)">{{ title }}</span></h3></b-col>
   
    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterRoversLoggedCard :singlebox="true"/>
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterShowLeafletRovers  :border="true" v-on:fullscreen="fullScreen"/>
    </b-col>

    <b-col class="col-12 mt-1 p-2 mb-5">
        <CasterRoversListLoggedCard  />
    </b-col>             

</b-row>

<b-modal ref="full-screen" size="xl"  title="ROVERS MAP" @show="waitSetWidth" @hide="closeMap" hide-footer centered content-class="darkmodal2">
   <div ref="fullscreendiv" :style="{width:100+'%',height:500+'px'}">
        <CasterShowLeafletRovers v-if="modalmapwidth != 0"  :fsmapheight="modalmapheight" :fsmapwidth="modalmapwidth" :border="false"/>   
   </div>
</b-modal> 

</b-container>
</div>		
</template>
<script>
    import CasterRoversLoggedCard from './CasterRoversLoggedCard'
    import CasterShowLeafletRovers from './CasterShowLeafletRovers'
    import CasterRoversListLoggedCard from './CasterRoversListLoggedCard'
    import axios from 'axios'
	export default {
        name:'CasterRoversLogged',
        components:{
            CasterRoversLoggedCard,
            CasterShowLeafletRovers,
            CasterRoversListLoggedCard
        },  
        data(){
            return {
                    type:0,
                    reseller:0,
                    title:'',
                    showmap:false,
                    modalmapwidth:0,
                    modalmapheight:485
                };
                
                },
        created(){
           
          
        },

        mounted(){
            this.$store.commit('setbasesdatapoll',0)
            this.$store.commit('setroversdatapoll',1)
            this.getTitle()
        },

        beforeDestroy() {
            window.removeEventListener("resize",this.setModalWidth)
        }, 

        methods:{
           getTitle(){

                 axios.get('/api/castertitle',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.$store.getters.resellerid,  
                        dealer:this.$store.getters.dealerid,
                        company:this.$store.getters.companyid
                     }
                })
				.then((response) => {

                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                            this.title = (response.data)
                            }
                            
                    });

            },

            showMap(){
                    this.showmap = true
                },

            fullScreen(){
                this.modalmapwidth = 0
                this.$refs['full-screen'].show()
            },

            waitSetWidth(){
                setTimeout(this.setModalWidth,150)
            },

            setModalWidth(){
                window.addEventListener("resize", this.setModalWidth)
                this.modalmapwidth = this.$refs['fullscreendiv'].clientWidth -20
            },

            closeMap(){
                window.removeEventListener("resize",this.setModalWidth)
                this.$refs['full-screen'].hide()
            }
        }
	
	};
</script>