<template>
<div>
<div>
    <div class="meter" v-if="!pollerror">
            <span class="progress" :class="lineclass"></span>
    </div>

    <div style="width:100%;position:relative;padding:1px;font-size:0.9em;background-color:red;color:white" v-else>
           <b-col class="col-12 text-center">
               WARNING LAST DATA RECEIVED {{ lastpoll }}
            </b-col>   
    </div>

</div>
  
</div> 
</template>


<script>
    import axios from 'axios'
    import { mapState } from 'vuex'
	export default {
        data(){
		return {
                    linetoggle:true,
                    lineclass:'lineoff',
                    routechange:true,
                    token:'',
                    pollerror:false,
                    lastpoll:''
                };
                
                },
		
        created(){
            this.token = this.$store.getters.token
            this.checkPollStatus()
        },

        computed: mapState(['route','dataind']),

        watch: {
                    route: function () {
                            this.drawLine()
                         }, 

                      dataind: function () {
                           this.drawLine()
                         },             
                         
          },

		methods: {
       
                drawLine(){
                    if(this.linetoggle){this.lineclass = 'lineon'} else this.lineclass = 'lineoff'
                    this.linetoggle = !this.linetoggle
                    this.checkPollStatus()
                },

                checkPollStatus(){
                    axios.get('/api/castersystem',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                    }
                })
				.then((response) => {

            if(response.data.error == 'Unauthorized'){    
                   
                   this.$swal({
                            title: "Inactivity Timeout",
                            text: 'Logging out',
                            icon: "warning",
                            showCloseButton: false,
                            showConfirmButton: false,
                               timer:3000
                        })

                       // this.$store.commit('setloggedin',false) 
                   } else {

                        if(response.data.casterpolloffline == 1){
                            this.pollerror = true
                            this.lastpoll = response.data.lastcasterpoll
                        } else this.pollerror = false

                        }
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            })
                    });
                },

		}	   
	
	};

</script> 