<template>
<div>

<b-container style="max-width:80%;">

    <b-row>
        <b-col class="col-12 col-sm-6 mt-3"><h3>System Admins</h3></b-col>
        <b-col class="col-12 col-sm-4 offset-sm-2 col-md-3 offset-md-3 mt-3" >
                <b-button variant="outline-success" size="sm" block @click="editUser(0)">Add User</b-button>
        </b-col>
    </b-row>


  <b-row class="mt-5 mb-3" >
      <b-col>
             <b-table 
                striped 
                responsive 
                hover 
				:items="users" 
                :fields="fields"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                :busy="isBusy"
                sort-icon-left
                dark
                >
                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteUser(data.item.value)" class="mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <b-button size="sm" @click="editUser(data.item.value)" class="mr-2 xsbutton" variant="outline-success">
                        <b-icon-pencil />
                    </b-button>
                </template>    

                </b-table>
       </b-col>             
</b-row>

<b-modal ref="edit-user" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
     <CasterUserAdd
        :userid="usersel"
        v-on:closemodal=closemodal()
        v-on:updatemodal=updatemodal() 
        />
</b-modal>

<b-modal ref="delete-user" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
     <CasterUserDelete
        :id="usersel"
        v-on:closemodal=closemodal()
        v-on:updatemodal=updatemodal() 
        />
</b-modal>  

</b-container>
	
</div> 
</template>


<script>
    import CasterUserAdd from './CasterUserAdd'
    import CasterUserDelete from './CasterUserDelete'
    import axios from 'axios'
	export default {
        name:'CasterUsersAdmin',
        components:{
            CasterUserAdd,
            CasterUserDelete
        },
		data(){
		return {
		
			token:'',
			query:'',
			isSearch:false,
			users:[],
            fields:[
                { key: 'title', label: 'Name', sortable: true  },
                { key: 'email', label: 'Email', sortable: true  },
                { key: 'created', label: 'Created', sortable: true  },
                { key: 'show_details', label: ' Actions', thClass: 'text-center' }
               ],
            sortBy: 'tbuser.title',
            sortDesc: false,
            rows:0,
            isBusy: false,
            depts:[],
            deptsel:0,
            usersel:0,
            modaltitle:'',
            archive:0,
		};
		
		},
		created(){
			this.token = this.$store.getters.token
			this.fetchUsers()
		},
	
		methods: {

			fetchUsers(){

		
				axios.get('/api/casterusers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         role:1
                        }
                })
				.then((response) => {
                    this.users = response.data.users
                    this.rows = this.users.length
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},


            editUser(id){
               this.usersel = id
               this.modaltitle = 'Edit User'
               this.$refs['edit-user'].show()
           },

           deleteUser(id){
               this.usersel = id
               this.modaltitle = 'Delete User'
               this.$refs['delete-user'].show()
           },

          
            closemodal(){
                this.$refs['edit-user'].hide()
                this.$refs['delete-user'].hide()
            },

            updatemodal(){
                this.$refs['edit-user'].hide()
                this.$refs['delete-user'].hide()
                this.fetchUsers()
            },
        }
	
	}

</script> 