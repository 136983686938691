<!-- Version 281022/1430 -->
<template>
<div v-if="display">

<b-row class="mt-3 pb-5">
      <b-col class="col-12 mt-2">
             <b-table 
                striped 
                responsive 
                hover
                :items="resellers" 
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @row-clicked="showReseller($event)"
                sort-icon-left
                dark
                 >
                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteReseller(data.item.value)" class="mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <b-button size="sm" @click="editReseller(data.item.value)" class="mr-2 xsbutton" variant="outline-success">
                        <b-icon-pencil />
                    </b-button>
                </template>    

                </b-table>
       </b-col> 
</b-row>

<b-modal ref="edit-reseller" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterResellerAdd
    :resellerid="resellersel"
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    v-on:updatecompany=updatemodal()
    />
</b-modal>

<b-modal ref="delete-reseller" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterResellerDelete
    :id="resellersel" 
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    />
</b-modal>

</div> 
</template>

<script>
    import CasterResellerAdd from './CasterResellerAdd'
    import CasterResellerDelete from './CasterResellerDelete'
    import axios from 'axios'
	export default {
        props:['stext'],
        name:'CasterResellersTable',
        components:{
            CasterResellerDelete,
            CasterResellerAdd
        }, 
        data(){
        return {
                id:0,
                token:'',
                resellers:[],
                resellersel:0,
                modaltitle:'',
                fields:[
                { key: 'text', label: 'Name', sortable: true  },
                { key: 'email', label: 'Email', sortable: true  },
                { key: 'tel', label: 'Tel', sortable: true  },
                { key: 'mobile', label: 'Mobile', sortable: true  },
                { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                ],
                perPage: 15,
                currentPage:1,   
                sortBy:'text',
                display:false
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.fetchResellers()
        },

        watch: {
                    stext: function () {
                         this.fetchResellers()
                         },   
          },

		methods: {

            fetchResellers(){

				axios.get('/api/casterbusinesses',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        stext:this.stext,
                         sysadmin:1,
                     }
                })
				.then((response) => {
                    this.resellers = response.data.CasterBusiness
                    this.display = true
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

            showReseller(reseller){
                this.$store.commit('setresellerid',reseller.value) 
                this.$store.commit('setdealerid',0)
                this.$store.commit('setcompanyid',0)
                this.$store.commit('setroversdata','')
                this.$router.push({ name: 'casterreseller', params: {id:reseller.value} }).catch(()=>{})
            },

            editReseller(id){
               this.modaltitle = 'Edit Reseller'
               this.resellersel = id
               this.$refs['edit-reseller'].show()
           },

           deleteReseller(id){
               this.modaltitle = 'Delete Reseller'
               this.resellersel = id
               this.$refs['delete-reseller'].show()
           },

           updatemodal(){
                this.$refs['edit-reseller'].hide()
                this.$refs['delete-reseller'].hide()
                this.fetchResellers()
            },


           
		}	   
	
	};

</script> 


