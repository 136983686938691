<template>
<div class="imageBorder">
<b-container>
        <b-row class="mt-1">
            <b-col class="col-12 p-0">
                <div style="height:300px;width:100%;overflow-y: scroll;">
                <b-table 
                striped 
                responsive 
                hover 
				:items="events" 
                :fields="fields"
                @row-clicked="showEmail($event)"
                sort-icon-left
                sticky-header="280px"
                tbody="rowClassSm"
                tbody-tr-class="rowClassLight"
                dark 
                />
                </div>
            </b-col>    
        </b-row>


<b-modal ref="show-email" size="lg" :title="email.sent_email" hide-footer centered content-class="darkmodal">
   <b-row>
       <b-col class="col-12">Subject : {{ email.subject }}</b-col>
       <b-col class="col-12 mt-3">
           <div v-html="email.text" /> 
       </b-col>
   </b-row>

   <!-- <b-row>
       <b-col class="col-12 mt-5">
           <b-button variant="outline-success" block @click="sendEmail1()">NEXT</b-button>
       </b-col>
   </b-row> -->

</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-container>
</div>
</template>

<style scoped>
.rowClassLight {
    font-size: 2.2em !important;
    border-top-style: ridge;
    color: rgba(255,255,255,0.7) !important;
}
</style>


<script>
    import { mapState } from 'vuex' 
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        props:['roverid','rovername','update'],
        name:'CasterRoverEventsCard',
        components:{
            ErrorLogging
        },
        data(){
		return {
                events:[],
                email:{},
                fields: [
                    { key: 'datetime', label: 'date/time', sortable: false}, 
                    { key: 'typename', label: 'action', sortable: false},
                    { key: 'detail', label: 'detail', sortable: false},
                    { key: 'username', label: 'user', sortable: false},
                ],
                timeout:false,
                errorresponse :{}
            };
                
        },

        computed: mapState(['resellerid']),

        watch:{
                         
           resellerid: function() {
                    this.fetchEvents()
                    },
                         
        },

		mounted(){
            this.token = this.$store.getters.token
            this.fetchEvents()
        },

        beforeDestroy () {
            clearTimeout(this.timeout)
        }, 
        
        methods: {

            fetchEvents(){
            
             axios.get('/api/events',{
                    headers: {"Authorization" : "Bearer " + this.$store.getters.user.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        limit:100,
                        rover:this.roverid,
                        reseller:this.$store.getters.resellerid,
                        dealer:this.$store.getters.dealerid,
                        text:this.rovername,
                        }
                    })
				.then((response) => {

                    if(response.data.code == 999){
                            this.errorresponse = response.data
                    } else {
                        this.events = response.data.CasterEvents
                         if(this.update){this.timeout = setTimeout(this.fetchEvents, 5000)}
                        }
                    });
            },

        
            
             showEmail(event){
                //this.email = email
                 if(event.emailid == null){return}

                this.modaltitle = 'Email'

                axios.get('/api/casteremaillogs',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         id:event.emailid                  
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                                    this.errorresponse = response.data
                                } else {
                                this.email = response.data.CasterEmails[0]
                                this.email.text = this.email.text.replace("ip-rtk-uk.com/images","ip-rtk-aws.com/images")
                                this.$refs['show-email'].show()
                                }
                    });
                }	
        }       
};
</script> 