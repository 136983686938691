<template>
  <div id="app">
  <HomeLanding />
  </div>
</template>

<style src="./assets/custom.css"></style> 

<script>
    import HomeLanding from './components/HomeLanding'
    export default {
        name:'App',
        components:{
          HomeLanding
        }
      }
</script>     
