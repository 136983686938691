<template>
<div>

<b-row>

<b-col class="col-2 mt-0">
        <b-button variant="outline-success" size="sm" @click="resetSearch()" style="margin-top:32px"> reset search </b-button>
    </b-col>

</b-row>

<b-row class="mt-3">
    <b-col class="col-12 col-md-5 mt-3" v-if="total > limit">1 - {{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-12 col-md-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>
</b-row>    


<b-row class="mt-3 mb-5" >

      <b-col>
             <b-table
                class ="nowrap"
                ref = "dealers-table" 
                responsive 
                hover 
                sticky-header="600px"
                :items="displaydealers" 
                :fields="conditionalFields"
                :no-local-sorting="nosort"
                sort-direction="desc"
                @head-clicked="onSortChanged"
                @row-clicked="showDealer($event)"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                :busy="isBusy"
                sort-icon-left
                dark
                >
                <template #cell(show_details)="data">
                      <b-button size="sm" @click="deleteDealer(data.item.value)" class="mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <b-button size="sm" @click="editDealer(data.item.value)" class="mr-2 xsbutton" variant="outline-success">
                        <b-icon-pencil />
                    </b-button>
                </template>    

                </b-table>
            
            <b-button v-if="more" variant="outline-success" block @click.stop="showMore()">Show more results?</b-button>
            <b-button v-if="loadingmore" variant="outline-warning" disabled block>Loading results</b-button>
</b-col>
</b-row>	

<b-modal v-model="isBusy" hide-header hide-footer centered content-class="loadingmodal">
        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <b-spinner variant="success" class="mr-2"/>
                <h5>loading results</h5>
            </b-col>
        </b-row> 
</b-modal> 

<b-modal ref="delete-dealer" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterDealerDelete
    :id="dealersel" 
    v-on:closemodal="closemodal()"
    v-on:updatemodal="updatemodal()"
    />
</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style scoped>
.nowrap td {
    white-space: nowrap !important;
}

.modal-content{
    border:none !important;
}
</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import CasterDealerDelete from './CasterDealerDelete'
    import axios from 'axios'
    export default {
        props:['resellerid','dealerid','companyid','clearsearch','stext'],
        name:'CasterDealersTable2',
        components:{
            CasterDealerDelete,
            ErrorLogging
        },
        data(){
            return {
                id:0,
                token:'',
                dealers:[],
                displaydealers:[],
                dealersel:0,
                fields:[
                    { key: 'text', label: 'Name', sortable: true  },
                    { key: 'resellername', label: 'Reseller', sortable: true  },  
                    { key: 'email', label: 'Email', sortable: false  },
                    { key: 'tel', label: 'Tel', sortable: false  },
                    { key: 'mobile', label: 'Mobile', sortable: false  },
                    { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                    ],
                orderby:'dealer',
                sortdesc:false,
                nosort:false,
                isBusy:false,
                display:false,
                errorresponse:{},
                nosubs:false,
                searchtext:'',
                page:0,
                limit:50,
                more:false,
                loadingmore:false,
                total:0,
                rstart:1,
                rend:0,
                role:0,
                observer: null,
                modaltitle:''
                };
                
                },

		created(){
            if(this.$store.getters.prevroute == 'casterdealer'){
                this.searchtext = this.$store.getters.searchtext
            } else {
                this.$store.commit('setprevroute',this.$route.name)
                this.searchtext = ''
                this.$store.commit('setsearchtext','')
                }

            this.role = this.$store.getters.roleid
            this.token = this.$store.getters.token
            //this.searchtext = this.$store.getters.searchtext
            this.fetchDealers()
        },

        mounted(){
            this.addTableEventList()
        },

        computed: {
            
            filterdata() {
                return { ...this.resellerid, ...this.dealerid };
            },

            conditionalFields() {
                if(this.resellerid > 0){
                    return this.fields.filter(field => field.key !== 'resellername')
                } 

                return this.fields

                }
        },

         watch: {
                    filterdata: function () {
                        this.page = 0
                        this.fetchDealers()
                    },

                    clearsearch: function () { 
                        this.page = 0
                        this.fetchDealers()
                    },
                   
                    stext: function () { 
                        if(this.stext !== this.searchtext){
                            this.searchtext = this.stext
                            this.page = 0
                            this.fetchDealers()
                            }
                         },
                         
                    dealers: function() {
                        if(this.page > 0){
                            this.readdTableEventList
                        }
                    }
        }, 
        
		methods: {

      
            showDealer(dealer){console.log(dealer)
                this.$store.commit('setcountryid',dealer.country)
                this.$store.commit('setresellerid',dealer.reseller)
                this.$store.commit('setdealerid',dealer.value)
                console.log('set dealer '+dealer.value)
                this.$router.push({ name: 'casterdealer', params: {id:dealer.value} }).catch(()=>{})
            },

            editDealer(id){
               this.$emit('setdealer',id)  
            },

            deleteDealer(id){
               this.dealersel = id
               this.modaltitle = 'Delete Dealer'
               this.$refs['delete-dealer'].show()
           },

           updatemodal(){
                this.$refs['delete-dealer'].hide()
                this.fetchDealers()
            },

        
            fetchDealers(){
                this.isBusy = true
                axios.get('/api/casterdealerssort',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.token,
                        roleid:this.role,
                        reseller:this.resellerid,
                        dealer:this.dealerid,
                        id:this.dealerid,
                        list:1,
                        page:this.page,
                        limit:this.limit,
                        stext:this.searchtext,
                        orderby:this.orderby,
                        sortdesc:this.sortdesc
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                       this.errorresponse = response.data
                        } else {
                                this.more = false
                                this.loadingmore = false 
                                if(this.page == 0){
                                    this.dealers = response.data.CasterDealers
                                } else this.dealers.push(...response.data.CasterDealers)

                                this.displaydealers = this.dealers

                                this.updatePaging()
                                
                                this.total = response.data.CasterDealers.length
                                if(this.total < this.limit){
                                    this.more = false
                                    this.nosort = false
                                } else this.nosort = true

                                if(this.total != 0 && this.stext != ''){
                                    this.$emit('fetchresponse',this.stext)
                                }

                                this.isBusy = false
                                this.display = true
                        }
                    
                    });
			},

            updatePaging(){
                if(this.page == 0){
                    this.rstart = 1
                    this.rend = this.limit
                } else {
                this.rend = ((this.page + 1) * this.limit)
                if(this.rend > this.total){this.rend = this.total}
                } 
            },

            addTableEventList(){
                const tableScrollBody = this.$refs["dealers-table"].$el
                tableScrollBody.addEventListener("scroll", this.onScroll)
            },

            removeTableEventList(){
                const tableScrollBody = this.$refs["dealers-table"].$el
                tableScrollBody.removeEventListener("scroll", this.onScroll)
            },

            readdTableEventList(){
                this.$nextTick(() => {    
                    this.removeTableEventList()
                    this.addTableEventList()
                })    
            },

            showMore(){
                this.page++
                this.more = false
                this.loadingmore = true
                this.fetchDealers()
            },

            onScroll(event) {
                if (
                    event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 200
                ) {
                    if (!this.isBusy) {
                        if(this.total > this.limit){
                            this.more = true
                        } else this.removeTableEventList()
                    }
                }
            },

             // handle column sorting events to only call API when there is more results
             onSortChanged(field,event){
              
              if(event.sortable){
                  if(this.nosort){
                      this.orderby = field
                      this.sortdesc = this.$refs['subs-table'].localSortDesc !== true
                      this.page = 0
                      this.fetchDealers()
                  } 
              }
          },

            setDefaultSort(){
                if(this.resellerid > 0){this.orderby = 'dealer'}
                this.fetchDealers()
            },

            resetSearch(){
                this.$store.commit('setsearchtext','')
                this.statussel = 0
                this.fetchDealers()
                this.$emit('clearstext')
            },

        }	
    };

</script>