<template>
<div>
<div class="sectioncontcard" v-if="display">

<b-row class="mt-3" v-if="data[0].total != 0">
    
    <b-col class="col-10 offset-1">
          <SubsChart :data="data" />
    </b-col>

    <b-col class="col-12 mt-3 text-center">
        <h6>{{ data[0].total }} REFERENCE STATIONS</h6>
    </b-col>

</b-row>


 <b-row class="mt-3" v-else>
    <b-col class="col-12 text-center pt-3 pb-3">
        <h5 style="color:#48A1BA;">No Reference Stations Found</h5>
    </b-col>   
</b-row>

</div>

<div v-else>
    <h4>NO DATA</h4>
</div>
    
</div>
</template>

<script>
        import SubsChart from './SubsChart'
        import { mapState } from 'vuex'
        export default {
            name:'CasterBasestationsCardSmall',
                components:{
                SubsChart
                },     
        data(){
		return {
                data:[],
                display:false
                };
                
                },
        
        created(){
                this.showData()
        },

        computed: mapState(['basesdata']),

        watch: {
                    basesdata: function () {
                            this.showData()
                         }, 
        },
        
        methods: {
            showData(){
                        if(this.$store.getters.basesdata.chartdata !== undefined){
                                this.data = this.$store.getters.basesdata.chartdata.data
                                this.display = true  
                        } else this.display = false
                },
        }
	};
</script> 