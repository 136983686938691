<template>
  <div>

    <b-input-group style="z-index: 5;">

      <!--glass icon-->
      <b-input-group-prepend is-text>
        <b-icon-search font-scale="0.8" style="color:white" @click.stop="setSearch" />
      </b-input-group-prepend>

      <!--search input-->
      <b-form-input ref="searchInput" id="darksearch" type="search" size="sm" :placeholder="placeholder" v-model="searchtextlocal"
        @keypress="keyPressed" @click="xClearCheck" @input="checkCharCount(); filterSuggestions();" @keydown="navigateSuggestions" autocomplete="off" class="darksearch"  @focus="checkShowSuggestions" @blur="hideSuggestions"
      />

      <!--user suggestions-->
      <div class="suggestionsHolder darksearch " ref="suggestionsHolder" v-if="showSuggestions">
        <div class="suggestionsItem darksearch " v-for="sugestion in filteredSuggestions" :key="sugestion.term" @click.stop="searchtextlocal = sugestion.term; searchViaSuggestion();">
          {{ sugestion.term.slice(0, suggestionSearchTextLength)}}<span style="font-weight: bold;">{{sugestion.term.slice(suggestionSearchTextLength) }}</span> 
        </div>
      </div>

      <b-button style="margin-left:5px" size="sm" variant="outline-success" @click.stop="setSearch">search</b-button>

    </b-input-group>

  </div>
</template>

<style scoped>
  .suggestionsHolder {
    position: absolute;
    width: 100%;
    top:110%;
    min-height: 35px;
    max-height: 140px;
    overflow-y: scroll;
    z-index: 5;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    padding:1px;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .suggestionsHolder::-webkit-scrollbar {
    display: none;
  }
  .suggestionsItem {
    width: auto;
    height: auto;
    padding: 6px 8px;
    font-size: 14px;
    z-index: 6;
  }
  .suggestionsItem:hover {
    background-color: rgba(255, 255, 255, 0.075) !important;
  }
  .itemHighlight { /* Highlight is for arrow navigation */
    background-color: rgba(255, 255, 255, 0.075) !important;
  }
  
</style>

<!--Global style for overlay-->
<style>
.overlaySearch{
  position:fixed; 
  width:100%;
  height:100%;
  background:rgba(37, 53, 71, 0.815); 
  z-index: 4;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    suggestionType: {
      type: [Number, String],
      /*
      types:
        1 : CasterRovers,
        2 : casterUsers,
        3 : Subscriptions - CasterSubsLog & CasterSubscriptions,
        4 : CasterDealers,
        5 : CasterCompanies
        -
        222 : Testing type using in createSuggestionTermsExample() 
      */
    },
    suggestionFetchResponse: {
      type: String,
      //-expects a the searchText used in a fetch/filter, needs to only be sent if there was search result.

    },
    placeholder: {
      type: String,
      default: 'search',
      //-Is the placeholder text shown in the searchbar.
    },
    clearsearch: {
      type: Boolean,
    },
  },

  data() {
    return {
      searchtextlocal: '',
      suggestions: {},
      filteredSuggestions: [],
      showSuggestions: false,
      suggestionHighlighted: -1, //-1 is the search bar position
      suggestionOriginalSearchText: '',//this is a copy of your searchtext used when you cycle the suggestions
      suggestionSearchTextLength: 0, //this starts out as just searchText.length then goes to suggestionOrignalSearchText.length
      overlayElement: this.$store.getters.overlayElem,
      xClear:false,
    };
  },

  
  created() {
    //this.createSuggestionTermsExample(); //used for testing
    this.searchtextlocal = this.$store.getters.searchtext // rb add to use stored seatch text value
    this.getSuggestionListFromStore();
    this.checkTermExpirery();
  },
  
  computed: mapState(['overlayElem','searchtext']),

  watch: {
    suggestionFetchResponse(response) {
      this.$store.commit('setsearchtext',response)
      this.updateSuggestions(response, this.suggestionType);
    },

    overlayElem(elem) {
      this.overlayElement = elem;
    },

    // clearsearch: function(){
    //   if(this.searchtextlocal != ''){
    //     this.searchtextlocal = '';
    //     this.$store.commit('setsearchtext','')
    //     this.clearSearch();
    //   }
    // },

    // RB added to watch store searchtext value to reset
    searchtext: function(){
      if(this.searchtext == ''){this.searchtextlocal = ''}
    }
  },


  methods: {
    createSuggestionTermsExample() {
      //Just used in testing to make an example list need to change to new layout.

      /*
      const exampleTimeout = {
        list : [
          {type:222 ,term: "Oldest", lastUsedDate: new Date().getTime() - (1000 * 60 * 5) }, //5 mins ago
          {type:222 ,term: "Middle", lastUsedDate:  new Date().getTime() }, //now
          {type:222 ,term: "Youngest", lastUsedDate:  new Date().getTime() + (1000 * 60 * 5) }, //5 mins in future
        ]
      }
      */

      const exampleLargeList = {
        list : [
          {type:222 ,term: "Example01", lastUsedDate:  new Date().getTime() },
          {type:222 ,term: "Example02", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example03", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example04", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example05", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example06", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example07", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example08", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example09", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example10", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example11", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example12", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example13", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example14", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example15", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example16", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example17", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example18", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example19", lastUsedDate:  new Date().getTime() }, 
          {type:222 ,term: "Example20", lastUsedDate:  new Date().getTime() }, 
        ]
      }

      this.suggestions = exampleLargeList;
      this.$store.commit('setsearchSuggestions', this.suggestions);
    },

    getSuggestionListFromStore() {
      this.suggestions = this.$store.getters.searchSuggestions;
    },

    checkTermExpirery(){
      const now = new Date().getTime();

      const expireryTime = 1000 * 60 * 60 * 24 * 30 * 3; //3 months in milis
      //const expireryTime = 1000 * 60 * 60 * 24 * 30; //30 days in milis
      //const expireryTime = 1000 * 60 * 5; //5 mins for testing

      //go through each term to check if they are too old.
      this.suggestions.list = this.suggestions.list.filter( ({lastUsedDate}) => {
        return ( now - lastUsedDate <= expireryTime );
      })

      //update store
      this.$store.commit('setsearchSuggestions', this.suggestions)
    },

    preventSuggestionScrollByWheel(){
      if (!this.showSuggestions) return;

      this.$nextTick( () => {
        this.$refs.suggestionsHolder?.addEventListener('wheel', (event) => {
          event.preventDefault();
        } , {passive: false} );
      });
    },

    hideSuggestions(event){
      //for blur event use a small delay to give time for the click search to work 
      if (event?.type === "blur") {
          setTimeout( () => {
            this.showSuggestions = false;
            //return overlay to normal
            this.overlayElement?.classList.remove('overlaySearch'); 
          },100)
          return; 
      }

      //other wise
      this.showSuggestions = false;
      //return overlay to normal
      this.overlayElement?.classList.remove('overlaySearch');
    },

    unHideSuggestions() {
      this.showSuggestions = true;
      this.preventSuggestionScrollByWheel();
      //show overlay
      this.overlayElement?.classList.add('overlaySearch');
    },

    checkShowSuggestions(){
      //if the text is longenough and we have a suggestion show the suggestions
      if ( this.searchtextlocal.length < 4 || !this.filteredSuggestions.length > 0) {
        this.hideSuggestions();
        return;
      }
      this.unHideSuggestions();
    },

    filterSuggestions() {
      //our suggestions list should be where the suggestion type matches,
      // searchtext is a partial match for a suggestion, but only in continuation, ie searchText:'ch' term: 'cheese' is valid but searchText:'ch' term:'search' isnt on if the term follows on from text.
      // Dont show a suggestion if it's the same lengh.
      this.filteredSuggestions = this.suggestions.list.filter( (suggestion) => {
        return Number(suggestion.type) === Number(this.suggestionType) && 
        this.searchtextlocal.toLowerCase() === suggestion.term.slice(0, this.searchtextlocal.length).toLowerCase() &&
        this.searchtextlocal.length !== suggestion.term.length;
      });
      //limit to 10 results
      this.filteredSuggestions = this.filteredSuggestions.slice(0,10)

      //hide if there isn't one suggestion
      if(!this.filteredSuggestions.length > 0){
        this.hideSuggestions();
      }
    },  

    xClearCheck(){
      if ( this.xClear ) {
        return
      }
      this.xClear = true;
      setTimeout( () => { this.xClear = false; } , 100)
    },

    checkCharCount() {

      // RB Add send chars in real-time (for logged rovers etc)
      this.$emit('searchtext2',this.searchtextlocal)
      
      //update text length
      this.suggestionSearchTextLength = this.searchtextlocal.length

      // RB Add to reset search on x click
      if(this.searchtextlocal == '' && this.xClear){
          this.$store.commit('setsearchtext','')
          this.$emit('clearsearch')
      }   
      
      if ( this.searchtextlocal.length < 4) {
        this.hideSuggestions();
        return;
      }

      //if the search text is long enough show the suggestions
      this.unHideSuggestions();

    },

    setSearch() {
      if (this.searchtextlocal != '') {
        this.$emit('textsearch', this.searchtextlocal)
        this.hideSuggestions();
      } else {
        this.clearSearch();
      }
    },

    keyPressed(event) { 
      if (event.key === 'Enter') {
        if(this.searchtextlocal == ''){
          this.clearSearch();
          return;
        }
        event.preventDefault();
        this.setSearch();
      }
      this.$emit('search', this.searchtextlocal);
    },

    searchViaSuggestion() {
      this.hideSuggestions();
      this.suggestionHighlighted = -1; //reset
      this.setSearch();
      this.suggestionOriginalSearchText = this.searchtextlocal; //update ready for new suggestions
      this.filterSuggestions()
      this.$refs.searchInput.blur();
    },

    navigateSuggestions(event){
      if ( !this.showSuggestions ) return;
      
      //navigating from -1 which is the search bar itself, set orginal search term to be remembered.
      if ( this.suggestionHighlighted === -1 ) {
        this.suggestionOriginalSearchText = this.searchtextlocal;
        this.suggestionSearchTextLength = this.suggestionOriginalSearchText.length;
      }

      //navigating up, change search text, and go back to original saved if at top.
      if( event.key === 'ArrowUp' && this.suggestionHighlighted > -1) {
        event.preventDefault(); //stopping the cursor from jumping to the start of the word
        this.suggestionHighlighted -- ;

        if ( this.suggestionHighlighted === -1 ) {
          this.searchtextlocal = this.suggestionOriginalSearchText; 
        } else {
          this.searchtextlocal = this.filteredSuggestions[this.suggestionHighlighted].term;
        }

      }

      //navigate down, change search
      if( event.key === 'ArrowDown' && this.suggestionHighlighted < (this.filteredSuggestions.length - 1) ) { //-1 for length vs zero index
        this.suggestionHighlighted ++ ;
        this.searchtextlocal = this.filteredSuggestions[this.suggestionHighlighted].term;
      }
      
      //change item highlighting
      const optionsElementList = this.$refs.suggestionsHolder.childNodes;

      //loop through the suggestion items and add/remove highlight class
      for ( let i = -1; i < optionsElementList.length; i++ ) {
        if (i === this.suggestionHighlighted && i != -1) {
          optionsElementList[i].classList.add('itemHighlight');
          //keep overflow elements in view.
          optionsElementList[i].scrollIntoView(false, {
            behavior:"smooth",
            block:"start",
            inline:"start"
          });
        }
        else { 
          if ( i === -1 ) {
            for ( let x = 0; x < optionsElementList.length -1; x++ ) {
              optionsElementList[x].classList.remove('itemHighlight');   
            }
            continue;
          }
          optionsElementList[i].classList.remove('itemHighlight'); 
        }
      }

      //search if you press enter
      if ( event.key === 'Enter' ) {
        this.searchViaSuggestion()
      }      
    },

    updateSuggestions(searchTerm,type) {   
        //dont save a term if it gets no results or if its shorter than 5 chars
        if( !(searchTerm?.length > 4) ) return;
    
        //get suggestionsList
        let suggestions = this.$store.getters.searchSuggestions;
    
        //check if term exists already.
        const sugestionToUpdate = suggestions.list.find( (suggestion) => {
            return (suggestion.term === searchTerm && suggestion.type === type);
        });
    
        //update term
        if(sugestionToUpdate) {
            sugestionToUpdate.lastUsedDate = new Date().getTime();
            this.$store.commit('setsearchSuggestions', suggestions);
            return;
        }
    
        //add new term
        const newTerm = {type: type, term: searchTerm, lastUsedDate: new Date().getTime()};
        suggestions.list.push(newTerm);  
        this.$store.commit('setsearchSuggestions', suggestions);
    },

    clearSearch() {
      if (this.searchtextlocal == '') {
        this.$store.commit('setsearchtext', this.searchtextlocal)
        this.$emit('clearsearch')
      }
    },
  }

};
</script>