<template>
<div>
<b-container>

<b-row>
    
     
     <b-col class="col-12 mt-3" v-if="nostocksubs">
        <div class="rtmarkerexpiry" style="background-color:rgba(255, 96, 96, 0.7)">No dealer stock subscriptions available</div>
     </b-col>    
     
     <b-col class="col-12 mt-3"> 
            <label>Type</label>
                <b-form-select
                    v-model="subsel"
                    :options="subtypes"
                    name="subsel"
                    @change="setSub($event)"
                    v-validate="{min_value:1}"
					:class="{'b-form-select': true, 'is-danger': errors.has('subsel') }"  
                    />
                     <span style="color:red" v-show="errors.has('subsel')">
                    ** required</span>
                </b-col>

        <b-col class="col-12 mt-3">    
                <label>Reference</label>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="sub.purchase_order"
                    name="sub.purchase_order"
                    v-validate="{required:true}"
					:class="{'darkinput': true, 'is-danger': errors.has('sub.purchase_order') }"
                    class="darkinput"
                    />
                    <span style="color:red" v-show="errors.has('sub.purchase_order')">
                    {{ errors.first('sub.purchase_order') }}</span>
            </b-col>
       
        <b-col class="col-12 col-sm-6 mt-3">
                <label>Activation Date</label>

                 <VueCtkDateTimePicker
                            v-model="datesel1"
                            format='DD-MM-YYYY HH:mm'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="datesel1" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput"
                                name="datesel1"
                                v-validate="{required:true}"
                                :class="{'b-form-select': true, 'is-danger': errors.has('datesel1') }"
                                :disabled="subsel == 0  || activatenow == 1"
                                />
                            </VueCtkDateTimePicker>    

        </b-col>   

        <b-col class="col-12 col-sm-6 mt-3">
                <label>Expiration Date</label>
                <VueCtkDateTimePicker
                            v-model="expire"
                            format='DD-MM-YYYY HH:mm'
                            :dark="true"
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="expire" 
                                autocomplete = "off"
                                placeholder="automatic"
                                class="darkinput"
                                name="expire"
                                v-validate="{required:true}"
                                :class="{'b-form-select': true, 'is-danger': errors.has('expire') }"
                                disabled
                                />
                            </VueCtkDateTimePicker>    

        </b-col>  

        <b-col class="col-12 mt-3">
                <b-form-checkbox
                    v-model="activatenow"
                    inline
                    value=1
                    unchecked-value=0
                    size="lg"
                    :disabled="subsel == 0"
              />
              <label>Start from current date/time</label>
            </b-col>  


    <b-col class="col-12 mt-5 mb-3">    
        <b-button variant="outline-success" @click.stop="validateData()" block>Add subscription</b-button>
    </b-col>
</b-row>


<b-modal ref="renew-sub"  size="lg" title="Renewing Subscription" hide-footer centered content-class="darkmodal">
    <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row>

   <b-row>
        <b-col class="col-12 mt-5">
            <b-button variant="outline-success" @click.stop="finishRenewal()" block>Finish</b-button>
       </b-col>   
   </b-row>   

</b-modal>

<SendEmail 
        :sendmail="sendmail"
        :emailid="emailid"   
        :emailto="emailto" 
        :rover="sub.rover"
        :showdiag="showdiag"
        v-on:emailsent="emailSendStatus"
        />

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-container>
	
</div> 
</template>

<script>
    import SendEmail from './SendEmail'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
    import { Validator } from 'vee-validate'

	export default {
        props:['rover'],
        name:'CasterSubscriptionAdd',
        components:{
            SendEmail,
            ErrorLogging
        },
		data(){
		return {
			token:'',
            sub:{},
            stocksub:{},
            currentsub:{},
            renewalsub:{},
            subtypes:[],
            subsel:0,
            date:0,
            datenow:'',
            datesel1:'',
            expire:'',
            expirydays:0,
            updating:false,
            user:{},
            activatenow:1,
            modaltitle:'',
            sendmail:false,
            sent:99,
            emailto:'',
            emailid:5, // New Sub
            showdiag:false,
            sending:false,
            errorresponse:{},
            stagemessages:[],
            nostocksubs:false
        };
		
		},

        mounted(){

            Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   
            this.user = this.$store.getters.user
            this.token = this.$store.getters.token
            this.datenow = moment().format('DD-MM-Y HH:mm:ss')
            this.datesel1 = this.datenow
            this.activatenow = 1
            this.sub.activatenow = this.activatenow
            this.sub.rover =this.rover.value
            this.sub.country = this.rover.country,
            this.sub.reseller = this.rover.reseller,
            this.sub.dealer = this.rover.dealer,
            this.sub.company =this.rover.company
            this.sub.user = this.$store.getters.user.id
            this.fetchSubStock()
           
            
        },

        watch:{
            activatenow: function () {
                   if(this.activatenow == 1){
                       this.datesel1 = this.datenow
                       this.setDate()
                    } else {
                        this.datesel1 = moment(this.sub.enddate2).format('DD-MM-Y HH:mm:ss')
                        this.setDate()
                        }
                    this.sub.activatenow = this.activatenow    
                },
                
            datesel1: function () {
                    if(this.subsel != 0){
                    this.setDate()
                    }
                }
         },

     
		methods: {

          
              fetchSubStock(){
                this.subtypes = []
                axios.get('/api/casterntripsubscriptionsstock2',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        dealer:this.rover.dealer,
                        }
                })
				.then((response) => {
                        if(response.data.code == 999){
                        this.errorresponse = response.data
                        } else {       

                        this.subtypes.push({value:0,text:'Select subscription type'})
                        this.subtypes.push(...response.data.CasterSubs) // If new sub just show stock subs

                        if(response.data.CasterSubs.length == 0){
                            this.nostocksubs = true
                            }  

                        console.log(this.subtypes)    
                     }
                    });
        
			},

            // Get selected stock sub values when selected
            setSub(event){        
                let data = this.subtypes.filter(sub => sub.value == event)
                this.sub.subtype = data[0].type
                this.sub.subid = data[0].value  
                this.stocksub.title = data[0].text
                this.stocksub.days = data[0].days
                this.stocksub.months = data[0].months
                this.stocksub.years = data[0].years
                this.$validator.validate('subsel')
                this.expire = ''
                this.setDate()
            },

            // Calculate expiry dates
            setDate(){
                let datetime = moment(this.datesel1, "DD-MM-YYYY HH:mm")
                let end = ''
                if(this.stocksub.days !== 0){
                    end = moment(datetime).add(this.stocksub.days, 'days')
                }
                if(this.stocksub.months !== 0){
                    end = moment(datetime).add(this.stocksub.months, 'months')
                }
                if(this.stocksub.years !== 0){
                    end = moment(datetime).add(this.stocksub.years, 'years')
                }
                this.expire = moment(end).format('DD-MM-YYYY HH:mm')
                this.sub.substart = moment(datetime).format('DD-MM-YYYY HH:mm')
                this.sub.subend = this.expire // moment(this.sub.end).format('DD-MM-YYYY HH:mm:ss')

                this.$validator.validate('datesel1')
                this.$validator.validate('expire')
            },

        
            validateData(){
                this.$validator.validateAll().then(result => {
                        if (result) { this.sendSubData()}
                });        
             },
         
            sendSubData(){  //  Send data addrover stage 7

                this.stagemessages = [] 
                this.$refs['renew-sub'].show()
               
                axios({
                    method: 'post',   
                    headers: {"Authorization" : "Bearer " + this.token},
                    url: '/api/roveraddtest',
                    data: {
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            roverdata:this.sub,
                            stage:7
                           
                        }
                    })
                    .then((response)=> {
                            if(response.data.code == 999){
                                    this.errorresponse = response.data
                                    this.saving = false
                                } else {
                                    this.stagemessages.push(response.data)
                                    this.sendEmail()
                                }
                        
                            });	
            },

                      finishRenewal(){
                    this.$emit('update')
            },


            sendEmail(){
                this.emailto = this.sub.useremail
                this.sendmail = !this.sendmail // trigger to SendEmail
            },

            emailSendStatus(value){
                    this.sending = false
                    this.sent = value
            }, 


            }
	}
</script> 