<template>
<div>
 <b-container class="mainpagecont">

<b-row class="mt-5 pt-3">

        <b-col class="col-6">
            <h4>Caster RTCM3 Messages</h4>
        </b-col>

        <b-col class="col-3 offset-3">
            <b-button variant="info" block size="sm" @click="autoRefresh()" v-if="auto"><b-icon-clock class="icon" />  AUTO REFRESH</b-button>
            <b-button variant="outline-info" size="sm" block  @click="autoRefresh()" v-else><b-icon-clock class="icon" />  AUTO REFRESH</b-button>      
        </b-col>               

 </b-row>    

<b-row class="mt-5"> 

        <b-col class="col-3">
                <label>Basestation</label>
                  <CasterSearch :setsearchtext="basenamesel" v-on:textsearch=updateSearchBS v-on:clearsearch=clearSearchBS />
                <div class="choiceholder" v-if="showbases">
                        <ul class="makerlist">
                        <li v-for="basestation in basestations" :key="basestation.value" @click="baseSel(basestation)">{{ basestation.text }}</li>
                        </ul>
                    </div>
        </b-col>    

        <b-col class="col-3">
                        <label>Start Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterstart"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterstart" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2"
                                size="sm"   
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

            <b-col class="col-3">
                        <label>End Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterend"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterend" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2"
                                size="sm"   
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

            <b-col class="col-2" style="margin-top:30px">
                <b-button variant="info" size="sm"  style="min-width:80px" @click="fetchRequests()">filter</b-button>
                <b-icon-x-circle style="margin-left:10px;font-size:1.6em" v-if="filterstart !== ''"  @click="clearDates()"  />
            </b-col>       

</b-row>              

<b-row class="mt-3 mb-3" >
     <b-col class="col-12 text-right">
         {{ requests.length }} Results
     </b-col> 

      <b-col>
             <b-table 
                striped 
                responsive 
                hover 
				:items="requests" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :busy="isBusy"
                @row-clicked="showSats($event)"
                dark
                >
            
       
                </b-table>
       </b-col>             
</b-row>            
                        

</b-container>
		
</div> 
</template>

<script>
    import CasterSearch from './CasterSearch'
    import moment from 'moment'
    import axios from 'axios'
	export default {
        name:'CasterLogRTCM',
        components:{
            CasterSearch
        }, 
        data(){
		return {
                token:'',
                requests:[],
                basestations:[],
                basenamesel:null,
                showbases:false,
                auto:false,
                timeout:false,
                filterstart:'',
                filterend:'',
                fields:[
                 { key: 'Timestamp', label: 'Date/Time', formatter:'setTime', sortable: true, style:'break' },
                { key: 'Basestation', label: 'Reference Station', sortable: true },
                { key: 'Message', label: 'Message', sortable: true },
                { key: 'Num_Satellites', label: 'Num Satellites', sortable: true },
                { key: 'Station_id', label: 'Station id', sortable: true },
               ],
                sortBy: 'value',
                sortDesc: false,
                isBusy: false,
                };
                
                },

        mounted(){
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setbasesdatapoll',0)
        },             

		created(){
            this.token = this.$store.getters.token
            this.fetchRequests()
        },

         beforeDestroy () {
            clearInterval(this.timeout)
        },

		methods: {

            fetchRequests(){
                    this.isBusy = false
                    axios.get('/api/casterrequestsRTCM3',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         basestation:this.basenamesel,
                         start:this.filterstart,
                         end:this.filterend
                         }
                    })
				.then((response) => {
                    this.requests = response.data.Requests
                    this.isBusy = false
                    
                    if(this.auto == true){
                                clearTimeout(this.timeout)
                                this.timeout = setTimeout(this.fetchRequests, 5000)
                            }
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });

			},

            autoRefresh(){
                this.auto = !this.auto
                 if(this.auto == true){
                    this.fetchRequests()
                    } else {
                        clearTimeout(this.timeout)
                        }
            },

           fetchBasestations(){

				axios.get('/api/basestations',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            basename:this.basenamesel
                    }
                })
				.then((response) => {
                        this.basestations = response.data.basestations
                        if(this.basestations.length > 0){this.showbases = true} else this.showbases = false
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
            
            baseSel(base){
                this.basenamesel = base.text
                this.showbases = false
                this.timeout = false
                this.fetchRequests()
           }, 

            updateSearchBS(data){
                this.basenamesel = data
                this.fetchBasestations()
           }, 
           
           clearSearchBS(){
                this.basenamesel = null
                this.basestations = []
                this.showbases = false
                this.timeout = false
                this.fetchRequests()
           },

            clearDates(){
                this.timeout = false
                this.filterstart = ''
                this.filterend = ''
                this.fetchRequests()
           },

            setTime(datetime){  
                return  moment.utc(datetime).local().format('DD-MM-YY HH:mm:ss')
            },
		}	   
	};
</script> 