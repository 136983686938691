<template>
<div>
<b-container class="mainpagecont">

<b-row class="mt-5 pt-3">

        <b-col class="col-4">
            <h4>Event Log</h4>
        </b-col>

         <b-col class="col-3 offset-2" v-if="showdatefilter">
                        <label>Start Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterstart"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterstart" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2" 
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

            <b-col class="col-3" v-if="showdatefilter">
                        <label>End Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterend"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterend" 
                                autocomplete = "off"
                                placeholder="select" 
                                class="darkinput2"
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

</b-row>  

<b-row class="mt-3"> 

        <b-col class="col-3">
            <label>Type</label>
            <b-form-select class="darkinput2"  v-model="groupsel" :options="groups" @change="filterGroup($event)" ></b-form-select>     
        </b-col>

         <b-col class="col-3">
            <label>Event</label>
            <b-form-select class="darkinput2"  v-model="typesel" :options="types" @change="filterType($event)" ></b-form-select>     
        </b-col>


        <b-col class="col-3">
            <label>Company</label>
            <b-form-select class="darkinput2"  v-model="companysel" :options="companies" @change="filterCompany($event)" ></b-form-select>     
        </b-col>

     
</b-row>

<b-row class="mt-3">
            <b-col class="col-6">
                <b-button variant="outline-success" size="sm"  @click="fetchLogs()"><b-icon-arrow-clockwise /></b-button>
                <b-button variant="outline-success" size="sm"  @click="clearFilter()"><b-icon-x-circle /></b-button>
            </b-col>   

            <b-col class="col-6 text-right">
                <b-button variant="outline-success" size="sm"  @click="showDateFilter()"><b-icon-calendar-date /></b-button>
            </b-col>           
</b-row>  

<b-row class="mt-3">
    
</b-row>

<b-row class="mt-3">


  <b-col class="col-6" v-if="totalRows >50">
    <b-pagination
      size="sm"
      :total-rows="totalRows"
      v-model="currentpage"
      :per-page="perPage"
      align="fill"
      class="customPagination"
      aria-controls="my-table"
      last-number
    ></b-pagination>
  </b-col>

     <b-col class="col-6 text-right" v-if="totalRows >50">
         {{ totalRows }} Result<span v-if="totalRows != 1">s</span>
     </b-col> 

     <b-col class="col-12" v-else>
         {{ totalRows }} Result<span v-if="totalRows != 1">s</span>
     </b-col> 

    <!-- <b-col class="col-6 text-right">
        per page 
        <b-button style="margin-left:5px" size="sm" variant="outline-success" @click="setPaging(10)">10</b-button>
        <b-button size="sm" variant="outline-success" @click="setPaging(50)">50</b-button>
        <b-button size="sm" variant="outline-success" @click="setPaging(100)">100</b-button>
        <b-button size="sm" variant="outline-success" @click="setPaging(500)">500</b-button>
    </b-col> -->

</b-row>

<b-row class="mt-3">

      <b-col>
             <b-table 
                striped 
                responsive 
                hover
                :items="events" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :sort-by.sync="sortBy"
                :sort-desc="sortDesc"
               
                :busy="isBusy"
                dark
                >

                 <template #cell(typetext)="data">
                    {{ data.item.typename }} : {{ data.item.text }} 
                </template>
            
                <template #table-busy>
                    <div class="text-center text-white my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                    </div>
                </template>

                </b-table>
       </b-col>             
</b-row>            
  
</b-container>
		
</div> 
</template>

<style>

.errorevent{background-color:rgb(194, 82, 82) !important;font-size:0.8em !important;}
.successevent{background-color:rgb(53, 135, 53) !important;font-size:0.8em !important;}
.adminevent{background-color:rgb(65, 65, 171) !important;font-size:0.8em !important;}

</style>

<script>
    import axios from 'axios'
	export default {
        data(){
		return {
    
                token:'',
                events:[],
                dealers:[],
                companies:[],
                types:[],
                  groups:[
                    {value:'',text:'All'},
                    {value:'admin',text:'Admin'},
                    {value:'user',text:'User'}
                ],
                companysel:0,
                dealersel:0,
                typesel:0,
                groupsel:'admin',
            
                showdatefilter:false,
                filterstart:'',
                filterend:'',
                fields:[
                { key: 'datetime', label: 'Date/Time', sortable: true },
                { key: 'typetext', label: 'Event' },
                { key: 'eventgroup', label: 'Type', sortable: true },
                { key: 'dealername', label: 'Dealer', sortable: true },
                { key: 'companyname', label: 'Company', sortable: true },
                { key: 'username', label: 'User', sortable: true }
               ],
                pageOptions: [10, 20, 50],
                totalRows: 1,
                perPage: 50,
                currentpage: 1,
                startrecord:0,
                
                sortBy: 'value',
                sortDesc: true,
                isBusy: false,
                user:{},
                };
                
                },

     
		created(){
            this.user = this.$store.getters.user
            this.token = this.$store.getters.token
            this.dealersel = this.user.dealer
            this.fetchDealers()
            this.fetchCompanies()
            this.fetchTypes()
            this.fetchLogs()
        },


         watch: {
                    currentpage: function () {
                         if(this.currentpage > 1){
                         this.offset = this.currentpage * this.perPage
                         } else this.offset = 0
                         console.log(this.offset)
                         this.fetchLogs() 
                         },   
          },

		methods: {

            // rowClass(item, type) {
            //     if (!item || type !== 'row') return
            //     if(item.color == '#d44f3b'){return 'errorevent'}
            //     if(item.color == '#53c16b'){return 'successevent'}
            //     if(item.color == 'blue'){return 'adminevent'}
            // },

            fetchLogs(){
				
                this.isBusy = true
				axios.get('/api/events',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         dealer:this.dealersel,
                         company:this.companysel,
                         type:this.typesel,
                         group:this.groupsel,
                         start:this.filterstart,
                         end:this.filterend,
                         offset:this.offset,
                         limit:this.perPage
                         }
                })
				.then((response) => {
                    this.events = response.data.CasterEvents
                    this.totalRows = response.data.count
                     this.isBusy = false
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            })
                    });

			},

           fetchDealers(){

				axios.get('/api/casterdealers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                    }
                })
				.then((response) => {
                    this.dealers.push({value:0,text:'All'}) 
                    this.dealers.push(...response.data.CasterDealers)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
            
            fetchCompanies(){

				axios.get('/api/castercompanies',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         dealer:this.dealersel
                         }
                })
				.then((response) => {
                    this.companies = []
                    this.companies.push({value:0,text:'All'}) 
                    this.companies.push(...response.data.CasterCompanies)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

          

            fetchTypes(){

				axios.get('/api/castereventtypes',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        group:this.groupsel
                        }
                })
				.then((response) => {
                    this.types = []
                    this.types.push({value:0,text:'All'}) 
                    this.types.push(...response.data.CasterEventTypes)
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
            
           filterDealer(event){
               this.dealersel = event
               this.fetchCompanies()
               this.fetchLogs()
           },

           filterCompany(event){
               this.companysel = event
               this.fetchLogs()
           },

           filterType(event){
               this.typesel = event
               this.fetchLogs()
           },

         
            filterGroup(event){
               this.groupsel = event
               this.fetchLogs()
               this.fetchTypes()
           },

           showDateFilter(){
               this.showdatefilter = !this.showdatefilter
           },

           clearFilter(){
               this.dealersel = 0
               this.companysel = 0
               this.typesel = 0
               this.groupsel = ''
               this.start = ''
               this.end = ''
               this.fetchCompanies()
               this.fetchTypes()
               this.fetchLogs()
           },

           setPaging(number){
               this.perPage = number
           },
    
		}	   
	
	};

</script> 


