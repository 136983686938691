<template>
<div>
<b-container class="mainpagecont">

<b-row class="mb-3">
    <b-col class="col-12 col-md-4 mt-3">
        <h3>Rovers</h3>
    </b-col>

    <b-col class="col-12 col-md-4 mt-3">
            <CasterSearchSuggestions 
            placeholder="search rovers"
            :clearsearch="clearsearch"
            suggestionType = "1"
            :suggestionFetchResponse="fetchResponse" 
            v-on:textsearch=updateSearch 
            v-on:clearsearch=clearFilter
            />
    </b-col>

    <b-col class="col-12 col-md-4 mt-3 text-right">
            <b-button variant="outline-success" size="sm" @click.stop="refreshData()">
                <b-icon-arrow-clockwise />
                 Refresh
                </b-button>
    </b-col>

</b-row>

<CasterOptionsFilter v-if="searchtext == ''"
            :resellerid="resellerid" 
            :dealerid="dealerid" 
            :companyid="companyid"
            :companysshow="true"
            :roversshow="true"  
            :roverid=0
            :clearsearch="clearsearch"
            v-on:resellersel=updateResellerFilter 
            v-on:dealersel=updateDealerFilter  
            v-on:companysel=updateCompanyFilter
            v-on:roversel=updateRoverFilter 
            />  

<b-row>       

    <b-col class="col-12">
        <CasterRoversTable2 
            :stext="searchtext"
            :resellerid="resellerid"  
            :dealerid="dealerid" 
            :companyid="companyid"
            :roverid="roverid" 
            :key="componentKey"
            :refresh="refresh"
            :clearsearch="clearsearch" 
            v-on:clearstext="clearSearch"
            v-on:fetchresponse=setFetchResponse  
            />
    </b-col>
</b-row>

</b-container>
</div> 
</template>

<script>
    import CasterSearchSuggestions from  './CasterSearchSuggestions'
    import CasterOptionsFilter from './CasterOptionsFilter'
    import CasterRoversTable2 from './CasterRoversTable2'
    export default {
        name:'CasterRovers',
        components:{
            CasterSearchSuggestions,
            CasterOptionsFilter,
            CasterRoversTable2
        }, 
        data(){
            return {
                resellerid:0, // All UK
                dealerid:0,
                companyid:0,
                roverid:0,
                user:{},
                componentKey:0,
                searchtext:'',
                refresh:false,
                fetchResponse:'',
                clearsearch:true,
                roleid:0
                };
                
                },

		created(){
            this.roleid = this.$store.getters.roleid // 1 - System Admin 5 - Reseller Admin 10 - Dealer Admin 
           
            // Are stored search filters use them
             if(this.$store.getters.searchvalues.length > 0){
                switch(this.roleid){
                    case 1:
                    this.resellerid = this.$store.getters.searchvalues[0]
                    this.dealerid = this.$store.getters.searchvalues[1]
                    this.companyid = this.$store.getters.searchvalues[2]
                    break;
                    case 5:
                    this.resellerid = this.$store.getters.resellerid
                    this.dealerid = this.$store.getters.searchvalues[1]
                    this.companyid = this.$store.getters.searchvalues[2]
                    break;
                    case 10:
                    this.resellerid = this.$store.getters.resellerid
                    this.dealerid = this.$store.getters.dealerid
                    this.companyid = this.$store.getters.searchvalues[2]
                    break;
                }
                
                this.roverid = this.$store.getters.searchvalues[3]
            } else this.setDefaults()
               
        
            },

         mounted(){
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setbasesdatapoll',0)
            this.searchtext = this.$store.getters.searchtext
        }, 
        
       
		methods: {

            setDefaults(){ 
               
                 // If system admin set to all Resellers
                 if(this.roleid == 1){
                    this.resellerid = 0 
                    this.dealerid = 0
                    this.companyid = 0
                    this.roverid = 0
                    this.subtype = 0
                } else {
                // All other users go with stored login values    
                this.resellerid = this.$store.getters.resellerid
                this.dealerid = this.$store.getters.dealerid
                this.companyid = this.$store.getters.companyid
                this.roverid = 0
                this.subtype = 0
                }
                
            },

            updateResellerFilter(id){
                this.resellerid = id
                this.dealerid = 0
                this.companyid = 0
            },

            updateDealerFilter(id){
                this.dealerid = id
                this.companyid = 0
            },

            updateCompanyFilter(id){
                this.companyid = id
            },

            updateRoverFilter(id){
                this.roverid = id
            },

            updateSearch(text){
                this.searchtext = text
            },

            setFetchResponse(text){
                this.fetchResponse = text
            },

            clearSearch(){
                this.searchtext = ''
                this.clearsearch = !this.clearsearch
                this.setDefaults()
            },

            clearFilter(){
                this.searchtext = ''
            },

            refreshData(){
                this.refresh = !this.refresh
            },

            menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },

            updatemodal(){
                this.$refs['rover-add'].hide()
                this.componentKey += 1
           },

		}	   
	
	};

</script> 
