<template>
<div>

<b-row class="mt-3">
 
    <b-col class="col-12 col-md-3 mt-3" v-if="roleid == 1">
        <label>Reseller</label>
        <b-form-select
                    v-model="resellersel"
                    :options="resellers"
                    v-on:change="setReseller()"
                    class="darkinput2"
                    size="sm" 
                    />
    </b-col>

    <b-col class="col-12 col-md-3 mt-3" v-if="roleid <= 5">
        <label>Dealer</label>
        <b-form-select
                    v-model="dealersel"
                    :options="dealersdisplay"
                    v-on:change="setDealer()"
                    class="darkinput2"
                    size="sm"  
                    />
    </b-col>

    <b-col class="col-12 col-md-3 mt-3" v-if="roleid <= 10 && companysshow">
        <label>Company</label>
        <b-form-select
                    v-model="companysel"
                    :options="companiesdisplay"
                    v-on:change="setCompany()"
                    class="darkinput2" 
                    size="sm"  
                    />
    </b-col>

    <b-col class="col-12 col-md-3 mt-3" v-if="roversshow">
        <label>Rover</label>
        <b-form-select
                    v-model="roversel"
                    :options="roversdisplay"
                    v-on:change="setRover()"
                    class="darkinput2" 
                    size="sm"  
                    />
    </b-col>

    </b-row>

	
</div> 
</template>



<script>
    import axios from 'axios'
    export default {
       props:['resellerid','dealerid','companyid','roverid','clearsearch','companysshow','roversshow'],
       data(){  
		return {
            roleid:0,
            resellerset:0,
            dealerset:0,
            companyset:0,
			token:'',
			resellers:[
                    {value:0,text:'All Resellers'},
                    // {value:99,text:'UK Resellers only'},
                    {value:1,text:'Cloudbase'},
                    {value:2,text:'CW Agritech'},
                    {value:7,text:'SDF'},
                    {value:11,text:'RTK Danmark'}
                ],   
            resellersdisplay:[],
            dealers:[],
            dealersdisplay:[],
            companies:[],
            companiesdisplay:[],
            resellersel:0,
            dealersel:0,
            companysel:0,
            rovers:[],
            roversdisplay:[],
            roversel:0,
          
            update:false,
            display:false
       };
		
		},
		created(){
			this.token = this.$store.getters.token
            this.userid = this.$store.getters.user.id
            this.roleid = this.$store.getters.roleid // 1 - System Admin 5 - Reseller Admin 10 - Dealer Admin 
           
                // If system admin set to Cloudbase
                if(this.roleid == 1){
                    this.resellerset = 0 
                    this.dealerset = 0
                    this.companyset = 0
                } else {
                // All other users go with stored login values    
                this.resellerset = this.$store.getters.resellerid
                this.dealerset = this.$store.getters.dealerid
                this.companyset = this.$store.getters.companyid
                
                }
            
            this.resellersel = this.resellerid
            this.dealersel = this.dealerid
            this.companysel = this.companyid
            this.roversel = this.roverid

            this.fetchOptions()
            
		},

        computed: {
            
            filterdata() {
                return { ...this.resellersel, ...this.dealersel, ...this.companysel, ...this.roversel };
            }
        },

        watch: {

            filterdata: function () {
                this.$store.commit('setsearchvalues',[this.resellersel,this.dealersel,this.companysel,this.roversel])
            },

            clearsearch: function () { console.log('fo clear')
                this.resellersel = 0
                this.dealersel = 0
                this.companysel = 0
                this.roversel = 0
                this.filterOptions()
                this.$store.commit('setsearchvalues',[])
                },
        },

        methods: {

            fetchOptions(){
                axios.get('/api/testgetfilterselectoptions',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.userid,
                        token:this.token,
                        reseller:this.resellerset,
                        dealer:this.dealerset,
                        company:this.companyset
                         }
                })
				.then((response) => {
                    
                        // this.resellers = []
                        // this.resellers.push({text:'All Resellers',value:0})
                        // this.resellers.push({text:'All UK Resellers',value:99})
                        // this.resellers.push(...response.data.resellers)
                        this.dealers = []
                        this.dealers.push({text:'All Dealers',value:0})
                        this.dealers.push(...response.data.dealers)
                        this.dealersdisplay = this.dealers
                        this.companies = []
                        this.companies.push({text:'All Companies',value:0})
                        this.companies.push(...response.data.companys)
                        this.companiesdisplay = this.companies
                        this.rovers.push({text:'All Rovers',value:0})
                        this.rovers.push(...response.data.rovers)
                        this.roversdisplay = this.rovers

                        this.filterOptions()    
               
                    })

                
            },

            filterOptions(){
               
                if(this.resellersel > 0){ 
                    this.dealersdisplay = this.dealers.filter(dealer=> dealer.reseller == this.resellersel)
                    this.dealersdisplay.unshift({value:0,text:'All Dealers'})

                    this.companiesdisplay = this.companies.filter(company => company.reseller == this.resellersel)
                    this.companiesdisplay.unshift({value:0,text:'All Companies'})

                    this.roversdisplay = this.rovers.filter(rover => rover.reseller == this.resellersel)
                    this.roversdisplay.unshift({value:0,text:'All Rovers'})
                } else {
                    this.dealersdisplay = this.dealers
                    this.companiesdisplay = this.companies
                    this.roversdisplay = this.rovers
                }
                
                if(this.dealersel > 0){ 
                    this.companiesdisplay = this.companies.filter(company => company.dealer == this.dealersel)
                    this.companiesdisplay.unshift({value:0,text:'All Companies'})

                    this.roversdisplay = this.rovers.filter(rover => rover.dealer == this.dealersel)
                    this.roversdisplay.unshift({value:0,text:'All Rovers'})
                } else {
                    this.companiesdisplay = this.companies
                    this.roversdisplay = this.rovers

                }
                
                if(this.companysel > 0){
                    this.roversdisplay = this.rovers.filter(rover => rover.company == this.companysel)
                    this.roversdisplay.unshift({value:0,text:'All Rovers'})
                } else this.roversdisplay = this.rovers
                
            },

          
            setReseller(){
                this.dealersel = 0
                this.companysel = 0
                this.roversel = 0
                this.filterOptions()
                this.$emit('resellersel',this.resellersel)
            },

            setDealer(){
                this.companysel = 0
                this.roversel= 0
                this.filterOptions()
                this.$emit('dealersel',this.dealersel)
            },

            setCompany(){
                this.roversel = 0
                this.filterOptions()
                this.$emit('companysel',this.companysel)
            },

            setRover(){
                this.$emit('roversel',this.roversel)     
            },

            
         

        }
	
	}

</script> 