<template>
<div class="imageBorder" style="font-size:0.8em">
       
        <b-row class="mt-4">
            <b-col class="col-6">SPEED</b-col>
            <b-col class="col-6">{{ rover.speed }} kph</b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col class="col-6">POSITION LAT</b-col>
            <b-col class="col-6">{{ Number(rover.last_lat).toFixed(8) }}</b-col>
        </b-row>

         <b-row class="mt-1">
            <b-col class="col-6">POSITION LONG</b-col>
            <b-col class="col-6">{{ Number(rover.last_long).toFixed(8) }}</b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col class="col-6">DATA AGE</b-col>
            <b-col class="col-6" v-if="rover.rtk_status > 0">{{ rover.data_age }}</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col class="col-6">HDOP</b-col>
            <b-col class="col-6" v-if="rover.rtk_status > 0">{{ rover.hdop }}</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

        <b-row class="mt-1 mb-3">   
            <b-col class="col-6">NTRIP CLIENT</b-col>
            <b-col class="col-6" v-if="rover.rtk_status > 0">{{ rover.user_agent }}</b-col>
            <b-col class="col-6" v-else> - </b-col>
        </b-row>

</div>
</template>

<script>
    export default {
        props:['rover'],
        data(){
		return {
                  
                };
                
                },

		created(){
              
        },

   
	};
</script> 