<template>
<div class="mainpage">

<NavBarDashboard />
<div class="progressloader">
     <CasterLoader :route="route"/>
</div>     
<div ref="overlay" class="overlay"></div>

     <SideMenuCaster v-if="roleid == 1 && route !== 'casternetworkdisplay'" 
          :width="margin"
          :color="menubg"
          v-on:linkname="menuLink"
          />

     <SideMenuCasterReseller  v-if="roleid == 5 && route !== 'casternetworkdisplay'"  
          :width="margin"
          :color="menubg"
          v-on:linkname="menuLink"
          />       

     <SideMenuCasterDealer v-if="roleid == 10 && route !== 'casternetworkdisplay'" 
          :width="margin"
          :color="menubg"
          v-on:linkname="menuLink"
          />  
          
     <b-container class="mainpagecont" :style="{ maxWidth: pagewidth + 'px', marginLeft: margin + 'px', marginTop: '80px' }">
          <router-view  />
     </b-container>

     <CasterPollDataBases />
     <SystemLogout  :loggedin="loggedin"/>

</div>
</template> 

<script>
     import SideMenuCaster from './SideMenuCaster'
     import SideMenuCasterDealer from './SideMenuCasterDealer'
     import SideMenuCasterReseller from './SideMenuCasterReseller'
     import NavBarDashboard from './NavBarDashboard'
     import CasterLoader from './CasterLoader.vue'
     import CasterPollDataBases from './CasterPollDataBases'
     import SystemLogout from './SystemLogout.vue'
     import axios from 'axios'
     import { mapState } from 'vuex'
 
     export default {
          name:'DashBoardCaster',
          components:{
               NavBarDashboard,   
               CasterLoader,
               SideMenuCaster,
               SideMenuCasterDealer,
               SideMenuCasterReseller,
               CasterPollDataBases,
               SystemLogout
        },    
        data(){
            return {
                    reseller:0,
                    dealer:0,
                    token:'',
                    mobile:false,
                    user:{},
                    roleid:0,
                    pagewidth:0,
                    margin:0,
                    swidth:0,
                    name:'',
                    route:'',
                    orient:false,
                    phone:false,
                    timeout:false,
                    menubg:''
                 
                };
                
                },

		created(){
               this.token = this.$store.getters.token
               this.roleid = this.$store.getters.roleid
               this.reseller = this.$store.getters.resellerid
               this.dealer = this.$store.getters.dealerid
               this.user = this.$store.getters.user
               this.route = this.$route.name
               window.addEventListener("orientationchange", this.orientChange)
               window.addEventListener("resize", this.setWidth)
               if(window.innerWidth < 800){this.phone = true} else this.phone = false
               this.$store.commit('setmobile',this.phone)
               this.swidth = window.innerWidth
               this.setTimezone()
               this.setWidth()
               this.startTimer()
          },

          mounted(){
            this.addOverlyElementToStore()
          },

          beforeDestroy() {
               window.removeEventListener("resize",this.setWidth)
               window.removeEventListener("orientationchange",this.orientChange)
               clearInterval(this.timeout)
          },

        
          computed: mapState(['openmenu','loggedin']),

          watch: {
               openmenu: function () {
                    this.openMenu()
                    },

               $route: function(){
                    this.route = this.$route.name
               }
          },
          
          methods: {

         
          startTimer(){
                if(!this.timeout){
                    this.timeout = setInterval(this.securityCheck, 10000) // poll every 10 secs
                } 
          },     

          securityCheck(){
                    if(this.$store.getters.systemcheckpoll == 0){return}
                    axios.get('/api/castersystemtest',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                    }
                        })
                    .then((response) => {

                    if(response.data == 'NOT AUTHORISED'){    
                     
                         clearInterval(this.timeout)
                         console.log('auto log out')
                         this.userEvent(3)
                         this.$store.commit('setloggedin',false)
                               
                   } else {
                              this.$store.commit('setmaint',response.data.maintentance)
                              if(response.data.version !== this.$store.getters.version){
                                   this.$store.commit('setversion',response.data.version)
                                   window.location.reload()
                              }

                              if(response.data.devversion !== this.$store.getters.devversion && this.$store.getters.dev == 1){
                                   this.$store.commit('setdevversion',response.data.devversion)
                                   window.location.reload()
                              }
                             
                              this.$store.commit('setalarms',response.data.castas)
                         }
                   
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            })
                    });
          },     

          orientChange(){
               this.orient = true 
               this.reSize()
          },        

          setWidth(){
               if(this.orient == false){this.reSize()}     
            },

          reSize(){
               
               this.swidth = window.innerWidth  
              
             
               if(this.swidth < 1200 || window.innerHeight > window.innerWidth){
                    this.mobile = true     
                    this.pagewidth = this.swidth
                    this.margin = 0
                    this.$store.commit('setopenmenu',false)      //   auto close menu if mobile
               } else {
                         this.mobile = false
                         this.pagewidth = this.swidth - 350
                         this.margin = 300
                       //  if(this.$route.name !== 'casternetworkdisplay'){
                              this.$store.commit('setopenmenu',true)  //   auto open menu if desktop
                              
                        // } else this.$store.commit('setopenmenu',false) 
                    }
        
                    this.$store.commit('setmobile',this.mobile)
                    this.orient = false
                   
            },

            openMenu(){

                 this.swidth = window.innerWidth  

                 if(this.swidth < 1200){
                    this.menubg = 'black'
                 } else {
                    this.menubg = 'transparent'
                    }
                    
            },

          menuLink(name){
                 this.name = name
                 if(this.name == 'casterdredger'){
                    this.$router.push({ name: name, params: {id:958} }).catch(()=>{})
                 }

                 if(this.name == 'casterdealer'){
                    this.$router.push({ name: name, params: {id:this.user.dealer} }).catch(()=>{})
                 } else this.$router.push({ name: name, params: {} }).catch(()=>{})
                 },	   
       
          setTimezone(){
                if(new Date().getTimezoneOffset() == -60){ // -60 equals UTC+1 (BST)
                    this.$store.commit('settimezone',0) 
               } else this.$store.commit('settimezone',1) 
          },

           userEvent(type){
                 //let type = 13  //   Company archived
                 let group = 'user'

                 axios({
                        method: 'post',  
                        headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                        url: '/api/userevent',
                        data: {
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            user:this.$store.getters.user.id,
                            type:type,
                            group:group,
                            text:this.$store.getters.user.fname+' '+this.$store.getters.user.lname
                            } 
                        })
                    .then((response)=> {
                
                    if(response.data.code == 999){
                            this.errorresponse = response.data
                    } else {
                       this.$emit('updatemodal')
                        }
                                        
                    });	
            },
          
          addOverlyElementToStore(){
            const overlayElement = this.$refs.overlay;
            this.$store.commit('setoverlayElem', overlayElement);
          },

          }
	};
</script>