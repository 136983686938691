<template>
<div style="position:absolute;width:100%;left:0;top:62px;height:90vh;">

<div class="fullscreencontxz" >

    <CasterShowLeafletRovers  :fsmapheight="boxh" :fsmapwidth="boxw" :networkdisplay="1" v-if="showrovers && showmap"  />
    <CasterShowLeaflet :fsmapheight="boxh" :fsmapwidth="boxw" :networkdisplay="1" v-if="showbases  && showmap" />
    <CasterShowLeafletBSArea  :baseslist="mappoints" :fsmapheight="boxh" :fsmapwidth="boxw" v-if="showcover  && showmap"/>
</div>


<div class="fullscreencont2"> 
 
    <div>
        <div  @click.stop="showRovers()">
            <CasterRoversLoggedCardSmall/>
        </div>

        <div  @click.stop="showBases()">
            <CasterBasestationsCardSmall />
        </div>

        <div  v-if="showbases & roleid < 100">
            <b-button variant="outline-success" size="sm" block @click.stop="showCover()">COVERAGE</b-button>
        </div> 

    </div>     
   
</div>  

</div> 
</template>

<style scoped>
.countryselection{
    position: fixed;
    top:80px;
    right:10px;
    z-index:999;
    width:350px;
}
</style>


<script>
    import CasterShowLeaflet from './CasterShowLeaflet'
    import CasterShowLeafletRovers from './CasterShowLeafletRovers'
    import CasterShowLeafletBSArea from './CasterShowLeafletBSArea'
    import CasterRoversLoggedCardSmall from './CasterRoversLoggedCardSmall'
    import CasterBasestationsCardSmall from './CasterBasestationsCardSmall'
    export default {
        name:'CasterNetworkDisplay',
        components:{
            CasterShowLeaflet,
            CasterShowLeafletRovers,
            CasterShowLeafletBSArea,
           CasterRoversLoggedCardSmall,
           CasterBasestationsCardSmall
        },
    data(){
		return {
                rovers:[],
                basestations:[],
                mappoints:[],
                boxh:0,
                boxw:0,
                showmap:false,
                showrovers:true,
                showbases:false,
                showcover:false,
                roleid:0,
                reseller:0,
                };
                
                },

    created(){
            window.addEventListener("resize", this.setMapSize)
            this.user = this.$store.getters.user
            this.roleid = this.$store.getters.roleid
        },

    mounted(){
        this.$store.commit('setbasesdatapoll',1)
        this.$store.commit('setroversdatapoll',1)
        this.boxw = window.innerWidth
        this.boxh = window.innerHeight
        this.showRovers()

        },  

   
    beforeDestroy () {
            window.removeEventListener("resize",this.setMapSize)
    },   
	
	methods: {

        setMapSize(){
            this.boxw = window.innerWidth
            this.boxh = window.innerHeight
        },

        showRovers(){
                this.showbases = false
                this.showrovers = true
                this.showcover = false
                this.showmap = true
                //this.getRoversData()
            },

        showBases(){
            this.showrovers = false
            this.showbases = true
            this.showcover = false
            //this.getBasesData()
        },

        showCover(){
            this.showrovers = false
            this.showbases = false
            this.showcover = true
            this.showRefStationsData()
        },


        showRefStationsData(){  //  Get basestations  data
               this.basesdata = this.$store.getters.basesdata
              
               if(this.basesdata.basestations != undefined){
                   this.basestations = this.basesdata.basestations
                   let mappoints = []
                   this.basestations.forEach(element => {
                       mappoints.push({id:element.value,latitude:element.latitude,longitude:element.longitude,color:element.statuscolor,name:element.text,status:element.mapstatus})
                   });
                  // if(JSON.stringify(mappoints) !== JSON.stringify(this.mappoints)){
                       this.mappoints = mappoints
                  //     }

                   }
                       
               },

            
         
            menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
                },

            setCountry(code){
                this.$store.commit('setcountryid',code)
            }
        
        }
    };
</script>