<template>
<div>

<b-container class="mainpagecont">

    <b-row>
        <b-col class="col-12 col-sm-8 mt-3"><h4>{{ dealer.dealer }} Stock Subscriptions</h4></b-col>
    </b-row>

    <b-row class="mt-3 pb-5">

        <b-col class="col-12 mt-1">

            <b-row class="mt-3">
                <b-col class="col-12" v-if="subs.length !==0">
                    <b-table
                        :items="subs" 
                        :fields="fields"
                        tbody-tr-class="rowClass"
                        thead-tr-class="rowHClass"
                        dark
                        />
                </b-col>

                <b-col class="col-12 pt-3 pb-3" v-else>
                    <b-button variant="danger" block disabled>NO SUBSCRIPTIONS IN STOCK</b-button>
                </b-col>

            </b-row>

        </b-col>
      
    </b-row>  

</b-container>
	
</div> 
</template>



<script>
    import axios from 'axios'
    export default {
        data(){
		return {
		
            dealersel:0,
            dealer:{},
            modaltitle:'',
            subs:[],
            fields:[
                { key: 'text', label: 'Type'  },
                { key: 'count', label: '# Stock' }
                ],
            user:{},
            display:false
            };
		
		},
		created(){
            this.token = this.$store.getters.token
            this.user = this.$store.getters.user
            this.$store.commit('setbasesdatapoll',0)
            this.dealersel = this.$store.getters.dealerid
        },

        mounted(){
            this.fetchStockSubs()
        },

       
		methods: {
         
            fetchStockSubs(){
                axios.get('/api/casterntripsubscriptionsstocklistdealer',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        dealer:this.dealersel
                        }
                })
                .then((response) => {
                    this.subs = response.data[0].subs
                    this.display = true
                })
                .catch(err => {
                    alert("Error fetching subscription stock list.")
                    console.error("fetchStockSubs error: ",err)  
                });
			},
        }
	
	}

</script> 