<template>
<div>

<NavBarBlank v-if="!loggedin"/>
<router-view  />

</div>
</template> 

<script>
import NavBarBlank from './NavBarBlank.vue'
import { mapState } from 'vuex'
export default {
    name:'HomeLanding',
        components:{
            NavBarBlank,
    }, 
    data() {
      return {
        pwtoken: '',
        pwemail: '',
        pwdealer:'',
        token:'',
      }
    },  
   
    mounted(){
       
      this.token = this.$store.getters.token
      this.pwtoken = this.$route.query['token']
      this.pwemail = this.$route.query['email']
      this.pwdealer = this.$route.query['dealer']

        if(this.pwtoken !== undefined && this.pwemail !== undefined){ // check for pw reset link
            this.$router.push({ name: 'reset-password-form', params: {token:this.pwtoken,email:this.pwemail,dealer:this.pwdealer} }).catch(()=>{})
         } else 
           if(this.$store.getters.token == '' || this.loggedin == false ){
             let url =  window.location.pathname.split('/'); 
             let link = '';
            
            if(url[1] !== ''){ 
              if(url[2] == 'reset'){ link = this.$store.getters.branding.dealer } else link = url[2]
              this.$router.push({ name: 'systemlogin', params: {dealer:link} }).catch(()=>{})
             } 
            } 
    },

    computed: mapState(['loggedin']),

}
</script>