<template>
<div class="imageBorder">

    <b-row  v-if="display">

      
    <b-col class="col-9 mt-1 boxtext">LATEST ALARMS</b-col>

    <b-col class="col-3 mt-1 text-right">
        <b-button variant="outline-success" size="sm" @click.stop="clearAlarms()">clear</b-button>
    </b-col>

    <b-col class="col-12 mt-2" style="max-height:170px;overflow-y:auto">
         
             <b-table
                class="comptable" 
                striped 
                small
                responsive 
                hover
                :items="alarms" 
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :busy="isBusy"
                tbody-tr-class="rowClassLight"
                thead-tr-class="rowHClass"
                sort-icon-left
                dark
                >
                <template #cell(Colour)="data">
                  <div class="statusbutton" :style="{ backgroundColor: data.item.color }" />
                </template>

                 <template #table-busy>
                    <div class="text-center my-2" >
                    <b-spinner class="align-middle" style="color:orange"></b-spinner>
                    <strong style="color:orange"> Loading...</strong>
                    </div>
                </template>    

                </b-table>
       </b-col> 

</b-row>
 

</div>
</template>

<style scoped>


.statusbutton{
    width:15px;
    height: 15px;
    /* border-radius: 20px; */
    /* margin-right:30px; */
    float: left;
}

</style>

<script>
    import axios from 'axios'
    import moment from 'moment'

	export default {
        props:['type'],
        data(){
		return {
                token:'',
                display:false,
                timeout:false,
                alarms:[],
                fields:[
                    { key: 'Colour', label: '', sortable: true}, 
                    { key: 'text', label: 'Alarm', sortable: true  },
                    { key: 'status', label: 'Status', sortable: true,formatter:'formatstatus'  },
                    { key: 'datetime', label: 'Date/time', sortable: true  },
                        ],
                perPage: 100,
                currentPage:1,   
                sortBy:'id',
                isBusy:true,
                system:0        
               };
                
                },

	mounted(){
                this.token = this.$store.getters.token
                if(this.$store.getters.user.role == 1 && this.$store.getters.user.business == 11){this.system = 2}
                this.fetchAlarms()
                },

     beforeDestroy () {
            clearInterval(this.timeout)
        },                

	methods: {

                fetchAlarms(){
                axios.get('/api/alarmlogs',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        system:this.system
                    }
                })
				.then((response) => {
                    this.alarms = response.data.alarms
                    this.isBusy = false
                    this.display = true

                    if(!this.timeout){
                        this.timeout = setInterval(this.fetchAlarms, 3000)
                    }
                 
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
           },

           clearAlarms(){

                axios.get('/api/alarmlogs',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token, 
                        clear:1
                    }
                })
				.then((response) => {
                        console.log('clear'+response)
                 
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
           },

                formatTime(datetime){  // BST Fiddle
                    return  moment(datetime).format('DD-MM-YY HH:mm:ss') 
                },

                formatstatus(status){
                    if(status == 1){return 'ALARM'} else return 'CLEARED'
                }
                
                
            }	 

	};
</script> 