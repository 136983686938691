<template>
<div>

    <!--Main Page Content-->
    <b-container class="mainpagecont">
        <!--Header-->
        <b-row class="mt-5 pt-3">
            <b-col class="col-12 col-md-4 mt-1 p-2" >
                <h3> Email Templates </h3>
            </b-col>
        </b-row>

        <!--Admin Emails-->
        <b-row class="mt-1 imageBorder">
            <b-col>
                <b-row class="mt-1">
                    <b-col>
                        <h5 class="textfadeDark">Default Emails:</h5>
                    </b-col>
                </b-row>

                <b-row class="mt-1 textfadeDarker" >
                    <b-col class="col-6 col-sm-4">
                        <h6 block >Admin Email 1:</h6>
                        <h6 block >Admin Email 2:</h6>
                       
                    </b-col>

                    <b-col class="col-6 col-sm-4">
                        <h6 block class="overflowElipse">{{savedAdminEmails.add1}}</h6> 
                        <h6 block class="overflowElipse">{{savedAdminEmails.add2}}</h6>
                    </b-col>
                    
                    <b-col class="col-12 col-sm-4">
                        <b-button class="h-100" variant="outline-success" title="edit" block @click.stop="editAdminEmails">
                            Edit
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!--Table Tools-->
        <b-row class="mt-2 pt-1">

            <!--Filter Bar-->
            <b-col class="col-12 col-md-6 pb-2 text-center">
                <b-input-group>

                    <b-input-group-prepend v-show="searchLabel">
                        <b-input-group-text class="textfade" >Filter </b-input-group-text>
                    </b-input-group-prepend>
                    <b-input
                        name="Search"
                        placeholder="Enter Filter term..."
                        v-model="searchFilter"
                        class="darksearch"
                        @focus="searchLabel = false"
                        @focusout="searchLabel = true"
                        @input="filterResults"

                    />
                    <b-input-group-append is-text v-show="searchLabel">
                        <b-icon icon="filter" class="textfade"/>
                    </b-input-group-append>

                </b-input-group>
            </b-col>

            <!-- Type Dropdown Box -->
            <b-col class="col-12 col-md-6 pb-2 text-center">
                <b-input-group>
                    <b-input-group-prepend>
                        <b-input-group-text class="textfade" >Type </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-select
                        name="Type"
                        v-model="tableTypeDropdown.value"
                        :options="tableTypeDropdown.options"
                        class="darksearch textsmall"
                    />
                </b-input-group>
            </b-col>
        </b-row>
        
        <!--Result Count-->
        <b-row v-if="total != 0">
            <b-col class="col-6 mt-1">{{ total }} Result<span v-if="total != 1">s</span></b-col>
        </b-row>
        
        <!--Table-->
        <b-row class="mt-3 mb-3">
            <b-col>
                <b-table
                    ref="templateTable"
                    striped 
                    responsive 
                    hover
                    :items="filteredItems" 
                    :fields="fields"
                    tbody-tr-class="rowWClass"
                    thead-tr-class="rowHClass"
                    sort-icon-left
                    dark
                    :filter="searchFilter"
                    selectable      
                    select-mode="single"
                    @row-selected="focusTemplate"

                    stacked="lg"
                >   
                    <!--Heading-->
                    <template v-slot:thead-top>
                        <tr>
                            <th colspan="3" >Template</th>
                            <th colspan="4" >Send Emails To</th>
                        </tr>
                    </template>

                    <!--Content-->
                    <template v-slot:cell(message)="data">
                        <div click="focusTemplate(data.item)" :style="tableContentClickCatcherMinHeight" v-show="data.index !== expandedId" class="clickCatcher"/>
                        <div click="focusTemplate(data.item)" :style="tableContentClickCatcherMaxHeight" v-show="data.index === expandedId" class="clickCatcher"/>
                        <!-- <span> {{data}}</span> -->

                        <TinyReadOnlyDisplay
                        v-model = data.item.message
                        :id = data.index
                        :minHeight="tableContentMinHeight"
                        :maxHeight="tableContentMaxHeight"
                        :hoverID="expandedId"
                        />
                    </template>

                    <!--Admin 1-->
                    <template v-slot:cell(send_admin1)="data">
                            <b-icon v-show="data.item.send_admin1" icon='check2-circle' scale=2 variant="success"/>
                            <b-icon v-show="!data.item.send_admin1" icon='x-circle' scale=1.8 variant="danger"/>
                    </template>

                    <!--Admin 2-->
                    <template v-slot:cell(send_admin2)="data">
                            <b-icon v-show="data.item.send_admin2" icon='check2-circle' scale=2 variant="success"/>
                            <b-icon v-show="!data.item.send_admin2" icon='x-circle' scale=1.8 variant="danger"/>
                    </template>

                    <!--Dealer-->
                    <template v-slot:cell(send_dealer)="data">
                            <b-icon v-show="data.item.send_dealer" icon='check2-circle' scale=2 variant="success"/>
                            <b-icon v-show="!data.item.send_dealer" icon='x-circle' scale=1.8 variant="danger"/>
                    </template>

                    <!--Reseller-->
                    <template v-slot:cell(send_reseller)="data">
                            <b-icon v-show="data.item.send_reseller" icon='check2-circle' scale=2 variant="success"/>
                            <b-icon v-show="!data.item.send_reseller" icon='x-circle' scale=1.8 variant="danger"/>
                    </template>
                </b-table>
            </b-col>
        </b-row>

    </b-container>

    <!--Edit Admin Emails Modal-->
    <b-modal v-model="editAdminEmailsModal" title="Edit Admin Emails" hide-footer centered content-class="darkmodal" @hide="cancelEditModal">

        <!--Input boxes-->
        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <b-input-group>

                    <b-input-group-prepend v-show="clearAddInputOneVis">
                        <b-input-group-text class="textfadeDarker" >Email 1 </b-input-group-text>
                    </b-input-group-prepend>
                    <b-input
                        name="address 1"
                        v-validate = "'required|email'" 
                        placeholder="input email address..."
                        v-model="adminEmails.add1"
                        class="darkinput"
                        @focus="clearAddInputOneVis = false"
                        @focusout="clearAddInputOneVis = true"
                        @input="validateField('address 1')"
                    />


                    <b-input-group-append is-text v-show="clearAddInputOneVis">
                        <b-icon icon="x" @click.stop="adminEmails.add1 = '' "/>
                    </b-input-group-append>
                </b-input-group>

                <b-input-group>

                    <b-input-group-prepend v-show="clearAddInputTwoVis">
                        <b-input-group-text class="textfadeDarker" >Email 2 </b-input-group-text>
                    </b-input-group-prepend>
                    <b-input
                        name="address 2"
                        v-validate = "'required|email'" 
                        placeholder="input email address..."
                        v-model="adminEmails.add2"
                        class="darkinput"
                        @focus="clearAddInputTwoVis = false"
                        @focusout="clearAddInputTwoVis = true"
                        @input="validateField('address 2')"
                    />
                    <b-input-group-append is-text v-show="clearAddInputTwoVis">
                        <b-icon icon="x" @click.stop="adminEmails.add2 = '' "/>
                    </b-input-group-append>
                </b-input-group>

            </b-col>
        </b-row>

        <!--Validation Error Messages-->
        <b-row class="p-1">
            <b-col class="col-12 text-center">
                <span class="colorDanger" >{{ errors.first('address 1') }}</span>
            </b-col>
        </b-row>
        <b-row class="p-1">
            <b-col class="col-12 text-center">
                <span class="colorDanger " >{{ errors.first('address 2') }}</span>
            </b-col>
        </b-row>    

        <!--Submit success/fail messages-->
        <b-row v-show="upload" class="p-1">
            <b-col class="col-12 text-center">
                <b-icon icon="upload" animation="cylon-vertical" font-scale="2"/>
            </b-col>
        </b-row>  
        <b-row v-show="uploadSuccess" class="p-1">
            <b-col class="col-12 text-center">
                <b-icon class="colorSuccess" icon="check2" animation="throb" font-scale="3"/>
            </b-col>
        </b-row>  
        <b-row v-show="uploadFailed" class="p-1">
            <b-col class="col-12 text-center">
                <b-icon class="colorDanger" icon="x" animation="throb" font-scale="3"/>
            </b-col>
        </b-row>

        <!--Footer with confirm/cancel buttons-->    
        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <b-button-group v-show="!upload" class="m-1">
                    <b-button title="Confirm" class="mr-5" size="sm" variant="outline-success" @click.stop="postEmails">
                        Confirm
                    </b-button>
                    <b-button title="Cancel"   size="sm" variant="outline-danger" @click.stop="cancelEditModal">
                        Cancel 
                    </b-button>
                </b-button-group>
            </b-col>
        </b-row>  

    </b-modal>
    
    <!--Edit Template Modal-->
    <b-modal 
        v-model="editTemplateModalFlag" 
        ref="editTemplateModal"  
        @ok="postTemplate" 
        @cancel="cancelTemplate" 
        @close="cancelTemplate"
        centered 
        content-class="darkmodal editTemplateModalCs" 
        size="lg"
        title="Edit Template"
        hide-footer
        >
        
        <!--Collapsable content for easier phone editing-->
        <b-collapse id="collapse-section" visible>

            <!--Send To-->
            <b-row class="p-1">
                <b-col class="col-12 ">Send Email To:</b-col>
            </b-row>
            <!--Admin 1-->
            <b-row class="p-1">
                <b-col class="col-12 ">
                    <b-input-group class="noWrap"> 
                        <b-input-group-prepend>
                            <b-input-group-text class="textfadeDarker modalLabel" >Admin 1</b-input-group-text>
                        </b-input-group-prepend>

                        <b-input-group-text class="squareBorder">
                            <b-form-checkbox switch class="checkLeftPad" :value="1" :unchecked-value="0" v-model="focusedTemplate.send_admin1"/>
                        </b-input-group-text> 
                        
                        <b-input-group-text class="darkinput emailOverflow">
                            {{adminEmails.add1}}  
                        </b-input-group-text> 
                    </b-input-group>
                </b-col>
            </b-row>
            <!--Admin 2-->
            <b-row class="p-1">
                <b-col class="col-12">
                    <b-input-group class="noWrap"> 
                        
                        <b-input-group-prepend>
                            <b-input-group-text class="textfadeDarker modalLabel" >Admin 2</b-input-group-text>
                        </b-input-group-prepend>

                        <b-input-group-text class="squareBorder">
                            <b-form-checkbox switch class="checkLeftPad" :value="1" :unchecked-value="0" v-model="focusedTemplate.send_admin2"/>
                        </b-input-group-text> 

                        <b-input-group-text class="darkinput emailOverflow">
                            {{adminEmails.add2}} 
                        </b-input-group-text> 
                    </b-input-group>
                </b-col>

            </b-row>
            <!--Dealer-->
            <b-row class="p-1">
                <b-col class="col-12">
                    <b-input-group class="noWrap"> 
                        
                        <b-input-group-prepend>
                            <b-input-group-text class="textfadeDarker modalLabel" >Dealer</b-input-group-text>
                        </b-input-group-prepend>

                        <b-input-group-text class="squareBorder">
                            <b-form-checkbox switch class="checkLeftPad" :value="1" :unchecked-value="0" v-model="focusedTemplate.send_dealer"/>
                        </b-input-group-text> 

                    </b-input-group>
                </b-col>
            </b-row>
            <!--Reseller-->
            <b-row class="p-1">
                <b-col class="col-12">
                    <b-input-group class="noWrap"> 
                        
                        <b-input-group-prepend>
                            <b-input-group-text class="textfadeDarker modalLabel" >Reseller</b-input-group-text>
                        </b-input-group-prepend>

                        <b-input-group-text class="squareBorder">
                            <b-form-checkbox switch class="checkLeftPad" :value="1" :unchecked-value="0" v-model="focusedTemplate.send_reseller"/>
                        </b-input-group-text> 

                    </b-input-group>
                </b-col>

            </b-row> 
            
            <!--Type-->
            <b-row class="p-1">
                <b-col class="col-12 text-center">
                    <b-input-group>

                        <b-input-group-prepend>
                            <b-input-group-text class="textfadeDarker modalLabel" >Type </b-input-group-text>
                        </b-input-group-prepend>
                        <b-form-select
                            name="Type"
                            v-model="focusedTemplate.typeValue"
                            :options="editTemplate.typeOptions"
                            v-validate = "'templateType|required'"
                            @input="validateField('Type')"
                            class="textsmall modalsearch"
                        />
                    </b-input-group>
                    <span class="colorDanger">{{errors.first("Type")}}</span>
                </b-col>
            </b-row>

            <!--Subject-->
            <b-row class="p-1">
                <b-col class="col-12 text-center">
                    <b-input-group>

                        <b-input-group-prepend v-show="clearAddInputOneVis">
                            <b-input-group-text class="textfadeDarker modalLabel" >Subject </b-input-group-text>
                        </b-input-group-prepend>
                        <b-input
                            name="Subject"
                            v-validate = subjectValidation 
                            placeholder="..."
                            v-model="focusedTemplate.title"
                            class="darkinput"
                            @focus="clearAddInputOneVis = false"
                            @focusout="clearAddInputOneVis = true"
                            @input="validateField('Subject')"
                        />

                        <b-input-group-append is-text v-show="clearAddInputOneVis">
                            <b-icon icon="x" @click.stop="focusedTemplate.title = '' "/>
                        </b-input-group-append>

                    </b-input-group>
                    <span class="colorDanger">{{errors.first("Subject")}}</span>

                </b-col>
            </b-row>      
        </b-collapse>
       

        <!--Content-->
         <b-row class="p-1 m-1">
            <b-col class="col-12 p-0 m-0 text-center">
                <!--Collapse Button-->
                <b-icon v-b-toggle="'collapse-section'" icon="arrows-collapse" scale="2" class="pb-4 pt-1 ml-2 colorSuccess"></b-icon>

                <TinyRichTextEditor 
                name="Content"
                v-model="focusedTemplate.message"
                :message="focusedTemplate.message"
                v-validate="'min:3|max:3000|required'"
                @input="validateField('Content')"
                />

                <span class="colorDanger">{{errors.first("Content")}}</span>

            </b-col>
        </b-row>        

        <!--Submit success/fail messages-->
        <b-row v-show="upload" class="p-1">
            <b-col class="col-12 text-center">
                <b-icon icon="upload" animation="cylon-vertical" font-scale="2"/>
            </b-col>
        </b-row>  
        <b-row v-show="uploadSuccess" class="p-1">
            <b-col class="col-12 text-center">
                <b-icon class="colorSuccess" icon="check2" animation="throb" font-scale="3"/>
            </b-col>
        </b-row>  
        <b-row v-show="uploadFailed" class="p-1">
            <b-col class="col-12 text-center">
                <b-icon class="colorDanger" icon="x" animation="throb" font-scale="3"/>
            </b-col>
        </b-row>

        <!-- Buttons -->
        <b-row  >
          <b-col class="col-12 col-md-6 mt-5">
              <b-button title="Confirm" class="mr-5" size="sm" block variant="outline-success" @click.stop="postTemplate">
                  Confirm
              </b-button>
          </b-col>
          <b-col class="col-12 col-md-6 mt-5">
              <b-button title="Cancel" class="" size="sm" block variant="outline-danger" @click.stop="cancelTemplate">
                  Cancel
              </b-button>       
          </b-col>
        </b-row>

    </b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div>
</template>

<style>
.custom-select.darksearch.textsmall{
    font-size: 1em;
}
.custom-select.modalsearch.textsmall{
    font-size: 1em;
}
.overflowElipse{
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
}
.emailOverflow{
    width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    text-align: left;
    
}
.editTemplateModalCs > .modal-footer{
    justify-content: center;
}


/* The large/lg bootstrap break point*/
@media (max-width: 991px){ 
    .preLgStacked::before{
        width: auto !important;
        max-width: 50% !important;
    }
    .emailContent > div{
        width: 100% !important;
    }
}
@media (min-width: 992px){
    /*Dont want to overwrite base utility classes, so made a copy*/
    .colW-10{
        width:10% !important
    }
    .colW-20{
        width:20% !important
    }
    .colW-50{
        width:50% !important
    }
    td.emailContent{
        padding: 1px;
        margin:1px;
    }

}

/* The extrasmall bootstrap break point*/
@media (max-width: 575px){
    .editTemplateModalCs > .modal-body{
        margin:0px !important;
        padding: 0px !important;
    }
}
</style>

<style scoped>
.modalLabel{
    width:5rem;
}
.checkLeftPad{
    padding-left: 2.7rem !important;
}
.noWrap{
    flex-wrap:nowrap !important;
}
.squareBorder{
    border-radius: 0px;
}
#editTemplateFooter{
   width: auto;
}
.emailContent > div{
    white-space: pre-wrap;
}
.clickCatcher{
    position: absolute;
    width: 40%;
    height: 200px; /*default, overridden by :style="tableContentClickCatcherHeight" */
    z-index: 10;    
}
</style>



<script>
import axios from 'axios';
import ErrorLogging from './ErrorLogging';
import TinyRichTextEditor from './TinyRichTextEditor.vue';
import TinyReadOnlyDisplay from './TinyReadOnlyDisplay.vue';
import { Validator } from 'vee-validate';

export default {
    name:'CasterEmailTemplates',
    components:{ErrorLogging, TinyRichTextEditor, TinyReadOnlyDisplay},
    data() {
        return {
            token:"",
            items:[],
            filteredItems:[],
            fields:[
                {key:'title',           label: 'Subject',   sortable: true,     class:'colW-20 preLgStacked'},
                {key:'message',         label: 'Content',   sortable: true,     class:'colW-50 preLgStacked emailContent'},
                {key:'typeString',      label: 'Type',      sortable: true,     class:'colW-10 preLgStacked'},
                {key:'send_admin1',     label: 'Admin 1',   sortable: true,     class:'colW-10 preLgStacked'},
                {key:'send_admin2',     label: 'Admin 2',   sortable: true,     class:'colW-10 preLgStacked'},
                {key:'send_dealer',     label: 'Dealer',    sortable: true,     class:'colW-10 preLgStacked'},
                {key:'send_reseller',   label: 'Reseller',  sortable: true,     class:'colW-10 preLgStacked'},
            ],
            savedAdminEmails:{},
            adminEmails:{},
            editAdminEmailsModal:false, 
            editTemplateModalFlag:false,
            clearAddInputOneVis:true,
            clearAddInputTwoVis:true,
            upload:false,
            uploadSuccess:false,
            uploadFailed:false,
            errorresponse:{},
            total:0,
            searchLabel:true,
            searchFilter:"",
            hoveringId:null,
            expandedId:null,
            focusedTemplate:{},
            focusedTemplateUnedited:{},
            subjectValidation: {
                required:true,
                min:3,
                max:255,
                regex: /^[A-Za-z0-9\s.,\-_?!:;()[\]]+$/
            },
            editTemplate:{
                typeOptions:[
                    {value:null, text:"Unset", disabled:true},
                    {value:1, text:"Reseller"},
                    {value:2, text:"Dealer"},
                ],
            },
            tableTypeDropdown:{
                value:0,
                options:[
                    {value:0, text:"All",},
                    {value:1, text:"Reseller"},
                    {value:2, text:"Dealer"},
                ],
            },
            tableContentMinHeight:50,
            tableContentMaxHeight:400,
            editTemplateChanged: false,
        }
    },
    computed:{
        tableContentClickCatcherMinHeight(){
            return{
                height: this.tableContentMinHeight + 'px'
            }
        },
        tableContentClickCatcherMaxHeight(){
            return{
                height: this.tableContentMaxHeight + 'px'
            }
        }
    },
    created(){
        this.token = this.$store.getters.token;
        this.getTemplates();
        this.getAdminEmails();
        this.addTemplateTypeValidationRule()
    },
    watch:{
        'tableTypeDropdown.value'(){
            this.filterType()
        }
    },
    methods:{
        addTemplateTypeValidationRule(){
            Validator.extend("templateType", {
                getMessage: field => 'The ' + field + ' value needs to be set.',
                validate: (value) => {
                    if(value === null) return false;
                    if(! this.editTemplate.typeOptions.find( option => option.value === value) ) return false;
                    return true;
                }
            })
        },

        getTemplates(){
            axios.get('api/getemails',
            {
                headers: {"Authorization" : "Bearer " + this.token},
                params:{
                    userid:this.$store.getters.user.id,
                    token:this.$store.getters.user.token,
                    emailTemplates:true,
                }                
            })
            .then( response => {
                if(response.data.code == 999){
                    this.errorresponse = response.data
                    return;
                }
                this.items = response.data.templates;
                this.filteredItems = response.data.templates;
                this.total = response.data.total;
            })
            .catch( err => {
                console.error("CasterEmailTemplates: getTemplates :",err);
            });
        },

        getAdminEmails(){
            axios.get('api/getemails',
            {
                headers: {"Authorization" : "Bearer " + this.token},
                params:{
                    userid:this.$store.getters.user.id,
                    token:this.$store.getters.user.token,
                    adminEmails:true,
                }                
            })
            .then( response => {
                if(response.data.code == 999){
                    this.errorresponse = response.data
                    return;
                }
                //JSON fiddle to prevent a relationship between savedAdminEmails & adminEmails
                this.savedAdminEmails = JSON.parse(JSON.stringify(response.data));
                this.adminEmails = response.data;
                
            })
            .catch( err => {
                console.error("CasterEmailTemplates: getTemplates :",err);
            });
        },

        editAdminEmails(){
            this.editAdminEmailsModal = true;
        },

        validateField(name){
            this.editTemplateChanged = true;
            this.$validator.validate(name);
        },

        async postEmails(){
            //conditions
            if(this.upload) return;
            this.upload = true;

            const valid = await this.$validator.validateAll().catch( err => console.error(err));
            if(!valid) {
                this.upload = false;
                return;
            }

            if(this.adminEmails.add1 === this.savedAdminEmails.add1 
                && 
                this.adminEmails.add2 === this.savedAdminEmails.add2
            ){
                this.upload = false;
                this.cancelEditModal();
                return;
            }

            //prepare data
            let dataObj = {
                userid:this.$store.getters.user.id,
                token:this.$store.getters.user.token,
                adminEmails:true,  
            }       
            if(this.adminEmails.add1 !== this.savedAdminEmails.add1){
                dataObj.add1 = this.adminEmails.add1;
            }
            if(this.adminEmails.add2 !== this.savedAdminEmails.add2){
                dataObj.add2 = this.adminEmails.add2;
            }

            //post
            axios.post('api/updateemails', dataObj,
            {
                headers: {"Authorization" : "Bearer " + this.token},        
            })
            .then( response => {

                if(response.data.code == 999){
                    this.errorresponse = response.data
                    this.uploadFailed = true;
                    return;
                }

                this.uploadSuccess = true;
                this.getAdminEmails();                
            })
            .catch( err => {
                this.uploadFailed = true;
                console.error("CasterEmailTemplates: postEmails :",err);
            })
            .finally( () => {
                this.upload = false;
                
                //show & hide appropreit icons then close modal.
                if(this.uploadFailed){
                    setTimeout( () => {
                        this.uploadFailed = false;
                        this.editAdminEmailsModal = false;
                    }, 5000)
                }
                setTimeout( () => {
                    this.uploadSuccess = false;
                    this.editAdminEmailsModal = false;
                }, 2000)
            });
        },

        cancelEditModal(){
            this.editAdminEmailsModal = false;
            //JSON fiddle to prevent relationship
            this.adminEmails = JSON.parse(JSON.stringify(this.savedAdminEmails));        
        },

        filterResults(){
            setTimeout( () => {
                this.total = this.$refs.templateTable.paginatedItems.length
            },100)
        },

        filterType(){
            if(this.tableTypeDropdown.value === 0){
                this.filteredItems = this.items;
                return;
            }
            this.filteredItems = this.items.filter( item => {
                return item.typeValue === this.tableTypeDropdown.value
            })
        },

        focusTemplate(template){
            if (!Array.isArray(template) || template.length <= 0) return;

            //make a copy of the template, JSON fiddle to prevent reactive relationship
            this.focusedTemplate = JSON.parse(JSON.stringify(template[0])); 
            this.focusedTemplateUnedited = JSON.parse(JSON.stringify(template[0])); 
            this.editTemplateModalFlag = true;
        },

        cancelTemplate(modalEvent){
            //prevent hide event
          modalEvent.preventDefault();

            if (!this.editTemplateChanged) {
              this.$nextTick(() => {
                this.$refs.editTemplateModal.hide();
              });
              return
            }
            this.$bvModal.msgBoxConfirm('Cancel editing and lose all changes?', {
                contentClass: 'darkmodal',
                title: 'Please Confirm',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then( value => {
                if(!value)return;
                this.$refs.editTemplateModal.hide();
            })
            .catch(err => {
                console.error('CasterEmailTemplate, cancelTemplate:', err)
            });

        },

        async postTemplate(modalEvent){
            //prevent hide event
            modalEvent.preventDefault();

            //conditions
            if(this.upload) return;
            this.upload = true;

            const valid = await this.$validator.validateAll().catch( err => console.error('CasterEmailTemplates, postTemplate: ',err));
            if(!valid) {
                this.upload = false;
                return;
            }
                       
            //prepare data
            let dataObj = {
                userid:this.$store.getters.user.id,
                token:this.$store.getters.user.token,
                emailTemplates:true,
                template:this.focusedTemplate  
            }                   
           
            //post
            axios.post('api/updateemails', dataObj,
            {
                headers: {"Authorization" : "Bearer " + this.token},        
            })
            .then( response => {

                if(response.data.code == 999){
                    this.errorresponse = response.data
                    this.uploadFailed = true;
                    return;
                }

                this.uploadSuccess = true;
                this.getTemplates();            
            })
            .catch( err => {
                this.uploadFailed = true;
                console.error("CasterEmailTemplates: postEmails :",err);
            })
            .finally( () => {
                this.upload = false;
                
                //show hide appropreit icons and close modal.
                if(this.uploadFailed){
                    setTimeout( () => {
                        this.uploadFailed = false;
                    }, 5000)
                    return;
                }
                setTimeout( () => {
                    this.uploadSuccess = false;
                    this.$refs.editTemplateModal.hide();
                }, 2000)
                
            });
            
        },

        /* Feature dropped.
        @row-hovered="expandTemplate" //these @ are for the table events
        @row-unhovered="contractTemplate"
        expandTemplate(event,index){
            this.hoveringId = index;
            const nowhoveringId = index;
            setTimeout( () => {
                if(this.hoveringId === nowhoveringId){
                    this.expandedId = nowhoveringId;
                }
            },300)
        },
        contractTemplate(){
            this.hoveringId = null;
            setTimeout( () => {
                if(this.hoveringId === null){
                    this.expandedId = null;
                }
            },500)
        }
        */
    }  
} 
</script>