<template>
<div>
<b-container>

    <b-row class="mt-3">
    <b-col v-if="pages.length >  0" class="col-5 mt-3">{{ rstart }}-{{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>


     <b-col class="col-7 text-right mt-3" v-if="total > limit">
          <b-btn v-for="page in pages" :key="page.value" size="sm" @click="gotoPage(page.value)" style="min-width:30px;margin-right:1px">{{ page.text }}</b-btn>      
    </b-col>
</b-row> 

<b-row class="mt-2" v-if="display">

      <b-col class="col-12">
             <b-table
                ref="table" 
                responsive 
                hover 
                :items="users" 
                :fields="fields"
                :busy="isBusy"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                dark
                >
                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteUser(data.item.value)" class="mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <b-button size="sm" @click="editUser(data.item.value)" class="mr-2 xsbutton" variant="outline-success">
                        <b-icon-pencil />
                    </b-button>
                </template>  

                 <template #table-busy>
                    <div class="text-center my-2" >
                    <b-spinner class="align-middle" style="color:orange"></b-spinner>
                    <strong style="color:orange"> Loading...</strong>
                    </div>
                </template>      

                </b-table>
    
       </b-col> 

</b-row>

<b-row class="mt-3" v-if="users.length == 0 && !isBusy">
      <b-col class="col-12 text-center pt-3 pb-3">
          <h5>No Users Found</h5>
       </b-col>   
</b-row>

<b-modal ref="edit-user" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
     <CasterUserAdd
        :userid="usersel"
        v-on:closemodal=closemodal()
        v-on:updatemodal=updatemodal() 
        />
</b-modal>

<b-modal ref="delete-user" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
     <CasterUserDelete
        :id="usersel"
        v-on:closemodal=closemodal()
        v-on:updatemodal=updatemodal() 
        />
</b-modal>   

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-container>	
</div> 
</template>

<script>
    import CasterUserAdd from './CasterUserAdd'
    import CasterUserDelete from './CasterUserDelete'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        props:['resellerid','dealerid','companyid','role','search','stext'],
        name:'CasterUsersTable', 
        components:{
            CasterUserAdd,
            CasterUserDelete,
            ErrorLogging
        },
		data(){
		return {
			token:'',
            users:[],
            fields:[],
            page:0,
            limit:100,
            total:0,
            rstart:1,
            rend:0,
            pages:[],   
            usersel:0,
            modaltitle:'',
            display:false,
            isBusy:true,
            errorresponse:{}
        };
		
		},
		created(){
			this.token = this.$store.getters.token
            this.setFields()
            this.fetchUsers()
       },

        watch: {
           stext: function(){
                this.fetchUsers()
            },
        },    

		methods: {

            gotoPage(page){
                this.page = page
                this.fetchUsers()
            },

            updatePaging(){
                this.rstart = (this.page * this.limit) + 1
                this.rend = (this.rstart + this.limit) - 1
                if(this.rend > this.total){this.rend = this.total} 
            },

            setFields(){

                if(this.companyid != 0){
                this.fields = [
                { key: 'title', label: 'Name', sortable: true  },
                { key: 'email', label: 'Email', sortable: true  },
                { key: 'roletitle', label: 'Role', sortable: true  },
                { key: 'readonly', label: 'Read Only', sortable: true  },
                { key: 'created', label: 'Created', sortable: true  },
                { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                ]    
                } else

                if(this.resellerid == 0){
                this.fields = [
                { key: 'title', label: 'Name', sortable: true  },
                { key: 'resellertitle', label: 'Reseller', sortable: true  },
                { key: 'dealertitle', label: 'Dealer', sortable: true  },
                { key: 'companytitle', label: 'Company', sortable: true  },
                // { key: 'email', label: 'Email', sortable: true  },
                { key: 'roletitle', label: 'Role', sortable: true  },
                // { key: 'readonly', label: 'Read Only', sortable: true  },
                { key: 'last_login', label: 'Last Active', formatter: 'formatLogin',sortable: true  },
                { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                ]
                }

                if(this.resellerid > 0){
                this.fields = [
                { key: 'title', label: 'Name', sortable: true  },
                { key: 'dealertitle', label: 'Dealer', sortable: true  },
                { key: 'companytitle', label: 'Company', sortable: true  },
                // { key: 'email', label: 'Email', sortable: true  },
                { key: 'roletitle', label: 'Role', sortable: true  },
                // { key: 'readonly', label: 'Read Only', sortable: true  },
                { key: 'last_login', label: 'Last Active', formatter: 'formatLogin', sortable: true  },
                { key: 'show_details', label: ' Actions' , thClass: 'text-center'}
                ]
                }

            },

            formatLogin(date){
                if(date == '01-01-1970 01:00'){return '-'} else return date
            },

			fetchUsers(){
                this.isBusy = true
              
				axios.get('/api/casterusers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         stext:this.stext,
                         role:this.role,
                         reseller:this.resellerid,
                         dealer:this.dealerid,
                         company:this.companyid,
                         page:this.page,
                         limit:this.limit
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                        this.users = response.data.users
                        this.total = response.data.total

                        
                        if(this.total != 0 && this.stext != ''){
                                this.$emit('sendFetchResponse',this.stext)
                        }

                        this.updatePaging()
                            
                            this.isBusy = false
                            this.display = true
                        }
                    
                    });
			},

           editUser(id){
               this.usersel = id
               this.modaltitle = 'Edit User'
               this.$refs['edit-user'].show()
           },

           deleteUser(id){
               this.usersel = id
               this.modaltitle = 'Delete User'
               this.$refs['delete-user'].show()
           },

            updatemodal(){
               this.$refs['delete-user'].hide() 
               this.$refs['edit-user'].hide()
               this.fetchUsers()
           },

           }
	
	}

</script> 