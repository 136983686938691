<template>
<div>

<b-row class="mt-0 pb-5" v-if="display">

    
    <b-col v-if="pages.length >  0" class="col-5 mt-3">{{ rstart }}-{{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>


     <b-col class="col-7 text-right mt-3" v-if="total > limit">
          <b-btn v-for="page in pages" :key="page.value" size="sm" @click="gotoPage(page.value)" style="min-width:30px;margin-right:1px">{{ page.text }}</b-btn>      
    </b-col>

    <b-col class="col-12 mt-3" style="min-height:400px" v-if="scroll">
         
             <b-table
                class="comptable" 
                striped 
                responsive 
                hover
                :items="companies" 
                :fields="fields"
                sticky-header="380px"
                :busy="isBusy"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @row-clicked="showCompany($event)" 
                sort-icon-left
                dark
                >
                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteCompany(data.item.value)" class="mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <b-button size="sm" @click="editCompany(data.item)" class="mr-2 xsbutton" variant="outline-success">
                        <b-icon-pencil />
                    </b-button>
                </template>

                 <template #table-busy>
                    <div class="text-center my-2" >
                    <b-spinner class="align-middle" style="color:orange"></b-spinner>
                    <strong style="color:orange"> Loading...</strong>
                    </div>
                </template>    

                </b-table>
       </b-col> 

       <b-col class="col-12 mt-3" v-else>
         
             <b-table
                class="comptable" 
                striped 
                responsive 
                hover
                :items="companies" 
                :fields="fields"
                :busy="isBusy"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @row-clicked="showCompany($event)" 
                sort-icon-left
                dark
                >
                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteCompany(data.item.value)" class="mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <b-button size="sm" @click="editCompany(data.item)" class="mr-2 xsbutton" variant="outline-success">
                        <b-icon-pencil />
                    </b-button>
                </template>

                 <template #table-busy>
                    <div class="text-center my-2" >
                    <b-spinner class="align-middle" style="color:orange"></b-spinner>
                    <strong style="color:orange"> Loading...</strong>
                    </div>
                </template>    

                </b-table>
       </b-col> 
</b-row>


<b-modal ref="edit-company" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterCompanyAdd
    :companyid="companysel"
    nosave=1
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    v-on:updatecompany=updatemodal()
    />
</b-modal> 


<b-modal ref="delete-company" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterCompanyDelete
    :id="companysel" 
    v-on:updatemodal=updatemodal()
    />
</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style>
.comptable .table-dark td{
    word-break: break-word !important;
}
</style>

<script>
    import CasterCompanyAdd from './CasterCompanyAdd'
    import CasterCompanyDelete from './CasterCompanyDelete'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        props:['stext','reseller','dealerid','scroll'],
        name:'CasterCompaniesTable',
        components:{
            CasterCompanyAdd,
            CasterCompanyDelete,
            ErrorLogging
        }, 
        data(){
            return {
                id:0,
                token:'',
                companies:[],
                companysel:0,
                fields:[],
                sortBy:'text',
                display:false,
                modaltitle:'',
                isBusy:true,
                page:0,
                total:0,
                limit:100,
                rstart:1,
                rend:0,
                pages:[],
                errorresponse:{}
                };
                
                },

		created(){

            this.token = this.$store.getters.token
            this.searchtext = this.stext
            this.fetchFields()
            this.fetchCompanies()
           
        },

         watch: {
                    stext: function () {
                            this.page = 0;
                            this.fetchCompanies()
                         },   
          },

		methods: {

            gotoPage(page){
                this.page = page
                this.fetchCompanies()
            },

            updatePaging(){
                this.rstart = (this.page * this.limit) + 1
                this.rend = (this.rstart + this.limit) - 1
                if(this.rend > this.total){this.rend = this.total} 
            },

            fetchFields(){
                if(this.filter && this.$store.getters.roleid != 10){
                    this.fields = [
                        { key: 'text', label: 'Name', sortable: true  },
                        { key: 'dealername', label: 'Dealer', sortable: true  },
                        { key: 'contact', label: 'Contact', sortable: true  },
                        { key: 'tel', label: 'Tel', sortable: true  },
                        { key: 'mobile', label: 'Mobile', sortable: true  },
                        { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                    ]
                
                } else {
                    this.fields = [
                        { key: 'text', label: 'Name', sortable: true  },
                        { key: 'contact', label: 'Contact', sortable: true  },
                        { key: 'tel', label: 'Tel', sortable: true  },
                        { key: 'mobile', label: 'Mobile', sortable: true  },
                        { key: 'email', label: 'Email', sortable: true  },
                        { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                        
                    ]
                }

            },

           
            fetchCompanies(){
                   
                    this.isBusy = true
                    axios.get('/api/castercompanies',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         stext:this.stext,
                         reseller:this.reseller,
                         dealer:this.dealerid,
                         //list:1,
                         nousers:1,
                         page:this.page,
                         limit:this.limit
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        return
                    }
                    this.companies = response.data.CasterCompanies
                    this.total = response.data.Total
                    this.pages = []
                    
                    let pages = Math.ceil(this.total/this.limit)
                    let count = 0
                        while(count < pages){
                            this.pages.push({value:count,text:count+1})
                            count++
                        }
                        this.updatePaging()

                    this.isBusy = false
                    this.display = true
                        
                }).catch( err => {
                    console.error("CasterCompaniesTable, fetchCompanies :",err)
                })
			},

             editCompany(company){
                this.modaltitle = 'Edit Company : '+company.text
                this.companysel = company.value
                this.$refs['edit-company'].show()
           },

           deleteCompany(id){
               this.companysel = id
               this.modaltitle = 'Delete Company'
               this.$refs['delete-company'].show()
           },

     
            updatemodal(){
                this.$refs['edit-company'].hide()
                this.$refs['delete-company'].hide()
                this.fetchCompanies()
            },

       
            showCompany(company){
                this.$store.commit('setcompanyid',company.value)
                this.$store.commit('setresellerid',company.business)
                this.$store.commit('setdealerid',company.dealer)
                
                this.$router.push({ name: 'castercompany', params: {id:company.value} }).catch(()=>{})

                
            },

         

		}	   
	
	};

</script> 


