<template>
  <div ref="mapcont" class="imageBorder setmapmin">

    <div class="titleholder">
      <span v-if="!dataerror">
        <span v-if="border"> SESSION TRACK</span>
        <span v-if="loading"> UPDATING</span>
      </span>
      <span v-if="dataerror" style="color:red">NO SESSION TRACKING DATA</span>
    </div>

    <div class="leafletbuttons">
      <b-button variant="outline-success" size="sm" @click.stop="showSat()"><b-icon-image /></b-button>
      <b-button variant="outline-success" size="sm" @click.stop="fullScreen()">
        <b-icon-fullscreen />
      </b-button>
    </div>

    <div class="leafletrtk">
      <div class="rtkstatus" style="background-color:rgba(60, 210, 165, 0.7)">FIXED</div>
      <div class="rtkstatus" style="background-color:rgba(48, 190, 255, 0.7)">FLOAT</div>
      <div class="rtkstatus" style="background-color:rgba(255, 159, 12, 0.7)">DGPS</div>
      <div class="rtkstatus" style="background-color:rgba(255, 96, 96, 0.7);min-width:46px;text-align:center">GPS</div>
      <div class="rtkstatus" style="background-color:rgba(102, 102, 102, 0.7);min-width:46px;text-align:center">EST
      </div>
    </div>

    <v-map ref="myMap" :center="currentCenter" :zoom="currentZoom"
      :style="{ height: mapheight + 'px', width: mapwidth + 'px', left: '10px', top: '10px' }" @ready="onReady"
      v-if="mapshow">
      <!-- <v-tilelayer :options="{ maxZoom: 25 }" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> -->
      <v-tilelayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" />

      <v-marker v-for="marker in markers" :key="marker.id" :lat-lng="[marker.lat, marker.long]">
        <v-icon v-if="marker.type == 1" :icon-anchor="staticAnchor" class-name="iconclass">
          <img :src="mapmarker" style="height:20px;width:auto">
          <!-- <div class="headline" v-if="marker.name">{{ marker.name }}</div> -->

        </v-icon>

        <v-icon v-if="marker.type == 2" :icon-anchor="staticAnchor" class-name="iconclass">
          <img :src="mapmarker2" style="height:20px;width:auto">
        </v-icon>

      </v-marker>

      <v-polyline v-for="line in polyline" :key="line.id" :lat-lngs="line.points" :color="line.color"
        v-on:click="lineClick(line)">
      </v-polyline>

    </v-map>

    <b-modal v-model="pointDetailShow" @hidden="resetPointDetails" :title="pointDetails?.title ? pointDetails.title : 'GETTING DATA'"
      size="md" content-class="darkmodal" hide-footer centered >

      <b-row class="mt-3">

        <b-col class="col-6">QUALITY</b-col>
        <b-col v-if="pointDetails?.statustext" class="col-6">{{ pointDetails.statustext }}</b-col>
        <b-col v-else class="col-6"><b-icon icon="three-dots" animation="cylon" /></b-col>

        <b-col class="col-6">NO. SATELLITES</b-col>
        <b-col v-if="pointDetails?.satelites" class="col-6">{{ pointDetails.satelites }}</b-col>
        <b-col v-else class="col-6"><b-icon icon="three-dots" animation="cylon" /></b-col>

        <b-col class="col-6">ALTITUDE</b-col>
        <b-col v-if="pointDetails?.altitude" class="col-6">{{ pointDetails.altitude }}</b-col>
        <b-col v-else class="col-6"><b-icon icon="three-dots" animation="cylon" /></b-col>

        <b-col class="col-6">DISTANCE</b-col>
        <b-col v-if="pointDetails?.distance" class="col-6">{{ pointDetails.distance }}</b-col>
        <b-col v-else class="col-6"><b-icon icon="three-dots" animation="cylon" /></b-col>

        <b-col class="col-6">HDOP</b-col>
        <b-col v-if="pointDetails?.hdop" class="col-6">{{ pointDetails.hdop }}</b-col>
        <b-col v-else class="col-6"><b-icon icon="three-dots" animation="cylon" /></b-col>

        <b-col class="col-6">DATA AGE</b-col>
        <b-col v-if="pointDetails?.age" class="col-6">{{ pointDetails.age }}</b-col>
        <b-col v-else class="col-6"><b-icon icon="three-dots" animation="cylon" /></b-col>

      </b-row>

    </b-modal>

  </div>
</template>


<script>
import { mapState } from 'vuex'
import mapmarker from '@/assets/images/mapmarker.png'
import mapmarker2 from '@/assets/images/mapmarker2.png'
import spin from '@/assets/images/process.gif'
import moment from 'moment'
import * as Vue2Leaflet from 'vue2-leaflet'
import { latLng } from "leaflet"
import * as L from 'leaflet'

delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: 'CasterShowLeafletRoverSession',
  props: ['fsmapheight', 'fsmapwidth', 'border', 'fullscreen', 'mapdata', 'id', 'sessionid'],
  components: {
    'v-map': Vue2Leaflet.LMap,
    'v-marker': Vue2Leaflet.LMarker,
    'v-icon': Vue2Leaflet.LIcon,
    'v-tilelayer': Vue2Leaflet.LTileLayer,
    'v-polyline': Vue2Leaflet.LPolyline,
  },
  data() {
    return {

      token: '',
      spin,
      pointcount: 0,
      pointcountlast: 0,
      sessionidLast:0,
      currentsession: {},
      rover: {},
      showsat: false,
      start: true,
      markers: [],
      points: [],
      pointDetails: {},
      polyline: [],
      centered: false,
      notzoomed: true,
      staticAnchor: [5, 20],
      currentZoom: 16,
      currentCenter: latLng(0, 0),
      satlayergroup: {},
      mapheight: 0,
      mapwidth: 0,
      mapshow: false,
      pointDetailShow: false,
      lineDetailFetching: false,
      mapmarker,
      mapmarker2,
      loading: true,
      dataerror: false,
      lineClickDebounce: false,
    };

  },


  computed: mapState(['openmenu']),

  watch: {
    mapdata: function () {
      this.currentsession = this.mapdata

      // if(this.pointcount == 0){this.dataerror = true} else this.dataerror = false
      if (this.pointcount !== this.currentsession.points.length && this.$refs.myMap != undefined) {

        this.pointcount = this.currentsession.points.length
        if (this.pointcount == 0) { this.dataerror = true } else this.dataerror = false
        if (this.$refs.myMap == undefined) { console.error('undefined') } else { this.updateMap(); this.addToPointDetails(); }

      } else this.loading = false
    },

    sessionid: function () {
      this.start = true;
      this.resetPointDetails();

    },

    openmenu: function () {
      this.setMapSize()
    },

  },

  created() {
    this.currentsession = this.mapdata
    window.addEventListener("resize", this.setMapSize)
  },

  mounted() {
    this.setMapSize()
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.setMapSize)
  },


  methods: {

    onReady() {
      this.satlayergroup = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x} 28', { attribution: '' });
      this.updateMap()
    },

    updateMap() {
      this.loading = true
      this.polyline = []
      this.points = []
      this.markers = []

      let keyid = 0
      let index = 0
      let points = this.currentsession.points
      this.markers.push(...this.currentsession.markers)
      let mpoints = []

      if (points.length > 0) {
        this.dataerror = false
        if (this.centered == false) { this.centerMap() }

        do {

          mpoints.push([points[index]['lat'], points[index]['long']])
          if (points.length > 1) {
            mpoints.push([points[index + 1]['lat'], points[index + 1]['long']])
            this.polyline.push({ points: mpoints, color: points[index + 1]['color'], id: keyid, time: 123 })
          } else this.polyline.push({ points: mpoints, color: points[index]['color'], id: keyid, time: 123 })

          mpoints = []
          index++
          keyid++

        } while (index < points.length - 1)

        this.points.push(...points) //  Add line points to main points list (only used to set page boundaries)

        if (this.start == true) {
          this.start = false
          this.setBounds()
          this.toTop()
        }

      } //else this.dataerror = true
      this.loading = false
    },

    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },

    zoom() {
      this.notzoomed = !this.notzoomed
      if (!this.notzoomed) {
        this.currentZoom = 16
        this.centerMap()
      } else this.setBounds()

    },

    centerMap() {
      if (this.markers.length == 1) {
        this.$refs.myMap.mapObject.setView(latLng(this.markers[0]['lat'], this.markers[0]['long']), this.currentZoom) // Zoom and Focus to current position
        this.centered = true
      }
      if (this.markers.length > 1) {
        this.$refs.myMap.mapObject.setView(latLng(this.markers[1]['lat'], this.markers[1]['long']), this.currentZoom) // Zoom and Focus to current position
        this.centered = true
      }
    },

    setBounds() {
      this.$refs.myMap.mapObject.fitBounds(this.points.map(m => { return [m['lat'], m['long']] }), { padding: [45, 45] })
    },

    showSat() {
      if (this.showsat == false) { this.satlayergroup.addTo(this.$refs.myMap.mapObject) } else this.$refs.myMap.mapObject.removeLayer(this.satlayergroup)
      this.showsat = !this.showsat
    },


    lineClick(line) {
      if ( this.lineClickDebounce || this.pointDetailShow ) return;
      this.lineClickDebounce = true;
      this.showDetail(line.id); //use array index as time
      setTimeout( () => {this.lineClickDebounce = false} , 1000 );
    },

    resetPointDetails() {
      this.pointDetails = {};
      this.pointDetailShow = false; //for modal vis
    },

    addToPointDetails() {
      //check we have details
      if ( !this.points[0].ggaPopupinfo ) return; 

      //if there has been a reduction in points vs points last  (used in the mapdata watch) close modal
      if ( this.pointcount < this.pointcountlast && this.sessionid === this.sessionidLast) { 
        this.resetPointDetails();
        this.pointcountlast = this.pointcount;
        return;
      }

      //get timezone for title
      const dateForTimezone = new Date();
      const timezone = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).formatToParts(dateForTimezone).find(part => part.type === 'timeZoneName').value;          

      //update point details with title and units
      this.points.forEach( point => {

        //time/timezone formatting for title
        const localDateTime = moment.utc(point.ggaPopupinfo.timestamp).local().format('DD-MM-YY HH:mm:ss')
        point.ggaPopupinfo.title = `${localDateTime} (${timezone})`;

        //add units to altitude/distance depending on data loss.
        if ( point.ggaPopupinfo.altitude != 'Data Loss' && !point.ggaPopupinfo.altitude.toString().includes('m') ) point.ggaPopupinfo.altitude += 'm';
        if ( point.ggaPopupinfo.distance != 'Data Loss' && !point.ggaPopupinfo.distance.toString().includes('km') ) point.ggaPopupinfo.distance += ' km'; 

      })

      //update pointDetails incase clicks line before this fn is called.
      if ( this.pointDetails.index && this.points[this.pointDetails.index] ) {
        this.showDetail(this.pointDetails.index);
      }

      this.pointcountlast = this.pointcount;
      this.sessionidLast = this.sessionid;

    },

    showDetail(lineId) {
      if ( this.pointDetailShow ) return; 
      this.pointDetailShow = true; //for modal vis

      this.pointDetails = this.points[lineId].ggaPopupinfo;

      //due to model behaviour occasionally with frequenct clicking you can somehow get here twice in a row without setting this.pointDetailShow to false. 
      //if that happens the point details are empty
      setTimeout( () => {
        if ( !this.pointDetails.title ) {
          this.pointDetails = this.points[lineId]?.ggaPopupinfo;
        }
      } , 3000)
        
      this.pointDetails.index = lineId;
    },

    fullScreen() {
      this.$emit('fullscreen')
    },

    setMapSize() {

      if (this.fsmapheight == undefined) {
        this.mapwidth = this.$refs.mapcont.clientWidth + 24
        this.mapheight = this.$refs.mapcont.clientHeight + 24
      } else {
        this.mapheight = this.fsmapheight
        this.mapwidth = this.fsmapwidth
      }
      this.mapshow = true
    }
  }

};

</script>