<template>
<div class="imageBorder">


        <b-row v-if="display">

             <b-col class="col-4 mt-3">NTRIP Clients</b-col>

             <b-col class="col-4 mt-3 text-center">
                {{ timeperiod }}
            </b-col>             
            <b-col class="col-4 mt-3 text-right">
               <b-button size="sm" variant="outline-success" @click="setPeriod('day')">DAY</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('week')">WEEK</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('month')">MONTH</b-button>
               <!-- <b-button size="sm" variant="outline-success" @click="setPeriod('year')">YEAR</b-button> -->

            </b-col>            
            <b-col class="col-12 mt-3">
                 <BarChartStackedVertical :chartdata="data" :height="200" />
            </b-col>
 
        </b-row>

        <b-row class="mt-3" v-if="display">
               <b-col class="col-4 mt-3" v-for="label in labels" :key="label.key">
                    <div class="square" :style="{ backgroundColor: label.color }" />{{ label.name }} 
                </b-col>   
        </b-row>     

         <b-row class="mt-3" v-if="!display">
                <b-col class="col-12 text-center pt-3 pb-3">
                        <h5 style="color:#48A1BA;">No Data</h5>
                </b-col>   
        </b-row>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>        

</div>
</template>

<script>
    import BarChartStackedVertical from './BarChartStackedVertical'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
	export default {
        props:['dealer'],
        name:'CasterNTRIPClientsChartCard',
        components:{
                BarChartStackedVertical,
                ErrorLogging
        }, 
        data(){
		return {
                data:[],
                labels:[],
                token:'',
                display:false,
                period:3600, // 1 hour
                timeout:'',
                timeperiod:'',
                start:'',
                end:'',
                steps:0,
                errorresponse:{}
               };
                
                },

	created(){
                this.token = this.$store.getters.token
                this.setPeriod('day')
                },

    beforeDestroy () {
                clearTimeout(this.timeout)
                },                

	methods: {

                getNetworkLogins(){

                     axios.get('/api/ntripclienthistory',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                                userid:this.$store.getters.user.id,
                                token:this.$store.getters.user.token,
                                start:this.start,
                                end:this.end,
                                steps:this.steps,
                                dealer:this.dealer
                         }
                        })
			.then((response) => {

                if(response.data.code == 999){
                            this.errorresponse = response.data
                        } else {
                            this.data = response.data
                            let key = 1
                            this.labels = []
                            this.data.datasets.forEach(element => {
                               this.labels.push({key:key,name:element.label,color:element.color})
                               key++
                            });
                            this.display = true
                            this.timeout = setTimeout(this.getNetworkLogins, 300000) // poll every 5 mins
                           
                        }

                    })
                   
                },
                
            setPeriod(value){
                    switch(value){
                        case 'day':
                        this.start = moment().format("YYYY-MM-DD")
                        this.end = moment().format("YYYY-MM-DD")    
                        this.timeperiod = moment().format("DD-MM-YYYY")
                        this.steps = 24
                        break;
                        case 'week':
                        this.start = moment().startOf('isoweek').format("YYYY-MM-DD")
                        this.end = moment().endOf('isoweek').format("YYYY-MM-DD")
                        this.timeperiod = moment().startOf('isoweek').format("DD-MM-YYYY")+' - '+moment().endOf('isoweek').format("DD-MM-YYYY")
                        this.steps = 7
                        break;
                        case 'month':
                        this.start = moment().startOf('month').format("YYYY-MM-DD")
                        this.end = moment().endOf('month').format("YYYY-MM-DD")
                        this.timeperiod =  moment().startOf('month').format("DD-MM-YYYY")+' - '+ moment().endOf('month').format("DD-MM-YYYY")
                        this.steps = moment().daysInMonth() 
                        break;
                        case 'year':
                        this.start = moment().startOf('year').format("YYYY-MM-DD")
                        this.end = moment().endOf('year').format("YYYY-MM-DD")
                        this.timeperiod =  moment().startOf('year').format("DD-MM-YYYY")+' - '+ moment().endOf('year').format("DD-MM-YYYY")
                        this.steps = 12
                        break;                        
                    }    
                        this.$emit('periodchange',{start:this.start,end:this.end,period:value})
                        this.getNetworkLogins()    
                }
            }
        };
</script> 