<template>
<div>
    
<b-row class="mt-3" v-if="!isBusy">
   
    <b-col v-if="pages.length >  0" class="col-12 col-md-5 mt-3">{{ rstart }}-{{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>


     <b-col class="col-12 col-md-7 text-md-right mt-3" v-if="total > limit">
          <b-btn v-for="page in pages" :key="page.value" size="sm" @click="gotoPage(page.value)" style="min-width:30px;margin-right:1px">{{ page.text }}</b-btn>      
    </b-col>

</b-row>

<b-row>        
        <b-col class="col-12 mt-3">
             <b-table
                class="rovers" 
                responsive 
                hover 
                :items="rovers" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :busy="isBusy"
                :sort-by="sortBy"
                @row-clicked="showRover($event)"
                dark
                >

                <template #table-busy>
                    <div class="text-center text-warning my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                    </div>
                </template>

                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteRover(data.item.value)" class="mt-1 mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <!-- <b-button size="sm" @click="editRover(data.item)" class="mt-1 mr-2 xsbutton" variant="outline-success" v-if="role=1">
                        <b-icon-pencil />
                    </b-button> -->
                </template>    

                </b-table>
       </b-col>

</b-row>

<b-modal ref="edit-rover"  :title="modaltitle" size="lg" hide-footer centered content-class="darkmodal">
   <CasterRoverAdd
    :roverid="roversel" 
    v-on:closemodal="closemodal()"
    v-on:updatemodal="updatemodal()"
    v-on:updatecompany="updatemodal()"
    />
</b-modal>

<b-modal ref="delete-rover" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterRoverDelete
    :id="roversel" 
    v-on:closemodal="closemodal()"
    v-on:updatemodal="updatemodal()"
    />
</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style>
.rovers .table-dark td{
    word-break: unset !important;
}
</style>

<script>
    import CasterRoverAdd from './CasterRoverAdd'
    import CasterRoverDelete from './CasterRoverDelete'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
	export default {
        props:['stext','reseller','dealer','company','refresh'],
        name:'CasterRoversTable',
        components:{
            CasterRoverAdd,
            CasterRoverDelete,
            ErrorLogging
        }, 
        data(){
        return {
                id:0,
                token:'',
                rovers:[],
                roversel:0,
                fields:[],
                sortBy:'text',
                isBusy: true,
                modaltitle:'',
                display:false,
                role:0,
                page:0,
                total:0,
                limit:100,
                rstart:1,
                rend:0,
                pages:[],
                errorresponse:{},
                };
                
                },

		created(){
            this.role = this.$store.getters.roleid
            this.token = this.$store.getters.token
            this.fetchFields()
            this.fetchRovers()
        },

           watch: {
                    stext: function () {
                       
                         this.$store.commit('setsearchresults',[])
                         this.page = 0
                         this.fetchRovers()
                         }, 
                    reseller: function () {
                         this.$store.commit('setsearchresults',[])
                         this.page = 0
                         this.fetchRovers()
                         },     
                    dealer: function () {
                         this.$store.commit('setsearchresults',[])
                         this.page = 0
                         this.fetchRovers()
                         },
                    company: function () {
                         this.$store.commit('setsearchresults',[])
                         this.page = 0
                         this.fetchRovers()
                         },
                    refresh: function () {
                         this.$store.commit('setsearchresults',[])
                         this.page = 0
                         this.fetchRovers()
                         },         
                         
          },

		methods: {

            gotoPage(page){
                this.page = page
                this.fetchRovers()
            },

            updatePaging(){
                this.rstart = (this.page * this.limit) + 1
                this.rend = (this.rstart + this.limit) - 1
                if(this.rend > this.total){this.rend = this.total} 
            },

            fetchFields(){
                 if(this.company !== 0){
                    this.fields = [
                    { key: 'text', label: 'Rover', sortable: true  },    
                    { key: 'statustext', label: 'RTK Status', sortable: true  },
                    { key: 'lastconnect2', label: 'Last Connect', formatter: 'formatDate', sortable: true  },   
                    { key: 'subscriptiondetail[0].status', label: 'Subscription', sortable: true  },
                    { key: 'subscriptiondetail[0].subscription[0].text', label: 'Type', sortable: true  },
                    { key: 'subscriptiondetail[0].enddate2', label: 'Expire', formatter: 'formatDate',sortable: true  },
                    { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                    ]
                 } else {
                    this.fields = [
                    { key: 'text', label: 'Rover', sortable: true  },    
                    { key: 'companytitle', label: 'Company', sortable: true  },
                    { key: 'statustext', label: 'RTK Status', sortable: true  },
                    { key: 'lastconnect2', label: 'Last Connect', formatter: 'formatDate', sortable: true  },   
                    { key: 'subscriptiondetail[0].status', label: 'Subscription', sortable: true  },
                    { key: 'subscriptiondetail[0].subscription[0].text', label: 'Type', sortable: true  },
                    { key: 'subscriptiondetail[0].enddate2', label: 'Expire', formatter: 'formatDate',sortable: true  },
                    { key: 'show_details', label: ' Actions', thClass: 'text-center' }
                    ]
                 }
            }, 
            
          
            fetchRovers(){

                this.isBusy = true
                let s1 = this.stext
                
                axios.get('/api/companyroverstest',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.reseller,  
                        dealer:this.dealer,
                        company:this.company,
                        stext:this.stext,
                        page:this.page,
                        limit:this.limit
                     }
                })
				.then((response) => {

                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                            if(s1 == this.stext){
                                this.rovers = response.data.rovers
                                this.total = response.data.total
                                this.pages = []
                                let pages = Math.ceil(this.total/this.limit)
                                let count = 0
                                while(count < pages){
                                    this.pages.push({value:count,text:count+1})
                                    count++
                                }
                                this.updatePaging()
                            }
                            this.display = true
                            this.isBusy = false
                        } 

                    });

        
			},

          editRover(item){
               this.roversel = item.value
               this.modaltitle = item.text
               this.$refs['edit-rover'].show() 
           },

           deleteRover(id){
               this.roversel = id
               this.modaltitle = 'Delete Rover'
               this.$refs['delete-rover'].show()
           },    
           
            showRover(event){
            //    this.$store.commit('setresellerid',event.reseller)
            //    this.$store.commit('setdealerid',event.dealer)
               this.$router.push({ name: 'casterrovermanage', params: {id:event.value} }).catch(()=>{})
            },

             updatemodal(){
                this.$refs['delete-rover'].hide()
                this.$refs['edit-rover'].hide() 
                this.fetchRovers()
            },

             formatDate(timedate) {
                if(timedate == null){return '-'}
                return  moment.utc(timedate).local().format('DD-MM-YY HH:mm:ss')
            },

		}	   
	
	};
</script>