<template>
<div class="imageBorder">

<b-row v-if="display">
        <b-col class="col-9 boxtext" >REFERENCE STATIONS</b-col>
        <b-col class="col-3 text-right" >
                <b-icon-fullscreen @click.stop="fullScreen()" v-b-tooltip.hover title="Expand" />
        </b-col>
</b-row>      

<b-row v-if="display" style="min-height:80px">

        <div class="charttextholder">
                <h2 class="boxtext_md">{{ data[0].total }}</h2>
                {{ data[1].value }}/{{ data[0].total }} <span style="font-size:0.7em"> ONLINE</span>
        </div>

        <div class="pieholderlg" v-if="singlebox">
                <SubsChart :data="data" /> 
        </div>

        <div class="pieholder" v-else>
                <SubsChart :data="data" />
        </div>  

</b-row>

 <b-row v-else class="mt-3">
                <b-col class="col-12 text-center pt-3 pb-3">
                        <h5 style="color:#48A1BA;">No Reference Stations Found</h5>
                </b-col>   
</b-row>


<b-modal v-if="display" ref="full-screen" size="md"  title="REFERENCE STATIONS" hide-footer centered content-class="darkmodal">

<b-row class="mt-3">
     <b-col class="col-12">
       
        <b-row class="mt-3">
                <b-col class="col-2 col-sm-8 col-lg-2">
                <h2 class="boxtext_md">{{ data[0].total }}</h2>
                </b-col>

                <b-col class="col-7 col-lg-7 text-center">
                 <BarChartH :datavalue="data[1].value" :totalvalue="data[0].total" unitvalue=' ONLINE' />
                </b-col>  

                <b-col class="col-12 col-sm-4 col-lg-3">
                        <SubsChart :data="data" />
                </b-col>
        </b-row>
       
    </b-col>
       
    <b-col class="col-6">
                <b-row class="mt-3" > 
                        <b-col class="col-12">{{ data[1].value }} {{ data[1].label }}</b-col>
                        <b-col class="col-12">{{ data[0].value }} {{ data[0].label }}</b-col>     
                </b-row>  
     </b-col>   
</b-row>

</b-modal>

</div>
</template>


<script>
        import SubsChart from './SubsChart'
        import BarChartH from './BarChartH';
        import { mapState } from 'vuex'
        export default {
                props:['singlebox'],
                name:'CasterBasestationsCard',
                components:{
                SubsChart,
                BarChartH
                },     
        data(){
		return {
                data:[],
                display:false
                };
                
                },
        
        created(){
                this.showData()
        },

	computed: mapState(['basesdata']),

        watch: {
                    basesdata: function () {
                            this.showData()
                         }, 
        },

	methods: {

                showData(){
                        if(this.$store.getters.basesdata.chartdata !== undefined){
                                this.data = this.$store.getters.basesdata.chartdata.data
                                this.display = true  
                        } else this.display = false
                },

                fullScreen(){
                      this.$refs['full-screen'].show()
                },
        }
	
	};

</script> 