<template>
    <div>
    
    <b-container class="addrowpage">
    
    <b-row class="mt-3">
        
            <b-col class="col-12 col-sm-8 mt-3">
                <h5>Main Administrator</h5>
            </b-col>
    
            <b-col class="col-12 col-sm-4 mt-3 text-right" v-if="adminusers.length > 0">
                <b-btn variant="outline-success" size="sm" @click.stop="selectAdmin()">select user</b-btn>
            </b-col>
    
            <b-col class="col-12 col-md-6 mt-3">   
                    <label>Forename</label>
                    <b-form-input 
                        type="search"
                        placeholder=""
                        autocomplete="off" 
                        v-model="admindetails.fname" 
                        name="admindetails.fname"
                        v-validate="{required:true}"
                        :class="{'input': true, 'is-danger': errors.has('admindetails.fname') }" 
                        class="darkinput"
                        size="md"
                        @input = "validateField('admindetails.fname')"
                        />
                        <span style="color:red;">{{ errors.first('admindetails.fname') }}</span>
            </b-col>
    
            <b-col class="col-12 col-md-6 mt-3">
                    <label>Surname</label>
                    <b-form-input 
                        type="search"
                        placeholder=""
                        autocomplete="off" 
                        v-model="admindetails.lname" 
                        name="admindetails.lname"
                        v-validate="{required:true}"
                        :class="{'input': true, 'is-danger': errors.has('admindetails.lname') }" 
                        class="darkinput"
                        size="md"
                        @input = "validateField('admindetails.lname')"
                        />
                        <span style="color:red;">{{ errors.first('admindetails.lname') }}</span>
            </b-col>
       
            <b-col class="col-12 mt-3">
                    <label>Login Email</label>
                   
                    <b-form-input 
                        type="search"
                        placeholder=""
                        autocomplete="off" 
                        v-model="admindetails.email" 
                        name="admindetails.email"
                        v-validate="'required:true|customRule:'+emailerror"
                        :class="{'input': true, 'is-danger': errors.has('admindetails.email') }"  
                        class="darkinput"
                        size="md"
                        @input = "validateField('admindetails.email')"
                        />
                        <span style="color:red" v-show="errors.has('admindetails.email')">{{ errors.first('admindetails.email') }}</span>
                        <span v-if="emailerror2" style="color:red;">Invalid email format</span>
                  
            </b-col>
    
            <b-col class="col-12 col-sm-6 mt-3">
            <label>Phone</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="admindetails.tel" 
                    class="darkinput"
                    size="md"  
                    />
        </b-col>
    
        <b-col class="col-12 col-sm-6 mt-3">
            <label>Mobile</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="admindetails.mobile" 
                    class="darkinput"  
                    size="md"
                    />
        </b-col>   
            
            <b-col class="col-12 col-sm-6 mt-3">
                <label>Login Password </label>
                    <b-input-group>
                    <b-form-input 
                        type="search"
                        placeholder="new password"
                        autocomplete="off" 
                        v-model="admindetails.password" 
                        name="admindetails.password"
                        v-validate="{required:true}"
                        :class="{'input': true, 'is-danger': errors.has('admindetails.password') }"  
                        class="darkinput"
                        size="md"
                        @input = "validateField('admindetails.password')"
                        />
                    <b-input-group-append>
                        <b-button variant="light" @click="createPassword()"><b-icon-arrow-clockwise /></b-button>
                    </b-input-group-append>
                    </b-input-group>
                    <span style="color:red;">{{ errors.first('admindetails.password') }}</span>
              </b-col>
    
    </b-row>    
    
    <b-row class="mt-5">
        <b-col class="col-12">
            <b-button variant="outline-success" block ref="next" size="md" @click="checkAdminDetails()">NEXT</b-button>
         </b-col>  
    </b-row>     
    
    <b-modal ref="select-admin" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
                <b-table 
                    striped 
                    responsive 
                    hover 
                    :items="adminusers" 
                    :fields="userfields"
                    sort-icon-left
                    tbody-tr-class="rowClassSm"
                    thead-tr-class="rowHClass"
                    @row-clicked="setAdmin($event)"
                    dark
                    >
                    <template #cell(selected_admin)="data">
                            <b-icon-check-circle style="color:3cd2a5;margin-left:17px;height:1.4em;width:1.4em"  v-if="data.item.value == admindetails.adminuser"/>
                    </template>  
                
                    </b-table>
    </b-modal>
    
    </b-container>
            
    </div> 
    </template>
    
    
    <script>
        import axios from 'axios'
        import { Validator } from 'vee-validate'
        import './customRule'
        export default {
            props:['passadmin'],
            name:'CasterAddSetAdmin',
            data(){
            return {
                    admindetails:{
                        fname:'',
                        lname:'',
                        email:'',
                        tel:'',
                        mobile:'',
                        password:'',
                        adminuser:0
                    },
                    adminusers:[],
                    modaltitle:'',
                    userfields:[
                    { key: 'fname', label: 'Forename', sortable: true  },
                    { key: 'lname', label: 'Surname', sortable: true  },
                    { key: 'roletitle', label: 'Role', sortable: true  },
                    { key: 'selected_admin', label: ' Selected', tdClass: 'text-left' }
                    ],
                    emailerror:false,
                    emailerror2:false
                    };
                    
                    },
    
            created(){
                
                    this.admindetails.fname = this.passadmin.fname
                    this.admindetails.lname = this.passadmin.lname
                    this.admindetails.email = this.passadmin.email
                    this.admindetails.mobile = this.passadmin.mobile
                    this.admindetails.tel = this.passadmin.tel
                    this.admindetails.adminuser = this.passadmin.adminuser
                    if(Array.isArray(this.passadmin.users)){
                        this.adminusers = this.passadmin.users;
                    }   
                    if(this.passadmin.password != ''){
                        this.admindetails.password = this.passadmin.password
                    } else this.createPassword()
              },
    
            mounted() {
                Validator.localize('en',{
                        messages: {
                        required: '** required.',
                        },
                })
            },            
    
           methods: {
    
                selectAdmin(){
                    this.modaltitle = "Select Main Administrator"
                    this.$refs['select-admin'].show()
                },
    
                setAdmin(event){
                    this.admindetails.fname = event.fname
                    this.admindetails.lname = event.lname
                    this.admindetails.email = event.email
                    this.admindetails.adminuser = event.value
                    this.$refs['select-admin'].hide()
                },
    
                createPassword(){
                    let pwdChars = "23456789ABCDEFGHJKMNPQRSTUVWXYZabcdefghjklmnpqrstuvwxyz"
                    let pwdLen = 8
                    this.admindetails.password = Array(pwdLen).fill(pwdChars).map(function(x) { return x[Math.floor(Math.random() * x.length)] }).join('')
                },
                
                checkAdminDetails(){
                    this.$validator.validateAll().then(result => {
                    if(result){
                        if (!this.isValidEmail(this.admindetails.email)) {
                        this.emailerror2 = true
                        return
                        } else {
                            this.emailerror2 = false
                            this.$emit('adminset',this.admindetails)  
                        }      
                       
                        }
                    })
                },
    
               validateField(field){
                    setTimeout(() => this.validateDebounced(field), 300);
                },
    
                validateDebounced(field){
                    this.$validator.validate(field)
                    if(field == 'admindetails.email'){this.emailCheck()}
                },
    
               emailCheck(){	
                    if(this.admindetails.email !== this.currentemail){
    
                    axios.get('/api/checkuserexists',{
                        headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                        params:{
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            email:this.admindetails.email
                        }
                    })
                    .then((response) => {
    
                        if(response.data.code == 999){
                            this.errorresponse = response.data
                        } else {
                    
                        this.emailerror2 = false    
                        if(response.data.exists == true){
                            this.emailerror = true
                            } else this.emailerror = false
                        }    
                    
                        });
    
                    }    
                },
    
                isValidEmail(email) {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    return emailRegex.test(email);
                }, 
            }	   
        
        };
    
    </script>