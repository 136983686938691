<template>
<div class="imageBorder">

        <b-row v-if="display">
            <b-col class="col-12 col-md-12 mt-1 mb-3 boxtext">SYSTEM STATUS</b-col>
        </b-row>

        <b-row class="boxtext mb-1" v-for="system in systems" :key="system.id">
            <b-col class="col-6" ><div class="statusbutton" :style="{ backgroundColor:castasync(system)  }"></div> Casta  {{ system.description }}  Sync</b-col>
            <b-col class="col-6" >{{ formatTime(system.lastcasterpoll) }}</b-col>

            <b-col class="col-6" ><div class="statusbutton" :style="{ backgroundColor:basesync(system)  }"></div> Casta  {{ system.description }}  Bases Sync</b-col>
            <b-col class="col-6" >{{ formatTime(system.lastbasepoll) }}</b-col>

            <b-col class="col-6" ><div class="statusbutton" :style="{ backgroundColor:connectasync(system)  }"></div> Connecta {{ system.description }} Sync</b-col>
            <b-col class="col-6" >{{ formatTime(system.lastconnectaviewapoll) }}</b-col>

            <b-col class="col-6" ><div class="statusbutton" :style="{ backgroundColor:ntripcheck(system)  }"></div> NTRIP {{ system.description }} Check</b-col>
            <b-col class="col-6" >{{ formatTime(system.last_ntripcheck) }}</b-col>
        </b-row>
        <b-row class="boxtext">
            <b-col class="col-6" ><div class="statusbutton" :style="{ backgroundColor:dataind2  }"></div> Daily Subs Expiry Check</b-col>
            <b-col class="col-6" >{{ formatTime(status.lastchecksubexpiry) }}</b-col>

            <b-col class="col-6" v-if="system == 0"><div class="statusbutton" :style="{ backgroundColor:dataind9  }"></div> Daily GDPR Check</b-col>
            <b-col class="col-6"  v-if="system == 0" >{{ formatTime(status.lastdailycheck) }}</b-col>
         </b-row>

         <b-row  class="boxtext mt-2" v-if="display"> 
          
            <b-col class="col-12 col-sm-6" v-if="system == 0" ><div class="statusbutton" :style="{ backgroundColor:dataind3  }"></div>UK Rovers timer</b-col>
            <b-col class="col-12 col-sm-6" ><div class="statusbutton" :style="{ backgroundColor:dataind12  }"></div> Denmark Rovers timer</b-col>

            <b-col class="col-12 col-sm-6" v-if="system == 0" ><div class="statusbutton" :style="{ backgroundColor:dataind15  }"></div>UK Bases timer</b-col>
            <b-col class="col-12 col-sm-6" ><div class="statusbutton" :style="{ backgroundColor:dataind16  }"></div> Denmark Bases timer</b-col>
            
            <b-col class="col-12 col-sm-6" v-if="system == 0"><div class="statusbutton" :style="{ backgroundColor:dataind13  }"></div> NTRIP UK check timer</b-col>
            <b-col class="col-12 col-sm-6" ><div class="statusbutton" :style="{ backgroundColor:dataind14  }"></div> NTRIP Denmark check timer</b-col>

            <b-col class="col-12 col-sm-6" ><div class="statusbutton" :style="{ backgroundColor:dataind11  }"></div> Connecta timer</b-col>
            <b-col class="col-12 col-sm-6"><div class="statusbutton" :style="{ backgroundColor:dataind4  }"></div> Subs expiry timer</b-col>
            <b-col class="col-12 col-sm-6" v-if="system == 0"><div class="statusbutton" :style="{ backgroundColor:dataind10  }"></div> GDPR timer</b-col>
            <b-col class="col-12 col-sm-6" ><div class="statusbutton" :style="{ backgroundColor:dataind8  }"></div> System Alarm timer</b-col>
            
            
        </b-row>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>     


</div>
</template>

<style scoped>

.statusbutton{
    width:15px;
    height: 15px;
    /* border-radius: 20px; */
    margin-top:4px;
    margin-right:20px;
    float: left;
}

</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'

    export default {
        props:['type'],
        name:'CasterSystemCheckCard',
        components:{
            ErrorLogging
        }, 
        data(){
        return {
                system:0,  // 0 - Both 1 - Denamrk Only
                systems:[],
                test:{},
                data:[],
                token:'',
                display:false,
                timeout:false,
                dataind1:'white',
                dataind2:'rgba(60, 210, 165, 0.65)',
                dataind3:'white',
                dataind4:'white',
                dataind5:'white',
                dataind6:'white',
                dataind7:'white',
                dataind8:'white',
                dataind9:'white',
                dataind10:'white',
                dataind11:'white',
                dataind12:'white',
                dataind13:'white',
                dataind14:'white',
                dataind15:'white',
                dataind16:'white',
                healthcheck:false,
                casterdbpool:false,
                casterdbpool2:false,
                casterbasepoll:false,
                casterbasepoll2:false,
                castecheckntrip:false,
                castercheckntrip2:false,
                checksubexpiry:false,
                dailycheck:false,
                status:{
                    polling:0,
                    lastcasterpollformat:'',
                    lastchecksubexpiryformat:'',
                    lastdailyroutinesformat:'',
                    },
                errorresponse:{}    
               };
                
                },

    mounted(){
                this.token = this.$store.getters.token
                // Set system to Denmark only if Denmark system admin
                if(this.$store.getters.user.role == 1 && this.$store.getters.user.business == 11){this.system = 2}
                this.getAlarmData()
                },

     beforeDestroy () {
            clearInterval(this.timeout)
        },                

    methods: {

                getAlarmData(){
                    axios.get('/api/checksystemstatustest',{
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        system:this.system
                    }
                })
                .then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {

                    this.systems =  response.data.systems  
                    this.status = response.data.system
                 
                    let now = moment(new Date())
                    let lastcasterpoll = (this.status.lastcasterpoll)
                   
                    let diff = moment.duration(now.diff(lastcasterpoll))
                    if(parseInt(diff.asSeconds()) > 20){this.dataind1 = 'red'} 
                    
                    diff = moment.duration(now.diff(this.status.lastchecksubexpiry))
                    if(parseInt(diff.asMinutes()) > 60){this.dataind2 = 'red'}

                    diff = moment.duration(now.diff(this.status.lastconnectaviewapoll))
                    if(parseInt(diff.asMinutes()) > 60){this.dataind11 = 'red'}

                    diff = moment.duration(now.diff(this.status.lastdailycheck))
                    if(parseInt(diff.asHours()) > 24){this.dataind9 = 'red'} else this.dataind9 = 'rgba(60, 210, 165, 0.65)'

                    diff = moment.duration(now.diff(this.status.laststatspoll))
                    if(parseInt(diff.asMinutes()) > 10){this.dataind7 = 'red'} else this.dataind7 = 'rgba(60, 210, 165, 0.65)'

                    this.healthcheck = response.data.healthcheck
                    this.casterdbpool = response.data.casterdbpool
                    this.casterdbpool2 = response.data.casterdbpool2
                    this.casterbasepoll = response.data.casterbasepoll
                    this.casterbasepoll2 = response.data.casterbasepoll2
                    this.casterntripcheck = response.data.casterntripcheck
                    this.casterntripcheck2 = response.data.casterntripcheck2
                    this.connectapoll = response.data.connectapoll
                    this.checksubexpiry = response.data.checksubexpiry
                    this.dailycheck = response.data.dailycheck
                    this.cpu = response.data.cpu
                    this.castartcm3 = response.data.casta.rtcm3
                    this.castaggas = response.data.casta.ggas
                    this.castarovers = response.data.casta.rovers

                    if(this.healthcheck == 1){this.dataind8 = 'rgba(60, 210, 165, 0.65)'} else this.dataind8 = 'red'
                    if(this.casterdbpool == 1){this.dataind3 = 'rgba(60, 210, 165, 0.65)'} else this.dataind3 = 'red'
                    if(this.casterdbpool2 == 1){this.dataind12 = 'rgba(60, 210, 165, 0.65)'} else this.dataind12 = 'red'
                    if(this.casterbasepoll == 1){this.dataind15 = 'rgba(60, 210, 165, 0.65)'} else this.dataind15 = 'red'
                    if(this.casterbasepoll2 == 1){this.dataind16 = 'rgba(60, 210, 165, 0.65)'} else this.dataind16 = 'red'
                    if(this.casterntripcheck == 1){this.dataind13 = 'rgba(60, 210, 165, 0.65)'} else this.dataind13 = 'red'
                    if(this.casterntripcheck2 == 1){this.dataind14 = 'rgba(60, 210, 165, 0.65)'} else this.dataind14 = 'red'
                    if(this.connectapoll == 1){this.dataind11 = 'rgba(60, 210, 165, 0.65)'} else this.dataind11 = 'red'
                    if(this.checksubexpiry == 1){this.dataind4 = 'rgba(60, 210, 165, 0.65)'} else this.dataind4 = 'red'
                    if(this.dailycheck == 1){this.dataind10 = 'rgba(60, 210, 165, 0.65)'} else this.dataind10 = 'red'
                    if(this.castartcm3 > 0){this.dataind5 = 'rgba(60, 210, 165, 0.65)'} else this.dataind5 = 'red'
                    if(this.castaggas > 0){this.dataind6 = 'rgba(60, 210, 165, 0.65)n'} else this.dataind6 = 'red'
                 
                    this.display = true

                    if(!this.timeout){this.timeout = setInterval(this.getAlarmData, 3000)}

                    }
                    
                    });
                },

                  formatTime(datetime){ 
                    let time =  moment(datetime).local().format('DD-MM-YY HH:mm:ss')
                    return time
                },

                castasync(system){
                    if(system.polling == 1){return 'orange'} else return 'rgba(60, 210, 165, 0.65)'
                },

                basesync(system){
                    if(system.basepolling == 1){return 'orange'} else return 'rgba(60, 210, 165, 0.65)'
                },

                connectasync(system){
                    if(system.connectaviewapoll == 1){return 'orange'} else return 'rgba(60, 210, 165, 0.65)'
                },

                ntripcheck(system){
                    let now = moment(new Date())
                    let diff = moment.duration(now.diff(system.last_ntripcheck))
                    if(parseInt(diff.asSeconds()) > 305){return 'red'} else return 'rgba(60, 210, 165, 0.65)'
                },
                
                
            }    

    };
</script>