<template>
<div>

    <h3 v-show="!ready">
        Loading
        <b-icon icon="arrow-clockwise" animation="spin"/>
    </h3>
    <div v-show="ready">
        <Editor
            ref='tinyRichText'
            :init="{
                base_url: '/tinymce', 
                suffix: '.min',
                skin_url: `/tinymce/skins/ui/Cloudbase`,
                plugins: 'link lists',
                toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
                license_key: 'gpl',
                body_class: 'editArea',
                content_css: 'CloudbaseModal',
            }"
            v-model="content"
            @input="emitContent"
        ></Editor>
    </div> 

</div>
</template>

<style>
/* note styling for the text window is its own html page and has its own css page, as well as a skin 
ie to change the background color change it in body within: /skins/content/CUSTOM/content.min.css
I to overwite some aspects ive gotten the specific selectors needed
*/

.tox-tinymce{
  border-radius: 5px  !important;
}
.tox .tox-edit-area__iframe{
    background-color: rgba(0, 0, 0, 0) !important;
}
.tox .tox-statusbar__resize-handle svg{
    fill:rgb(60, 210, 165) !important;

}
.editArea{
    color: rgb(255, 255, 255);
    white-space: pre-wrap;
}
</style>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
    name:'TinyModal',
    components:{Editor},
    props:{
        value:String
    },
    data() {
        return {
            content:"",
            domObserver:null,
            ready:false,
            observeFlags:{
                unwantedElements:true,
                readyOb:true,
            }
        }
    },
    created(){
        this.content = '<pre>' + this.value + '</pre>';
        this.observeDOM(); //need to wait till DOM has finished adding "tiny" elements, mounted doesnt work.
        setTimeout( () => { //have a backup timeout for ready, it's just for a cleaner loading experience.
            this.endObserver;
            this.ready = true; 
            this.observeFlags.readyOb = false;
        },(1000*10)); 
    },
    watch:{
        observeFlags:{
            handler(){
                if( Object.values(this.observeFlags).includes(true) ) return;
                this.endObserver();
            },
            deep:true,
        }
    },
    methods:{
        emitContent(eventContent){
            this.$emit('input', eventContent);
        },
        observeDOM(){
            this.domObserver = new MutationObserver( () => {
                this.cleanUpUnwantedElements();
                this.readyCheck();
            })
            this.domObserver.observe(document.body, {childList:true, subtree:true})
        },

        cleanUpUnwantedElements(){
            const upgradeButton = document.getElementsByClassName("tox-promotion")

            if(upgradeButton?.length > 0){
                upgradeButton[0].parentElement.removeChild(upgradeButton[0])
                this.observeFlags.unwantedElements = false;
            }
        },

        readyCheck(){
            //get iframe within tiny editor, which contains the template
            if(!this.$refs.tinyRichText?.element?.nextElementSibling)return;
            const iframe = this.$refs.tinyRichText?.element?.nextElementSibling?.querySelector('iframe'); 
            
            //get content from within iframe
            if(!iframe?.contentDocument?.body) return;
            
            const iframeBody = iframe.contentDocument.body
            if(!iframeBody?.firstChild || iframeBody.firstChild?.innerHTML?.length < 1)return;

            //set ready
            this.ready = true;        
            this.observeFlags.readyOb = false;    
        },
        
        endObserver(){
            this.domObserver.disconnect();
        }
    }  
} 
</script>