<template>
<div>

<div v-if="simms.length > 0 && !gettingdata">
    
    <b-row class="mt-3">
        <b-col class="col-6" v-if="totalsel == 0">
             select
        </b-col>
         <b-col class="col-6" v-else>
             {{ totalsel }} selected
        </b-col>

        <b-col class="col-6 text-right">
             {{ total }} result<span v-if="total != 1">s</span>
        </b-col>
    </b-row>

    <b-row class="mt-1" >
         <b-col class="col-2">
            <input type="checkbox" 
                v-model="selectall"
                value=true
                unchecked-value=false
                @change="selectedAll()"
                >
        </b-col>  
        <b-col class="col-10">All</b-col>
    </b-row>    
             
    <b-row class="mt-1" v-for="(item, index) in simms" :key="item.simId" >
         <b-col class="col-2">
            <input type="checkbox" 
                :id="index" 
                v-model="simms[index].value"
                value=true
                unchecked-value=false
                @change="selectedSim(index,item.value)"
                >
        </b-col>  
        <b-col class="col-5">{{ simms[index].iccid }}</b-col>
        <b-col class="col-5">{{ simms[index].endPointName }}</b-col>
       
    </b-row> 

    <b-row class="mt-3" v-if="selectwarning">
         <b-col class="col-12 text-center">
            <b-button variant="danger" disabled>No SIMs selected</b-button>
         </b-col>
    </b-row>
   
    <b-row class="mt-5 pt-3 border-top">
         <b-col class="col-12 col-sm-10 offset-1">
            <b-button variant="outline-success"  @click="update()" block>ADD SELECTED TO STOCK</b-button>
        </b-col>   

    </b-row> 

</div>

<div v-if="!gettingdata && simms.length == 0">
     <b-row class="mt-5 mb-5">
         <b-col class="col-12 text-center">
                    <h5 style="margin-top:10px"><strong>No new Sims</strong></h5>
        </b-col>  
      </b-row> 
</div>    

<div v-if="gettingdata">
     <b-row class="mt-5 mb-5">
         <b-col class="col-12 text-center">
                    <b-spinner class="align-middle"></b-spinner>
                    <h5 style="margin-top:10px"><strong>Getting live data</strong></h5>
        </b-col>  
      </b-row> 
</div>    

<b-modal ref="status-messages" size="lg" title="Actions" hide-footer centered content-class="darkmodal">
   <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row> 

   <b-row>
         <b-col class="col-12 mt-5">
        <b-button variant="outline-success" @click.stop="finishAdd()" block>Finish</b-button>
       </b-col>   
   </b-row>          

</b-modal>    

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
		
</div> 
</template>


<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        props:['reseller','dealer'],
        name:'CasterSimmBicsRefresh',
        components:{
            ErrorLogging
        }, 
        data(){
        return {
                token:'',
                simms:[],
                totalsel:0,
                selarray:[],
                gettingdata:false,
                dealername:'',
                errorresponse:{},
                stagemessages:[],
                selectwarning:false,
                selectall:false
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.fetchSimms()
       },
       
       methods: {

            
            fetchSimms(){
                this.simms = [] 
                this.gettingdata = true
				axios.get('/api/castersimmsbicsrefresh',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.reseller
                    }
                    })
				.then((response) => {
                    if(response.data.code == 999){
                       this.errorresponse = response.data
                      } else {
                        this.simms = response.data.sims
                        this.total = response.data.total
                        this.gettingdata = false
                        if(this.simms.length > 0){
                        this.simms.forEach((element) => {element.value = false})
                        }
                      }
                    })
                    
			},

              update(){
                    if (this.totalsel != 0) {
                      
                    let simms = this.simms.filter(simm => simm.value == true)
                    this.stagemessages.push({message:'Processing',text:'',error:false})
                    this.$refs['status-messages'].show()   
                  
                   axios({ 
                                method: 'post',     
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/casterbicssimmsaddstock',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    reseller:this.reseller,
                                    simms:simms
                                    }
                                }).then((response) => {

                                if(response.data.code == 999){
                                   this.errorresponse = response.data
                                } else {
                                    if(response.data.messages !== null){
                                    this.stagemessages = response.data.messages
                                    } else console.log(response.data)    
                                }
                    })
                      
                    } else this.selectwarning = true 
              },

           
              selectedSim(selindex,value){
                this.simms[selindex].value = value
                this.totalsel = this.simms.filter(simm=>simm.value == true).length
                if(this.totalsel > 0){this.selectwarning = false}  
              },

              selectedAll(){
                let value = true
                if(!this.selectall){value = false}
                this.simms.forEach(element => {
                    element.value = value
                });
                this.totalsel = this.simms.filter(simm=>simm.value == true).length
                if(this.totalsel > 0){this.selectwarning = false}  
              },

              finishAdd(){
                    this.$refs['status-messages'].hide()
                    this.$emit('updatemodal')
              }
      
		}	   
	
	};
</script> 