<template>
<div>
  <b-container class="mainpagecont" v-if="notauth">
    <b-row>
        <b-col class="col-12 col-sm-8 offset-sm-2 p-5">
            <b-button variant="danger" block disabled>UNAUTHORISED TO VIEW THIS RESOURCE</b-button>
        </b-col>
    </b-row>
</b-container>    

<b-container class="mainpagecont" v-if="display">

<b-row>
        <b-col class="col-12 col-sm-6 p-2"><h3>Dealer Overview<br/><span style="font-size:0.7em;color:rgba(255,255,255,0.7)"> {{ dealer.text }}</span></h3></b-col>
        <b-col class="col-12 col-sm-6 p-2 text-right" v-if="roleid == 1 || roleid == 5">
            <b-btn variant="outline-success" size="sm" @click="LoginAsDealer">LOGIN AS DEALER</b-btn>
        </b-col>
</b-row>

<b-row>

    <b-col class="col-12 col-sm-6 col-md-6 col-lg-3 mt-1 p-2 boxclickable" @click="menuLink('casterroverslogged')">
        <CasterRoversLoggedCard  />
    </b-col>

    <b-col class="col-12 col-sm-6 col-md-6 col-lg-3  mt-1 p-2 boxclickable" @click.stop="menuLink('casterreferencestations')">
        <CasterBasestationsCard  />
    </b-col> 

     <b-col class="col-12 col-sm-6 col-md-6 col-lg-3  mt-1 p-2 boxclickable" @click="menuLink('castersubscriptions')">
        <CasterSubsCard :dealerid="dealer.value"  /> 
    </b-col>

     <b-col class="col-12 col-md-6 col-lg-3 mt-1 p-2">
        <CasterSimmUsageCardAdmin />
    </b-col>
   
    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterLastEventsCard  />
   </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterShowLeafletRovers  :border="true" v-on:fullscreen="fullScreen"/>
    </b-col>

     <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterNetworkRTKChartCard  />
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterNetworkLoginsChartCard :dealer="dealer" /> 
    </b-col>

     <b-col class="col-12 col-lg-6 mt-1 p-2" >
        <CasterStockingCard :dealer="dealer" />
    </b-col>

    <b-col class="col-12 mt-1 p-2"><h5>Action History</h5></b-col>  
    <b-col class="col-12 mt-1 p-2" >
            <CasterRoverEventsCard :update="true" :dealerid="dealer.value" />
    </b-col> 
    
    <b-col class="col-12 mt-1 p-2"><h5>Companies</h5></b-col>  
    <b-col class="col-12 mt-1 p-2" >
        <div class="imageBorder">
            <CasterCompaniesTable 
                        :reseller="dealer.business" 
                        :dealerid="dealer.value" 
                        />
        </div>             
    </b-col> 

    <b-col class="col-12 mt-1 p-2"><h5>Rovers</h5></b-col>  
    <b-col class="col-12 mt-1 p-2" >
        <div class="imageBorder">
            <CasterRoversTable 
                :reseller="dealer.business" 
                :dealer="dealer.value" 
                :company=0 
                :key="componentKey"
                />
        </div>    
    </b-col> 

</b-row> 


<b-modal ref="company-edit" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
     <CasterDealerAdd
    :dealerid="dealer"
    nosave=1
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    v-on:updatedealer=updatemodal()
    />
</b-modal>   

<b-modal ref="full-screen" size="xl"  title="ROVERS MAP" @show="waitSetWidth" @hide="closeMap" hide-footer centered content-class="darkmodal2">
   <div ref="fullscreendiv" :style="{width:100+'%',height:500+'px'}">
        <CasterShowLeafletRovers v-if="modalmapwidth != 0"  :fsmapheight="modalmapheight" :fsmapwidth="modalmapwidth" :border="false"/>   
   </div>
</b-modal>


</b-container>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<script>
    import CasterRoversLoggedCard from './CasterRoversLoggedCard'
    import CasterBasestationsCard from './CasterBasestationsCard'
    import CasterSubsCard from './CasterSubsCard'
    import CasterSimmUsageCardAdmin from './CasterSimmUsageCardAdmin'
    import CasterLastEventsCard from './CasterLastEventsCard'
    import CasterShowLeafletRovers from './CasterShowLeafletRovers'
    import CasterNetworkRTKChartCard from './CasterNetworkRTKChartCard'
    import CasterNetworkLoginsChartCard from './CasterNetworkLoginsChartCard'
    import CasterStockingCard from './CasterStockingCard'
    import CasterRoverEventsCard from './CasterRoverEventsCard'
    
    import CasterDealerAdd from './CasterDealerAdd'
    import CasterRoversTable from './CasterRoversTable'
    import CasterCompaniesTable from './CasterCompaniesTable'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
   
	export default {
        name:'CasterDealer',
        components:{
            CasterRoversLoggedCard,
            CasterBasestationsCard,
            CasterSubsCard,
            CasterSimmUsageCardAdmin,
            CasterLastEventsCard,
            CasterShowLeafletRovers,
            CasterNetworkRTKChartCard,
            CasterNetworkLoginsChartCard,
            CasterStockingCard,
            CasterRoverEventsCard,
            CasterDealerAdd,
            CasterRoversTable,
            CasterCompaniesTable,
            ErrorLogging
        },    
        data(){
		return {
                    dealerid:0,
                    roleid:0,
                    dealer:{},
                    modaltitle:'',
                    errorresponse:{},
                    display:false,
                    notauth:false,
                    componentKey:0,
                    showmap:false,
                    modalmapwidth:0,
                    modalmapheight:485,
                };
                
                },

		created(){
            this.dealerid = this.$route.params.id
            this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            this.$store.commit('setcompanyid',0)
            this.fetchDealer()
        },

        mounted(){
            this.$store.commit('setbasesdatapoll',1)
            this.$store.commit('setroversdatapoll',1)
            this.$store.commit('setprevroute',this.$route.name)
        },

        beforeDestroy () {
            window.removeEventListener("resize",this.setModalWidth)
            //RB 2208 if(this.roleid == 1 || this.roleid == 5){this.$store.commit('setdealerid',0)}  // reset dealer if sysadmin or reseller admin
        },

		methods: {

            fetchDealer(){
                axios.get('/api/casterdealers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         reseller:this.$store.getters.resellerid,
                         dealer:this.dealerid,
                         id:this.$store.getters.dealerid
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                            if(response.data.CasterDealers.length == 0){
                                this.notauth = true
                            } else {
                                this.notauth = false
                                this.dealer = response.data.CasterDealers[0]
                                this.display = true
                                }
                            }
                        
                  });
			},

            showMap(){
                    this.showmap = true
                },

            fullScreen(){
                this.modalmapwidth = 0
                this.$refs['full-screen'].show()
            },

            waitSetWidth(){
                setTimeout(this.setModalWidth,150)
            },

            setModalWidth(){
                window.addEventListener("resize", this.setModalWidth)
                this.modalmapwidth = this.$refs['fullscreendiv'].clientWidth -20
            },

            closeMap(){
                window.removeEventListener("resize", this.setModalWidth)
                this.$refs['full-screen'].hide()
            },

            closemodal(){
                this.$refs['company-edit'].hide()
                this.$refs['rover-add'].hide()
            },

            updatemodal(){
                this.$refs['company-edit'].hide()
                this.$refs['rover-add'].hide()
                //this.fetchDealer()
            },
          
            menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },

            LoginAsDealer(){
                let branding = this.$store.getters.branding
                branding.logintitle = this.dealer.logintitle
                this.$store.commit('setbranding',branding)
                this.$store.commit('setloggedin',false)
            }
		}	   
	
	};

</script>