import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

//get user data from localstore
const userSearchSuggestions = localStorage.getItem('searchSuggestionList')
  ? JSON.parse(localStorage.getItem('searchSuggestionList'))
  : { list: []};

const store = new Vuex.Store({
        
    plugins: [createPersistedState({
        paths: ['searchvalues','searchvalues2','searchtext','resellerid','dealerid','companyid','countryid','version','devversion','dev','timezone','token','user','roleid','basesdata','mobile','loggedin','branding','maint'
       ,'changeusername','searchSuggestions','overlayElem','prevroute'],
      })],
          
           state: {
              token:'',
              user:{},
              alarms:[],
              resellerid:0,
              dealerid:0,
              companyid:0,
              countryid:1,  // 1 UK 2 DENMARK
              roleid:0,
              version:0,
              devversion:0,
              dev:1,
              timezone:0, // 0 - UK 1 - Europe
              roversdata:{},
              basesdata:{},
              lasteventsdata:[],
              chartdata:[],
              subsdata:{},
              basesdatapoll:0,
              basesdatapolling:false,
              roversdatapoll:0,
              roversdatapolling:false,
              systemcheckpoll:1,
              eventspoll:1,
              loggedin:false,
              openmenu:true,
              mobile:false,
              branding:'',
              maint:'',
              searchresults:[],
              searchvalues:[0,0,0,0],
              searchvalues2:[], // {type value}  types 1 - Rover RTK Status 2 - Substatus 
              searchtext:'',
              prevroute:'',
              changeusername:false,
              searchSuggestions: userSearchSuggestions,
              overlayElem: null,
          },
          mutations: {

              setresellerid(state, resellerid) {
                     state.resellerid = resellerid
              },

              setcountryid(state, countryid) {
                     state.countryid = countryid
              },

              setdealerid(state, dealerid) {
                     state.dealerid = dealerid
              },

              setcompanyid(state, companyid) {
                     state.companyid = companyid
              },

              setroleid(state, roleid) {
                     state.roleid = roleid
              },

              setversion(state, version) {
                     state.version = version
              },

              setdevversion(state, devversion) {
                     state.devversion = devversion
              },

              settimezone(state, timezone) {
                     state.timezone = timezone
              },

              setuser(state, user) {
                     state.user = user
              },

              setroversdata(state, roversdata) {
                     state.roversdata = roversdata
              },
  
              setbasesdata(state, basesdata) {
                     state.basesdata = basesdata
              },

              setlasteventsdata(state, lasteventsdata) {
                     state.lasteventsdata = lasteventsdata
              },

              setchartdata(state, chartdata) {
                     state.chartdata = chartdata
              },

              setsubsdata(state, subsdata) {
                     state.subsdata = subsdata
              },
  
              setbasesdatapoll(state, basesdatapoll) {
                     state.basesdatapoll = basesdatapoll
              },

              setroversdatapoll(state, roversdatapoll) {
                     state.roversdatapoll = roversdatapoll
              },

              setroversdatapolling(state, roversdatapolling) {
                     state.roversdatapolling = roversdatapolling
              },

              setbasesdatapolling(state, basesdatapolling) {
                     state.basesdatapolling = basesdatapolling
              },

              setsystemcheckpoll(state, systemcheckpoll) {
                     state.systemcheckpoll = systemcheckpoll
              },

              seteventspoll(state, eventspoll) {
                     state.eventspoll = eventspoll
              },
              
              settoken(state, token) {
                     state.token = token
              },

              setmaint(state, maint) {
                     state.maint = maint
              },
  
              setloggedin(state, loggedin) {
                     state.loggedin = loggedin
              },
  
              setopenmenu(state, openmenu) {
                     state.openmenu = openmenu
              },

              setmobile(state, mobile) {
                     state.mobile = mobile
              },
  
              setbranding(state, branding) {
                     state.branding = branding
              },
              setsearchresults(state, searchresults) {
                     state.searchresults = searchresults
              },

              setsearchtext(state, searchtext) {
                     state.searchtext = searchtext
              },

              setsearchvalues(state, searchvalues) {
                     state.searchvalues = searchvalues
              },

              setsearchvalues2(state, searchvalues2) {
                     state.searchvalues2 = searchvalues2
              },

              setalarms(state, alarms) {
                     state.alarms = alarms
              },

              setchangeusername(state, changeusername) {
                     state.changeusername = changeusername
              },

              setprevroute(state, prevroute) {
                     state.prevroute = prevroute
              },

              setsearchSuggestions(state, searchSuggestions) {
                     state.searchSuggestions = searchSuggestions;
                     localStorage.setItem("searchSuggestionList", JSON.stringify(state.searchSuggestions));
              },

              setoverlayElem(state, overlayElem) {
                state.overlayElem = overlayElem;
              },
  
         },
          getters: {

              resellerid (state) {
                     return state.resellerid
              },

              dealerid (state) {
              return state.dealerid
              },

              companyid (state) {
              return state.companyid
              },
       
              countryid (state) {
              return state.countryid
              },

              roleid (state) {
              return state.roleid
              },

              version (state) {
              return state.version
              },

              devversion (state) {
              return state.devversion
              },

              dev (state) {
                     return state.dev
                     },

              timezone (state) {
              return state.timezone
              },

              user (state) {
              return state.user 
              },

              maint (state) {
              return state.maint       
              },
  
              basesdata (state) {
              return state.basesdata 
              },

              lasteventsdata (state) {
                     return state.lasteventsdata 
              },

              chartdata (state) {
                     return state.chartdata 
              },

              subsdata (state) {
                     return state.subsdata 
              },
  
              basesdatapoll (state) {
              return state.basesdatapoll 
              },

              roversdatapoll (state) {
                     return state.roversdatapoll 
              },

              roversdatapolling (state) {
                     return state.roversdatapolling 
              },

              basesdatapolling (state) {
                     return state.basesdatapolling 
              },

              systemcheckpoll (state) {
                     return state.systemcheckpoll 
              },

              eventspoll (state) {
                     return state.eventspoll 
              },
  
              roversdata (state) {
              return state.roversdata
              },

              token (state) {
                      return state.token
              },
  
              loggedin (state) {
                      return state.loggedin
              },
  
              openmenu (state) {
                      return state.openmenu
              },

              mobile (state) {
                      return state.mobile
              },
  
              branding (state){
              return state.branding
              },

              searchresults(state) {
                     return state.searchresults
              },

              searchtext(state) {
                     return state.searchtext
              },

              searchvalues(state) {
                     return state.searchvalues
              },

              searchvalues2(state) {
                     return state.searchvalues2
              },

              alarms(state) {
                     return state.alarms
              },

              changeusername(state) {
                     return state.changeusername
              },

              prevroute(state) {
                     return state.prevroute
              },

              searchSuggestions(state) {
                     return state.searchSuggestions
              },

              overlayElem(state) {
                     return state.overlayElem;
              }
  
          }
      })

export default store