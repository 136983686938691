<template>
  <div>
    <b-input-group>
      <b-input-group-prepend is-text>
        <b-icon-search font-scale="0.8" style="color:white" />
      </b-input-group-prepend>

      <b-form-input id="darksearch" type="search" size="sm" v-model="searchtext" @keyup="searchDealer()"
        @input="clearSearch()" autocomplete="off" placeholder="" class="darksearch" />

    </b-input-group>

  </div>
</template>

<script>
export default {
  props: ['setsearchtext'],
  data() {
    return {
      searchtext: '',
    };
  },
  created() {
    // RB turn off ayto fill
    //   if(this.$store.getters.searchtext != ''){
    //     this.searchtext = this.$store.getters.searchtext
    //     this.$emit('textsearch',this.searchtext)
    // }
  },

  watch: {
    setsearchtext: function () {
      this.searchtext = this.setsearchtext
      this.$store.commit('setsearchtext', this.searchtext)
    },
  },

  methods: {


    searchDealer() {

      if (this.debouncedSearchTimer) { clearTimeout(this.debouncedSearchTimer); }

      this.debouncedSearchTimer = setTimeout(() => {
        this.$store.commit('setsearchtext', this.searchtext)
        this.$emit('textsearch', this.searchtext)
      }, 200);

    },

    clearSearch() {
      if (this.searchtext == '') {
        this.$store.commit('setsearchtext', this.searchtext)
        this.$emit('clearsearch')
      }
    },
  }

};

</script>