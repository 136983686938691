<template>
<div>
<b-container>

<b-row>

    <b-col class="col-12 col-md-6 col-lg-3 mt-3">
        <label>User Role</label>
        <b-form-select
                    v-model="rolesel"
                    :options="setRoleTypes"
                    class="darkinput2"
                    size="sm"
                    @change = "setRole" 
                    />
    </b-col>

    <b-col class="col-2 mt-3">
        <b-button variant="outline-success" size="sm" @click="resetSearch()" style="margin-top:32px"> reset search </b-button>
    </b-col>

</b-row>

<b-row class="mt-3">
        <b-col class="col-12 col-md-5 mt-3" v-if="total > limit">1 - {{ rend }} of {{ total }} Results</b-col>
        <b-col v-else class="col-12 col-md-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>
</b-row> 

<b-row class="mt-2">

      <b-col class="col-12">
             <b-table
                class ="nowrap"
                ref = "users-table" 
                responsive 
                hover
                sticky-header="600px" 
                :items="displayusers" 
                :fields="conditionalFields"
                :sort-by="sortBy"
                @head-clicked="onSortChanged"
                :no-local-sorting="nosort"
                :busy="isBusy"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                dark
                >
                <template #cell(show_details)="data">
                    <b-button size="sm" @click="deleteUser(data.item.value)" class="mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                    <b-button size="sm" @click="editUser(data.item.value)" class="mr-2 xsbutton" variant="outline-success">
                        <b-icon-pencil />
                    </b-button>
                </template>  

                </b-table>

                <b-button v-if="more" variant="outline-success" block @click.stop="showMore()">Show more results?</b-button>
                <b-button v-if="loadingmore" variant="outline-warning" disabled block>Loading results</b-button>          
    
       </b-col> 

</b-row>

<b-row class="mt-3" v-if="users.length == 0 && !isBusy">
      <b-col class="col-12 text-center pt-3 pb-3">
          <h5>No Users Found</h5>
       </b-col>   
</b-row>

<b-modal v-model="isBusy" hide-header hide-footer centered content-class="loadingmodal">
        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <b-spinner variant="success" class="mr-2"/>
                <h5>loading results</h5>
            </b-col>
        </b-row> 
</b-modal>

<b-modal ref="edit-user" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
     <CasterUserAdd
        :userid="usersel"
        v-on:closemodal=closemodal()
        v-on:updatemodal=updatemodal() 
        />
</b-modal>

<b-modal ref="delete-user" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
     <CasterUserDelete
        :id="usersel"
        v-on:closemodal=closemodal()
        v-on:updatemodal=updatemodal() 
        />
</b-modal>   

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-container>	
</div> 
</template>

<script>
    import CasterUserAdd from './CasterUserAdd'
    import CasterUserDelete from './CasterUserDelete'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        props:['resellerid','dealerid','companyid','clearsearch','stext'],
        name:'CasterUsersTable2', 
        components:{
            CasterUserAdd,
            CasterUserDelete,
            ErrorLogging
        },
		data(){
		return {
			token:'',
            users:[],
            displayusers:[],
            fields:[
                { key: 'title', label: 'Name', sortable: true  },
                { key: 'resellertitle', label: 'Reseller', sortable: true  },
                { key: 'dealertitle', label: 'Dealer', sortable: true  },
                { key: 'companytitle', label: 'Company', sortable: true  },
                { key: 'roletitle', label: 'Role', sortable: true  },
                { key: 'last_login', label: 'Last Active', formatter: 'formatLogin', sortable: true  },
                { key: 'show_details', label: ' Actions' , thClass: 'text-center'}
                ],
            roletypes:[],
            searchtext:'',
            page:0,
            limit:50,
            total:0,
            rstart:1,
            rend:0,
            orderby:'rover',
            sortdesc:false,
            sortBy:'title',
            usersel:0,
            rolesel:0,
            roleid:0,
            modaltitle:'',
            isBusy:true,
            errorresponse:{},
            more:false,
            nosort:false,
            loadingmore:false,
            observer: null,
            filtertype:3 // User status

        };
		
		},
		created(){
			this.token = this.$store.getters.token
            this.roleid = this.$store.getters.roleid
            this.rolesel = this.getTableFilter(this.filtertype)

            this.$store.commit('setprevroute',this.$route.name)
            this.searchtext = ''
            this.$store.commit('setsearchtext','')

            this.fetchUsers()
       },

       mounted(){
            this.addTableEventList()
        },

        computed: {
            
            filterdata() {
                return { ...this.resellerid, ...this.dealerid, ...this.companyid, ...this.roverid };
            },

            conditionalFields() {

                    if(this.rolesel == 1 || this.rolesel == 5){ // systemadmin reseller admin
                        return this.fields.filter(field => field.key !== 'dealertitle' && field.key !== 'companytitle')
                    } 

                    if(this.rolesel == 10){ // dealer admin
                        return this.fields.filter(field => field.key !== 'companytitle')
                    } 

                    if((this.resellerid > 0 && this.dealerid == 0 && this.companyid == 0)){
                        return this.fields.filter(field => field.key !== 'resellertitle')
                    } 

                    if(this.dealerid > 0 && this.companyid == 0){
                        return this.fields.filter(field => field.key !== 'dealertitle' && field.key !== 'resellertitle')
                    }

                    if(this.companyid > 0){
                        return this.fields.filter(field => field.key !== 'companytitle' && field.key !== 'dealertitle' && field.key !== 'resellertitle')
                    }

                    return this.fields

                    },

             setRoleTypes(){
                if(this.dealerid > 0 && this.companyid == 0){
                    return [
                    { value:0, text:'All'},
                    { value:10, text:'Dealer Admin'},
                    { value:20, text:'Company Admin'},
                    { value:30, text:'Company User'},
                ] 
                } else 
                if(this.dealerid > 0 && this.companyid > 0){
                    return [
                    { value:0, text:'All'},
                    { value:20, text:'Company Admin'},
                    { value:30, text:'Company User'},
                ] 
                } else 
                if(this.$store.getters.roleid == 1){
                return [
                    { value:0, text:'All'},
                    { value:1, text:'System Admin'},
                    { value:5, text:'Reseller Admin'},
                    { value:10, text:'Dealer Admin'},
                    { value:20, text:'Company Admin'},
                    { value:30, text:'Company User'},
                ]
                } else
                return [
                    { value:0, text:'All'},
                    { value:1, text:'Reseller Admin'},
                    { value:10, text:'Dealer Admin'},
                    { value:20, text:'Company Admin'},
                    { value:30, text:'Company User'},
                ]  
             }      
        },

         watch: {
                    filterdata: function () {
                        this.page = 0
                        this.rolesel = 0
                        this.fetchUsers()
                    },

                    clearsearch: function () {
                        this.page = 0
                        this.fetchUsers()
                    },
                   
                    stext: function () {
                        if(this.stext !== this.searchtext){
                            this.searchtext = this.stext
                            this.page = 0
                            this.fetchUsers()
                        }
                         },
                         
                    users: function() {
                        if(this.page > 0){
                            this.readdTableEventList
                        }
                    }
        }, 

		methods: {

            updatePaging(){
                if(this.page == 0){
                    this.rstart = 1
                    this.rend = this.limit
                } else {
                this.rend = ((this.page + 1) * this.limit)
                if(this.rend > this.total){this.rend = this.total}
                } 
            },

            addTableEventList(){
                const tableScrollBody = this.$refs["users-table"].$el
                tableScrollBody.addEventListener("scroll", this.onScroll)
            },

            removeTableEventList(){
                const tableScrollBody = this.$refs["users-table"].$el
                tableScrollBody.removeEventListener("scroll", this.onScroll)
            },

            readdTableEventList(){
                this.$nextTick(() => {    
                    this.removeTableEventList()
                    this.addTableEventList()
                })    
            },

            showMore(){
                this.page++
                this.more = false
                this.loadingmore = true
                this.fetchUsers()
            },

            onScroll(event) {
                if (
                    event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 200
                ) {
                    if (!this.isBusy) {
                        if(this.total > this.limit){
                            this.more = true
                        } else this.removeTableEventList()
                    }
                }
            },

            // handle column sorting events to only call API when there is more results
            onSortChanged(field,event){
              
              if(event.sortable){
                  if(this.nosort){
                      this.orderby = field
                      this.sortdesc = this.$refs['users-table'].localSortDesc !== true
                      this.page = 0
                      this.fetchUsers()
                  } 
              }
          },


            resetSearch(){
                this.searchtext = ''
                this.$store.commit('setsearchtext','')
                this.fetchUsers()
                this.rolesel = 0
                this.$emit('clearstext')
            },

            setRole(){
                this.page = 0
                this.storeTableFilter(this.rolesel,this.filtertype)
                this.fetchUsers()
            },

            storeTableFilter(value,type){
                let searchvalues = this.$store.getters.searchvalues2.filter(value=>value.type != type) // clear previous value
                searchvalues.push({type:type,value:value})
                this.$store.commit('setsearchvalues2',searchvalues)
            },

            getTableFilter(type){
                let searchvalues = this.$store.getters.searchvalues2.filter(value=>value.type == type)
                if(searchvalues.length > 0){
                    return searchvalues[0].value
                } else return 0
            },

            
            formatLogin(date){
                if(date == '01-01-1970 01:00'){return '-'} else return date
            },

			fetchUsers(){
                this.isBusy = true
              
				axios.get('/api/casteruserssort',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         stext:this.searchtext,
                         rolesel:this.rolesel,
                         reseller:this.resellerid,
                         dealer:this.dealerid,
                         company:this.companyid,
                         roleid:this.roleid,
                         page:this.page,
                         limit:this.limit,
                         orderby:this.orderby,
                         sortdesc:this.sortdesc
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                        this.more = false
                        this.loadingmore = false 
                        if(this.page == 0){
                            this.users = response.data.users
                        } else this.users.push(...response.data.users)

                        this.displayusers = this.users

                        this.updatePaging()
                        
                        this.total = response.data.total
                        if(this.total < this.limit){
                            this.more = false
                            this.nosort = false
                        } else this.nosort = true

                        if(this.total != 0 && this.stext != ''){
                            this.$emit('sendFetchResponse',this.stext)
                        }

                            this.isBusy = false
                        }
                    
                    });
			},

           editUser(id){
               this.usersel = id
               this.modaltitle = 'Edit User'
               this.$refs['edit-user'].show()
           },

           deleteUser(id){
               this.usersel = id
               this.modaltitle = 'Delete User'
               this.$refs['delete-user'].show()
           },

            updatemodal(){
               this.$refs['delete-user'].hide() 
               this.$refs['edit-user'].hide()
               this.fetchUsers()
           },

           }
	
	}

</script> 