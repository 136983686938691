<template>
<div>
<b-container>

<transition name="slide"> 

   <div v-show="isPanelOpen" class="sidebar-panel"  :style="{backgroundColor:color}">

    <b-row>
        <b-col class="col-12">  
            <h6>RESELLER ADMIN</h6>
        </b-col>
    </b-row>              
      
    <b-row class="mt-3">

    <b-col class="col-12">

      
    <ul class='nav'>
                 <li v-bind:class="[{ submenuactive: route === 'casterresellerhome'},'dropdown_item-1']" @click="menuLink('casterresellerhome')">
                    <b-icon-house v-bind:class="[{ sidemenuiconsel: homeActive }, 'sidemenuicon']" />Home</li>

                <li class="dropdown">
                    <a class="dropdown-toggle"  data-toggle="dropdown" href="#" id="network" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleSubMenuIcons(1)">
                    <b-icon-diagram-2 v-bind:class="[{ sidemenuiconsel: networkActive }, 'sidemenuicon']" />Network
                    </a>
                    <ul class='dropdown-menu'>
                        <li v-bind:class="[{ submenuactive: route === 'casternetworkdisplay'},'dropdown_item-1']" @click.stop="menuLink('casternetworkdisplay')">Display</li>
                        <li v-bind:class="[{ submenuactive: route === 'casterroverslogged'},'dropdown_item-2']" @click.stop="menuLink('casterroverslogged')">Logged Rovers</li>
                        <li v-bind:class="[{ submenuactive: route === 'casterreferencestations'},'dropdown_item-3']" @click.stop="menuLink('casterreferencestations')">Reference Stations</li>
                      </ul>
                </li>

                <li class="dropdown">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleSubMenuIcons(2)">
                    <b-icon-people v-bind:class="[{ sidemenuiconsel: usersActive }, 'sidemenuicon']" />Manage</a>
                    <ul class='dropdown-menu'>
                        <li v-if="issystemadmin" v-bind:class="[{ submenuactive: route === 'castersubscriptionsstockreselle'},'dropdown_item-5']" @click.stop="menuLink('castersubscriptionsstockreseller')">Stocking</li>
                        <li v-bind:class="[{ submenuactive: route === 'casterusers'},'dropdown_item-1']" @click.stop="menuLink('casterusers')">Users</li>
                        <li v-bind:class="[{ submenuactive: route === 'casterdealers'},'dropdown_item-1']" @click.stop="menuLink('casterdealers')">Dealers</li>
                        <li v-bind:class="[{ submenuactive: route === 'castercompanies'},'dropdown_item-2']" @click.stop="menuLink('castercompanies')">Companies</li>
                        <li v-bind:class="[{ submenuactive: route === 'casterrovers'},'dropdown_item-3']" @click.stop="menuLink('casterrovers')">Rovers</li>
                        <li v-bind:class="[{ submenuactive: route === 'castersubscriptions'},'dropdown_item-4']" @click.stop="menuLink('castersubscriptions')">Subscriptions</li>
                        <li v-bind:class="[{ submenuactive: route === 'castersimms'},'dropdown_item-5']" @click.stop="menuLink('castersimms')">SIM's</li>
                        <li v-if="issystemadmin" v-bind:class="[{ submenuactive: route === 'casterbases'},'dropdown_item-5']" @click.stop="menuLink('casterbases')">Basestations</li>    
                    </ul>
                </li>

                <!-- System admin Reseller user -->
                <li class="dropdown" v-if="issystemadmin">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleSubMenuIcons(5)">
                    <b-icon-gear-fill v-bind:class="[{ sidemenuiconsel: sysadminActive }, 'sidemenuicon']" />System Admin</a>
                    <ul class='dropdown-menu'>
                        <li v-bind:class="[{ submenuactive: route === 'casterusersadmin'},'dropdown_item-1']" @click.stop="menuLink('castersystemstatus')">System Status</li>
                    </ul>
                </li>
                
                
                <li class="dropdown">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleSubMenuIcons(3)">
                    <b-icon-list-task v-bind:class="[{ sidemenuiconsel: logsActive }, 'sidemenuicon']" />Logs</a>
                    <ul class='dropdown-menu'>
                        <li v-bind:class="[{ submenuactive: route === 'casteremails'},'dropdown_item-5']" @click.stop="menuLink('casteremails')">Email Log</li>
                        <li v-bind:class="[{ submenuactive: route === 'castereventlogreseller'},'dropdown_item-5']" @click.stop="menuLink('castereventlogreseller')">System Log</li>
                         </ul>
                </li>

                <li class="dropdown">
                    <a class="dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" @click="toggleSubMenuIcons(4)">
                    <b-icon-graph-up v-bind:class="[{ sidemenuiconsel: statsActive }, 'sidemenuicon']" />Statistics</a>
                    <ul class='dropdown-menu'>
                        <li v-bind:class="[{ submenuactive: route === 'casterstatsnetworklogins'},'dropdown_item-1']" @click.stop="menuLink('casterstatsnetworklogins')">Network Logins</li>
                        <li v-bind:class="[{ submenuactive: route === 'casterstatsntripclients'},'dropdown_item-2']" @click.stop="menuLink('casterstatsntripclients')">NTRIP Clients</li>
                    </ul>
            </li>
            <li class='logout' @click.stop="userprofile()"><b-icon-person class="sidemenuicon" />User Profile</li>
            <li class='logout' @click.stop="logout()"><b-icon-box-arrow-right class="sidemenuicon" />Logout</li>
          </ul>

        </b-col>
        </b-row> 

        <b-row>
            <b-col class="col-12">
            <CasterAlerts @setActiveIcon="menuLink" @alertsButtonEl="updateAlertsButtonEl"/>
            </b-col>
        </b-row>    

        <b-row class="mt-3" v-show="!hideQuickLinks">
             <b-col class="col-12 mt-3"><h6>QUICK LINKS</h6></b-col>
            
           <b-col class="col-12 mt-3">
                <b-button size="sm" variant="outline-success" block @click="newDealer()" class="quicklinkbutton">
                    <b-icon-plus-circle style="margin-right:10px" />  Add Dealer
                </b-button>
            </b-col>

            <b-col class="col-12 mt-3">
                <b-button size="sm" variant="outline-success" block @click="newCompany()" class="quicklinkbutton">
                  <b-icon-plus-circle style="margin-right:10px" />   Add Company
                </b-button>
            </b-col>

            <b-col class="col-12 mt-3">
                  <b-button size="sm" variant="outline-success" block  @click.stop="addRover()" class="quicklinkbutton">
                    <b-icon-plus-circle style="margin-right:10px" /> Add Rover
                  </b-button>
            </b-col>

            <b-col class="col-12 mt-3">
                  <b-button ref="stockingQLBtn" size="sm" variant="outline-success" block  @click.stop="menuLink('castersubscriptionsstockreseller')" class="quicklinkbutton">
                    <b-icon-bar-chart-fill style="margin-right:10px" /> Stocking
                  </b-button>
            </b-col>

            <b-col class="col-12 mt-3" v-if="user.role == 1 && user.business == 1">
                  <b-button size="sm" variant="outline-success" block  @click.stop="LoginAsSystemAdmin()" class="quicklinkbutton">
                    <b-icon-person style="margin-right:10px" /> System Admin
                  </b-button>
            </b-col>
            
        </b-row>     
   </div>

</transition>

<b-modal ref="rover-add" size="lg" hide-header hide-footer centered content-class="darkmodal">
   <CasterRoverAdd
    :roverid = 0
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    />
</b-modal> 

<b-modal ref="edit-dealer" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterDealerAdd
    :dealerid=0
    nosave=1
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    v-on:updatedealer=updatemodal()
    />
</b-modal>

<b-modal ref="edit-company" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
   <CasterCompanyAdd
    :companyid=0
    nosave=1
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal()
    v-on:updatecompany=updatemodal()
    />
</b-modal>

<b-modal ref="edit-profile" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
     <CasterUserProfile
    :userid="user.id"
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal() 
    />
</b-modal> 

</b-container>

</div>
</template>

<script>
    import CasterRoverAdd from './CasterRoverAdd'
    import CasterDealerAdd from './CasterDealerAdd'
    import CasterCompanyAdd from './CasterCompanyAdd'
    import CasterUserProfile from './CasterUserProfile'
    import CasterAlerts from './CasterAlerts'
    import axios from 'axios'
    import { mapState } from 'vuex'
    export default {
        name:'SideMenuCasterReseller',
        components:{
            CasterRoverAdd,
            CasterDealerAdd,
            CasterCompanyAdd,
            CasterUserProfile,
            CasterAlerts
        },
        props: ['width','color'],
        data() {
            return {
                isPanelOpen: true,
                route:'',
                panelbg:'red',
                homesel:true,
                networksel:false,
                userssel:false,
                statssel:false,
                settingssel:false,
                adminsel:false,
                sysadminsel:false,
                logssel:false,
                modaltitle:'',
                hideQuickLinks:false,
                menuopen:0,
                menulast:0,
                menuBodyListener:false,
                menuActiveIcon:null,
                user:{},
                issystemadmin:false
            }
        },     
        created(){
            this.user = this.$store.getters.user
            if(this.user.role == 1){this.issystemadmin = true}
            this.mobile = this.$store.getters.mobile
            this.logo = this.$store.getters.branding.logo
            this.isPanelOpen = !this.mobile     //  start with closed menu if mobile
            this.route =  this.$route.name
        },

        mounted(){
            this.setActiveIcon(this.route)
        },


        computed:{
            homeActive(){
               
            if(this.homesel){
                return 'sidemenuiconsel'
             } else return null  
            }, 
            usersActive(){
            if(this.userssel){
                return 'sidemenuiconsel'
             } else return null  
            }, 
            networkActive(){
            if(this.networksel){
                return 'sidemenuiconsel'
                }  else return null    
            },
            statsActive(){
            if(this.statssel){
                return 'sidemenuiconsel'
                }  else return null    
            },
            settingsActive(){
            if(this.settingssel){
                return 'sidemenuiconsel'
                }  else return null   
            },  
            adminActive(){
            if(this.adminsel){
                return 'sidemenuiconsel'
                }  else return null    
            },
            sysadminActive(){
                if(this.sysadminsel){
                return 'sidemenuiconsel'
                }  else return null    
            },
            logsActive(){
            if(this.logssel){
                return 'sidemenuiconsel'
                }  else return null    
            },    
            
            ...mapState(['openmenu'])
        },

        
        watch: {
            openmenu: function () {
                 this.swidth = window.innerWidth  
                 if(this.swidth < 1200){
                    this.isPanelOpen = this.openmenu
                 } else this.isPanelOpen = true
                
                //Disable scrolling if menu is in small screen size with open panel
                if(this.isPanelOpen && this.swidth <= 800){
                    document.body.style.overflow = 'hidden';
                    document.body.style.height = '100%';
                    document.body.style.width = '100%';
                }else{
                    document.body.style.overflow = '';
                    document.body.style.height = '';
                    document.body.style.width = '';
                 }  
            },
            // RB 16/2/24
            // openmenu: function () {
            //    if(this.$store.getters.mobile == true){this.panelbg = 'black'} else this.panelbg = 'transparent'
            //    this.isPanelOpen = this.openmenu
            //     },
        }, 
        
        methods: {
            //JM - Icon highlighting & quick link logic-------------------------------------------//
            subMenuBodyClose(){          
                //only act if there is a listener
                if(this.menuBodyListener){
                    //remove the listener and reset the flag
                    document.body.removeEventListener("click", this.subMenuBodyClose);
                    this.menuBodyListener = false;

                    //flips icons to corret highlight
                    this.subMenuIconReset(this.menuActiveIcon);
                }
            },

            subMenuAddBodyListnerForClosing(){  
                //we need to turn the icon back to grey if the user closed the menu by clicking outside the dropdown
                //flag to prevent multiple listeners being added
                
                if(!this.menuBodyListener){
                    //time out for debounce the first time you use, the click event from the opening menu triggers this event which we dont want
                    setTimeout( () => { 
                        document.body.addEventListener("click", this.subMenuBodyClose);
                    },0)

                    this.menuBodyListener = true;  
                }
            },

            subMenuIconReset(activeTracker = this.menuActiveIcon, ){
                //turn all icons grey
                this.homesel = false;
                this.networksel = false;
                this.userssel = false;  //manage
                this.logssel = false;
                this.statssel = false;
                this.sysadminsel = false;

                //reset menulast used when user clicks to open and to close
                this.menulast = 0;
                //enable Active Icon
                switch(activeTracker){
                    case 0:
                    this.homesel = true;
                    break;  
                    case 1:
                    this.networksel = true;
                    break;  
                    case 2:
                    this.userssel = true;
                    break; 
                    case 3:
                    this.logssel = true;
                    break;
                    case 4:
                    this.statssel = true;
                    break; 
                    case 5:
                    this.sysadminsel = true;
                    break; 
                }   

                //Show quick lings again
                this.hideQuickLinks = false;
            },

            toggleSubMenuIcons(menu){
                //if listener exists, remove it
                if(this.menuBodyListener){
                    document.body.removeEventListener("click", this.subMenuBodyClose);
                    this.menuBodyListener = false;
                }

                //if user opens and closes menu by clicking the same dropdown
                if(this.menulast === menu){
                    this.subMenuIconReset();
                    return;

                //if user clicks another menu or the page, closing the first menu
                }else{
                    //listen for closing the submenu by clicking elsewhere
                    this.subMenuAddBodyListnerForClosing();
                    
                    //reset usinng the menu clicked
                    this.subMenuIconReset(menu);
                    this.menulast = menu;
                    this.hideQuickLinks = true;
                }
            },
            
            setActiveIcon(route){
                this.homesel = false
                this.networksel = false
                this.userssel = false
                this.statssel = false 
                this.settingssel = false   
                this.adminsel = false
                this.sysadminsel = false
                this.logssel = false
                
                if(this.isPanelOpen && this.$refs.stockingQLBtn){
                    this.$refs.stockingQLBtn.classList.remove("quicklinkbuttonFakeActive");
                }
                if(this.alertsButtonElement){
                    this.alertsButtonElement.classList.remove("alertsBtnFakeActive");
                }

                switch(route){
                    case 'casterdealer':
                    case 'casterresellerhome':    
                        this.homesel = true;
                        this.menuActiveIcon = 0;
                        break;
                    case 'casternetworkdisplay':
                    case 'casterroverslogged':  
                    case 'casterreferencestations':    
                        this.networksel = true;
                        this.menuActiveIcon = 1;
                        break;
                    case 'casterusers': 
                    case 'casterdealers':  
                    case 'castercompanies':
                    case 'casterrovers': 
                    case 'castersubscriptions':  
                    case 'casterdealersimms': 
                    case 'castersimms':  
                        this.userssel = true;
                        this.menuActiveIcon = 2;
                        break;
                    case 'casterdealeremails':
                    case 'castereventlogreseller':
                    case 'casteremails':
                        this.logssel = true;
                        this.menuActiveIcon = 3;
                        break;
                    case 'castergdpr':     
                        if(this.isPanelOpen && this.alertsButtonElement){
                            this.menuActiveIcon = null;               
                            this.alertsButtonElement.classList.add("alertsBtnFakeActive");
                        }
                        break;
                    case 'casterstatsnetworklogins':
                    case 'casterstatsntripclients':
                    case 'casterstatssubs':
                        this.statssel = true;
                        this.menuActiveIcon = 4;
                        break;       
                    case 'castersubscriptionsstockreseller':
                        if(this.isPanelOpen && this.$refs.stockingQLBtn){
                            this.menuActiveIcon = null;
                            this.$refs.stockingQLBtn.classList.add("quicklinkbuttonFakeActive");
                        }
                        break;
                    default:
                        this.menuActiveIcon = null;               
                }
            },

            menuLink(name){
                //caled when you click a link in a menu
                if(name == 'casternetworkdisplay'){
                    this.networksel = true;
                    this.menuopen = 0;
                    this.hideQuickLinks = true; 
                }

                if(name == 'casterresellerhome'){
                    this.homesel = true;
                    this.menuopen = 0;
                    this.hideQuickLinks = false;
                }    

                if(this.$store.getters.mobile){
                    this.$store.commit('setopenmenu',false) //  close menu on item select if mobile    
                }   
            
                this.$emit('linkname',name)
                this.route = name
                this.setActiveIcon(name)
              
            },

            updateAlertsButtonEl(element){
                this.alertsButtonElement = element;
            },
            //JM - Icon & quicklink logic end-----------------------------------------------------//

            userprofile(){
                this.modaltitle = 'Edit Profile'
                this.$refs['edit-profile'].show()
            }, 

            addRover(){
                this.modaltitle = 'New Rover'
                this.$refs['rover-add'].show()
            },

             newDealer(){
                this.dealerid = 0 
                this.modaltitle = 'Add Dealer'
                this.$refs['edit-dealer'].show() 
            },

            newCompany(){
                this.modaltitle = 'Add Company'
                this.$refs['edit-company'].show() 
            },

            updatemodal(){
                if(this.$refs['edit-dealer']) this.$refs['edit-dealer'].hide();
                if(this.$refs['edit-company']) this.$refs['edit-company'].hide(); 
                if(this.$refs['rover-add']) this.$refs['rover-add'].hide();
                this.componentKey++
            },
         
            closemodal(){
                this.$refs['edit-dealer'].hide()
                this.$refs['edit-company'].hide() 
            },

         
            logout(){

                   axios({
                                method: 'post',    
                                headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                                url: '/api/userevent',
                                data: {user:this.$store.getters.user.id,type:2,text:this.$store.getters.user.fname+' '+this.$store.getters.user.lname}
                                })
                            .then((response)=> {
                       
                            if(response.data.error){
                                this.$swal({
                                                title: "Update Error",
                                                text: response.data.error,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })

                                } else this.$store.commit('setloggedin',false)


                                                
                            }).catch(err => {
                                        this.$swal({
                                                title: "Update Error",
                                                text: err,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                        });	
            },

            LoginAsSystemAdmin(){
                let branding = this.$store.getters.branding
                branding.logintitle = 'system'
                this.$store.commit('setbranding',branding)
                this.$store.commit('setloggedin',false)
            }
                
		}	   
	
	};
</script> 
