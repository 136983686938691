<template>
<div ref="mapcont" class="setmapmin">

            <v-map
            ref="myMap"
            :center="currentCenter"
            :zoom="currentZoom"
            :options="mapOptions"
            :style="{height: mapheight  + 'px', width: mapwidth + 'px', left: mapleft + 'px', top: maptop + 'px'}"
            @ready="onReady"
            @update:zoom="zoomChange"
            :noBlockingAnimations="blockanimation"
            >

          <v-tilelayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" />

            <v-marker v-for="mappoint in baseslist" :key="mappoint.value"
                
                :lat-lng="[mappoint.latitude,mappoint.longitude,mappoint.status]"
               >

               <v-icon :icon-anchor="staticAnchor">
                <div class="lmarkerhold2" :style="{ 'width':areawidth+'px','height':areawidth+'px' }"  />
                <div class="textbox" :style="{ 'top':areawidth/2+'px','left':areawidth/2 - 30+'px' }" >{{mappoint.name}}</div>
              </v-icon>

             </v-marker> 

          </v-map>

</div>
</template>

<style scoped>

.lmarkerhold2{
  border-radius:50%;
  z-index:997;
  background: radial-gradient(circle, rgba(13,249,6,0.4) 0%, rgba(13,249,6,0.4) 60%, rgba(255,255,255,0.4) 60%, rgba(255,255,255,0.3) 100%);
 }
 
.textbox{
    position:absolute;
    color:white;
    font-size:1em;
    background-color:transparent;
    padding-top:3px;
    padding-left:5px;
    padding-right:5px;
    white-space:nowrap;
    text-align:center;
    margin-top:-11px;  
    width:60px;  
    z-index:997; 
 }

</style>

<script>
  import "leaflet/dist/leaflet.css"
  import * as Vue2Leaflet from 'vue2-leaflet'
  import { latLng } from "leaflet"
  import * as L from 'leaflet'
  
  export default {
    components: {
      'v-map': Vue2Leaflet.LMap,
      'v-marker': Vue2Leaflet.LMarker,   
      'v-icon': Vue2Leaflet.LIcon,
      'v-tilelayer': Vue2Leaflet.LTileLayer,
      },
      props:['baseslist','fsmapheight','fsmapwidth'],
      
      data(){
        return {
                token:'',
                points:[],
                pointsfix:[],
                staticAnchor: [0,0],
                mapheight:0,
                mapwidth:0,
                mapleft:0,
                maptop:0,
                showsat:false,
                blockanimation:true,
                currentZoom:5,
                scale:156, // 156km/px
                circlewidth:85, // kms
                satlayergroup: {},
                currentCenter: latLng(54.188255397553895, -2.482830042690006),
                setbounds:false,
                areawidth:0,
                mapOptions: {
                    zoomSnap: 1
                },
                };
              },


        created(){
            this.setMapSize()
        },
        

        mounted(){
          console.log(this.baseslist)
          //this.updateMap()
        },

        watch: {
          fsmapwidth: function() {
            this.mapheight = this.fsmapheight
            this.mapwidth = this.fsmapwidth
          }
       },

        
        methods: {

          onReady() {
            
            this.zoomChange(6)
            this.satlayergroup = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x} 28', {attribution: ''});
            this.pointsfix.push(latLng(58.69402278444607, -11.33908970168965),latLng(58.860052177684516, 2.0331069415086267),latLng(50.008138177483104, -10.878669757387913),latLng(50.46905998970061, 3.7546771680281634))
            this.updateMap()
          },

          updateMap(){

                    if(this.baseslist.length > 0){
                      let points = []
                      this.baseslist.forEach(element => {
                          points.push([element.latitude,element.longitude])
                      })
                      this.points = points
                      if(this.baseslist.length > 1){ this.setBounds() }
                    }
                
          },

          setBounds(){ 
              if(this.$refs.myMap !== undefined){this.$refs.myMap.mapObject.fitBounds(this.points, {padding: [20, 20]})}
          }, 
     
          setCenter(){
              this.setbounds = false
              this.$refs.myMap.mapObject.removeLayer(this.satlayergroup)
              this.$refs.myMap.mapObject.fitBounds(this.pointsfix, {padding: [30, 30]})
          },

          toggleSat(){
                if(this.showsat == false){this.satlayergroup.addTo(this.$refs.myMap.mapObject)} else this.$refs.myMap.mapObject.removeLayer(this.satlayergroup)
                this.showsat = !this.showsat
          },

          zoomChange(zoom){
              if(zoom != this.currentZoom){
              let x= 0
              let scale = 156    
              while(x != zoom){
                  scale = scale/2
                  x++
              } 
              this.scale = scale   
              this.areawidth = this.circlewidth/this.scale
              this.staticAnchor = [this.areawidth/2,this.areawidth/2]
              this.currentZoom = zoom
              } 
          },

          setMapSize(){
                this.maptop = 0
                this.mapleft = 0
                this.mapheight = this.fsmapheight - 0
                this.mapwidth = this.fsmapwidth - 15
          },
      
		}	   
	
	};

</script>