<template>
<div>


<b-row class="mt-3">
    <b-col v-if="pages.length >  0" class="col-5 mt-3">{{ rstart }}-{{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>


     <b-col class="col-7 text-right mt-3" v-if="total > limit">
          <b-btn v-for="page in pages" :key="page.value" size="sm" @click="gotoPage(page.value)" style="min-width:30px;margin-right:1px">{{ page.text }}</b-btn>      
    </b-col>
</b-row>       

<b-row class="mt-3">
      <b-col>

             <b-table 
                responsive
                stacked="sm"
                hover 
                :items="emails" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @row-clicked="showEmail($event)"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                sort-icon-left
                :busy="isBusy"
                dark
                >

                <template #table-busy>
                    <div class="text-center text-orange my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong style="margin-left:10px"> Loading...</strong>
                    </div>
                </template>

                </b-table>
            
    
</b-col>
</b-row>	

<b-modal ref="edit-email" size="lg" :title="email.sent_email" hide-footer centered content-class="darkmodal">
   <b-row>
       <b-col class="col-12">Subject : {{ email.subject }}</b-col>
       <b-col class="col-12 mt-3">
           <div v-html="email.text" /> 
       </b-col>
   </b-row>

   <b-row>
       <b-col class="col-12 mt-5">
           <b-button variant="outline-success" block @click="sendEmail1()">NEXT</b-button>
       </b-col>
   </b-row>

</b-modal>

<b-modal ref="send-email" size="md" title="Resend Email" hide-footer centered content-class="darkmodal">

     <b-col class="col-12 mt-3">
        <label>Email</label>
                <b-form-input 
                    type="search"
                    placeholder="optional"
                    autocomplete="off" 
                    v-model="email.sent_email" 
                    name="email.sent_email"
                     v-validate="{required:true}"
					:class="{'darkinput': true, 'is-danger': errors.has('email.sent_email') }"
                    class="darkinput"
                    />
		</b-col>  

         <b-col class="col-12 mt-5">
           <b-button variant="outline-success" block @click="resendEmail()">SEND EMAIL</b-button>
       </b-col>        

</b-modal>    

</div> 
</template>


<script>
    import axios from 'axios'
	export default {
        props:['reseller','dealer','company','type'], 
        data(){
            return {
                email:{},
                emails:[],
                fields:[
                { key: 'subject', label: 'Subject', sortable: true  },
                { key: 'sent_email', label: 'To', sortable: true  },
                { key: 'resellertitle', label: 'Reseller', sortable: true  },
                { key: 'dealertitle', label: 'Dealer', sortable: true  },
                { key: 'companytitle', label: 'Company', sortable: true  },
                { key: 'date', label: 'Date/Time', sortable: true  }
                ],
                total:0,
                sortBy:'date',
                sortDesc:true,
                display:false,
                page:0,
                limit:100,
                rstart:1,
                rend:0,
                pages:[],
                token:'',
                user:{},
                isBusy:false
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.user = this.$store.getters.user
            this.fetchEmails()
        },

		methods: {

            gotoPage(page){
                this.page = page
                this.fetchEmails()
            },

            updatePaging(){
                this.rstart = (this.page * this.limit) + 1
                this.rend = (this.rstart + this.limit) - 1
                if(this.rend > this.total){this.rend = this.total} 
            },

            fetchEmails(){

                this.isBusy = true
				axios.get('/api/casteremaillogs',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         reseller:this.reseller,
                         dealer:this.dealer,
                         company:this.company,
                         type:this.type,
                         page:this.page,
                         limit:this.limit
                  
                     }
                })
				.then((response) => {
                    this.total = response.data.Total
                    this.emails = response.data.CasterEmails
                    this.pages = []
                    let pages = Math.ceil(this.total/this.limit)
                    let count = 0
                    while(count < pages){
                        this.pages.push({value:count,text:count+1})
                        count++
                    }

                    this.updatePaging()

                    this.isBusy = false
                    this.display = true
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},

            
             showEmail(email){
                //this.email = email
                
                this.modaltitle = 'Email'

                axios.get('/api/casteremaillogs',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         id:email.value                  
                     }
                })
				.then((response) => {
                    this.email = response.data.CasterEmails[0]
                    this.email.text = this.email.text.replace("ip-rtk-uk.com/images","ip-rtk-aws.com/images")
                    this.$refs['edit-email'].show()
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });

                
            },

            sendEmail1(){
                 this.$refs['send-email'].show()
            },

            resendEmail(){
                   
                        axios({
                                method: 'post',   
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/resendemail',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,
                                    email: this.email,
                                    user: this.user
                                    }
                                })
                            .then((response)=> {

                            if(response.data.error){
                                this.$swal({
                                                title: "Email not sent",
                                                text: response.data.error,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                } else {
                                        this.$swal({
                                                title: "Email resent",
                                                text: '',
                                                icon: "success",
                                                showCloseButton: true,
                                                showConfirmButton: false,
                                                timer:3000
                                                })
                                                .then(() => {
                                                   this.$refs['send-email'].hide() 
                                                   this.$refs['edit-email'].hide()
                                                   this.fetchEmails()
                                                    }) 
                                }
                        
                            }).catch(err => {
                                        this.$swal({
                                                title: "Email not sent",
                                                text: err,
                                                icon: "error",
                                                showCloseButton: true,
                                                showConfirmButton: false
                                                })
                                        });	
            },
 
		}	   
	
	};
</script>