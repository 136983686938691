<template>
<div>
</div>
</template>

<script>

import axios from 'axios'

export default {
    props:['loggedin'],
    name:'SystemLogout',
    data(){
        return{
        link:''
        }
  },


  watch: {
        loggedin: function() {
            if(this.loggedin == false){
                this.link = this.$store.getters.branding.logintitle
                if(this.link == ''){this.link = 'system'}
                this.logout() 
            }
        }
    },     

  methods:{

    logout(){

        axios({
            method: 'post',
            url: '/api/auth/logout',
            }).then(
                this.clearData()
            )
            .catch(err => {
            console.log(err)
            })
        },

    clearData(){
        this.$store.commit('setmobile','')
        this.$store.commit('setroleid',0)
        this.$store.commit('setcountryid',0)
        this.$store.commit('setresellerid',0)
        this.$store.commit('setdealerid',0)
        this.$store.commit('setcompanyid',0)
        this.$store.commit('setroversdatapoll',0) 
        this.$store.commit('setbasesdatapoll',0)     
        this.$store.commit('settoken','')
        this.$store.commit('setuser','')
        this.$store.commit('setsearchtext','')
        this.$store.commit('setsearchvalues',[])
        this.$store.commit('setsearchvalues2',[])

        setTimeout(this.gotoLogin,200)
    },

    gotoLogin(){
        window.location.replace(
        "/login/"+this.link,
        );
        //this.$router.push({ name: 'systemlogin', params: {dealer:this.link} }).catch(()=>{})
    }
  
  }            
}
</script>