<template>
    <div>
    <b-row class="mt-1 pb-3" style="border-bottom:1px solid rgba(255,255,255,0.5)">
        <div class="stageselectmain">
            <div class="stageselectbox"><div class="stageSelect" @click="setPage(1)" v-bind:class="companyclass "><b-icon-building class="selicon"/></div>details</div>
            <div class="stageselectline" v-bind:class="line1class"></div>
            <div class="stageselectbox"><div class="stageSelect" @click="setPage(2)" v-bind:class="userclass"><b-icon-person class="selicon"/></div>admin</div>
            <div class="stageselectline" v-bind:class="line2class"></div>
            <div class="stageselectbox"><div class="stageSelect" @click="setPage(3)" v-bind:class="brandclass"><b-icon-image class="selicon"/></div>branding</div>
            <div class="stageselectline" v-bind:class="line2class"></div>
            <div class="stageselectbox"><div class="stageSelect" @click="setPage(4)" v-bind:class="createclass"><b-icon-check class="selicon"/></div><span v-if="resellerid == 0">create</span><span v-else>update</span></div>
        
        </div>
                    
    </b-row>
    
    <b-container class="addrowpage" v-if="page == 1 && display">
    
        <b-row>
    
        <b-col class="col-12 mt-3">
            <label>Title</label>
                <b-form-input 
                    type="search"
                    placeholder="reseller name"
                    autocomplete="off" 
                    v-model="reseller.text" 
                    name="reseller.text"
                    v-validate="{required:page1}"
                    :class="{'input': true, 'is-danger': errors.has('reseller.text') }" 
                    class="darkinput"
                    size="md"
                    @input = "validateField('reseller.text')"
                    />
                    <span style="color:red;">{{ errors.first('reseller.text') }}</span>
        </b-col> 
        
       
        <b-col class="col-12 mt-3">
            <label>Address</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="reseller.address1" 
                    name="reseller.address1"
                    v-validate="{required:page1}"
                    :class="{'input': true, 'is-danger': errors.has('reseller.address1') }"
                    class="darkinput"
                    size="md"
                    @input = "validateField('reseller.address1')"  
                    />
                    <span style="color:red;">{{ errors.first('reseller.address1') }}</span>
        </b-col>
    
        <b-col class="col-12 mt-3">
            <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="reseller.address2" 
                    name="reseller.address2"
                    class="darkinput"
                    size="md"
                    />
        </b-col>
    
        <b-col class="col-12 mt-3"> 
            <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="reseller.address3" 
                    name="reseller.address3"
                    class="darkinput"
                    size="md"
                    />
        </b-col>        
    
        <b-col class="col-12 col-md-6 mt-3">
            <label>Town / City</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="reseller.towncity" 
                    name="reseller.towncity"
                    v-validate="{required:page1}"
                    :class="{'input': true, 'is-danger': errors.has('reseller.towncity') }"
                    class="darkinput"
                    size="md"
                    @input = "validateField('reseller.towncity')"  
                    />
                    <span style="color:red;">{{ errors.first('reseller.towncity') }}</span>
        </b-col>
    
        <b-col class="col-12 col-md-6 mt-3">
            <label>County</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="reseller.county" 
                    name="reseller.county"
                    v-validate="{required:true}"
                    :class="{'input': true, 'is-danger': errors.has('reseller.county') }"
                    class="darkinput"
                    size="md"
                    @input = "validateField('reseller.county')"  
                    />
                    <span style="color:red;">{{ errors.first('reseller.county') }}</span>
        </b-col>
    
    
        <b-col class="col-12 col-md-6 mt-3">
            <label>Postcode</label>
                <b-form-input 
                    type="search"
                    placeholder=""
                    autocomplete="off" 
                    v-model="reseller.postcode" 
                    name="reseller.postcode"
                    v-validate="{required:page1}"
                    :class="{'input': true, 'is-danger': errors.has('reseller.postcode') }"
                    class="darkinput"
                    size="md"
                    @input = "validateField('reseller.postcode')"  
                    />
                    <span style="color:red;">{{ errors.first('reseller.postcode') }}</span>
        </b-col>
    
        <b-col class="col-12 col-md-6 mt-3">
                    <label>Country</label>
                    <b-form-select
                        v-model="reseller.country"
                        :options="countries"
                        class="darkinput"
                        size="md"
                        name="reseller.country"
                        v-validate="{min_value:1|page1}"
                        :class="{'input': true, 'is-danger': errors.has('reseller.country') }"
                        @change = "validateField('reseller.country')"  
                        />
                        <span style="color:red" v-show="errors.has('reseller.country')">
                        ** required</span>
        </b-col>
       
    
        <b-col class="col-12 mt-3">
            <label>Website</label>
                <b-form-input 
                    type="search"
                    placeholder="optional"
                    autocomplete="off" 
                    v-model="reseller.website" 
                    name="reseller.website"
                    class="darkinput"
                    size="md"
                    />
        </b-col>
    
        
    </b-row>
           
    <b-row class="mt-5">
        <b-col class="col-12">
            <b-button variant="outline-success" block ref="next" size="md" @click="checkResellerDetails()">NEXT</b-button>
         </b-col>  
    </b-row>     
    
    
    </b-container>
    
    <CasterAddSetAdmin 
        v-if="page == 2"
        :passadmin="reseller"
        v-on:adminset="updateAdminUser"
        />
    
    
    <b-container class="addrowpage" v-if="page == 3">
    
    <b-row>
           
             <b-col class="col-12 mt-3">
                  <label>Login page name</label>
                  <b-form-input 
                        type="search"
                        placeholder="eg: cwagritech"
                        autocomplete="off" 
                        v-model="reseller.logintitle" 
                        name="reseller.logintitle"
                        v-validate="'required:true|alpha_dash|max:40|min:3'"
                        :class="{'input': true, 'is-danger': errors.has('reseller.logintitle') }"
                        class="darkinput"
                        size="md"
                        @input = "validateField('reseller.logintitle')"  
                        />
             </b-col> 
    
            <b-col class="col-12 mt-3">
               <label>Reseller logo</label>
            </b-col>
             <b-col class="col-12" v-if="logoimage == ''">
                   <ImageUpload 
                    type="branding/logos"
                    v-on:upload="LogofileUploaded"
                    />
             </b-col>
    
            <b-col class="col-10 offset-1" v-else>
                  <img :src="'https://ip-rtk-aws.com/images/branding/logos/'+logoimage"  style="width:100%" />
                  <b-button class="mt-1" size="sm" variant="outline-success" @click="resetLogo()"><b-icon-arrow-clockwise /></b-button> 
            </b-col>        
    
            <b-col class="col-12 mt-3">
                <label> Login page background image</label>
            </b-col>
             <b-col class="col-12"  v-if="bgimage == ''">
                 <ImageUpload 
                    type="branding/homebgs"
                    v-on:upload="BGfileUploaded"
                    />
             </b-col>   
    
               <b-col class="col-10 offset-1" v-else>
                  <img :src="'https://ip-rtk-aws.com/images/branding/homebgs/'+bgimage"  style="width:100%" />
                  <b-button class="mt-1" size="sm" variant="outline-success" @click="resetBg()"><b-icon-arrow-clockwise /></b-button>  
            </b-col>       
    
    </b-row>
    
    <b-row class="mt-5">
             <b-col class="col-12 mt-3">         
                <b-button variant="outline-success" block size="md"  @click="brandingCheck()">NEXT</b-button>
            </b-col>  
    </b-row>          
    
    
    </b-container>
    
    <b-container class="addrowpage" v-if="page == 4" style="font-size:1em">
    
        <b-row class="mt-3">
            <b-col class="col-12"><h5>{{ reseller.text }}</h5></b-col>
           
            <b-col class="col-12 mt-3">
                <b-row>
                    <b-col class="col-2"><b-icon-geo-alt class="icon1" /></b-col>
                    <b-col class="col-10">
                        <b-row>
                            <b-col class="col-12">{{ reseller.address1}}</b-col>
                            <b-col class="col-12" v-if="reseller.address2">{{ reseller.address2}}</b-col>
                            <b-col class="col-12" v-if="reseller.address3">{{ reseller.address3}}</b-col>
                            <b-col class="col-12">{{ reseller.towncity}}</b-col>
                            <b-col class="col-12">{{ reseller.postcode}}</b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
    
            <b-col class="col-12">
                <b-row class="mt-3" v-if="reseller.mobile">
                    <b-col class="col-2"><b-icon-phone  class="icon1" /></b-col>
                    <b-col class="col-10">{{ reseller.mobile}}</b-col>
                </b-row>
                <b-row class="mt-3" v-if="reseller.tel">
                    <b-col class="col-2"><b-icon-telephone  class="icon1" /></b-col>
                    <b-col class="col-10">{{ reseller.tel}}</b-col>
                </b-row>
            </b-col>
    
            <b-col class="col-12 mt-3">
                <b-row>
                    <b-col class="col-2"><b-icon-person  class="icon1" /></b-col>
                    <b-col class="col-10">
                        <b-row>
                            <b-col class="col-12">{{ reseller.fname}} {{ reseller.lname }}</b-col>
                            <b-col class="col-12">{{ reseller.email }}</b-col>
                            <b-col class="col-12">{{ reseller.password }}</b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
    
            <b-col class="col-12 mt-3">
                <b-row>
                    <b-col class="col-2"><b-icon-globe  class="icon1"/></b-col>
                    <b-col class="col-10" v-if="this.reseller.country == 2">https://rtkdk.dk/login/{{ reseller.logintitle }}</b-col>
                    <b-col class="col-10" v-else>https://ip-rtk-uk.com/login/{{ reseller.logintitle }}</b-col>
                </b-row>
            </b-col>
            
           
        </b-row>     
        
        <b-row class="mt-3">
                    <b-col class="col-12 mt-1">
                        <label style="margin-right:10px;">{{  emailtypetext }} </label>
                        <b-form-checkbox
                        inline
                        v-model="sendwelcomeemail"
                        size="lg"
                        value=1
                        unchecked-value=0
                        />    
                    </b-col>
                    <b-col class="col-12 mt-1" v-if="sendwelcomeemail == 1">
                        <b-form-input 
                        type="search"
                        placeholder="email address"
                        autocomplete="off" 
                        v-model="emailto" 
                        class="darkinput"
                        />
                    </b-col>
        </b-row>      
    
        <b-row class="mt-5">
         <b-col class="col-12">
                <b-button variant="outline-success" block size="md" @click="updateReseller()"><span v-if="resellerid==0">CREATE</span><span v-else>UPDATE</span> RESELLER</b-button>
            </b-col>  
        </b-row>
    
    </b-container>
    
    <b-modal ref="create-reseller" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
    
    <b-row v-for="message in stagemessages " :key="message.id">
            <b-col class="col-12 col-sm-5">
                {{ message.message }}
        </b-col>
        <b-col class="col-12 col-sm-5">
                {{ message.text }}
        </b-col>
        <b-col class="col-12 col-sm-2 text-right">
                <b-icon-check-square v-if="message.error == false" />
                <b-icon-x-square v-else />
        </b-col>
    </b-row> 
    
    <b-row>
            <b-col class="col-12 mt-5">
            <b-button variant="outline-success" @click.stop="finishReseller()" block>Finish</b-button>
        </b-col>   
    </b-row>          
    
    </b-modal>
    
    <SendEmail
            :sendmail="sendmail"
            :emailid="emailid"
            :emailto="emailto" 
            :id="addedreseller"
            :pw="reseller.password"
            :showdiag=false
            v-on:emailsent="emailSendStatus"
            />
    
    <ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>
            
    </div> 
    </template>
    
    <style scoped>
    .icon1{
        font-size:1.4em;
    }
    </style>
    
    <script>
        import CasterAddSetAdmin from './CasterAddSetAdmin.vue'
        import ErrorLogging from './ErrorLogging'
        import SendEmail from './SendEmail'
        import ImageUpload from './ImageUpload'
        import { Validator } from 'vee-validate'
        import axios from 'axios'
        import './customRule'
        export default {
            props:['resellerid'],
            name:'CasterResellerAdd',
            components:{
                CasterAddSetAdmin,
                ImageUpload,
                SendEmail,
                ErrorLogging
            },
            data(){
            return {
                    token:'',
                    reseller:{
                        value:0,
                        text:'',
                        address1:'',
                        address2:'',
                        address3:'',
                        towncity:'',
                        county:'',
                        postcode:'',
                        country:0,
                        tel:'',
                        mobile:'',
                        website:'',
                        logintitle:'',
                        logo:'',
                        background:'',
                        fname:'',
                        lname:'',
                        email:'',
                        password:'',
                        adminuser:0,
                        users:[]
                    },
                    resellerusers:[],
                    stagemessages:[],
                    user:{},
                    logintitle:'',
                    logoimage:'',
                    bgimage:'',
                    page:1,
                    page1:true,
                    page2:false,
                    addedreseller:0,
                    modaltitle:'',
                    companydetail:false,
                    userdetail:false,
                    branddetail:false,
                    companyclass:'stageactive',
                    userclass:'',
                    brandclass:'',
                    createclass:'',
                    line1class:'stageselectline',
                    line2class:'stageselectline',
                    line3class:'stageselectline',
                    errorresponse:{},
                    sendwelcomeemail:0,
                    emailtypetext:'',
                    sent:99,
                    sendmail:false,
                    emailto:'',
                    emailid:40, // 40 - Reseller welcome / 41 - details email
                    display:false,
                    userfields:[
                    { key: 'fname', label: 'Forename', sortable: true  },
                    { key: 'lname', label: 'Surname', sortable: true  },
                    { key: 'roletitle', label: 'Role', sortable: true  },
                    { key: 'selected_admin', label: ' Selected', tdClass: 'text-left' }
                    ],
                    countries:[
                    { value: 0, text: 'Select'},        
                    { value: 1, text: 'UK' },
                    { value: 2, text: 'Denmark' }    
                    ]
                    };
                    
                    },
    
            created(){
    
                this.token = this.$store.getters.token
                this.user = this.$store.getters.user
    
                if(this.resellerid == 0){ 
                   this.newReseller()
                } else {    // Edit Reseller
                    this.companyclass = 'stagecomp'
                    this.userclass = 'stagecomp'
                    this.brandclass = 'stagecomp'
                    this.createclass = 'stagecomp'
                    this.line1class = 'stageselectlinecomp'
                    this.line2class = 'stageselectlinecomp'
                    this.line3class = 'stageselectlinecomp'
                    this.emailtypetext = 'Re-send login details'
                    this.sendwelcomeemail = 0
                    this.fetchReseller()
                }
              },
    
            mounted() {
                Validator.localize('en',{
                        messages: {
                        required: '** required.',
                        },
                })
            },            
    
           watch:{
    
                companydetail: function () {
                             if(this.companydetail){this.companyclass = 'stagecomp',this.line1class = 'stageselectlinecomp'} else {this.companyclass = 'stageactive',this.line1class = 'stageselectline'}
                             },
                branddetail: function () {
                             if(this.branddetail){this.brandclass = 'stagecomp'} else this.brandclass = 'stageactive'
                             },
           },
           methods: {
    
            newReseller(){
                this.emailtypetext = 'Send welcome email'
                this.companyclass = 'stagecomp'
                this.display = true
               }, 
    
            fetchReseller(){
    
                axios.get('/api/casterbusinesses',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:this.resellerid
                    }
                })
                .then((response) => {
                    if(response.data.code == 999){
                            this.errorresponse = response.data
                        } else {
                        this.reseller = response.data.CasterBusiness[0]
                        this.resellerusers = this.reseller.users
                        this.currentemail = this.reseller.email
                        this.bgimage = this.reseller.background
                        this.logoimage = this.reseller.logo
                        this.display = true
                        }    
                    })
                },
    
                checkResellerDetails(){
                    this.$validator.validateAll().then(result => {
                    if(result){
                        this.line1class = 'stageselectlinecomp'
                        this.userclass = 'stagecomp'
                        this.page = 2
                    }
                    })
                },
    
            updateAdminUser(details){
                this.reseller.fname = details.fname
                this.reseller.lname = details.lname
                this.reseller.email = details.email
                this.reseller.mobile = details.mobile
                this.reseller.tel = details.tel
                this.reseller.password = details.password
                this.reseller.adminuser = details.adminuser
                this.reseller.validated = true
                // Auto set login title if new dealer
                if(this.reseller.logintitle == ''){this.reseller.logintitle = this.reseller.text.replace(/\s/g, '').toLowerCase()}
                if(this.currentlogintitle !== this.reseller.logintitle){this.checkLoginTitle()}
                this.line2class = 'stageselectlinecomp'
                this.brandclass = 'stagecomp'
                this.page = 3
            },

            
            brandingCheck(){
                if(this.reseller.logintitle == '' || this.logoimage == '' || this.bgimage == ''){return}
                this.line2class = 'stageselectlinecomp'
                this.createclass = 'stagecomp'
                this.page = 4
            },
    
            resetLogo(){
                this.logoimage = ''
            },

            resetBg(){
                this.bgimage = ''
            },          

            
            validateField(field){
                this.$validator.validate(field)
            },
            
            async checkLoginTitle(){
                await this.loginTitleCheck()
            },    

            async loginTitleCheck(){	

            return new Promise((resolve) => {

            axios.get('/api/casterbusinesses',{
                headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                params:{
                    userid:this.$store.getters.user.id,
                    token:this.$store.getters.user.token,
                    logintitle:this.reseller.logintitle
                }
            })
            .then((response) => {
                if(response.data.code == 999){
                    this.errorresponse = response.data
                } else {
                    if(response.data.count > 0){this.logintitleerror = true} else this.logintitleerror = false
                    }    
                resolve()
                });
            }) // End resolve promise
        },
    
                setPage(id){
                    switch(id){
                        case 1:
                            this.page = id
                        break
                        case 2:
                         if(this.reseller.validated){
                            this.page = id
                            } 
                        break    
                        case 3:
                         if(this.reseller.validated){
                            this.page = id
                            } 
                        break     
                        case 4:
                        if(this.brandclass == 'stagecomp'){
                            this.page = id
                            }
                        break
                    }
                }, 
              
                BGfileUploaded(file){
                    this.bgimage = file
                },
    
                LogofileUploaded(file){
                    this.logoimage = file
                },
    
                updateReseller(){
                    this.reseller.background = this.bgimage
                    this.reseller.logo = this.logoimage
    
                    if(this.reseller.value == null){this.modaltitle = 'Creating Reseller'} else this.modaltitle = 'Updating Reseller'
                    this.$refs['create-reseller'].show()
                    
                     axios({
                                    method: 'post',     //   New Reseller
                                    headers: {"Authorization" : "Bearer " + this.token},
                                    url: '/api/casterreselleradd',
                                    data: {
                                        userid:this.$store.getters.user.id,
                                        token:this.$store.getters.user.token,
                                        resellerdata:this.reseller
                                        }
                                    })
                                .then((response)=> {
         
                                    if(response.data.code == 999){
                                        this.stagemessages = response.data.messages
                                        //this.errorresponse = response.data
                                    } else {
                                            this.stagemessages = response.data.messages
                                            this.reseller.value = response.data.resellerid
                                            this.addedreseller = this.reseller.value
                                            if(this.sendwelcomeemail == 1){this.sendEmail()}
                                        
                                        // this.sendEmail(response.data)
                                    }
                                });	
                },
                
                sendEmail(){
                    this.sendmail = !this.sendmail // trigger to SendEmail
                },
    
                emailSendStatus(data){
                        this.stagemessages.push(...data.messages)
                },
                
                finishReseller(){
                        this.$refs['create-reseller'].hide()
                        this.$emit('updatemodal')
                },
    
             closemodal(){
                  this.$emit('closemodal')
             },
    
            }	   
        
        };
    
    </script> 
    
    
    