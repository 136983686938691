<template>
<div class="imageBorder"> 

        <b-row v-if="display">

             <b-col class="col-12 col-sm-3 mt-3">{{ charttype }}</b-col>

             <b-col class="col-12 col-sm-6 mt-3 text-center">
                <b-button style="margin-right:5px" size="sm" variant="outline-success" @click="setType(0)">Availability</b-button>
               <b-button style="margin-right:5px" size="sm" variant="outline-success" @click="setType(1)">Connects</b-button>
               <b-button size="sm" variant="outline-success" @click="setType(2)">Disconnects</b-button>
            </b-col>    

            <b-col class="col-12 col-sm-3  mt-3 text-right">
                            <VueCtkDateTimePicker
                            v-model="filterstart"
                            format='DD-MM-YYYY'
                            :no-value-to-custom-elem="true"
                            :only-date="true"
                            :dark="true"
                            >

                            <b-form-input 
                                type="search"
                                v-model="filterstart" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2"
                                size="sm"  
                                />


                            </VueCtkDateTimePicker>   
                            
              

               <!-- <b-button size="sm" variant="outline-success" @click="setPeriod('day')">DAY</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('week')">WEEK</b-button>
               <b-button size="sm" variant="outline-success" @click="setPeriod('month')">MONTH</b-button> -->
               <!-- <b-button size="sm" variant="outline-success" @click="setPeriod('year')">YEAR</b-button> -->

            </b-col>            
            <b-col class="col-12 mt-5">
                 <LineChartSingle :chartdata="data" :height="200" :datasetsel="dataset"/>
            </b-col>

        </b-row>

         <b-row class="mt-3" v-else>
                <b-col class="col-12 text-center pt-3 pb-3" style="min-height:200px">
                        <h5 style="color:#48A1BA;">No Data</h5>
                </b-col>   
        </b-row>

</div>
</template>

<script>
    import LineChartSingle from './LineChartSingle'
    import axios from 'axios'
    import moment from 'moment'
	export default {
        props:['baseid'],
        name:'CasterReferenceStationChartCard',
        components:{
            LineChartSingle
        },  
        data(){
		return {
                data:[],
                token:'',
                display:false,
                period:3600, // 1 hour
                timeout:false,
                timeperiod:'',
                filterstart:moment().format("DD-MM-YYYY"),
                start:'',
                end:'',
                steps:24,
                dataset:0,
                charttype:'AVAILABILITY'
               };
                
                },

	created(){
                this.token = this.$store.getters.token
                this.setDay()
                },
                
    beforeDestroy () {
            clearInterval(this.timeout)
        },
        
    watch: {
        filterstart: function() {
            this.setDay(this.filterstart)
        }
    },    

	methods: {

            getBaseData(){

                axios.get('/api/basestationhistorystats',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                                userid:this.$store.getters.user.id,
                                token:this.$store.getters.user.token,
                                start:this.start,
                                end:this.end,
                                steps:this.steps,
                                id: this.baseid
                         }
                        })
			.then((response) => {

                    if(response.data.error == 'Unauthorized'){    
                     
                      this.$swal({
                            title: "System Error",
                            text: response.data.error,
                            icon: "error",
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                   }
                   
                   else {
                           this.data = response.data
                           this.display = true
                           //if(!this.timeout){this.timeout = setInterval(this.getBaseData, 3000)}
                        }

                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            })
                    });
                },

                setDay(date){
                    if(date == null){
                        date = moment().format("YYYY-MM-DD")
                    } else date = moment(date).format("YYYY-DD-MM")
                    
                    this.start = date
                    this.end = date
                    this.getBaseData()    
                },

                setType(type){
                    this.dataset = type
                    switch(this.dataset){
                        case 0:
                        this.charttype = 'AVAILABILITY'
                        break;
                        case 1:
                        this.charttype = 'CONNECTS'
                        break;
                        case 2:
                        this.charttype = 'DISCONNECTS'
                        break;    
                    }
                   
                },
                
                setPeriod(value){
                    
                    switch(value){
                        case 'day':
                        this.start = moment().format("YYYY-MM-DD")
                        this.end = moment().format("YYYY-MM-DD")    
                        this.timeperiod = moment().format("DD-MM-YYYY")
                        this.filterstart = this.timeperiod
                        this.steps = 24
                        break;
                        case 'week':
                        this.start = moment().startOf('isoweek').format("YYYY-MM-DD")
                        this.end = moment().endOf('isoweek').format("YYYY-MM-DD")
                        this.timeperiod = moment().startOf('isoweek').format("DD-MM-YYYY")+' - '+moment().endOf('isoweek').format("DD-MM-YYYY")
                        this.steps = 7
                        break;
                        case 'month':
                        this.start = moment().startOf('month').format("YYYY-MM-DD")
                        this.end = moment().endOf('month').format("YYYY-MM-DD")
                        this.timeperiod =  moment().startOf('month').format("DD-MM-YYYY")+' - '+ moment().endOf('month').format("DD-MM-YYYY")
                        this.steps = moment().daysInMonth() 
                        break;
                        case 'year':
                        this.start = moment().startOf('year').format("YYYY-MM-DD")
                        this.end = moment().endOf('year').format("YYYY-MM-DD")
                        this.timeperiod =  moment().startOf('year').format("DD-MM-YYYY")+' - '+ moment().endOf('year').format("DD-MM-YYYY")
                        this.steps = 12
                        break;                        
                    }    
                        this.$emit('periodchange',{start:this.start,end:this.end,period:value})
                        this.getBaseData()    
                }
            }	 

	};
</script> 