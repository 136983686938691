<template>
<div>

<b-row>

    <b-col class="col-12 col-md-6 col-lg-3 mt-3">
        <label>Type</label>
        <b-form-select
                    v-model="subtypesel"
                    :options="subtypes"
                    class="darkinput2"
                    size="sm"
                    @change = "setType" 
                    />
    </b-col>

    <b-col class="col-12 col-md-6 col-lg-3 mt-3">
        <label>Action</label>
        <b-form-select
                    v-model="subaction"
                    :options="statustypes"
                    class="darkinput2"
                    size="sm"
                    @change = "setStatus" 
                    />
    </b-col>

    <b-col class="col-2 mt-3">
        <b-button variant="outline-success" size="sm" @click="resetSearch()" style="margin-top:32px"> reset search </b-button>
    </b-col>


</b-row>

<b-row class="mt-3">
    <b-col class="col-12 col-md-5 mt-3" v-if="total > limit">1 - {{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-12 col-md-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>
</b-row>    


<b-row class="mt-3 mb-5" >

      <b-col>
             <b-table
                class ="nowrap"
                ref = "subs-table" 
                responsive 
                hover 
                sticky-header="600px"
                :items="displaysubs" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @sort-changed="onSortChanged"
                @row-clicked="viewRover($event)"
                :busy="isBusy"
                sort-icon-left
                dark
                >
                <template #cell(statuscolor)="data">
                  <div class="rtmarkerexpiry" :style="{ backgroundColor:data.item.color}" >{{ data.item.days }}</div>
                </template>

                </b-table>
            
            <b-button v-if="more" variant="outline-success" block @click.stop="showMore()">Show more results?</b-button>
            <b-button v-if="loadingmore" variant="outline-warning" disabled block>Loading results</b-button>
</b-col>
</b-row>	

<b-modal v-model="isBusy" hide-header hide-footer centered content-class="loadingmodal">
        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <b-spinner variant="success" class="mr-2"/>
                <h5>loading results</h5>
            </b-col>
        </b-row> 
</b-modal> 


<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style scoped>
.nowrap td {
    white-space: nowrap !important;
}

.modal-content{
    border:none !important;
}
</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    export default {
        props:['resellerid','dealerid','companyid','roverid','subtype','clearsearch','start','end','stext'],
        name:'CasterSubscriptionsLogTable',
        components:{
            ErrorLogging
        },
        data(){
            return {
                test:true,
                id:0,
                token:'',
                subs:[],
                displaysubs:[],
                subtypesel:0,
                subtypes:[],
                subaction:0,
                statustypes :[
                    {value:0,text:'All'},
                    {value:40,text:'new + renewed subscriptions'},
                    {value:50,text:'new subscriptions'},
                    {value:56,text:'renewed subscriptions'},
                    {value:58,text:'scheduled renewal subscriptions'},
                    {value:59,text:'expired subscriptions'},
                    {value:53,text:'cancelled subscriptions'}
                ],
                orderby:'rover',
                sortdesc:false,
                isBusy:false,
                display:false,
                filterchangetype:0, // 1 Reseller 2 Dealer 3 Company 4 Rover
                errorresponse:{},
                nosubs:false,
                page:0,
                limit:50,
                more:false,
                loadingmore:false,
                total:0,
                rstart:1,
                rend:0,
               
                fields:[
                { key: 'reseller', label: 'Reseller', sortable: true  },      
                { key: 'dealer', label: 'Dealer', sortable: true  },    
                { key: 'company', label: 'Company', sortable: true  },
                { key: 'rover', label: 'Rover', sortable: true  },
                { key: 'detail', label: 'Type', sortable: true  },
                { key: 'typename', label: 'Action', sortable: true  },
                { key: 'datetime', label: 'Created', sortable: true  },
                ],
                observer: null,
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.subtypesel = this.subtype
            this.fetchSubs()  
        },

        mounted(){
            this.addTableEventList()
        },

        computed: {
            filterdates() {
                return { ...this.start, ...this.end };
            },
            filterdata() {
                return { ...this.resellerid, ...this.dealerid, ...this.companyid, ...this.roverid };
            }
        },

         watch: {
                    subtype: function() {
                        this.subtypesel = this.subtype
                        this.page = 0
                        this.fetchSubs()
                    },

                    filterdates: function () {
                        this.page = 0
                        this.fetchSubs()
                    },

                    filterdata: function () {
                        this.page = 0
                        this.fetchSubs()
                    },

                    clearsearch: function () {
                        this.page = 0
                        this.subaction = 0
                        this.fetchSubs()
                    },
                   
                    stext: function () {
                        this.page = 0
                        this.subaction = 0
                        this.fetchSubs()
                         },
                         
                    subs: function() {
                        if(this.page > 0){
                            this.readdTableEventList
                        }
                    }
        }, 
        
		methods: {

            setType(){
                this.page = 0
                this.fetchSubs()
            },

            setStatus(){
                this.page = 0
                this.fetchSubs()
            },

            fetchSubs(){
                this.isBusy = true
                axios.get('/api/getsubscriptionslog',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.token,
                        reseller:this.resellerid,
                        dealer:this.dealerid,
                        company:this.companyid,
                        rover:this.roverid,
                        stext:this.stext,
                        page:this.page,
                        subtype:this.subtypesel,
                        type:this.subaction,
                        start:this.start,
                        end:this.end,
                        limit:this.limit,
                        orderby:this.orderby,
                        sortdesc:this.sortdesc
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        alert('exception')
                        //this.errorresponse = response.data
                        } else {
                                this.more = false
                                this.loadingmore = false 
                                if(this.page == 0){
                                    this.subs = response.data.subs
                                } else this.subs.push(...response.data.subs)

                                this.displaysubs = this.subs

                                this.subtypes = []
                                this.subtypes.push({value:0,text:'All'})
                                this.subtypes.push(...response.data.subtypes)
                                
                                this.updatePaging()
                                
                                this.total = response.data.total
                                if(this.total < this.limit){this.more = false}

                                if(this.total != 0 && this.stext != ''){
                                    this.$emit('fetchresponse',this.stext)
                                }

                                this.isBusy = false
                                this.display = true
                        }
                    
                    });
			},

            updatePaging(){
                if(this.page == 0){
                    this.rstart = 1
                    this.rend = this.limit
                } else {
                this.rend = ((this.page + 1) * this.limit)
                if(this.rend > this.total){this.rend = this.total}
                } 
            },

            addTableEventList(){
                const tableScrollBody = this.$refs["subs-table"].$el
                tableScrollBody.addEventListener("scroll", this.onScroll)
            },

            removeTableEventList(){
                const tableScrollBody = this.$refs["subs-table"].$el
                tableScrollBody.removeEventListener("scroll", this.onScroll)
            },

            readdTableEventList(){
                this.$nextTick(() => {    
                    this.removeTableEventList()
                    this.addTableEventList()
                })    
            },

            showMore(){
                this.page++
                this.more = false
                this.loadingmore = true
                this.fetchSubs()
            },

            onScroll(event) {
                if (
                    event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 200
                ) {
                    if (!this.isBusy) {
                        if(this.total > this.limit){
                            this.more = true
                        } else this.removeTableEventList()
                    }
                }
            },

            // handle column sorting events to only call API when there is more results
            onSortChanged(event){
                if(this.total > this.limit && this.total !== this.rend){
                    this.orderby = event.sortBy
                    this.sortdesc = event.sortDesc
                    this.fetchSubs()
                }
            },

            setDefaultSort(){
                if(this.resellerid > 0){this.orderby = 'dealer'}
                this.fetchSubs()
            },

            resetSearch(){
                this.subtypesel = 0
                this.subaction = 0
                this.$emit('clearstext')
            },

            viewRover(sub){
                this.$router.push({ name: 'casterrovermanage', params: {id:sub.roverid} }).catch(()=>{})
            }

        }	
    };

</script>