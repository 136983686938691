<template>
<div>
 <b-container class="mainpagecont">

<b-row class="mt-5 pt-3">

        <b-col class="col-6">
            <h4>Caster Log Messages</h4>
        </b-col>

        <b-col class="col-3 offset-3">
            <b-button variant="info" block size="sm" @click="autoRefresh()" v-if="auto"><b-icon-clock class="icon" />  AUTO REFRESH</b-button>
            <b-button variant="outline-info" size="sm" block  @click="autoRefresh()" v-else><b-icon-clock class="icon" />  AUTO REFRESH</b-button>      
        </b-col>               

 </b-row>  

<b-row class="mt-5"> 

   
        <b-col class="col-3">
                <label>Username</label>
                  <CasterSearch :setsearchtext="usernamesel" v-on:textsearch=updateSearch v-on:clearsearch=clearSearch />
                <div class="choiceholder" v-if="showrovers">
                        <ul class="makerlist">
                        <li v-for="rover in rovers" :key="rover.value" @click="roverSel(rover)">{{ rover.text }}</li>
                        </ul>
                    </div>
                </b-col>  


        <b-col class="col-3">
                <label>Basestation</label>
                  <CasterSearch :setsearchtext="basenamesel" v-on:textsearch=updateSearchBS v-on:clearsearch=clearSearchBS />
                <div class="choiceholder" v-if="showbases">
                        <ul class="makerlist">
                        <li v-for="basestation in basestations" :key="basestation.value" @click="baseSel(basestation)">{{ basestation.text }}</li>
                        </ul>
                    </div>
                </b-col>          

        <b-col class="col-3">
                        <label>Start Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterstart"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterstart" 
                                autocomplete = "off"
                                placeholder="select"
                                class="darkinput2"
                                size="sm"  
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

            <b-col class="col-3">
                        <label>End Date/Time</label>
                            <VueCtkDateTimePicker
                            v-model="filterend"
                            format='DD/MM/YYYY HH:mm'
                            :no-value-to-custom-elem="true"
                            :only-date="false"
                            >
                            <b-form-input 
                                type="search"
                                v-model="filterend" 
                                autocomplete = "off"
                                placeholder="select" 
                                class="darkinput2"
                                size="sm" 
                                />
                            </VueCtkDateTimePicker>    

            </b-col>

            <b-col class="col-10 mt-2">
                <b-form-checkbox
                    inline
                    v-model="basesonly"
                    size="lg"
                    value=1
                    unchecked-value=0
                    @change="filterBaseOnly"
              />
              <label>Base messages only</label>
            </b-col> 

            <b-col class="col-2 mt-2">
                <b-button variant="outline-success" size="sm"  @click="fetchRequests()" style="min-width:80px"> filter </b-button>
                <b-icon-x-circle style="margin-left:10px;font-size:1.6em" v-if="filterstart !== ''"  @click="clearDates()"  />
            </b-col>       

</b-row>  

      

<b-row class="mt-3 mb-3" >

     <b-col class="col-6">
            <strong v-if="isBusy">Loading...</strong>
     </b-col>

     <b-col class="col-6 text-right">
         {{ requests.length }} Results
     </b-col> 

      <b-col>
             <b-table 
                striped 
                responsive 
                hover 
				:items="requests" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                :busy="isBusy"
                dark
                >
                 <template #cell(status)="data">
                    <div class="statuscircle" :style="{ backgroundColor: data.item.Color }" />
                </template>

                </b-table>
       </b-col>             
</b-row>            
  
</b-container>
		
</div> 
</template>

<script>
    import CasterSearch from './CasterSearch'
    import moment from 'moment'
    import axios from 'axios'
	export default {
        name:'CasterLogMessages',
        components:{
            CasterSearch
        }, 
        data(){
		return {
    
                token:'',
                requests:[],
                rovers:[],
                basestations:[],
                showrovers:false,
                usernamesel: null,
                basenamesel: null,
                showbases:false,
                basesonly:0,
                auto:false,
                timeout:false,
                filterstart:'',
                filterend:'',
                fields:[
                { key: 'status', label: '' },    
                { key: 'Timestamp', label: 'Date/Time', formatter: 'setTime', sortable: true, style:'break' },
                { key: 'Username', label: 'Name', sortable: true },
                { key: 'Basestation', label: 'Reference Station', sortable: true },
                { key: 'Message_code', label: 'Messsage', sortable: true },
                { key: 'Type_code', label: 'Type', sortable: true },
                { key: 'Session_id', label: 'Session ID', sortable: true },
                { key: 'Description', label: 'Message', sortable: true },
               ],
                sortBy: 'value',
                sortDesc: false,
                isBusy: false, 
                };
                
                },

		created(){
            this.$store.commit('setbasesdatapoll',0)
            this.token = this.$store.getters.token
            this.fetchRequests()
        },

        beforeDestroy () {
            clearInterval(this.timeout)
        },

		methods: {

            fetchRequests(){
				this.isBusy = true
				axios.get('/api/casterlog',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         rover:this.usernamesel,
                         basestation:this.basenamesel,
                         start:this.filterstart,
                         end:this.filterend,
                         basesonly:this.basesonly
                         }
                })
				.then((response) => {
                    this.requests = response.data.Log
                    this.isBusy = false
                     if(this.auto == true){
                                clearTimeout(this.timeout)
                                this.timeout = setTimeout(this.fetchRequests, 5000)
                            }
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });

			},

            fetchRovers(){
                
				axios.get('/api/indexrovers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                        username:this.usernamesel
                    }
                })
				.then((response) => {
                    this.rovers = response.data.rovers
                    if(this.rovers.length > 0){this.showrovers = true} else this.showrovers = false
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
            
            fetchBasestations(){

				axios.get('/api/basestations',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                            basename:this.basenamesel
                    }
                })
				.then((response) => {
                        this.basestations = response.data.basestations
                        if(this.basestations.length > 0){this.showbases = true} else this.showbases = false
                    })
                    .catch(err => {
                        this.$swal({
                            title: "System Error",
                            text: err,
                            icon: "error",
                            timer: 3000,
                            showCloseButton: false,
                            showConfirmButton: false
                            
                            })
                    });
			},
            
           filterBaseOnly(){
               this.fetchRequests()
           }, 

            roverSel(rover){
                this.usernamesel = rover.username
                this.showrovers = false
                this.timeout = false
                this.fetchRequests()
           }, 
            
           updateSearch(data){
                this.usernamesel = data
                this.fetchRovers()
           }, 
           
           clearSearch(){
                this.usernamesel = null
                this.rovers = []
                this.showrovers = false
                this.timeout = false
                this.fetchRequests()
           },

           baseSel(base){
                this.basenamesel = base.text
                this.showbases = false
                this.timeout = false
                this.fetchRequests()
           }, 

            updateSearchBS(data){
                this.basenamesel = data
                this.fetchBasestations()
           }, 
           
           clearSearchBS(){
                this.basenamesel = null
                this.basestations = []
                this.showbases = false
                this.timeout = false
                this.fetchRequests()
           },

           filterDates(){
                this.timeout = false
                this.fetchRequests()
           },

            clearDates(){
                this.timeout = false
                this.filterstart = ''
                this.filterend = ''
                this.fetchRequests()
           },

            autoRefresh(){
                this.auto = !this.auto
                 if(this.auto == true){
                    this.fetchRequests()
                    } else {
                        clearTimeout(this.polldata)
                        }
            },

            setTime(datetime){  
                return  moment.utc(datetime).local().format('DD-MM-YY HH:mm:ss')
            },

		}	   
	
	};
</script>