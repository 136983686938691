<template>
<!-- <div ref="mapcont" class="setmapmin imageBorder">  -->
  <div ref="mapcont" :class="setimageborder">
  
  <div class="titleholder" v-if="border">ROVERS</div>
  <div class="leafletfullscreen">
    <b-button variant="outline-success" size="sm" @click.stop="resetZoom()" ><b-icon-arrow-clockwise v-b-tooltip.hover title="Refresh" /></b-button>
    <b-button variant="outline-success" size="sm" @click.stop="toggleSat()" ><b-icon-image v-b-tooltip.hover title="Show terrain" /></b-button>
    <b-button v-if="border" variant="outline-success" size="sm" @click.stop="fullScreen()"><b-icon-fullscreen v-b-tooltip.hover title="Expand" /></b-button>
  </div>

  <div class="leafletrtk">
    <div class="rtkstatus" style="background-color:rgba(60, 210, 165, 0.7)">FIXED</div>
    <div class="rtkstatus" style="background-color:rgba(48, 190, 255, 0.7)">FLOAT</div>
    <div class="rtkstatus" style="background-color:rgba(255, 159, 12, 0.7)">DGPS</div>
    <div class="rtkstatus" style="background-color:rgba(255, 96, 96, 0.7);min-width:46px;text-align:center">GPS</div>
    <div class="rtkstatus" style="background-color:rgba(102, 102, 102, 0.7);min-width:46px;text-align:center">EST</div>
  </div>

            <v-map 
            ref="myMap"
            :center="currentCenter"
            :zoom="currentZoom"
            :options="mapOptions"
            :style="{height: mapheight + 'px', width: mapwidth + 'px', left: mapleft + 'px', top: maptop + 'px'}"
            :noBlockingAnimations="blockanimation"
            v-if="mapshow"
            >
          
          <v-tilelayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" />

          <v-marker-cluster ref="myCluster" :key="mapkey" :options="clusterOptions">

          <div v-for="rover in roverslist" :key="rover.value">
            
            <v-marker
                v-if="rover.lastgga !== null && rover.latitude !== null"
                :key="rover.id"
                :lat-lng="[rover.latitude,rover.longitude,rover.status]"
                @click="viewRover(rover)"
               >
           
            <v-icon
              :icon-anchor="staticAnchor"
              class-name="iconclass"
              >
              <div class="lmarkerhold" :style="{ backgroundColor:rover.color }" />
              <div class="headline" :style="{ backgroundColor:rover.color,fontSize:fontsize }" >{{rover.name}}</div>
            </v-icon>

     
             </v-marker> 

             </div>

             </v-marker-cluster> 
          </v-map>

</div>
</template>

<style scoped>

.lmarkerhold{
  
  width:10px;
  height:10px;
  border-radius:5px;
  z-index:998;
  
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	transform: scale(1);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {transform: scale(0.70);}
  70% {transform: scale(1.1);}
  100% {transform: scale(0.70);}
}

.mapstyle{
  border:2px solid black;
}


.iconclass img{
    width:16px;
    height:16px;
} 


.mapmarker{
  width:80px;
  height:80px;
}

.mapmarker2{
  width:auto;
  height:20px;
}



</style>

<script>
  import { mapState } from 'vuex'
  import markericon from '@/assets/images/mapmarker.png'
  import "leaflet/dist/leaflet.css"
  import "leaflet.markercluster/dist/MarkerCluster.css"
  import "leaflet.markercluster/dist/MarkerCluster.Default.css"
  import * as Vue2Leaflet from 'vue2-leaflet'
  import { latLng } from "leaflet"
  import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
  import { divIcon as DivIcon, point as Point } from "leaflet"
  import * as L from 'leaflet'
  
  export default {
    components: {
      'v-map': Vue2Leaflet.LMap,
      'v-marker': Vue2Leaflet.LMarker,   
      'v-icon': Vue2Leaflet.LIcon,
      'v-tilelayer': Vue2Leaflet.LTileLayer,
      'v-marker-cluster': Vue2LeafletMarkerCluster
 
    },
      props:['border','fsmapheight','fsmapwidth','networkdisplay'],
      data(){
        return {
                token:'',
                rovers:[],
                roverslist:[],
                points:[],
                pointsfix:[],
                staticAnchor: [0,0],
                fontsize:'1em',
                setmapwidth:0,
                mapshow:false,
                showsat:false,
                setimageborder:'imageBorder setmapmin',
                blockanimation:true,
                timeout: '',
                currentZoom:6,
                mapkey:0,
                mapheight:0,
                mapwidth:0,
                maptop:10,
                mapleft:10,
                satlayergroup: {},
                currentCenter: latLng(54.188255397553895, -2.482830042690006),
                setbounds:true,
                markericon,
                mapOptions: {
                    zoomSnap: 0.5
                  },
                clusterOptions: {
                      maxClusterRadius: 30,
                      showCoverageOnHover: false,
                      iconCreateFunction: cluster => {
                        let markers = cluster.getAllChildMarkers()
                        let childCount = cluster.getChildCount();
                        let rtkzero = 0
                        let rtkfix = 0
                        let rtkfloat = 0
                        let rtkdgps = 0
                        let rtkstand = 0
                        let status = 0
                        let values = ''

                       markers.forEach(element => {
                          status = element['_latlng']['alt']
                        
                          switch (status){
                            case 0:
                            rtkzero++
                            break
                            case 1:
                            rtkstand++
                            break
                            case 2:
                            rtkdgps++
                            break
                            case 4:
                            rtkfix++
                            break
                            case 5:
                            rtkfloat++
                            break
                          }

                           })

                          let first = 0
                          if(rtkzero != 0){
                            rtkzero = (rtkzero/childCount * 100) * 3.6
                            values += 'rgba(128, 0, 128, 0.7) '+first+'deg '+Number(rtkzero+first)+'deg,'
                            first = Number(rtkzero+first)
                            }
                          if(rtkstand != 0){
                            rtkstand = (rtkstand/childCount * 100) * 3.6
                            values += 'rgba(255, 96, 96, 0.7) '+first+'deg '+Number(rtkstand+first)+'deg,'
                            first = Number(rtkstand+first)
                            }
                          if(rtkdgps != 0){
                              rtkdgps = (rtkdgps/childCount * 100) * 3.6
                              values += 'rgba(255, 159, 12, 0.7)  '+first+'deg '+Number(rtkdgps+first)+'deg,'
                              first = Number(rtkdgps+first)
                              }
                          if(rtkfloat != 0){
                              rtkfloat = (rtkfloat/childCount * 100) * 3.6
                              values += 'rgba(48, 190, 255, 0.7) '+first+'deg '+Number(rtkfloat+first)+'deg,'
                              first = Number(rtkfloat+first)
                            }
                          if(rtkfix != 0){
                              rtkfix = (
                              rtkfix/childCount * 100) * 3.6
                              values += 'rgba(60, 210, 165, 0.7) '+first+'deg '+Number(rtkfix+first)+'deg,'
                            }
                      
                           values =  values.slice(0, -1)

                    return new DivIcon({
                      html: "<div class='leafletcluster' style='background-image: conic-gradient("+values+");'><span class='cmarker'>"+childCount+"</span></div>",
                      className: "xpietest",
                      iconSize: new Point(40, 40)
                    });
                  }
                }, 
                };
                
              },

        beforeDestroy() {
               window.removeEventListener("resize",this.setMapSize)
               this.setbounds = false
        },       

        created(){
                window.addEventListener("resize", this.setMapSize)
                this.setCountry()
                this.satlayergroup = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x} 28', {attribution: ''});
        },

        mounted(){
          this.rovers = this.roversdata.rovers
          this.setMapSize()
          this.showData()
        },
        
      computed: mapState(['countryid','roversdata','openmenu','countryid']),

      watch: {
                  countryid: function () {
                    this.setCountry()
                    this.setbounds = true
                    this.showData()
                  },

                  roversdata: function () {
                    this.rovers = this.roversdata
                    this.showData()
                  },

                  openmenu: function () {
                    this.setMapSize()
                  },

                
       },

		methods: {

         setCountry(){
              switch(this.countryid){
                case 0:
                this.currentCenter = latLng(55.128541689922706, 2.9889090697207674)
                break;
                case 1:
                this.currentCenter = latLng(54.188255397553895, -2.482830042690006)
                break;
                case 2:
                this.currentCenter = latLng(55.959618925597255, 9.13289438112091)
                break;    
              }
          },


          showData(){
                        if(this.rovers == undefined){return}
                      
                        let mappoints = []
                        let pointsbounds = []

                        if(this.rovers.length > 0){

                         this.rovers.forEach(element => {
                            if(element.latitude !== null){
                                 pointsbounds.push({lat:element.latitude,lng:element.longitude})
                                 mappoints.push({id:element.value,latitude:element.latitude,longitude:element.longitude,color:element.color,name:element.text,status:element.rtk_fix_status})
                                  }
                             });

                          this.roverslist = mappoints
                          this.pointsfix = pointsbounds
 
                         if(this.roverslist.length > 1 && this.setbounds){ this.setBounds() } else this.setCenter()

                          this.mapshow = true
                          this.mapkey++

                        } else this.mapshow = false
           } ,
           
          setBounds(){ 
           
             if(this.$refs.myMap !== undefined && this.roverslist.length > 1){ 
                    this.$refs.myMap.mapObject.fitBounds(this.pointsfix, {padding: [50,50]})
                    this.setbounds = false
                    }
          },
          
       
          setCenter(){
              if(this.pointsfix.length == 1){
                this.currentCenter = latLng(this.pointsfix[0].lat, this.pointsfix[0].lng)
              }
          },

          resetZoom(){
              this.setbounds = true
              this.setBounds()
          },  
       
          toggleSat(){
                if(this.showsat == false){this.satlayergroup.addTo(this.$refs.myMap.mapObject)} else this.$refs.myMap.mapObject.removeLayer(this.satlayergroup)
                this.showsat = !this.showsat
          },

          viewRover(rover){
            this.$router.push({ name: 'casterrover', params: {id:rover.id} }).catch(()=>{})
          },

          setMapSize(){

          if(this.fsmapheight == undefined){
            this.mapwidth = this.$refs.mapcont.clientWidth + 24
            this.mapheight = this.$refs.mapcont.clientHeight + 24
          } else {
             this.mapheight = this.fsmapheight
             this.mapwidth = this.fsmapwidth
          }

          

          if(this.networkdisplay !== undefined){
            this.setimageborder = 'setmapmin'
            this.maptop = 0
            this.mapleft = 0
            this.mapheight = this.mapheight - 65
            //this.mapwidth = this.mapwidth - 15
            console.log('set nd:'+this.networkdisplay)
          }
              
             this.mapshow = true
       
          },

          fullScreen(){
              this.$emit('fullscreen')
          }

		}	   
	
	};

</script> 


