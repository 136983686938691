<template>
<div>
<b-container class="mainpagecont">

<b-row>
    <b-col class="col-12 col-sm-6 mt-3">
        <h4>System Reports</h4>
        <h5>{{ filterstart }} - {{ filterend }}</h5>
    </b-col>
    <b-col class="col-12 col-sm-3 offset-sm-3 text-right mt-3">
        <b-button variant="outline-success" size="sm" block @click.stop="emailReport()">Email report</b-button>
    </b-col>
</b-row>  

<b-row>

    <b-col class="col-5 col-sm-3 mt-3">
            <label>Start Date</label>
            <VueCtkDateTimePicker
                        v-model="filterstart"
                        format='DD/MM/YYYY'
                        :dark="true"
                        :no-value-to-custom-elem="true"
                        :only-date="true"
                        >
                        <b-form-input 
                            type="search"
                            v-model="filterstart" 
                            autocomplete = "off"
                            placeholder="automatic"
                            class="darkinput2"
                            size="sm"
                            />
                        </VueCtkDateTimePicker>    

    </b-col>  

    <b-col class="col-5 col-sm-3 mt-3">
        <label>End Date</label>
            <VueCtkDateTimePicker
                        v-model="filterend"
                        format='DD/MM/YYYY'
                        :dark="true"
                        :no-value-to-custom-elem="true"
                        :only-date="true"
                        >
                        <b-form-input 
                            type="search"
                            v-model="filterend" 
                            autocomplete = "off"
                            placeholder="automatic"
                            class="darkinput2"
                            size="sm"
                            />
                        </VueCtkDateTimePicker>    

    </b-col>

    <b-col class="col-2 col-sm-3 mt-3">
        <b-button variant="outline-success" size="sm" @click="setDates()" style="margin-top:32px"> set dates </b-button>
    </b-col>

</b-row>

<b-row class="mt-5">
    <b-col class="col-12 col-md-6"><h6>UK</h6>
        <ul>
                <li>{{ reportdata.activesubsuk }} Active subscription<span v-if="reportdata.activesubsuk != 1">s</span></li>
                <li>{{ reportdata.newsubsuk }} New subscription<span v-if="reportdata.newsubsuk != 1">s</span></li>
                <li>{{ reportdata.renewsubsuk }} Renewed subscription<span v-if="reportdata.renewsubsuk != 1">s</span></li>
                <li>{{ reportdata.newdealersuk }} 
                    New dealer<span v-if="reportdata.newdealersuk != 1">s</span>
                </li> 
                <li>{{ reportdata.newcompanysuk }} 
                    New compan<span v-if="reportdata.newcompanysuk != 1">ies</span><span v-else>y</span>
                </li>    
            </ul>
    </b-col>

    <b-col class="col-12 col-md-6"><h6>Denmark</h6>
            <ul>
                <li>{{ reportdata.activesubsdn }} Active subscription<span v-if="reportdata.activesubsdn != 1">s</span></li>
                <li>{{ reportdata.newsubsdn }} New subscription<span v-if="reportdata.newsubsdn != 1">s</span></li>
                <li>{{ reportdata.renewsubsdn }} Renewed subscription<span v-if="reportdata.renewsubsdn != 1">s</span></li>
                <li>{{ reportdata.newdealersdn }} 
                    New dealer<span v-if="reportdata.newdealersdn != 1">s</span>
                </li> 
                <li>{{ reportdata.newcompanysdn }} 
                    New compan<span v-if="reportdata.newcompanysdn != 1">ies</span><span v-else>y</span>
                </li>  
            </ul>
    </b-col>
    
</b-row>  

<b-row class="mt-3">
    <b-col class="col-12">UK Subscription changes by Reseller</b-col>
    <b-col class="col-12">
        <b-table
                responsive 
                hover 
                :items="reportdata.byreseller" 
                :fields="byreseller"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :busy="isBusy"
                dark
                >
                <template #table-busy>
                <div class="text-center text-warning my-2">
                <b-spinner class="align-middle"></b-spinner>
                <h5 style="margin-top:10px"><strong>...Loading</strong></h5>
                </div>
            </template>
                
        </b-table>        
    </b-col>
</b-row>

<b-row class="mt-3">
    <b-col class="col-12">UK Subscription changes by Dealer</b-col>
    <b-col class="col-12">
        <b-table
                responsive 
                hover 
                :items="reportdata.bydealeruk"
                :fields="bydealer"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :busy="isBusy"
                dark
                >
                <template #table-busy>
                    <div class="text-center text-warning my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <h5 style="margin-top:10px"><strong>...Loading</strong></h5>
                    </div>
                </template>
        </b-table>        
                
    </b-col>
</b-row>

<b-row class="mt-3 mb-5">
    <b-col class="col-12">UK Subscription changes by Company</b-col>
    <b-col class="col-12">
        <b-table
                responsive 
                hover 
                :items="reportdata.bycompanyuk"
                :fields="bycompany"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :busy="isBusy"
                dark
                >
                <template #table-busy>
                    <div class="text-center text-warning my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <h5 style="margin-top:10px"><strong>...Loading</strong></h5>
                    </div>
                </template>
        </b-table>        
                
    </b-col>
</b-row>

<b-modal ref="email-report" size="md" title="Email Report" hide-footer centered content-class="darkmodal">
 <b-row>
    <b-col class="col-12">
            <label>Email Address</label>
            <b-form-input 
                type="search"
                placeholder=""
                autocomplete="off" 
                v-model="emailto" 
                class="darkinput"
                size="md"
                />
    </b-col>

    <b-col class="col-12 mt-5">
        <b-button v-if="!sendOK" variant="outline-success" block @click.stop="sendEmail">SEND</b-button>
        <b-button v-else variant="outline-success" block disabled @click.stop="sendEmail">EMAIL SENT</b-button>
    </b-col>

 </b-row>
</b-modal>

</b-container>
</div> 
</template>


<script>
import axios from 'axios'
import moment from 'moment'
export default {
    name:'CasterReports',
    components:{
        
    },  
    data(){
        return {
            sizes: ['Small', 'Medium', 'Large', 'Extra Large'],
            token:'',
            filterstart:'',
            filterend:'',
            reportdata:{},
            searchtext:'',
            isBusy: true,
            emailto:'',
            sendOK:false,
            byreseller:[
            { key: 'reseller', label: 'Reseller', sortable: true  },      
            { key: 'newsubs', label: 'New', sortable: true  },    
            { key: 'renewsubs', label: 'Renewed', sortable: true  },
            { key: 'expire', label: 'Expired', sortable: true  },
            { key: 'active', label: 'Active', sortable: true  },
            ],
            bydealer:[
            { key: 'reseller', label: 'Reseller', sortable: true  },
            { key: 'dealer', label: 'Dealer', sortable: true  },      
            { key: 'newsubs', label: 'New', sortable: true  },    
            { key: 'renewsubs', label: 'Renewed', sortable: true  },
            { key: 'expire', label: 'Expired', sortable: true  },
            { key: 'active', label: 'Active', sortable: true  },
            ],
            bycompany:[
            { key: 'reseller', label: 'Reseller', sortable: true  },
            { key: 'dealer', label: 'Dealer', sortable: true  },
            { key: 'company', label: 'Company', sortable: true  },      
            { key: 'newsubs', label: 'New', sortable: true  },    
            { key: 'renewsubs', label: 'Renewed', sortable: true  },
            { key: 'expire', label: 'Expired', sortable: true  },
            { key: 'active', label: 'Active', sortable: true  },
            ]
            };
    },
	created(){
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setbasesdatapoll',0)
            this.token = this.$store.getters.token

            this.emailto = this.$store.getters.user.email

            moment.updateLocale('en', {week: {dow: 1,}}) // Monday is the first day of the week
            const today = moment()
            this.filterend = today.format('DD/MM/YYYY')
            this.filterstart = today.startOf('week').format('DD/MM/YYYY')
            
            this.fetchReports()
    },
    
	methods: {

            setDates(){
                this.fetchReports()
            },

            fetchReports(){
               this.isBusy = true 
               axios.get('/api/activityreports',{
                   headers: {"Authorization" : "Bearer " + this.token},
                   params:{
                       userid:this.$store.getters.user.id,
                       token:this.token,
                       start:this.filterstart,
                       end:this.filterend
                    }
               })
               .then((response) => {
                    this.isBusy = false
                   if(response.data.code == 999){
                       this.errorresponse = response.data
                       return;
                   } else {
                        this.reportdata = response.data
                   }
                  
               }).catch( err => {
                   console.error("CasterReports, fetchReports :",err)
               })
            },

            emailReport(){
                this.$refs['email-report'].show()
            },

            sendEmail(){

                axios({
                    method: 'post', 
                    headers: { "Authorization": "Bearer " + this.token },
                    url: '/api/emailactivityreport',
                    data: {
                    userid: this.$store.getters.user.id,
                    token: this.token,
                    email:this.emailto,
                    data: this.reportdata
                    }
                })
                .then( response => {

                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        this.sendOK = false;
                        return;
                    }

                    this.sendOK = true
                            
                })
                .catch( err => {
                    console.error("CasterReports: sendemail :",err);
                })
            }    
		}	   
	};
</script> 