<template>
<div>

<div class="sectioncontcard">
      
<b-row class="mt-3" v-if="this.numrovers != 0">
    
    <b-col class="col-10 offset-1">
          <SubsChart :data="rovers" />
    </b-col>

    <b-col class="col-12 mt-3 text-center">
        <h6>{{ numrovers }} LOGGED ROVER<span v-if="numrovers != 1">S</span></h6>
    </b-col>

</b-row>


 <b-row class="mt-3" v-else>
    <b-col class="col-12 text-center pt-3 pb-3">
        <h5 style="color:#48A1BA;">No Logged Rovers Found</h5>
    </b-col>   
</b-row>


</div>	
</div>
</template>

<script>
    import SubsChart from './SubsChart'
    import { mapState } from 'vuex'
    export default {
        name:'CasterRoversLoggedCardSmall',
                components:{
                SubsChart
                },     
        data(){
            return {
                rovers:[],
                numrovers:0,
                };
                
                },

    created(){
            this.setData(this.$store.getters.chartdata)   
    },
    
    computed: mapState(['chartdata']),

    watch: {
            chartdata: function () {
                    this.setData(this.chartdata) 
                    }, 
    },

	methods: {

        setData(chartdata){
            if(chartdata.data !== undefined){
                this.rovers = chartdata.data  
                this.numfix = chartdata.data[3].value
                this.numrovers = chartdata.total
            }
        }
	}	   
	
	};

</script> 