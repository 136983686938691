<template>
<div>

<span>

<b-row class="mt-3" >
     <b-col class="col-12 col-md-8">
           <h6>ASSIGNED SUBSCRIPTIONS</h6>
      </b-col> 

      <b-col class="col-12 col-md-4 text-md-right">
        <label>Status</label>
        <b-form-select
                    v-model="substatus"
                    :options="statustypes"
                    class="darkinput2"
                    size="sm" 
                    />
    </b-col> 
</b-row>

<b-row class="mt-3">
    <b-col v-if="pages.length >  0" class="col-12 col-md-5 mt-3">{{ rstart }}-{{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-12 col-md-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>


     <b-col class="col-12 col-md-7 text-left text-md-right mt-3" v-if="total > limit">
          <b-btn v-for="page in pages" :key="page.value" size="sm" @click="gotoPage(page.value)" style="min-width:30px;margin-right:1px">{{ page.text }}</b-btn>      
    </b-col>
</b-row>    


<b-row class="mt-3" v-if="substatus != 2">

      <b-col>
             <b-table 
                responsive 
                hover 
                :items="subs" 
                :fields="fields3"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @row-clicked="showRover($event)"
                :sort-by="sortBy"
                :busy="isBusy"
                sort-icon-left
                dark
                >
                <template #cell(statuscolor)="data">
                  <div class="rtmarkerexpiry" :style="{ backgroundColor:data.item.color}" >{{ data.item.days }}</div>
                </template>

                <template #table-busy>
                    <div class="text-center text-warning my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <h5 style="margin-top:10px"><strong>...Loading</strong></h5>
                    </div>
                </template>

                </b-table>
            
    
</b-col>
</b-row>	

<b-row class="mt-3" v-else>

      <b-col>
             <b-table 
                responsive 
                hover 
                :items="subs" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @row-clicked="showRover($event)"
                :sort-by="sortBy"
                :busy="isBusy"
                sort-icon-left
                dark
                >
                <template #cell(statuscolor)="data">
                  <div class="rtmarkerexpiry" style="background-color:#3cd2a5" >{{ data.item.days }}</div>
                </template>

                <template #table-busy>
                    <div class="text-center text-warning my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <h5 style="margin-top:10px"><strong>...Loading</strong></h5>
                    </div>
                </template>

                </b-table>
            
    
</b-col>
</b-row>

</span>

<span v-if="nosubs">

<b-row class="mt-3" >
     <b-col class="col-12 col-sm-6 offset-sm-3 text-center p-5">
           <b-button variant="danger" block disabled><h6>NO SUBSCRIPTIONS FOUND</h6></b-button>
      </b-col> 
</b-row>

</span>

<b-modal ref="show-sub" size="lg" title="modaltitle" hide-header hide-footer centered content-class="darkmodal">
   <casterrovermanage />
</b-modal>  

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style scoped>
table-dark td {
    word-break: unset !important;
}
</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        props:['resellerid','dealerid','companyid','subtypeid','expired','stock','stext'],
        name:'CasterSubscriptionsTable',
        components:{
            ErrorLogging
        },
        data(){
            return {
                id:0,
                token:'',
                subs:[],
                subsel:0,
                fields:[],
                substatus:3,
                statustypes :[],
                sortBy:'days',
                sortBy2:'subscription[0].title',
                isBusy:true,
                display:false,
                errorresponse:{},
                nosubs:false,
                page:0,
                limit:100,
                total:0,
                rstart:1,
                rend:100,
                pages:[],
                fields3:[
                { key: 'companydetail', label: 'Company', sortable: true  },
                { key: 'dealerdetail', label: 'Dealer', sortable: true  },
                { key: 'roverdetail', label: 'Rover', sortable: true  },
                { key: 'statustext', label: 'Status', sortable: true  },
                { key: 'subscription', label: 'Type', sortable: true  },
                { key: 'startdate', label: 'Activation', sortable: true  },
                { key: 'enddate', label: 'Expiry Date', sortable: true  },
                { key: 'renewsent', label: 'Renew', sortable: true  },
                { key: 'statuscolor', label: 'Renews #Days' , sortable: true  } 
                ]
                };
                
                },

		created(){
           
            this.token = this.$store.getters.token
            this.setFields()
            this.updateSubs()
        },

         watch: {
                    resellerid: function () {
                        this.substatus = 3
                        this.page = 0
                        this.updateSubs()
                         }, 

                    dealerid: function () {
                        this.page = 0
                        this.updateSubs()
                        this.substatus = 3
                         }, 

                    companyid: function () {
                        this.page = 0
                        this.updateSubs()
                        this.substatus = 3
                         }, 

                    subtypeid: function () {
                        this.page = 0
                        this.updateSubs()
                         },  
                         
                    substatus: function () {
                         this.page = 0
                         this.updateSubs()
                         },    
                  
                    stext: function () {
                        this.page = 0
                         this.updateSubs()
                         },   
        },          

		methods: {

            gotoPage(page){
                this.page = page
                this.updateSubs()
            },

            updateSubs(){
                this.fetchSubs()
                this.fetchSubStatus()
            },

            updatePaging(){
                this.rstart = (this.page * this.limit) + 1
                this.rend = (this.rstart + this.limit) - 1
                if(this.rend > this.total){this.rend = this.total} 
            },

            setFields(){
                if(this.dealerid == 0){
                this.fields = [
                { key: 'company', label: 'Company', sortable: true  },
                { key: 'dealer', label: 'Dealer', sortable: true  },
                { key: 'rover', label: 'Rover', sortable: true  },
                { key: 'statustext', label: 'Status', sortable: true  },
                { key: 'subscription', label: 'Type', sortable: true  },
                { key: 'startdate', label: 'Activation', sortable: true  },
                { key: 'enddate', label: 'Expiry Date', sortable: true  },
                { key: 'statuscolor', label: 'Days Remaining' , sortable: true  }         
                ]
                }
                if(this.companyid == 0 && this.dealerid != 0){
                this.fields = [
                { key: 'company', label: 'Company', sortable: true  },
                { key: 'rover', label: 'Rover', sortable: true  },
                { key: 'statustext', label: 'Status', sortable: true  },
                { key: 'subscription', label: 'Type', sortable: true  },
                { key: 'startdate', label: 'Activation', sortable: true  },
                { key: 'enddate', label: 'Expiry Date', sortable: true  },
                { key: 'statuscolor', label: 'Days Remaining' , sortable: true  }     
                ]
                }
                if(this.companyid != 0){
                this.fields = [
                { key: 'rover', label: 'Rover', sortable: true  },
                { key: 'statustext', label: 'Status', sortable: true  },
                { key: 'subscription', label: 'Type', sortable: true  },
                { key: 'startdate', label: 'Activation', sortable: true  },
                { key: 'enddate', label: 'Expire', sortable: true  },
                { key: 'enddate', label: 'Expiry Date', sortable: true  },
                { key: 'statuscolor', label: 'Days Remaining' , sortable: true  }        
                ]
                 }
            },

            fetchSubs(){
                
                this.isBusy = true
				axios.get('/api/casterntripsubscriptions',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,   
                        reseller:this.resellerid,
                        dealer:this.dealerid,
                        company:this.companyid,
                        type:this.subtypeid,
                        status:this.substatus,
                        stext:this.stext,
                        stock:0,
                        page:this.page,
                        limit:this.limit
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        } else {
                            if(response.data.CasterSubs != undefined){
                                this.subs = response.data.CasterSubs
                                this.total = response.data.Total
                                this.pages = []
                                let pages = Math.ceil(this.total/this.limit)
                                
                                let count = 0
                                while(count < pages){
                                    this.pages.push({value:count,text:count+1})
                                    count++
                                }
                                this.updatePaging()
                                if(this.subs.length == 0){this.nosubs = true} else this.nosubs = false
                                this.isBusy = false
                                this.display = true
                            } else this.display = false
                        }
                    
                    });
			},

            fetchSubStatus(){
                this.statustypes = []
				axios.get('/api/getsubstatustypestotals',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.resellerid,
                        dealer:this.dealerid,
                        company:this.companyid,
                        stext:this.stext,
                        expired:1
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        } else {
                            this.statustypes= response.data
                         //   if(this.statustypes.length > 0){this.substatus = this.statustypes[0].value}
                            }
                   
                    });
			},

           showRover(event){
                
                let roversel = 0
                if(event.status == 2){roversel = event.roverid} else roversel = event.rover
            
               axios.get('/api/companyroverstest',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         id:roversel
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        } else {
                        let rover = response.data.rovers[0]
                        this.$router.push({ name: 'casterrovermanage', params: {id:rover.value} }).catch(()=>{})
                        }
                   });
			},

        }	
    };

</script> 


