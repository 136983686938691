<template>
    <div class="imageBorder">
            
            <b-row class="mt-0 p-3" >
                <b-col class="col-12 col-sm-6">REFERENCE STATION LOGS</b-col>
               
                <b-col class="col-12 col-sm-4 mb-1 offset-sm-2">
                    <label>Message Code</label>
                    <b-form-select 
                        class="darkinput2"  
                        v-model="messagesel" 
                        :options="messagecodes" 
                        @change="filterMessage($event)"  
                        size="sm"
                    />
                </b-col>
    
                <b-col class="col-12 mt-3">
                    <div style="height:300px;width:100%;overflow-y: scroll;">
                    <b-table
                    sticky-header="280px" 
                    striped 
                    responsive 
                    hover 
                    :items="baselogs" 
                    :fields="fields"
                    sort-icon-left
                    tbody-tr-class="rowClassSm"
                    thead-tr-class="rowHClass"
                    dark
                    >
                    </b-table>
                    </div>
                </b-col>    
            </b-row>
    </div>
    </template>
    
<script>
import axios from 'axios'
import moment from 'moment'
export default {
    props:['baseid'],
    data(){
    return {
        baselogs:[],
        messagesel:3,
        messagecodes: [
            { value: 3, text:'All'},
            { value: 1, text:'New relay stream connected'},
            { value: 2, text:'Source disconnected - timed out'}
        ],
        fields: [
            { key: 'Description', label: 'Description', sortable: true}, 
            //{ key: 'Type_code', label: 'Type Code', sortable: true}, 
            { key: 'Message_code', label: 'Code', sortable: true}, 
            { key: 'Timestamp', label: 'Date/time', formatter:'settime', sortable: true}, 
        ],
        token:0,
        timeout:''
        };
},

beforeDestroy () {
    clearTimeout(this.timeout)
}, 

created(){
    this.token = this.$store.getters.token
    this.getBaseLogs()
},

methods: {

    getBaseLogs(){
            
            axios.get('/api/basestationlogs',{
            headers: {"Authorization" : "Bearer " + this.token},
            params:{
                userid:this.$store.getters.user.id,
                token:this.$store.getters.user.token,
                id:this.baseid,
                messageid:this.messagesel
                }
        })
        .then((response) => {

            if(response.data.code == 999){
                this.errorresponse = response.data
                console.log(response.data)
                } else {
                    this.baselogs = response.data.logs
                    this.timeout = setTimeout(this.getBaseLogs, 5000)
                }       

            });
    },

    filterMessage(type){
        this.messagesel = type
        this.getBaseLogs()
    },

    settime(datetime){ 
        return moment(datetime,'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss DD-MM-YYYY')  
    }

}	   
        
};    
</script> 