<template>
<div class="imageBorder maxHeight"> 
        
        <b-row>
            <b-col class="boxtext" cols="9" > 
                ROVER EVENTS
            </b-col>
            <b-col class="text-right" cols="3">
                <b-icon class="mr-1" icon="key" v-b-tooltip.hover title="key" v-b-toggle.lastEventsKeyCollapse />
                <b-icon-fullscreen v-b-tooltip.hover title="Expand" @click.stop="expandPanel()"/> 
            </b-col>    
        </b-row>
        <b-row>
            <b-col>
                <b-collapse id="lastEventsKeyCollapse">
                <b-container>
                    <CasterLastEventsCardKey/>
                </b-container>
                <hr>
                <hr>
                </b-collapse>
            </b-col>
        </b-row>
        

        <b-row class="mt-1" >

            <b-col class="col-12" style="max-height:300px;overflow-y:hidden;font-size:0.7em">
           
                <b-table 
                striped 
                responsive 
                hover 
				:items="messagelist" 
                :fields="fields"
                sort-icon-left
                sticky-header="280px"
                tbody="rowClassSm"
                dark 
                >
                <template #cell(colour)="data">
                  <div class="statuscircle" :style="{ backgroundColor: data.item.Colour }" />
                </template>

                 <template #cell(message)="data">  
                    <b>{{ data.item.Username }}</b> {{ convertLocalTime(data.item.Timestamp) }} <br/>{{ data.item.Message }}
                </template>
                </b-table>
     
            </b-col>    
        </b-row>


<b-modal ref="expand-modal" size="xl" hide-footer centered title="ROVER EVENTS" content-class="darkmodal" @hide="closePanel()">
    <CasterLogMessagesComp /> 
</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div>
</template>

<style scoped>
.eventbutton{
    font-size:1.5em;
}
.maxHeight{
    max-height: 352px;
    overflow-y: scroll;
    overflow-x: hidden;
}
</style>

<script>
    import CasterLogMessagesComp from './CasterLogMessagesComp'
    import CasterLastEventsCardKey from './CasterLastEventsCardKey.vue'
    import ErrorLogging from './ErrorLogging'
    import {mapState} from 'vuex'   
    import moment from 'moment'
    export default {
        name:'CasterLastEventsCard',
        components:{
            CasterLogMessagesComp,
            ErrorLogging,
            CasterLastEventsCardKey
        }, 
        data(){
		return {
                resellid:0,
                dealerid:0,
                messagelist:[],
                fields: [
                    { key: 'Colour', label: 'STATUS', sortable: false}, 
                    { key: 'Message', label: 'LATEST EVENTS', sortable: false}, 
                ],
                localoffset : 0,
                display:false,
                errorresponse:{}
                };
                
                },

		created(){
            this.localoffset = new Date().getTimezoneOffset(); // -60 BST 0 GMT 
            this.messagelist = this.$store.getters.lasteventsdata
        },

        computed: mapState(['resellerid','lasteventsdata']),
                
                watch: {
                    
                        // resellerid: function () {
                        // this.resellid = this.resellerid    
                        // this.getLastEventMessages()
                        // }, 
                        
                        lasteventsdata: function() {
                            this.messagelist = this.lasteventsdata
                        }
                },        

      
       methods: {

            convertLocalTime(datetime){
                if(this.localoffset < 0){
                    return  moment.utc(datetime).local().format('DD-MM-YY HH:mm:ss')
                } else return datetime
            },

            expandPanel(){
                this.$refs['expand-modal'].show() 
            },

            closePanel(){
                this.$store.commit('setbasesdatapoll',1)
            },
        }	   
	
	};

</script> 