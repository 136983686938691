<template>
  <div ref="mapcont" :class="setimageborder">
      
          <div class="titleholder textfade" v-if="border">LOCATION</div> 
          <div class="leafletfullscreen">
            <b-button variant="outline-success" size="sm" @click.stop="resetZoom()" ><b-icon-arrow-clockwise v-b-tooltip.hover title="Refresh" /></b-button>
            <b-button variant="outline-success" size="sm" @click.stop="toggleSat()" ><b-icon-image v-b-tooltip.hover title="Show terrian" /></b-button>
            <b-button v-if="border" variant="outline-success" size="sm" @click.stop="fullScreen()"><b-icon-fullscreen v-b-tooltip.hover title="Expand" /></b-button>
          </div> 
  
              <v-map
              ref="myMap"
              :center="currentCenter"
              :zoom="currentZoom"
              :options="mapOptions"
              :style="{height: mapheight  + 'px', width: mapwidth + 'px', left: mapleft + 'px', top: maptop + 'px'}"
              @ready="onReady"
              :noBlockingAnimations="blockanimation"
              v-if="mapshow"
              >
  
            <v-tilelayer url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" />
  
            <v-marker-cluster ref="myCluster"  :key="mapKey" :options="clusterOptions">
  
            <div v-for="base in bases" :key="base.value">
  
              <v-marker
                  v-if="base.lastgga !== null && base.latitude !== null"
                  :key="base.id"
                  :lat-lng="[base.latitude,base.longitude,base.mapstatus]"
                  @click="viewBase(rover)"
                 >
             
              <v-icon
                :icon-anchor="staticAnchor"
                class-name="iconclass"
                >
                <div class="lmarkerhold" :style="{ backgroundColor:base.statuscolor }" />
                <div class="headline" :style="{ backgroundColor:base.statuscolor,fontSize:fontsize }" >{{base.text}}</div>
              </v-icon>
  
       
               </v-marker> 
  
               </div>
  
               </v-marker-cluster> 
            </v-map>
  
          
  </div>
  </template>
  
  <style scoped>
  
  .lmarkerhold{
    
    width:10px;
    height:10px;
    border-radius:5px;
    z-index:998;
    
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {transform: scale(0.70);}
    70% {transform: scale(1.1);}
    100% {transform: scale(0.70);}
  }
  
  .mapstyle{
    border:2px solid black;
  }
  
  
  .iconclass img{
      width:16px;
      height:16px;
  } 
  
  
  .mapmarker{
    width:80px;
    height:80px;
  }
  
  .mapmarker2{
    width:auto;
    height:20px;
  }
  
  </style>
  
  <script>
    import { mapState } from 'vuex'
    import Vue from 'vue'
    import markericon from '@/assets/images/mapmarker.png'
    import "leaflet/dist/leaflet.css"
    import "leaflet.markercluster/dist/MarkerCluster.css"
    import "leaflet.markercluster/dist/MarkerCluster.Default.css"
    import * as Vue2Leaflet from 'vue2-leaflet'
    import { latLng } from "leaflet"
    import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
    import { divIcon as DivIcon, point as Point } from "leaflet"
    import * as L from 'leaflet'
  
    export default {
      components: {
        'v-map': Vue2Leaflet.LMap,
        'v-marker': Vue2Leaflet.LMarker,   
        'v-icon': Vue2Leaflet.LIcon,
        'v-tilelayer': Vue2Leaflet.LTileLayer,
        'v-marker-cluster': Vue2LeafletMarkerCluster
   
      },
        props:['roverslist','border','fsmapheight','fsmapwidth','networkdisplay'],
          data(){
              return {
                  testmap:true,
                  token:'',
                  rovers:[],
                  bases:[],
                  points:[],
                  pointsfix:[],
                  staticAnchor: [0,0],
                  fontsize:'1em',
                  setmapwidth:0,
                  mapshow:false,
                  showsat:false,
                  setimageborder:'imageBorder setmapmin',
                  blockanimation:true,
                  currentZoom:6,
                  setbounds:true,
                  satlayergroup: {},
                  currentCenter: latLng(54.188255397553895, -2.482830042690006),
                  countrycode:0,
                  mapheight:0,
                  mapwidth:0,
                  maptop:10,
                  mapleft:10,
                  mapKey:0,
                  mapOptions: {
                      zoomSnap: 0.5
                    },
                  clusterOptions: {
                        maxClusterRadius: 30,
                        showCoverageOnHover: false,
                        iconCreateFunction: cluster => {
                          let markers = cluster.getAllChildMarkers()
                          let childCount = cluster.getChildCount();
                         
                          let online = 0
                          let offline = 0
                          let status = 0
                          let values = ''
                        
                         markers.forEach(element => {
                            status = element['_latlng']['alt']
                          
                            switch (status){
                              case 1:
                              offline++
                              break
                              case 4:
                              online++
                              break
                            }
  
                             })
  
                          
                            let first = 0
                           
                            if(offline != 0){
                              offline = (offline/childCount * 100) * 3.6
                              values += 'rgba(255, 96, 96, 0.7) '+first+'deg '+Number(offline+first)+'deg,'
                              first = Number(offline+first)
                              }
                            if(online != 0){
                              online = (
                                online/childCount * 100) * 3.6
                                values += 'rgba(60, 210, 165, 0.7) '+first+'deg '+Number(online+first)+'deg,'
                              }
                        
                             values =  values.slice(0, -1)
  
                      return new DivIcon({
                        html: "<div class='leafletcluster' style='background-image: conic-gradient("+values+");'><span class='cmarker'>"+childCount+"</span></div>",
                        className: "xpietest",
                        iconSize: new Point(40, 40)
                      });

                    }
                    
                 }, 
          
                  };
                  
                },
  
      computed: mapState(['countryid','openmenu','basesdata']),
  
      watch: {
                basesdata: function () {
                  //console.log(this.basesdata)
                    this.updateMap()
                },
  
                countryid: function () {
                    this.countrycode = this.countryid
                    this.setCountry(0)
                },

                openmenu: function () {
                    this.setMapSize()
                }
            },
  
      created(){
                this.countrycode = this.$store.getters.countryid
                window.addEventListener("resize", this.setMapSize)
          },
          
      beforeDestroy() {
            window.removeEventListener("resize",this.setMapSize)
      }, 

      async mounted(){

         this.setMapSize()
         delete L.Icon.Default.prototype._getIconUrl;

           L.Icon.Default.mergeOptions({
               iconUrl: markericon,
               shadowUrl: markericon
           });

         await Vue.nextTick();
         this.showmap = true

       },
    
      methods: {
  
            onReady() {
              this.satlayergroup = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {attribution: ''});
              this.pointsfix.push(latLng(58.69402278444607, -11.33908970168965),latLng(58.860052177684516, 2.0331069415086267),latLng(50.008138177483104, -10.878669757387913),latLng(50.46905998970061, 3.7546771680281634))
              this.setCountry(1)
            },

         
            setCountry(loadmap){
                switch(this.countrycode){
                  case 0:
                  this.currentCenter = latLng(55.128541689922706, 2.9889090697207674)
                  break;
                  case 1:
                  this.currentCenter = latLng(54.188255397553895, -2.482830042690006)
                  break;
                  case 2:
                  this.currentCenter = latLng(55.959618925597255, 9.13289438112091)
                  break;    
                }

                if(loadmap == 1){this.updateMap()}
            },
  
            updateMap(){
              this.bases = []     
              this.bases = this.basesdata.basestations

                      if(this.bases.length > 0){
                        let points = []
                        let pointsbounds = []

                        this.bases.forEach(element => {
                            if(element.latitude !== null){
                              pointsbounds.push({lat:element.latitude,lng:element.longitude})
                              points.push([element.latitude,element.longitude])
                            }
                        })
                        this.points = points
                        this.pointsfix = pointsbounds
                     this.mapKey++
                   

                      if(this.bases.length > 1){ this.setBounds() } else this.setCenter()

                      }
                  
            },

            setBounds(){ 
              if(this.$refs.myMap !== undefined && this.setbounds){ 
                      this.$refs.myMap.mapObject.fitBounds(this.pointsfix, {padding: [20, 20]})
                      this.setbounds = false
                    }
            }, 
       
            setCenter(){
                if(this.pointsfix.length == 1){
                  this.currentCenter = latLng(this.pointsfix[0].lat, this.pointsfix[0].lng)
                }
            },

            resetZoom(){
                this.setbounds = true
                this.setBounds()
            },
  
            toggleSat(){
                  if(this.showsat == false){this.satlayergroup.addTo(this.$refs.myMap.mapObject)} else this.$refs.myMap.mapObject.removeLayer(this.satlayergroup)
                  this.showsat = !this.showsat
            },
  
            viewBase(base){
              this.$router.push({ name: 'casterreferencestation', params: {id:base.id} }).catch(()=>{})
            },
  
            setMapSize(){

              if(this.fsmapheight == undefined){
                this.mapwidth = this.$refs.mapcont.clientWidth + 24
                this.mapheight = this.$refs.mapcont.clientHeight + 24
              } else {
                this.mapheight = this.fsmapheight
                this.mapwidth = this.fsmapwidth
              }

              if(this.networkdisplay !== undefined){
                this.setimageborder = 'setmapmin'
                this.maptop = 0
                this.mapleft = 0
                this.mapheight = this.mapheight - 65
                //this.mapwidth = this.mapwidth - 15
            }
                 
              this.mapshow = true
          },
  
            fullScreen(){
                this.$emit('fullscreen')
            }
      }	   
    
    };
  
  </script> 
  
  
  