<template>
<div class="imageBorder" style="color:rgba(255,255,255,0.7)">       
    
        <b-row>
            <b-col class="col-12 col-sm-9 textfade">DETAILS</b-col>
            <b-col class="col-12 col-sm-3 text-right" v-if="role==1">
                <b-button variant="outline-success" size="sm" @click.stop="editBase()"><b-icon-pencil /></b-button>
            </b-col>
        </b-row>

        <b-row class="mt-3">
                <b-col class="col-5">Status</b-col>
                <b-col class="col-7" v-if="refstation.status == 1">Online</b-col>
                <b-col class="col-7" v-else>Offline</b-col>
                <b-col class="col-5">Connection</b-col>
                <b-col class="col-7" v-if="refstation.status == 1">{{ duration }}</b-col>
                <b-col class="col-7" v-else> - </b-col>
                <b-col class="col-5">Last Connected</b-col>
                <b-col class="col-7">{{ refstation.connected_time }}</b-col>
                <b-col class="col-5">Last Disconnected</b-col>
                <b-col class="col-7">{{ refstation.disconnected_time }}</b-col>
                <!-- <b-col class="col-5">Last RTCM3</b-col>
                <b-col class="col-7">{{ lastrtcm3 }}</b-col> -->
        </b-row>

        <b-row v-if="refstation.status == 1"> 
                <b-col class="col-5">Satellites</b-col>
                <b-col class="col-7" v-if="refstation.status == 1">{{ refstation.gsats }}/GPS {{ refstation.rsats }}/GLONASS </b-col>
                <b-col class="col-7" v-else> - </b-col>       
                <b-col class="col-5">Station ID</b-col>
                <b-col class="col-7">{{ refstation.station_id }}</b-col>
                <!-- <b-col class="col-5">Last RTCM3</b-col>
                <b-col class="col-7">{{ lastrtcm3 }}</b-col> -->
        </b-row>
    
        <b-row v-if="role == 1">        
            <b-col class="col-5">Mountpoint</b-col>
            <b-col class="col-7">{{ refstation.mount }}</b-col>
        </b-row>
    
        <div v-if="role == 1">
                <b-row class="mt-3" v-if="refstation.type == 4">   <!--/ MK 4 Bases Only  -->
    
                    <b-col class="col-5">Network</b-col>
                    <b-col class="col-7">{{ refstation.network_carrier }}</b-col>
    
                    <b-col class="col-5">Type</b-col>
                    <b-col class="col-7">{{ refstation.network_type }}</b-col>
    
                    <b-col class="col-5">Signal</b-col>
                    <b-col class="col-7">{{ refstation.signal_strength }}</b-col>
    
                    <b-col class="col-5">ICCID</b-col>
                    <b-col class="col-7">{{ refstation.iccid }}</b-col>

                    <b-col class="col-5">Data Usage (MB)</b-col>
                    <b-col class="col-7">{{ refstation.datausage }}</b-col>
    
                    <b-col class="col-5 mt-3">AC OK</b-col>
                    <b-col class="col-7 mt-3"><span v-if="refstation.ac_ok == 1">Yes</span><span v-else>No</span></b-col>
    
                    <b-col class="col-5">Battery OK</b-col>
                    <b-col class="col-7"><span v-if="refstation.battery_ok == 1">Yes</span><span v-else>No</span></b-col>
    
                    <b-col class="col-5">Voltage</b-col>
                    <b-col class="col-7">{{ refstation.voltage }} V</b-col>
    
                    <b-col class="col-5">Current</b-col>
                    <b-col class="col-7">{{ refstation.current }} mA</b-col>
    
                    <b-col class="col-5">Power</b-col>
                    <b-col class="col-7">{{ refstation.power }} W</b-col>
    
                </b-row>
                
                <b-row class="mt-3" >
    
                    <b-col class="col-5">Virtual IP</b-col>
                    <b-col class="col-7">{{ refstation.virtual_ip }}</b-col>
                    <b-col class="col-5">Actual IP</b-col>
                    <b-col class="col-7">{{ refstation.actual_ip }}</b-col>
    
                </b-row>
                
                <b-row class="mt-3 pb-3" v-if="refstation.virtual_ip != null && refstation.virtual_ip != 'Unknown' && role == 1">    
                   
                    <b-col class="col-12 col-sm-6 mt-3">
                        <b-button variant="outline-success" size="sm" block class="linkbutton" style="text-align:center !important" @click.stop="remoteAccess(1)"><b-icon-cloud style="margin-right:15px"/>Device Access</b-button>
                    </b-col>
    
                    <b-col class="col-12 col-sm-6 mt-3">
                    <b-button variant="outline-success" size="sm" block class="linkbutton" style="text-align:center !important" @click.stop="remoteAccess(2)"><b-icon-cloud style="margin-right:15px"/>Modem Access</b-button>
                    </b-col>
                  
                </b-row>
                
            </div>
    
       
    <b-modal ref="remote-access"  :title="modaltitle" hide-footer centered content-class="darkmodal">
    
        <b-row v-if="refstation.status == 2">
            <b-col class="col-12 text-center">
                <h5>BASESTATION NOT CONNECTED</h5>
            </b-col>
        </b-row>    
    
        <b-row v-else>
            <b-col class="col-12 col-md-4 mt-3">
                Username
            </b-col>
    
             <b-col class="col-12 col-md-8 mt-3">
                {{ viewauser }}
            </b-col>
            <b-col class="col-12 col-md-4 mt-3">
                Password
            </b-col>
    
             <b-col class="col-12 col-md-8 mt-3">
                {{ viewapassword }}
            </b-col>
    
            <b-col class="col-12 mt-3">
                    <b-button variant="outline-warning" size="sm" @click.stop="copyText()">Copy password to clipboard</b-button>
                </b-col>
    
             <b-col class="col-12 mt-5">
                <b-button variant="outline-success" size="sm" block class="linkbutton" style="text-align:center !important" @click.stop="openViewa()"><b-icon-cloud style="margin-right:15px"/>Open ViewA</b-button>
            </b-col>
        </b-row>
    
    </b-modal>
    
    <b-modal ref="edit-base"  :title="modaltitle" size="md" hide-footer centered content-class="darkmodal">
       <CasterBaseEdit
        :id="refstation.value" 
        v-on:updatemodal="updateModal()"
        />
    </b-modal>
    

</div>
</template>
    
    <style scoped>
    .linkbutton{
        text-align:left !important;
        padding-top:5px;
        padding-bottom:5px;
       
        
    }
    
    .linkbutton:hover{
        background-color: #3cd2a5 !important;
        color:black !important;
    }
    </style>
    
    
    <script>
       import CasterBaseEdit from './CasterBaseEdit'
       import moment from 'moment'
        export default {
            props:['refstation'],
            name:'CasterReferenceStationDetails',
            components:{
                CasterBaseEdit
            },
            data(){
            return {
                    id:0,
                    baselivedata:{},
                    duration:'',
                    role:0,
                    lastrtcm3: '',
                    modaltitle:'',
                    viewauser:'admin',
                    viewapassword:'5xOtY6FHHJFP6ketgkH6BJtV8gIHM3jK',
                    type:1
                    };
                    
                    },
    
            mounted(){
                this.role = this.$store.getters.roleid
            },

           
            watch: {
                refstation: function() {
                    this.calculateTime()
                    this.convertLocal(this.refstation.lastrtcm3)
                }
            },
     
         
            methods: {

          
                    calculateTime(){
                        let date1 = moment.utc()
                        let date2 = moment(this.refstation.connected_time_clean) 
                        let diff = date1.diff(date2, 'seconds')
                        let days = parseInt(diff/86400)
                        let hours = Math.trunc(parseInt(diff - (days * 86400))/3600) 
                        let minutes = Math.trunc(parseInt((diff - (days * 86400) -(hours * 3600))/60)) 
                        let secs = Math.trunc((diff - (days * 86400) - (hours * 3600) - (minutes * 60))) 
                        this.duration = days+'d '+ hours +'h '+ minutes +'m '+ secs+'s'
                    },
    
              
                    convertLocal(utctime){
                        this.lastrtcm3 = moment.utc(utctime).local().format('DD-MM-YYYY HH:mm:ss');
                    },
    
                    remoteAccess(type){
                        this.type = type // 1 Device 2 - Modem
                        this.modaltitle = this.refstation.text
                        this.$refs['remote-access'].show()
                    },
    
                    editBase(){
                        this.modaltitle = 'Edit '+this.refstation.text
                        this.$refs['edit-base'].show()
                    },
    
                    updateModal(){
                        this.$refs['edit-base'].hide()
                    },
    
                    openViewa(){    //  1 Device 2 - Modem
                        let url = ''
                        let credentials = this.viewauser + ':' + this.viewapassword 
                        let encodedCredentials = encodeURIComponent(credentials)
                        let device = this.refstation.mount.toLowerCase()
                        if(this.type == 1){url = 'https://'+device+'.device.viewa.cloudbase-rtk.net'} else url = 'https://'+device+'.modem.viewa.cloudbase-rtk.net'
                        let authenticatedUrl = `${url}?auth=${encodedCredentials}`
                        window.open(authenticatedUrl, '_blank')
    
                    }, 
                    
                    copyText() {
                    navigator.clipboard.writeText(this.viewapassword)
                    },
            
            }	   
        
        };
</script> 