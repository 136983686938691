<template>
<div>



<b-row class="mt-3">
    <b-col class="col-12 text-right">{{ roverslist.length}} Result<span v-if="roverslist.length != 1">s</span></b-col> 
</b-row>    

<b-row class="mt-3 mb-5">

      <b-col>
             <b-table
                class ="nowrap"
                ref = "rovers-table" 
                responsive 
                hover 
                :items="roverslist"
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                sort-icon-left
                :busy="isBusy"
                sticky-header="500px"
                @row-clicked="showRover($event)"
                dark
                >
                <template #cell(color)="data">
                  <div class="rtmarker" :style="{ backgroundColor:data.item.color }" />
                </template>

                <template #cell(conntime)="data">
                        {{ calcConnTime(data.item)}}
                </template>

                <template #cell(discon_rover)="data">
                      <b-button size="sm" @click="disconnectRover(data.item.value)" class="mr-2 xsbutton" variant="outline-danger">
                        <b-icon-x />
                    </b-button>
                </template>  

                </b-table>
    
</b-col>
</b-row>


<!-- <b-row class="mt-3" v-else>
      <b-col class="col-12 text-center pt-3 pb-3">
          <h5>No Logged Rovers Found</h5>
       </b-col>   
</b-row> -->

<b-modal ref="reset-rover"  :title="modaltitle"  hide-footer centered content-class="darkmodal">
   <CasterRoverSessionControl :rover="rover"/>
</b-modal>

</div> 
</template>

<style scoped>
.nowrap td {
    white-space: nowrap !important;
}

.modal-content{
    border:none !important;
}
</style> 

<script>
    import CasterRoverSessionControl from './CasterRoverSessionControl'
    import moment from 'moment'
    import { mapState } from 'vuex'
    export default {
        props:['clearsearch','stext','statussel'],
        name:'CasterRoversLoggedTable',
        components:{
            CasterRoverSessionControl
        },
        data(){
            return {
                roverslist:[],
                rover:{},
                fields:[
                { key: 'color', label: '', sortable: false},
                { key: 'statustext', label: 'STATUS', sortable: true},        
                { key: 'text', label: 'ROVER', sortable: true  },
                { key: 'connect_time_rover', label: 'CONNECT TIME',  formatter: 'setTime', sortable: true},
                { key: 'conntime', label: 'DURATION', sortable: false},
                { key: 'time_to_fix', label: 'TIME TO FIX', sortable: true},
                { key: 'data_age', label: 'GGA AGE', sortable: true  },
                { key: 'distance', label: 'DISTANCE', sortable: true},
                { key: 'basestation', label: 'REF.STATION', sortable: true},
                { key: 'discon_rover', label: 'DISCONNECT', thClass: 'text-center' }
                ],
                isBusy:true,
                sortBy:'connect_time_rover',
                sortDesc:true,
                searchtext:'',
                modaltitle:'',
                filtertype:1 // Rover RTK Status
                };
                
                },

		created(){
                if(this.$store.getters.prevroute == 'casterrover'){
                    this.searchtext = this.$store.getters.searchtext
                } else {
                    this.$store.commit('setprevroute',this.$route.name)
                    this.searchtext = ''
                    this.$store.commit('setsearchtext','')
                }

                if(this.$store.getters.roversdata !== undefined){
                    if(this.$store.getters.roversdata.length > 0){
                        this.roverslist = this.$store.getters.roversdata
                        this.filterTable()
                        this.calculateStatusTotals(this.roverslist)
                    }
                }
        },

        computed: mapState(['roversdata']),

        watch: {
                    roversdata: function () {
                        console.log(this.roversdata)
                        if(this.roversdata.length > 0){
                            this.filterTable()
                            this.calculateStatusTotals(this.roversdata)
                        } 
                    },

                    stext: function() {
                        this.$emit('fetchresponse',this.stext)
                        this.filterTable()
                        this.calculateStatusTotals(this.roversdata)
                    },

                    statussel: function(){
                        this.filterTable()
                    }
       },
        
		methods: {

            filterTable(){
               
                this.roverslist = this.roversdata
                if(this.stext != ''){
                    this.roverslist = this.roverslist.filter(item => item.text.includes(this.stext.toUpperCase()))
                } 

                if(this.statussel != 0){
                    this.roverslist = this.roverslist.filter(item => item.rtk_fix_status == this.statussel)
                }
                
                this.isBusy = false

            },

            calculateStatusTotals(roversdata){
                const fixed = roversdata.filter(item => item.rtk_fix_status == 4).length
                const gps = roversdata.filter(item => item.rtk_fix_status == 1).length
                const dgps = roversdata.filter(item => item.rtk_fix_status == 2).length
                const float = roversdata.filter(item => item.rtk_fix_status == 5).length
                const conn = roversdata.filter(item => item.rtk_fix_status == 0).length
                const est = roversdata.filter(item => item.rtk_fix_status == 6).length

                const all = fixed+gps+dgps+float+conn+est
                const rtkstatus = [{'value':0,'text':'All ('+all+')'},{'value':4,'text':'Fixed ('+fixed+')'},{'value':1,'text':'GPS ('+gps+')'},{'value':2,'text':'DGPS ('+dgps+')'},{'value':5,'text':'Float ('+float+')'},{'value':9,'text':'Connected ('+conn+')'}]
                this.$emit('rtkstatus',rtkstatus)
            },

            disconnectRover(event){
                this.rover = this.roverslist.filter(rover => rover.value == event)[0]
                this.modaltitle = this.rover.text
                this.$refs['reset-rover'].show()
            },

            clearFilter(){
                this.searchtext = ''
                this.roverslist = this.roversdata
            },
            
            updateSearch(text){
                if(text !== ''){
                this.searchtext = text
                this.roverslist = this.roversdata.rovers.filter(item => item.text.includes(this.searchtext.toUpperCase()))
                }
            },

            formatMount(string){
                if(string !== null){return string.slice(1,string.length)} else return string
            },

            setTime(datetime){  // BST Fiddle
                    let time =  moment.utc(datetime).local().format('DD-MM-YY HH:mm:ss')
                    return time
                },


            calcConnTime(data){
                let date1 = moment()
                let date2 = moment.utc(data.connect_time_rover) 
                let diff = date1.diff(date2, 'seconds')
                return this.calculateTime(diff)
            },

            calculateTime(start){
                    let days = parseInt(start/86400)
                    let hours = Math.trunc(parseInt(start - (days * 86400))/3600) 
                    let minutes = Math.trunc(parseInt((start - (days * 86400) -(hours * 3600))/60)) 
                    let secs = Math.trunc((start - (days * 86400) - (hours * 3600) - (minutes * 60))) 
                    return days+'d '+ hours +'h '+ minutes +'m '+ secs+'s'
                },

             dataAge(end){
                    let now = moment(new Date()); 
                    end = moment(end * 1000).format('YYYY-MM-DD HH:mm:ss'); 
                    let diff = moment.duration(now.diff(end));
                    let hours = parseInt(diff.asHours()) 
                    let minutes = parseInt(diff.asMinutes())
                    minutes = minutes - (hours*60)
                    let secs = parseInt(diff.asSeconds())
                    secs = secs - (hours*60*60 + minutes*60) 
                    let duration = ('00'+hours).slice(-2)+':'+('00'+minutes).slice(-2)+':'+('00'+secs).slice(-2)
                    return duration
            },  

            showRover(event){
                this.$router.push({ name: 'casterrover', params: {id:event.value} }).catch(()=>{})
           }
        }	
    };

</script>