<script>
  
import { Bar } from 'vue-chartjs'

export default {
    extends: Bar,
    props:['data','height'],

    data(){
      return {
        token:'',
        total:0,
        chartdata: {
                hoverBackgroundColor: "red",
                hoverBorderWidth: 10,
                labels: [],
                datasets: [
                    {
                        label: 'Data One',
                          backgroundColor: [],
                          pointBackgroundColor: 'white',
                          borderWidth: 1,
                          pointBorderColor: '#249EBF',
                          data: []
                    }
                    ]
            },

        chartoptions: {
          
          responsive: true,
          maintainAspectRatio: false,
         
            plugins: {
                datalabels: {
                display: false,
                },
            },
            legend: {
                display:false,
                labels: {
                  fontColor: "#ccc",
                    }
            },
            scales: {
                yAxes: [{
             
                scaleLabel: {
                  display: true,
                  labelString: '',
                  fontColor:"#ccc"
                },       
                ticks: {
                  fontColor: "#ccc",  
                  stepSize: 20,
                  reverse: false,
                  beginAtZero: true,
                },
                gridLines: { 
                    color:  "#666",
                    zeroLineColor: "#666" },
              }],
                xAxes: [{
                gridLines: { zeroLineColor: "ccc" },  
                ticks: {
                fontColor: "#ccc",
            }
          }]
            },

        },


  };

},

  mounted () {
        this.token = this.$store.getters.token
        this.buildChart()  
  },

  methods:{

    buildChart(){


            Object.keys(this.data).forEach(key => {
           
                    if(this.data[key].value !== 0){
                    this.chartdata.datasets[0].data.push(this.data[key].value)
                    this.chartdata.labels.push(this.data[key].label)
                    this.chartdata.datasets[0].backgroundColor.push(this.data[key].color)
                    }
                 
                   });
                       
                   this.renderChart(this.chartdata, this.chartoptions)
            
    },

  },
}

</script> 