<template>
<div class="imageBorder">

<b-row v-if="total > 0">
        <b-col class="col-9 boxtext" >SUBSCRIPTIONS</b-col>
        <b-col class="col-3 text-right" >
                <b-icon-fullscreen @click.stop="fullScreen()" v-b-tooltip.hover title="Expand" />
        </b-col>
</b-row>      

<b-row v-if="total > 0" style="min-height:80px">

        <div class="charttextholder">
                <h2 class="boxtext_md">{{  total }}</h2>
                {{ subs[3].value }}/{{ total }} <span style="font-size:0.7em"> ACTIVE</span>
        </div>

        <div class="pieholder">
                <SubsChart :data="subs" />
        </div>  

</b-row>

<b-row class="mt-3" v-else>
        <b-col class="col-12 text-center pt-3 pb-3">
                <h5 style="color:#48A1BA;">No Subscriptions</h5>
        </b-col>   
</b-row>      


<b-modal ref="full-screen" size="md"  title="SUBSCRIPTIONS" hide-footer centered content-class="darkmodal">

<b-row class="mt-3" v-if="total > 0">
     <b-col class="col-12">
       
        <b-row class="mt-3">
                  <b-col class="col-2 col-sm-8 col-lg-3">
                 <h2 class="boxtext_md">{{ total }}</h2>
                </b-col>

                <b-col class="col-7 col-lg-6 text-center d-none d-lg-block">
                 <barchartH :datavalue="subs[3].value" :totalvalue="total" unitvalue=' ACTIVE' />
                </b-col>  

                <b-col class="col-12 col-sm-4 col-lg-3">
                        <SubsChart :data="subs" />
                </b-col>
               
        </b-row>

    </b-col>
       

    <b-col class="col-6">
                <b-row class="mt-3" >
                  <b-col class="col-12">{{ subs[3].value }} {{ subs[3].label }}</b-col>     
                 <b-col class="col-12">{{ subs[4].value }} {{ subs[4].label }}</b-col>
                </b-row>  
     </b-col>   

     <b-col class="col-6">
             <b-row class="mt-3" >
                 <b-col class="col-12 ">{{ subs[5].value }} {{ subs[5].label }}</b-col>
                 <b-col class="col-12">{{ subs[2].value }} {{ subs[2].label }}</b-col>
               
                </b-row>  
     </b-col>   

</b-row>

        <b-row class="mt-3" v-else>
                <b-col class="col-12 text-center pt-3 pb-3">
                        <h5 style="color:#48A1BA;">No Subscriptions Found</h5>
                </b-col>   
        </b-row>   


</b-modal>        

</div>
</template>

<script>
    import SubsChart from './SubsChart'
    import { mapState } from 'vuex'
    export default {
                name:'CasterSubsCard',
                components:{
                SubsChart
                },             
        props:['dealerid','companyid'],        
        data(){
		return {
                subs:[],
                total:0,
                };
                },

        computed: mapState(['subsdata']),
                
                watch: {
                        subsdata: function () {
                                this.total = this.$store.getters.subsdata.total
                                this.subs = this.$store.getters.subsdata.data
                        }
                },    

        methods: {
                 fullScreen(){
                      this.$refs['full-screen'].show()
                },
  
		}	   
	
	};
</script> 