<template>
<div class="imageBorder boxclickable" @click="menuLink('casterroverhistory')">
      
<b-row >
     <b-col class="col-12 col-md-6">
        <b-row>
                <b-col class="col-12">REPORTS</b-col>
                 
        </b-row>

        <b-row class="mt-3">
                <b-col class="col-12">
                <h2 style="font-weight:bold">
                    <span style="font-size:0.3em;vertical-align:top">LAST</span> <span style="color:rgba(255,255,255,1)">5</span> <span style="font-size:0.3em;vertical-align:bottom">CONNECTIONS</span></h2>
                </b-col>

        </b-row>
  
    </b-col>

    <b-col class="col-12 col-md-6">
          <BarChartStacked :chartdata="data" v-if="display" />
    </b-col>
     
</b-row>

<b-row>
        <b-col class="col-12 mt-3">
                <h4 style="font-size:0.8em;">CONSUMPTION<br/>{{ total }}</h4>
        </b-col>   
</b-row>


</div>
</template>


<script>
import BarChartStacked from './BarChartStacked'
import axios from 'axios'
	export default {
        props:['id'],
        name:'CasterRoverLast5Card',
        components:{
                BarChartStacked
        }, 
        data(){
		return {
                token:'',
                data:[],
                total:'-',
                display:false
                };
                
                },

	created(){
            this.token = this.$store.getters.token
            this.fetchRovers()
        },

	methods: {

           fetchRovers(){

			axios.get('/api/lastfiveconnections',{
			headers: {"Authorization" : "Bearer " + this.token},
                params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token, 
                        green:0, 
                        rover:this.id      
                        }
                })
                .then((response) => {
                         if(response.data.code == 999){
                        console.log(response.data)
                      } else {
                                this.data = response.data
                                this.total = this.convertTime(response.data.totaltime)
                                if(this.data.datasets[0].data !== undefined){this.display = true}
                                
                                }
                                });
			},

                  convertTime(d) {
                                let h = Math.floor(d / 3600);
                                let m = Math.floor(d % 3600 / 60);
                                let s = Math.floor(d % 3600 % 60);

                                let hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
                                let mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
                                let sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
                                return hDisplay + mDisplay + sDisplay; 
                        },
                    menuLink(name){
                        this.$router.push({ name: name, params: {} }).catch(()=>{})
                        },
                },
	};
</script> 