<template>
<div>

<b-container>

    <b-row class="mt-3">
        
        <b-col class="col-6"><label>Manufacturer</label></b-col> 
          <b-col class="col-6 text-right">
              <b-button variant="outline-success" size="sm" @click="addMaker()"><b-icon-plus-circle class="bicon" />add maker</b-button>
          </b-col>  

		<b-col class="col-12 mt-1 mb-3">
                    <b-form-select
                    v-model="machine.make"
                    :options="makers"
                    name="machine.make"
                    @change="makerSel"
                    v-validate="{min_value:1}"
					:class="{'b-form-select': true, 'is-danger': errors.has('machine.make') }"  
                    class="darkinput"   
                    />
                    <span v-if="errors.has('machine.make')" style="color:red;">Select a manufacturer</span>
	
		</b-col>

        <b-col class="col-6"><label>Model</label></b-col> 
        <b-col class="col-6 text-right">
              <b-button variant="outline-success" size="sm" @click="addModel()"><b-icon-plus-circle class="bicon" />add model</b-button>
        </b-col> 

		<b-col class="col-12 mt-1">
            <b-form-select
                    v-model="machine.model"
                    :options="models"
                    name="machine.model"
                    @change="modelSel"
                    v-validate="{min_value:1}"
					:class="{'b-form-select': true, 'is-danger': errors.has('machine.model') }"  
                    :disabled="lockfield"
                    class="darkinput"   
                    />
                    <span v-if="errors.has('machine.model')" style="color:red;">Select a model</span>

		</b-col>


        <b-col class="col-12 mt-3">
                <label>Reg Num</label>
				<b-form-input  
                    type="search" 
                    placeholder="reg num / id num" 
                    autocomplete="off"
                    v-model="machine.regnum"
                    :disabled="lockfield"
                    class="darkinput"   
                    />

                </b-col>

         <b-col class="col-12 mt-3">
                <label>Receiver Serial Number </label>
				<b-form-input 
                    type="search" 
                    placeholder="optional" 
                    autocomplete="off"
                    v-model="machine.receiver_serial_num"
                    class="darkinput"   
                    />

                </b-col>

        <b-col class="col-12 mt-3">
                <label>Modem Serial Number </label>
				<b-form-input 
                    type="search" 
                    placeholder="optional" 
                    autocomplete="off"
                    v-model="machine.modem_serial_num"
                    class="darkinput"   
                    />

                </b-col>                         
      
        </b-row> 
 
      
    <b-row class="mt-5 pt-3 border-top">
         <b-col class="col-12 col-sm-4">
            <b-button variant="outline-success"  @click="returnMachine()" block v-if="!editmachine">SAVE</b-button>
            <b-button variant="outline-success"  @click="validateData()" block v-else>SAVE</b-button>
            
        </b-col> 

        <b-col class="col-12 col-md-4 offset-md-4">
				<b-button variant="outline-success" block @click="cancel(rover.value)">CANCEL</b-button>
		</b-col>  
    </b-row> 

     <b-modal ref="edit-maker" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
        <CasterMachineMakerEdit 
            :id = 0
            v-on:updatemakers="updatemakers"
            :makers = makers

            />
    
    </b-modal>

    <b-modal ref="edit-model" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
        <CasterMachineModelEdit
            :id = 0
            :maker = "machine.make"
            v-on:updatemodels="updatemodels"
            :models = models
            />
    </b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-container>

		
</div> 
</template>


<script>
    import CasterMachineMakerEdit from './CasterMachineMakerEdit'
    import CasterMachineModelEdit from './CasterMachineModelEdit'
    import ErrorLogging from './ErrorLogging'
    import { Validator } from 'vee-validate'
    import axios from 'axios'
	export default {
        props:['newmachine','company','machinedata','edit'],
        name:'CasterCompanyMachineEditOld',
        components:{
            CasterMachineMakerEdit,
            CasterMachineModelEdit,
            ErrorLogging
        },
        data(){
        return {
                token:'',
                rover:{},
                machines:[],
                machine:{
                
                },
                types:[],
                makers:[],
                models:[],
                modaltitle:'',
                lockfield:true,
                editmachine:false,
                errorresponse:{}
                };
                
                },

		created(){
      
            this.token = this.$store.getters.token
            if(this.edit){this.editmachine = true}
            
            // New machine
         
            if(this.newmachine){
                this.machine.id = 0
                this.machine.make = 0
                this.machine.model = 0

            } else {
                this.machine = this.machinedata
                this.machineid = this.machine.value
                this.lockfield = false
                }
             
            this.fetchMakers()    

        },

        mounted() {
            Validator.localize('en',{
                    messages: {
                    required: '** required.',
                    },
            })   
        },

      
        methods: {

            returnMachine(){
                this.$validator.validateAll().then(result => {
                    if(result){this.$emit('returnmachine',this.machine)}
                })  
            },

            validateData(){
                this.$validator.validateAll().then(result => {
                    if (result) {this.updateMachine()}
                    });
            },

           
            updateMachine(){
        
                    this.machine.company = this.company
                    
                        axios({
                                method: 'put',  //   Update Machine
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/companymachine',
                                data: {
                                    userid:this.$store.getters.user.id,
                                    token:this.$store.getters.user.token,  
                                    machinedata:this.machine
                                    }
                                })
                            .then((response)=> {
                       
                            if(response.data.code == 999){
                            this.errorresponse = response.data
                            } else {

                                    this.$swal({
                                                title: "Updated",
                                                text: 'Machinery updated',
                                                icon: "success",
                                                showCloseButton: true,
                                                showConfirmButton: false,
                                                timer:3000
                                                })
                                                .then(() => {
                                                    this.$emit('updatemachine',response.data)
                                                    }) 
                                }
                            });	
            },

            cancel(){
                this.$emit('closemodal',this.machine.id)
            },
        
             fetchMakers(){
                axios.get('/api/machinemakers',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params: { 
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token
                     } 
                    })
				.then((response) => {
                    if(response.data.code == 999){
                            this.errorresponse = response.data
                            } else {
                            this.makers = []
                            this.makers.push({value:0,text:'Select'})
                            this.makers.push(...response.data.makers)
                            this.fetchModels()
                            }
                    
                    });
           },

           makerSel(event){
                this.lockfield = false
                let maker = this.makers.filter(maker => maker.value == event)
               
                this.machine.make_title = maker[0].text
                this.machine.make = event
                this.machine.model = 0
               
                this.$validator.validate('machine.make')
                this.fetchModels()
           },

           modelSel(event){
                
                let model = this.models.filter(model => model.value == event)
                this.machine.model_title =model[0].text
                this.machine.model = event

                this.$validator.validate('machine.model')
           },

           updatemakers(value){
               this.lockfield = false
               this.$refs['edit-maker'].hide()
               this.machine.make = value
               this.fetchMakers()
            },

           updatemodels(value){
               this.$refs['edit-model'].hide()
               this.machine.model = value
               this.fetchModels()
           },

           fetchModels(){

               axios.get('/api/machinemodels',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params: {  
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,  
                        maker: this.machine.make } 
                })
				.then((response) => {
                     if(response.data.code == 999){
                            this.errorresponse = response.data
                            } else {
                            this.models = []
                            this.models.push({value:0,text:'Select'})
                            this.models.push(...response.data.models)
                            }
                    });
           
           },

           addMaker(){
               this.modaltitle = 'New Manufacturer'
               this.$refs['edit-maker'].show()
           },

           addModel(){
               this.modaltitle = 'New Model'
               this.$refs['edit-model'].show()
           },

         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script>