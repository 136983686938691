<template>
<div>
    <b-container >

    <b-row class="mt-1" v-if="response == ''">
        <b-col class="col-12 mb-3 text-center">
            <h5>Test NTRIP connection to Casta</h5>    
        </b-col>
    </b-row>
    
    <b-row  v-if="!denmarkonly">
        <b-col class="col-12 col-md-6">
            <b-button size="sm" block variant="outline-success" @click.stop="checkCasta(1)">UK</b-button>
        </b-col>
        <b-col class="col-12 col-md-6">  
            <b-button size="sm" block variant="outline-success" @click.stop="checkCasta(2)">Denmark</b-button>
        </b-col>
    </b-row>
    
    <b-row  v-else>
        <b-col class="col-12">  
            <b-button size="sm" block variant="outline-success" @click.stop="checkCasta(2)">Check Denmark Connection</b-button>
        </b-col>
    </b-row>

    <b-row>
        <b-col class="col-12 mt-3 mb-1" v-if="response != ''">
            <b-button variant="success" block disabled v-if="success">{{ response }} <b-icon-check2-circle /></b-button>
            <b-button variant="danger" block disabled v-else>{{ response }}  <b-icon-x-circle /></b-button>
        </b-col>
    </b-row>

</b-container>
		
</div> 
</template>

<script>
    import axios from 'axios'
	export default {
        props:['denmarkonly'],
        data(){
		return {
                response:'',
                success:false
                };
                
                },

	
		methods: {

          
             
            checkCasta(id){
                    this.response = ''
                    this.success = false
                    axios.get('/api/send-tcp-message',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.token,
                        id:id
                    }
                })

                .then((response)=> {

                    if(response.data.code == 999){
                        this.response = 'Connection Failed'
                        this.success = false
                    } else {
                        let data = typeof response.data === 'string' ? response.data : JSON.stringify(response.data)
                        if (data.includes("ICY 200 OK")) {
                            this.response = 'Connection Successful'
                            this.success = true
                        } else {
                            this.response = 'Connection Failed'
                            this.success = false
                        }
                      
                    }
            
                })
              
            },

       
         closemodal(){
              this.$emit('closemodal')
         },

		}	   
	
	};

</script> 