<script>
  
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
  },

  data(){
		return {

    test:0,
    tempdata:[],

    cdata:{
        labels:[],
        datasets:[],
    },

    ylabel:'testme',
    
    chartoptions: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                datalabels: {
                display: false,
                },
            },
            legend: {
                display:false,
                labels: {
                    fontColor: '#ffffff'
                    }
            },
            scales: {
                yAxes: [{
                // gridLines: { 
                //     color: "#999",  
                //     display: true,
                //     },
                scaleLabel: {
                  display: true,
                  labelString: '',
                  fontColor:"#ccc"
                },       
                ticks: {
                fontColor: "#FFF",  
                stepSize: 4,
                reverse: false,
                beginAtZero: true,
                },
                gridLines: { zeroLineColor: "#ccc" }, 
              }],
                xAxes: [{
                //gridLines: { zeroLineColor: "white" },  
                ticks: {
                fontColor: "#FFF", 
            }
          }]
            },
        },
    };
                
  },


    mounted () {
        this.createChart()
    },

    watch: {
        chartdata: function() {
            this.createChart()
        }
    },

    methods:{

        createChart(){
           
            let dsoptions = {
                label:'',
                pointBackgroundColor:'',
                fill:false,
                borderColor:'',
                backgroundColor:'',
                data:[],
            }

            let ds2 = JSON.stringify(dsoptions)
            this.cdata.labels = this.chartdata.labels
            this.tempdata = []
            
                    let dataset = JSON.parse(ds2)
                    dataset.label = this.chartdata.datasets.label
                    dataset.backgroundColor = this.chartdata.datasets.color
                    dataset.data = this.chartdata.datasets.data
                    this.tempdata.push(dataset)

           // if(this.test == 0){
                    this.cdata.datasets = this.tempdata
                    //this.cdata.datasets.push(dataset)
            
            this.chartoptions.scales.yAxes[0].scaleLabel.labelString = this.chartdata.datasets.label
            
            this.renderChart(this.cdata, this.chartoptions)
           // this.test = 2
           // console.log(this.test)
           // } else {
              
           //   console.log('no')
           // }
        }
    }
}

</script> 