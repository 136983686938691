<template>
<div>
<b-container class="mainpagecont">

<b-row class="mb-3">
    <b-col class="col-12 col-md-8 mt-3">
        <h3>Outstanding GDPR Acceptances</h3>
    </b-col>

    <b-col class="col-12 col-md-4 mt-3">
            <CasterSearch v-on:textsearch=updateSearch v-on:clearsearch=clearFilter />
    </b-col>

    <b-col class="col-12 mt-3">
        <h6>Note: If GDPR policy is not accepted by a Company within 28 days then all their data will be removed from the system to comply with current GDPR regulations</h6>
    </b-col>

</b-row>   

<b-row class="mt-3 mb-3" >
       <b-col class="col-12">
             <b-table
                ref="table" 
                responsive 
                hover 
                :items="users" 
                :fields="fields"
                :busy="isBusy"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                dark
                @row-clicked="showEmails($event)" 
                >
                 <template #table-busy>
                    <div class="text-center my-2" >
                    <b-spinner class="align-middle" style="color:orange"></b-spinner>
                    <strong style="color:orange"> Loading...</strong>
                    </div>
                </template>      

                </b-table>
    
       </b-col> 
</b-row>

<b-modal ref="user-emails" size="lg" :title="modaltitle" hide-footer centered content-class="darkmodal">
    <CasterGDPREmails
    :company=companysel
    v-on:closemodal=closemodal()
    />
</b-modal>  

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-container>
	
</div> 
</template>

<script>
import CasterSearch from './CasterSearch'
import CasterGDPREmails from './CasterGDPREmails'
import ErrorLogging from './ErrorLogging'
import axios from 'axios'
export default {
    name:'CasterGDPR',
    components:{
            CasterSearch,
            CasterGDPREmails,
            ErrorLogging
        }, 
    data(){
        return {
                users:[],
                modaltitle:'',
                companysel:0,
                searchtext:'',
                componentKey:0,
                errorresponse:{},
                fields:[
                { key: 'dealer', label: 'Dealer', sortable: true  },
                { key: 'company', label: 'Company', sortable: true  },
                { key: 'email', label: 'Email', sortable: true  },
                { key: 'emails_sent', label: 'Emails Sent', sortable: true  },
                { key: 'twoweek_warning', label: '14 Day', sortable: true  },
                { key: 'fourweek_warning', label: '28 Day', sortable: true  },
                { key: 'created', label: 'Created', sortable: true  },
                ]    
                };
                
                },

		created(){
            this.user = this.$store.getters.user
            this.fetchGDPR()
        },

         mounted(){
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setbasesdatapoll',0)
        },

    methods: {

            fetchGDPR(){
                this.isBusy = true
              
				axios.get('/api/casterindexcompliance',{
					headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                         userid:this.$store.getters.user.id,
                         token:this.$store.getters.user.token,
                         stext:this.searchtext,
                         reseller:this.$store.getters.resellerid,
                         dealer:this.$store.getters.dealerid
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                            this.users = response.data.data
                            this.isBusy = false
                        }
                    
                    });
            },

            showEmails(company){
                this.companysel = company.companyid
                this.modaltitle = 'Email Log'
                this.$refs['user-emails'].show()
            },

            updateSearch(text){
                this.searchtext = text
                this.fetchGDPR()
                console.log(this.searchtext)
            },

            clearFilter(){
                this.searchtext = ''
                this.fetchGDPR()
            },

         
            closemodal(){
                this.$refs['user-emails'].hide()
            },

        
    },     

       
	};

</script>