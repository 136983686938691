<template>
<div>

<b-container>

<b-row>
    <b-col class="col-5 mt-3"><h5>{{ currentsub.title }}</h5></b-col>  
    <b-col class="col-7 mt-3">
        <div class="rtmarkerexpiry" :style="{ backgroundColor:currentsub.color}" v-if="currentsub.status == 'Active'">
                {{ currentsub.status}} {{ currentsub.expirydays }} day<span v-if="currentsub.expirydays !== 1">s</span> remaining
        </div>
        <div class="rtmarkerexpiry" :style="{ backgroundColor:currentsub.color}" v-else>
                {{ currentsub.status}} 
        </div>
    </b-col>
    <b-col class="col-6 mt-3"><h6>Started : {{ currentsub.start }}</h6></b-col>
    <b-col class="col-6 mt-3"><h6>Expiry : {{ currentsub.end }}</h6></b-col>

    <b-col class="col-12 mt-3" style="border-top:1px solid rgba(255,255,255,0.7)"></b-col>

</b-row>            


<b-row>
    
        <b-col class="col-12 mt-3">    
                <label>Reference</label>
				<b-form-input 
                    type="search" 
                    placeholder="" 
                    autocomplete="off"
                    v-model="sub.purchase_order"
                    name="sub.purchase_order"
                    v-validate="{required:true}"
					:class="{'darkinput': true, 'is-danger': errors.has('sub.purchase_order') }"
                    class="darkinput"
                    />
                    <span style="color:red" v-show="errors.has('sub.purchase_order')">
                    {{ errors.first('sub.purchase_order') }}</span>
            </b-col>

        </b-row>

    <b-row class="mt-3"> 
         
         <b-col class="col-12 mt-3" >
              
              <b-form-checkbox
                          inline
                          v-model="sendsubemail"
                          size="lg"
                          value=1
                          unchecked-value=0
              />
              <label style="margin-right:15px">Send cancellation email </label> 
          </b-col> 
          
          <b-col class="col-12 mt-3" v-if="sendsubemail == 1">
                          <label>Email Address</label>
                          <b-form-input 
                          type="search"
                          placeholder=""
                          autocomplete="off" 
                          v-model="emailto" 
                          class="darkinput"  
                          />
              </b-col> 
    </b-row>
     
    <b-row>
    <b-col class="col-12 mt-5 mb-3">    
        <b-button variant="outline-danger" @click.stop="validateData()" block>Cancel active subscription</b-button>
    </b-col>
</b-row>


<b-modal ref="renew-sub"  size="lg" title="Cancelling Subscription" hide-footer centered content-class="darkmodal">
    <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row>

   <b-row>
        <b-col class="col-12 mt-5">
            <b-button variant="outline-success" @click.stop="finishRenewal()" block>Finish</b-button>
       </b-col>   
   </b-row>   

</b-modal>

<SendEmail 
        :sendmail="sendmail"
        :emailid=6 
        :emailto="emailto" 
        :subid="subid"
        :showdiag="false"
        v-on:emailsent="emailSendStatus"
        />

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</b-container>
	
</div> 
</template>

<script>
    import SendEmail from './SendEmail'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
    import { Validator } from 'vee-validate'

	export default {
        props:['subid'],
        name:'CasterSubscriptionCancel',
        components:{
            ErrorLogging,
            SendEmail
        },
		data(){
		return {
            roleid:0,
			token:'',
            sub:{},
            currentsub:{
                color:'',
                title:'',
                status:'',
                expirydays:0,
                start:'',
                end:''
             },
            date:0,
            datenow:'',
            expire:'',
            expirydays:0,
            user:{},
            modaltitle:'',
            sendmail:false,
            sent:99,
            sendsubemail:1,
            emailto:'',
            emailid:6, // Cancel Sub
            showdiag:false,
            sending:false,
            errorresponse:{},
            stagemessages:[],
        };
		
		},

        mounted(){

            Validator.localize('en',{ 
                    messages: {
                    required: '** required.',
                    },
            })   
            this.roleid = this.$store.getters.roleid
            this.user = this.$store.getters.user
            this.token = this.$store.getters.token
            this.datenow = moment().format('DD-MM-Y HH:mm:ss')
            this.fetchSub()
            
        },

        watch: {
                showdiag: function () {
                   if(this.showdiag){this.emailto = this.user.email} else this.emailto = this.sub.useremail
                },   
          },

     
		methods: {

            // get current subscription
            fetchSub(){
               
                axios.get('/api/casterntripsubscriptions',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        id:this.subid
                        }
                    })
				.then((response) => {

                    if(response.data.code == 999){
                            this.errorresponse = response.data
                    } else {
                        
                        let sub = response.data.CasterSubs[0]
                        
                        this.sub.action = 3             //  Renewal action
                        this.sub.id = sub.value         //  current active or expired sub 
                        this.sub.rover = sub.rover 
                        this.sub.username = sub.roverdetail
                        this.sub.nosimm = sub.nosimm
                        this.sub.countryid = sub.country
                        this.sub.resellerid = sub.reseller
                        this.sub.dealerid = sub.dealer
                        this.sub.companyid = sub.company
                        this.sub.user = this.user.id
                        this.sub.useremail = sub.companyemail
                        this.emailto = sub.companyemail
                        this.currentsub.color = sub.color
                        this.currentsub.title = sub.subscription
                        this.currentsub.expirydays = sub.days
                        this.currentsub.status = sub.statustext
                        this.currentsub.start = sub.startdate
                        this.currentsub.end = sub.enddate
                        
                        this.sub.canceldate = moment().format('DD-MM-Y HH:mm:ss')
                       
                        // Get stock subscriptions for this dealer
                    }
                    
                    });
            },

        
            validateData(){
                this.$validator.validateAll().then(result => {
                        if (result) { this.sendSubData()}
                });        
             },
         
            sendSubData(){  

                this.stagemessages = [] 
                this.$refs['renew-sub'].show()
               
                axios({
                    method: 'post',   
                    headers: {"Authorization" : "Bearer " + this.token},
                    url: '/api/rovercancelsubscription',
                    data: {
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token,
                            roverdata:this.sub,
                            country:this.sub.country,
                            reseller:this.sub.reseller,
                            dealer:this.sub.dealer,
                            useremail:this.sub.useremail
                        }
                    })
                    .then((response)=> {
                            if(response.data.code == 999){
                                    this.errorresponse = response.data
                                    this.saving = false
                                } else {
                                    this.stagemessages = response.data.messages
                                    if(this.sendsubemail == 1){
                                        this.sendEmail()
                                    }
                                }
                        
                            });	
            },

            cancelSub(){
                    this.$refs['cancel-sub'].show()
            },

            finishRenewal(){
                    this.$emit('update')
            },

            sendEmail(){
                this.sendmail = !this.sendmail // trigger to SendEmail
            },

            emailSendStatus(data){
                    this.stagemessages.push(...data.messages)
                    this.sending = false
                    this.sent = data.sent
            }, 

        
          
            }
	}
</script> 