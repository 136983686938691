<template>
<div>
    <b-container>

    <b-row class="mb-3">
        <b-col class="col-4 mt-1">Company</b-col>
        <b-col class="col-8 mt-1">{{ order.company}}</b-col>
        <b-col class="col-4 mt-1">User</b-col>
        <b-col class="col-8 mt-1">{{ order.name}}</b-col>
        <b-col class="col-4 mt-1">Stripe ref</b-col>
        <b-col class="col-8 mt-1">{{ order.order}}</b-col>
        <b-col class="col-4 mt-1">Amount</b-col>
        <b-col class="col-8 mt-1">£{{ order.amount}}</b-col>
        <b-col class="col-4 mt-1">Date</b-col>
        <b-col class="col-8 mt-1">{{ order.datetime}}</b-col>
    </b-row>

    <b-row v-for="sub in subs" :key="sub.id">
        <b-col class="col-6 mt-1">{{  sub.title }}</b-col>
        <b-col class="col-6 mt-1">{{  sub.substatustext }}</b-col>
    </b-row>
    </b-container>

    <b-container v-if="csims.length < simsubs.length">
    <b-row>
        <b-col class="col-12 mt-3"><h5>Add Sim to company stock</h5></b-col>
        <b-col class="col-12 mt-1">
            <b-form-select
                    v-model="simsel"
                    :options="simms"
                    class="darkinput2"
                    size="md"
                    @change="setSim($event)"  
                    />
       
        </b-col> 
       
    </b-row>
    </b-container>

    <b-container>
        <b-row class="mt-3">
            <b-col class="col-12"><h5>Assigned Sims</h5></b-col>
        </b-row>        
        <b-row v-for="csim in csims" :key="csim.id">
            <b-col class="col-5 mt-1">{{  csim.iccid }}</b-col>
            <b-col class="col-5 mt-1">{{  csim.endpoint_name }}</b-col>
            <b-col class="col-1 mt-1" v-if="order.status == 10">
                <b-button variant="outline-danger" @click.stop="removeSim(csim.value)" size="sm"><b-icon-x-circle /></b-button>
            </b-col>
        </b-row>    
    </b-container>

    <b-row class="mt-5 mb-2" v-if="order.status == 10">
        <b-col class="col-12 col-sm-5">
            <b-button variant="outline-success" size="md" block @click.stop="assignSub()"><b-icon-check-circle class="mr-2" />Save</b-button>
        </b-col>
        <b-col class="col-12 col-sm-5 offset-sm-2">
            <b-button variant="outline-warning" size="md" block @click.stop="cancel()"><b-icon-x-circle class="mr-2" />Cancel</b-button>
        </b-col>
    </b-row>

    <b-row class="mt-5 mb-2" v-else>
        <b-col class="col-12">
            <b-button variant="outline-warning" size="md" block @click.stop="cancel()"><b-icon-x-circle class="mr-2" />Cancel</b-button>
        </b-col>
    </b-row>

<b-modal ref="show-status"  size="lg" hide-header hide-footer centered content-class="darkmodal">
    <b-row v-for="message in stagemessages " :key="message.id">
        <b-col class="col-12 col-sm-5">
            {{ message.message }}
       </b-col>
       <b-col class="col-12 col-sm-5">
            {{ message.text }}
       </b-col>
       <b-col class="col-12 col-sm-2 text-right">
            <b-icon-check-square v-if="message.error == false" />
            <b-icon-x-square v-else />
       </b-col>
   </b-row>

   <b-row>
        <b-col class="col-12 mt-5">
            <b-button variant="outline-success" @click.stop="finish()" block>Finish</b-button>
       </b-col>   
   </b-row>   

</b-modal>

<b-modal ref="show-dialog" size="md" hide-header hide-footer centered content-class="darkmodal">
        <b-row>
            <b-col class="text-center mt-1">
                <h5>{{ modalmessage }}</h5>
            </b-col>
        </b-row>
</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>


<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
	export default {
        name:'CasterOrder',
        props:['order'],
        components:{
            ErrorLogging,
        },
        data(){
		return {
                token:'',
                userid:0,
                simsel:0,
                subs:[],
                simsubs:[], // array of subs with simms
                csims:[], // assign to company sims
                simms:[{value:0,text:'select sim'}],
                stagemessages:[],
                modalmessage:'',
                errorresponse:{}
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            this.userid = this.$store.getters.user.id
            this.fetchOrder()
            this.fetchSimms()
        },
      
		methods: {
        
           fetchOrder(){
				axios.get('/api/fetchorder',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         token:this.token,
                         userid:this.userid,
                         order:this.order.id,
                         company:this.order.companyid,
                         list:1
                     }
                })
				.then((response) => {
                        this.subs = response.data.subs
                        this.csims = response.data.sims
                        this.simsubs = this.subs.filter(sub => sub.subscription[0].simm == 1)
                    })
                },

            fetchSimms(){
                axios.get('/api/castersimms',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                         userid:this.userid,
                         token:this.token,
                         reseller:1,
                         dealer:2,
                         company:0,
                         status:0,
                         stock:true,
                         bases:false,
                         type:2
                     }
                })
				.then((response) => {
                    if(response.data.code == 999){this.errorresponse = response.data
                            } else this.simms.push(...response.data.CasterSimmss)
                    })        
            },

            setSim(item){
                let simsel = this.simms.filter(simm => simm.value == item)[0]
                simsel['iccid'] = simsel['number']
                simsel['endpoint_name'] = simsel['endpoint']
                simsel['endpoint'] = simsel['endpoint_id']
                this.csims.push(simsel) // Add selected sim to sim assign list
            },

            removeSim(id){
                this.csims = this.csims.filter(simm => simm.value !== id)
            },

            assignSub(){
                if(this.csims.length < this.simsubs.length){
                    this.modalmessage = "Assigned sims doesn't match order"
                    this.$refs['show-dialog'].show()
                    setTimeout(() => {this.$refs['show-dialog'].hide()}, 3000)
                } else this.saveAssignSub()
            },

            saveAssignSub(){
                        this.stagemessages = [] 
                        this.$refs['show-status'].show()

                        axios({
                        method: 'post',
                                headers: {"Authorization" : "Bearer " + this.token},
                                url: '/api/orderassignsimm',
                                data: {
                                    userid:this.userid,
                                    token:this.token,
                                    company:this.order.companyid,
                                    subs:this.subs,     //  Order subscription list
                                    sims:this.csims,    //  Assign sims list
                                    order:this.order.id
                                    }
                                })
                                .then((response)=> {
                                    if(response.data.code == 999){
                                        alert('exception')
                                    } else 
                                    this.stagemessages.push(...response.data.messages)
                                })
            },  

            finish(){
                this.$emit('updatemodal')
            },

            cancel(){
                this.$emit('closemodal')
            },

		}	   
	
	};

</script>