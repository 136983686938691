<template>
  <div class="imageBorder">

    <b-row class="mt-1" style="font-size:0.8em">
      <b-col class="col-12" style="max-height:300px;overflow-y: hidden;">

        <b-table striped responsive hover :items="messages" :fields="fields" sort-icon-left sticky-header="280px"
          tbody="rowClassSm" dark>
          <template #cell(colour)="data">
            <div class="rtmarker" :style="{ backgroundColor: data.item.Colour, width: '15px', height: '15px' }" />
          </template>

          <template #cell(message)="data">
            {{ data.item.Timestamp }} [{{ data.item.Session_id }}] <br />{{ data.item.Message }}
          </template>
        </b-table>

      </b-col>
    </b-row>


  </div>
</template>

<style>
.red {
  color: #ffffff;
}

.green {
  color: #cccccc;
}
</style>

<script>
import moment from 'moment'
export default {
  props: ['logs'],
  data() {
    return {
      messages: [],
      fields: [
        { key: 'Colour', label: '', sortable: false },
        { key: 'Message', label: 'LATEST EVENTS', sortable: false },
      ],
    };

  },

  created() {
    this.messages = this.logs
    this.formatAllTimestamps();
  },

  watch: {
    logs: function () {
      this.messages = this.logs
      this.formatAllTimestamps();
    },
  },

  methods: {

    setRowClass(item) {

      this.time = Math.round(+new Date() / 1000)
      if ((this.time - item.timestamp) > 30) { return 'green' } else return 'red'
    },

    formatTimestampToLocalTime(time) {
      let localTime = moment.utc(time).local().format('DD-MM-YYYY HH:mm:ss');
      return localTime
    },

    formatAllTimestamps(){
      for ( let message of this.messages ) {
        message.Timestamp = this.formatTimestampToLocalTime(message.Timestamp);
      }
    },

  }

};

</script>