<template>
<div class="imageBorder">
 
<b-row class="mt-1" v-if="subs.length > 0">
     
        <b-col class="col-8 boxtext">STOCKING</b-col>
        <!-- <b-col class="col-4">
                <h3 style="color:rgba(255,255,255,1)">{{ total }}</h3>
        </b-col> -->

        <b-col class="col-12 mt-3 mb-1">
          <BarChart :data="subs" />
        </b-col>  

</b-row>

 <b-row class="mt-3" v-else>
                <b-col class="col-12 text-center pt-3 pb-3">
                        <h5 style="color:#48A1BA;">No subscriptions in stock</h5>
                        <h5 style="color:#48A1BA;">Click to add more</h5>
                </b-col>   
        </b-row>   

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div>
</template>


<script>
import BarChart from './BarChart'
import ErrorLogging from './ErrorLogging'
import axios from 'axios'
	export default {
        name:'CasterSockingCard',
        components:{
            BarChart,
            ErrorLogging
        },   
        data(){
		return {
                token:'',
                subs:[],
                total:0,
                errorresponse:{},
                display:false
                };
                
                },

	created(){
            this.token = this.$store.getters.token
            this.fetchSubs()
           
        },

	methods: {

          
           fetchSubs(){

		axios.get('/api/casterstocking',{
			headers: {"Authorization" : "Bearer " + this.token},
                        params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        reseller:this.$store.getters.resellerid,
                        dealer:this.$store.getters.dealerid        
                        }
                })
		.then((response) => {
                        if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                                this.total = response.data.total
                                this.subs = response.data.data
                                this.display = true
                      }  
                    })
                  
		},

		}	   
	
	};

</script> 