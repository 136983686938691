<template>
  <div ref="mapcont" class="imageBorder setmapmin">
      
          <div class="titleholder textfade">LOCATION</div> 
  
          <div class="leafletbuttons">
             <b-button variant="outline-success" size="sm" @click.stop="toggleSat()" ><b-icon-image /></b-button>    
          </div>
  
            <v-map
              :center="currentCenter"
              :zoom="currentZoom"
              :options="mapOptions"
              :style="{height: mapheight  + 'px', width: mapwidth + 'px', left: '10px', top: '10px'}"
              :noBlockingAnimations="blockanimation"
              v-if="mapshow"
              >
                <v-tilelayer :url="tileurl" />
  
                <v-marker
                    v-if="base.latitude !== null"
                    :lat-lng="[base.latitude,base.longitude,base.status]"
                    >
                    <v-icon
                        :icon-anchor="staticAnchor"
                        >
                        <div class="lmarkerhold" :style="{ backgroundColor:base.color }" />
                        <div class="headline" :style="{ backgroundColor:base.color,fontSize:fontsize }" >{{base.name}}</div>
                    </v-icon>

                </v-marker> 
  
            </v-map>
  </div>
  </template>
  
  <script>
    import { mapState } from 'vuex'
    import "leaflet/dist/leaflet.css"
    import * as Vue2Leaflet from 'vue2-leaflet'
    import { latLng } from "leaflet"
  
    export default {
      components: {
        'v-map': Vue2Leaflet.LMap,
        'v-marker': Vue2Leaflet.LMarker,   
        'v-icon': Vue2Leaflet.LIcon,
        'v-tilelayer': Vue2Leaflet.LTileLayer,
      },
        props:['base'],
          data(){
            return {
                staticAnchor: [0,0],
                fontsize:'1em',
                setmapwidth:0,
                mapshow:false,
                showsat:false,
                blockanimation:true,
                currentZoom:8,
                tileurl:'',
                stadiatileurl: 'https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png',
                sattileurl: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                currentCenter: latLng(54.188255397553895, -2.482830042690006),
                mapheight:0,
                mapwidth:0,
                mapOptions: {
                    zoomSnap: 0.5
                }
            };
        },
      
      created(){
            window.addEventListener("resize", this.setMapSize)
      },
          
      beforeDestroy() {
            window.removeEventListener("resize",this.setMapSize)
      },
      
      computed: mapState(['openmenu']),

      watch: {
                  openmenu: function () {
                    this.setMapSize()
                }
       },

      mounted(){
            this.setMapSize()
            this.currentCenter = latLng(this.base.latitude, this.base.longitude)
            this.tileurl = this.stadiatileurl
            this.showmap = true
       },
    
      methods: {

            setMapSize(){
                this.mapwidth = this.$refs.mapcont.clientWidth + 24
                this.mapheight = this.$refs.mapcont.clientHeight + 24
                this.mapshow = true
            },
  
            toggleSat(){
                this.showsat = !this.showsat
                if(this.showsat == false){this.tileurl = this.stadiatileurl} else this.tileurl = this.sattileurl
            },
  
      }	   
    
    };
</script>