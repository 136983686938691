<template>
<div>

<b-container class="mainpagecont">

<b-row>
    <b-col class="col-9 mt-3">
        <h4>{{ title }} Rovers</h4>
    </b-col>
    <b-col class="col-3 text-right mt-3">
        <b-button variant="outline-success" @click="$router.go(-1)" >BACK</b-button>
    </b-col>    
</b-row>
 
<b-row>
    
     

  

    <b-col class="col-sm-3 mt-3">
        <b-button size="sm" variant="outline-success" block @click="addRover()">
            <b-icon-plus-circle class="bicon" /> Add Rover
        </b-button>
    </b-col>
   
</b-row>

<b-row class="mt-3 mb-5" >
    <b-col class="col-12">
        <CasterRoversTable :stext="searchtext"  :company="companyid" :key="componentKey" />
    </b-col>
    <!-- <b-col class="col-12" v-else>
        <casterroversdetails  :stext="searchtext" :key="componentKey" />
    </b-col>                   -->
</b-row>

 

<b-modal ref="rover-add" size="lg" hide-header hide-footer centered content-class="darkmodal">
   <CasterRoverAdd
    v-on:closemodal=closemodal()
    v-on:updatemodal=updateModal
    />
</b-modal>   

</b-container>
</div> 
</template>

<script>
    import CasterRoversTable from './CasterRoversTable'
    import CasterRoverAdd from './CasterRoverAdd'
    export default {
        name:'CasterCompanyRovers',
        components:{
            CasterRoversTable,
            CasterRoverAdd
        },
        data(){
		return {
                companyid:0,
                dealerid:0,
                showtable:false,
                componentKey:0,
                display:false,
                searchtext:'',
                modaltitle:'',
                title:'???'
                };
                
                },

		created(){
            this.companyid = this.$store.getters.companyid
            this.display = true
        },

		methods: {

            toggleView(){
                this.showtable = !this.showtable
            },

            menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },

            updateSearch(text){
                this.searchtext = text
            },

            addRover(){
                this.$refs['rover-add'].show()
            },

            updateModal(){
                this.$refs['rover-add'].hide()
                this.componentKey += 1
            },
         
		}	   
	
	};

</script> 
