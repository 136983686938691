<template>
<div>

<b-row>

<b-col class="col-12 col-md-6 col-lg-3 mt-3">
    <label>Status</label>
    <b-form-select
                v-model="substatus"
                :options="statustypes"
                class="darkinput2"
                size="sm"
                @change = "setStatus" 
                />
</b-col>

<b-col class="col-2 mt-3">
        <b-button variant="outline-success" size="sm" @click="resetSearch()" style="margin-top:32px"> reset search </b-button>
    </b-col>

</b-row>

<b-row class="mt-3">
    <b-col class="col-12 col-md-5 mt-3" v-if="total > limit">1 - {{ rend }} of {{ total }} Results</b-col>
    <b-col v-else class="col-12 col-md-5 mt-3">{{ total }} Result<span v-if="total != 1">s</span></b-col>
</b-row>    


<b-row class="mt-3 mb-5" >
   
      <b-col>
            <b-table v-show="substatus != 2"
                class ="nowrap"
                ref = "subs-table" 
                responsive 
                hover 
                sticky-header="600px"
                :items="displaysubs" 
                :fields="conditionalFields"
                :sort-compare="customSort"
                :no-local-sorting="nosort"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortdesc"
                @head-clicked="onSortChanged"
                @row-clicked="showRover($event)"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                :busy="isBusy"
                sort-icon-left
                dark
                >
                <template #cell(statuscolor)="data">
                  <div  class="rtmarkerexpiry" :style="{ backgroundColor:data.item.dayscolor[1]}" >{{ data.item.dayscolor[0] }}</div>
                </template>
   

                </b-table>

             <b-table v-show="substatus == 2"
                class ="nowrap"
                ref = "schsubs-table" 
                responsive 
                hover 
                sticky-header="600px"
                :items="displaysubs" 
                :fields="conditionalFields2"
                @row-clicked="showRover($event)"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                @head-clicked="onSortChanged2"
                :no-local-sorting="nosort"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortdesc"
                :busy="isBusy"
                sort-icon-left
                dark
                >
                <template #cell(statuscolor)="data">
                  <div  class="rtmarkerexpiry" :style="{ backgroundColor:data.item.dayscolor[1]}" >{{ data.item.dayscolor[0] }}</div>
                </template>
   

                </b-table>

            <b-button v-if="more" variant="outline-success" block @click.stop="showMore()">Show more results?</b-button>
            <b-button v-if="loadingmore" variant="outline-warning" disabled block>Loading results</b-button>
</b-col>
</b-row>	

<b-modal v-model="isBusy" hide-header hide-footer centered content-class="loadingmodal">
        <b-row class="p-3">
            <b-col class="col-12 text-center">
                <b-spinner variant="success" class="mr-2"/>
                <h5>loading results</h5>
            </b-col>
        </b-row> 
</b-modal> 

<b-modal ref="show-sub" size="lg" title="modaltitle" hide-header hide-footer centered content-class="darkmodal">
   <casterrovermanage />
</b-modal>  

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style scoped>

.nowrap td {
    white-space: nowrap !important;
}

.modal-content{
    border:none !important;
}
</style>

<script>
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    export default {
        props:['resellerid','dealerid','companyid','roverid','clearsearch','stext','subtypeid','expired','stock'],
        name:'CasterSubscriptionsTable2',
        components:{
            ErrorLogging
        },
        data(){
            return {
                id:0,
                token:'',
                subs:[],
                displaysubs:[],
                subsel:0,
                substatus:3,
                statustypes :[],
                statussel:0,
                sortBy:'days',
                isBusy:false,
                display:false,
                errorresponse:{},
                nosubs:false,
                searchtext:'',
                page:0,
                limit:50,
                orderby:'enddate',
                sortdesc:false,
                nosort:true,
                more:false,
                loadingmore:false,
                total:0,
                rstart:1,
                rend:0,
                role:0,
                fields:[
                { key: 'companydetail', label: 'Company', sortable: true  },
                { key: 'resellername', label: 'Reseller', sortable: true  },   
                { key: 'dealerdetail', label: 'Dealer', sortable: true  },
                { key: 'roverdetail', label: 'Rover', sortable: true  },
                { key: 'machinereg', label: 'Reg Num', show: false, sortable: false },
                { key: 'statustext', label: 'Status', sortable: false  },
                { key: 'subscription', label: 'Type', sortable: false  },
                { key: 'startdate', label: 'Activation', sortable: true },
                { key: 'enddate', label: 'Expiry Date', sortable: true},
                { key: 'renewsent', label: 'Renew', sortable: false  },
                { key: 'statuscolor', label: 'Days Remaining' , sortable: true  },
                { key: 'days', label: 'Days Remaining' , show: false, sortable: true  }    
                ],
                fields3:[
                { key: 'companydetail', label: 'Company', sortable: true  },
                { key: 'dealerdetail', label: 'Dealer', sortable: true  },
                { key: 'roverdetail', label: 'Rover', sortable: true  },
                { key: 'statustext', label: 'Status', sortable: false  },
                { key: 'subscription', label: 'Type', sortable: false  },
                { key: 'startdate', label: 'Activation', sortable: true  },
                { key: 'enddate', label: 'Expiry Date', sortable: true  },
                { key: 'statuscolor', label: 'Renews #Days' , sortable: true  },
                { key: 'days', label: 'xDays Remaining' , show: false, sortable: true  } 
                ],
                observer: null,
                modaltitle:'',
                filtertype:2 // Subscription status
                };
                
                },

		created(){
            this.role = this.$store.getters.roleid
            this.token = this.$store.getters.token

            if(this.$store.getters.prevroute == 'casterrovermanage'){
                this.searchtext = this.$store.getters.searchtext
            } else {
                this.$store.commit('setprevroute',this.$route.name)
                this.searchtext = ''
                this.$store.commit('setsearchtext','')
                }

            this.substatus = this.getTableFilter(this.filtertype)
            if(this.substatus == 0){this.substatus = 3} // set active subs
            
            this.fetchSubs()
        },

        mounted(){
            this.addTableEventList()
        },

        computed: {
            
            filterdata() {
                return { ...this.resellerid, ...this.dealerid, ...this.companyid, ...this.roverid };
            },

            conditionalFields() {

            if(this.stext == ''){
                if(this.resellerid > 0 && this.dealerid == 0 && this.companyid == 0){
                    return this.fields.filter(field => field.key !== 'resellername' && field.key !== 'machinereg' && field.key !== 'days')
                } 

                if(this.dealerid > 0 && this.companyid == 0){
                    if(this.dealerid !== 14){
                    return this.fields.filter(field => field.key !== 'machinereg' && field.key !== 'dealerdetail' && field.key !== 'resellername'  && field.key !== 'days')
                    } else return this.fields.filter(field => field.key !== 'resellername'  && field.key !== 'days')
                }
                
                if(this.companyid > 0){
                    if(this.dealerid !== 14){
                    return this.fields.filter(field => field.key !== 'machinereg' && field.key !== 'companydetail' && field.key !== 'dealerdetail' && field.key !== 'resellername'  && field.key !== 'days')
                    } return this.fields.filter(field => field.key !== 'companydetail' && field.key !== 'dealerdetail' && field.key !== 'resellername'  && field.key !== 'days')
                } 
            }    
                return this.fields.filter(field => field.key !== 'machinereg'  && field.key !== 'days')
        
            },

            conditionalFields2() {

                if(this.resellerid > 0 && this.dealerid == 0 && this.companyid == 0){
                    return this.fields3.filter(field => field.key !== 'resellername' && field.key !== 'days')
                } 

                if(this.dealerid > 0 && this.companyid == 0){
                    if(this.dealerid !== 14){
                    return this.fields3.filter(field => field.key !== 'dealerdetail' && field.key !== 'resellername'  && field.key !== 'days')
                    } else return this.fields3.filter(field => field.key !== 'resellername'  && field.key !== 'days')
                }

                if(this.companyid > 0){
                    if(this.dealerid !== 14){
                    return this.fields3.filter(field => field.key !== 'companydetail' && field.key !== 'dealerdetail' && field.key !== 'resellername'  && field.key !== 'days')
                    } return this.fields3.filter(field => field.key !== 'companydetail' && field.key !== 'dealerdetail' && field.key !== 'resellername'  && field.key !== 'days')
                } 

                return this.fields3.filter(field => field.key !== 'days')

                }
  
        },

         watch: {
                    filterdata: function () {
                            this.page = 0
                            this.fetchSubs()
                    },

                    clearsearch: function () {
                        this.page = 0
                        this.substatus = 3
                        this.fetchSubs()
                    },
                   
                    stext: function () {
                        if(this.stext !== this.searchtext){
                            this.searchtext = this.stext
                            this.page = 0
                            this.fetchSubs()
                        } 
                         },
                         
                    subs: function() {
                        if(this.page > 0){
                            this.readdTableEventList
                        }
                    }
        }, 
        
		methods: {
         
            customSort(a, b, key) {
            
            if (key === 'enddate') {
                if (a.enddate2 < b.enddate2) return -1;
                if (a.enddate2 > b.enddate2) return 1;
                return 0;
            } 
            if (key === 'startdate') {
                if (a.startdate2 < b.startdate2) return -1;
                if (a.startdate2 > b.startdate2) return 1;
                return 0;
            }
            if (key === 'statuscolor') {
                if (a.days < b.days) return -1;
                if (a.days > b.days) return 1;
                return 0;
            }  
            return false;
            },

          
            setStatus(){
                this.page = 0
                this.storeTableFilter(this.substatus,this.filtertype)
                this.fetchSubs()
            },

            storeTableFilter(value,type){
                let searchvalues = this.$store.getters.searchvalues2.filter(value=>value.type != type) // clear previous value
                searchvalues.push({type:type,value:value})
                this.$store.commit('setsearchvalues2',searchvalues)
            },

            getTableFilter(type){
                let searchvalues = this.$store.getters.searchvalues2.filter(value=>value.type == type)
                if(searchvalues.length > 0){
                    return searchvalues[0].value
                } else return 0
            },

            fetchSubs(){
                this.isBusy = true
                axios.get('/api/casterntripsubscriptionssort',{
                    headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.token,
                        roleid:this.role,   
                        reseller:this.resellerid,
                        dealer:this.dealerid,
                        company:this.companyid,
                        rover:this.roverid,
                        type:this.subtypeid,
                        status:this.substatus,
                        stext:this.searchtext,
                        stock:0,
                        page:this.page,
                        limit:this.limit,
                        orderby:this.orderby,
                        sortdesc:this.sortdesc

                     }
                })
				.then((response) => {
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        } else {
                                this.more = false
                                this.loadingmore = false 
                                if(this.page == 0){
                                    this.subs = response.data.CasterSubs
                                } else this.subs.push(...response.data.CasterSubs)

                                this.displaysubs = this.subs
                                this.updatePaging()
                                
                                this.total = response.data.Total
                                if(this.total < this.limit){
                                    this.more = false
                                    this.nosort = false
                                } else this.nosort = true

                                if(this.total != 0 && this.stext != ''){
                                    this.$emit('fetchresponse',this.stext)
                                }
                                const count = response.data.Counts
                                this.statustypes = []
                                this.statustypes.push({value:3,text:'Active ('+count[0]+')'})
                                this.statustypes.push({value:4,text:'Expired ('+count[1]+')'})
                                this.statustypes.push({value:2,text:'Scheduled ('+count[2]+')'})
                                this.isBusy = false
                                this.display = true
                        }
                    
                    });
			},

         
           showRover(event){
                let id = 0
                if(event.rover){id = event.rover} else id = event.roverid
                this.$router.push({ name: 'casterrovermanage', params: {id:id} }).catch(()=>{})
           },

            updatePaging(){
                if(this.page == 0){
                    this.rstart = 1
                    this.rend = this.limit
                } else {
                this.rend = ((this.page + 1) * this.limit)
                if(this.rend > this.total){this.rend = this.total}
                } 
            },

            addTableEventList(){
                const tableScrollBody = this.$refs["subs-table"].$el
                tableScrollBody.addEventListener("scroll", this.onScroll)
            },

            removeTableEventList(){
                const tableScrollBody = this.$refs["subs-table"].$el
                tableScrollBody.removeEventListener("scroll", this.onScroll)
            },

            readdTableEventList(){
                this.$nextTick(() => {    
                    this.removeTableEventList()
                    this.addTableEventList()
                })    
            },

            showMore(){
                this.page++
                this.more = false
                this.loadingmore = true
                this.fetchSubs()
            },

            onScroll(event) {
                if (
                    event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 200
                ) {
                    if (!this.isBusy) {
                        if(this.total > this.limit){
                            this.more = true
                        } else this.removeTableEventList()
                    }
                }
            },

            // handle column sorting events to only call API when there is more results
            onSortChanged(field,event){
                if(event.sortable){
                    if(this.nosort){
                        this.orderby = field
                        this.sortdesc = this.$refs['subs-table'].localSortDesc !== true
                        this.page = 0
                        this.fetchSubs()
                    } else {
                        if(field == 'statuscolor'){
                            this.sortBy = 'days'
                            this.sortdesc = !this.sortdesc
                        }
                    }
                }
            },

             // handle column sorting events to only call API when there is more results
             onSortChanged2(field,event){
              
              if(event.sortable){
                
                  if(this.nosort){
                      this.orderby = field
                      this.sortdesc = this.$refs['schsubs-table'].localSortDesc !== true;
                      this.page = 0
                      this.fetchSubs()
                  } else {
                    if(field == 'statuscolor'){
                            this.sortBy = 'days'
                            this.sortdesc = !this.sortdesc
                        }
                    }
              } 
          },

            resetSearch(){
                this.$store.commit('setsearchtext','')
                this.searchtext = ''
                this.fetchSubs()
                this.substatus = 3
                this.$emit('clearstext')
            },

        }	
    };

</script>