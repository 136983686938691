<template>
<div>
<b-container class="mainpagecont">

<b-row class="mb-3">
    <b-col class="col-12 col-md-4 mt-3">
        <h3>Reference Stations</h3>
    </b-col>

    <b-col class="col-12 col-md-4 mt-3">
            <CasterSearch v-on:textsearch=updateSearch v-on:clearsearch=clearFilter />
    </b-col>

    <b-col class="col-12 col-md-4 mt-3 text-right">
        
    </b-col>

</b-row>      


<b-row>       

    <b-col class="col-12">
        <CasterBasesTable 
            :stext="searchtext"
            />
    </b-col>
</b-row>


</b-container>
</div> 
</template>



<script>
    import CasterSearch from './CasterSearch'
    import CasterBasesTable from './CasterBasesTable'
    export default {
        name:'CasterBases',
        components:{
            CasterSearch,
            CasterBasesTable
        },  
        data(){
            return {
                user:{},
                display:false,
                searchtext:'',
                };
                
                },

		created(){
            this.user = this.$store.getters.user
            this.$store.commit('setbasesdatapoll',0)
            this.display = true
            },

         mounted(){
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setbasesdatapoll',0)
        },
        
        
		methods: {

            refreshRovers(){
                this.refreshrover = !this.refreshrover
            },

            menuLink(name){
                this.$router.push({ name: name, params: {} }).catch(()=>{})
            },

            updateTable(filter){
                this.resellerid = filter.reseller
                this.dealerid = filter.dealer
                this.companyid = filter.company
                this.refreshrover = !this.refreshrover
            },

            updateSearch(text){
                this.searchtext = text
            },

            clearFilter(){
                this.searchtext = ''
            },

            updatemodal(){
                this.$refs['rover-add'].hide()
                this.componentKey += 1
           },

		}	   
	
	};

</script> 
