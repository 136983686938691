var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h6',{directives:[{name:"show",rawName:"v-show",value:(!_vm.ready),expression:"!ready"}]},[_vm._v(" Loading "),_c('b-icon',{attrs:{"icon":"arrow-clockwise","animation":"spin"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ready),expression:"ready"}]},[_c('Editor',{ref:"tinyReadOnly",attrs:{"init":{
                base_url: '/tinymce', 
                suffix: '.min',
                skin_url: `/tinymce/skins/ui/Cloudbase`,
                body_class: 'tinyReadOnlyBody',
                content_css: 'CloudbaseReadOnlyDisplay',
                plugins: 'link lists',
                toolbar: false,
                menubar: false,
                statusbar:false,
                license_key: 'gpl',


                setup(editor) {
                    editor.on('init', function() {
                        editor.mode.set('readonly');
                        _vm.editorContainer = editor.getContainer();
                        editor.getContainer().classList.add('tinyReadOnlyContainer');
                        editor.getContainer().style.maxHeight = '50px';
                        _vm.editorIframe = editor.iframeElement.contentDocument;
                        _vm.editorInitalised = true;
                    });
                }, 
            }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }