<script>
  
import { Bar } from 'vue-chartjs'

export default {
    extends: Bar,
    props: ['chartdata'],
    data(){
      return {
        token:'',
        total:0,
        lastdataset:[],
        cdata:{
            labels:[],
            datasets:[],
        },

        chartoptions: {
                responsive: true,
                maintainAspectRatio: false,

                plugins: {
                    datalabels: {
                    display: false,
                    },
                },
				scales: {
					yAxes: [{
            
						stacked: true,
                        ticks: {
                            beginAtZero: true,
                            display: true,
                            fontColor: "#ccc",
                            min:0,
                            max:100,  
                            stepSize: 20
						},
            scaleLabel: {
              display: true,
              labelString: '%',
              fontColor:"#ccc"
            }, 
						gridLines: { 
              color:  "#666",
              zeroLineColor: "#666" },
					}],
					xAxes: [{
						stacked: true,
            ticks: {
							beginAtZero: true,
              display: true,
              fontColor: "#ccc" 
						},
          }]
        },
        legend:{
          position: 'bottom',
          labels: {
                    fontColor: 'rgba(255,255,255,0.5)'
                    }
            },
				
        },

  };

},

  mounted () {
        this.token = this.$store.getters.token
        this.buildChart()  
        
  },

   watch: {
                chartdata: function () {
                //   if(JSON.stringify(this.chartdata.datasets) !== JSON.stringify(this.lastdataset)){  // redraw if data changes
                    this.buildChart()
               //     }
                  
                },   
          },

  methods:{
     
    buildChart(){

            if(this.chartdata.datasets == undefined){return}

            this.cdata.datasets = []
            this.lastdataset = []
            let dsoptions = {
            label:'',
            data:[],
        }

        let ds2 = JSON.stringify(dsoptions)
        this.cdata.labels = this.chartdata.labels
      
        for (var i = 0; i < this.chartdata.datasets.length; i++) { 
                let dataset = JSON.parse(ds2)
                dataset.label = this.chartdata.datasets[i].label
                dataset.backgroundColor = this.chartdata.datasets[i].color
                dataset.data = this.chartdata.datasets[i].data
                this.cdata.datasets.push(dataset)
                this.lastdataset.push({label:dataset.label,color:this.chartdata.datasets[i].color,data:this.chartdata.datasets[i].data})
         }

         
        
        this.renderChart(this.cdata, this.chartoptions)
            
    },

  },
}

</script> 