<template>
<div>
<b-container class="mainpagecont">

<b-row class="mb-3">
    <b-col class="col-12 col-md-4 mt-3">
        <h3>Users</h3> 
    </b-col>

    <b-col class="col-12 col-md-4 mt-3">
            <CasterSearchSuggestions 
                placeholder="search users" 
                v-on:textsearch=updateSearch 
                v-on:clearsearch=clearFilter 
                suggestionType="2" 
                :suggestionFetchResponse="fetchResponse"
                :clearsearch="clearsearch"
                />
    </b-col>

   <b-col class="col-12 col-md-3 offset-md-1 mt-3">
        <b-button size="sm" variant="outline-success" block @click="newUser()">
            <b-icon-plus-circle class="bicon" /> Add User
        </b-button>
    </b-col>

</b-row>   

<CasterOptionsFilter v-if="searchtext == ''"
            :resellerid="resellerid" 
            :dealerid="dealerid" 
            :companyid="companyid" 
            :roverid=0
            :roversshow="false"
            :companysshow="true"
            :clearsearch="clearsearch"
            v-on:resellersel=updateResellerFilter 
            v-on:dealersel=updateDealerFilter  
            v-on:companysel=updateCompanyFilter
            />  

<b-row class="mt-3 mb-3" >
      <b-col class="col-12 p-1">
          <CasterUsersTable2 
            @sendFetchResponse="giveSearchResponse"
            v-on:clearstext="clearSearch" 
            :resellerid="resellerid" 
            :dealerid="dealerid" 
            :companyid="companyid" 
            :clearsearch="clearsearch"
            :stext="searchtext" 
            :key="componentKey"
             />
       </b-col> 
</b-row>

<b-modal ref="add-user" size="md" :title="modaltitle" hide-footer centered content-class="darkmodal">
     <CasterUserAdd
    :userid=0
    :resellerid="resellerid"
    :dealerid="dealerid"
    :companyid="companyid"
    v-on:closemodal=closemodal()
    v-on:updatemodal=updatemodal() 
    />

</b-modal>  

</b-container>
	
</div> 
</template>

<script>
import CasterSearchSuggestions from './CasterSearchSuggestions'
import CasterOptionsFilter from './CasterOptionsFilter'
import CasterUsersTable2 from './CasterUsersTable2'
import CasterUserAdd from './CasterUserAdd'
export default {
    name:'CasterUsers',
        components:{
            CasterSearchSuggestions,
            CasterOptionsFilter,
            CasterUsersTable2,
            CasterUserAdd,
        }, 
    data(){
        return {
                modaltitle:'',
                resellerid:0,
                dealerid:0,
                companyid:0,
                searchtext:'',
                componentKey:0,
                fetchResponse:'',
                clearsearch:''
          };
                
                },

		created(){

            //this.$store.commit('setsearchvalues',[])
            // Are stored search filters use them
            if(this.$store.getters.searchvalues.length > 0){
                this.resellerid = this.$store.getters.searchvalues[0]
                this.dealerid = this.$store.getters.searchvalues[1]
                this.companyid = this.$store.getters.searchvalues[2]
                this.roverid = this.$store.getters.searchvalues[3]
            } else this.setDefaults()
        },

         mounted(){
            this.$store.commit('setroversdatapoll',0)
            this.$store.commit('setbasesdatapoll',0)
            this.searchtext = this.$store.getters.searchtext
        },

    methods: {

        setDefaults(){ 
                const roleid = this.$store.getters.roleid // 1 - System Admin 5 - Reseller Admin 10 - Dealer Admin 
           
                 // If system admin set to all Resellers
                 if(roleid == 1){
                    this.resellerid = 0 
                    this.dealerid = 0
                    this.companyid = 0
                    this.roverid = 0
                } else {
                // All other users go with stored login values    
                this.resellerid = this.$store.getters.resellerid
                this.dealerid = this.$store.getters.dealerid
                this.companyid = this.$store.getters.companyid
                this.roverid = 0
                }

                
            },

            updateResellerFilter(id){
                this.resellerid = id
                this.dealerid = 0
                this.companyid = 0
            },

            updateDealerFilter(id){
                this.dealerid = id
                this.companyid = 0
            },

            updateCompanyFilter(id){
                this.companyid = id
            },

            giveSearchResponse(response) {
                this.fetchResponse = response;
            },

            updateSearch(text){
                this.searchtext = text
            },

           clearSearch(){
                this.searchtext = ''
                this.clearsearch = !this.clearsearch
                this.setDefaults()
            },

            clearFilter(){
                this.searchtext = ''
            },

            updatemodal(){
                this.$refs['add-user'].hide()
                this.componentKey++
            },

            closemodal(){
                this.$refs['add-user'].hide()
            },

            newUser(){
                this.userid = 0
                this.modaltitle = 'Add User'
                this.$refs['add-user'].show()
            },
    },     

       
	};

</script>