<template>
<div>

<b-container v-if="notauth">
    <b-row>
        <b-col class="col-12 col-sm-8 offset-sm-2 p-5">
            <b-button variant="danger" block disabled>UNAUTHORISED TO VIEW THIS RESOURCE</b-button>
        </b-col>
    </b-row>
</b-container>     

<b-container v-else>

<b-row>
    <b-col class="col-12 col-sm-4 p-2">
         <h3>
            Rover
        </h3>
    </b-col>
   
     <b-col class="col-12 col-sm-8 text-sm-right p-2" v-if="userrole < 10">
        <b-button size="sm" variant="outline-warning" style="min-width:150px;margin-left:5px" @click="resetRover()"  v-if="rover.rtk_status > 0 && rover.lastsession !== null">
            <b-icon-geo class="bicon" /> ROVER RESET
        </b-button>

        <b-button size="sm" variant="outline-success" style="min-width:150px;margin-left:5px" @click="showHistory()">
            <b-icon-clock-history class="dicon" />VIEW HISTORY
            </b-button>
        <b-button size="sm" variant="outline-success" style="min-width:150px;margin-left:5px" @click="manageRover()">
            <b-icon-card-list class="dicon" />MANAGE ROVER
        </b-button>
        <!-- <b-button size="sm" variant="outline-success" style="min-width:100px;margin-left:5px" @click="back()">
            <b-icon-arrow-left class="bicon" /> BACK
        </b-button> -->
        </b-col>

</b-row>

<b-row>

<b-col class="col-12 col-md-6 p-2" style="font-size:0.7em;color:rgba(255,255,255,0.7)">
    <h5>{{ rover.text }}</h5>
</b-col>

<b-col class="col-12 col-md-6 text-md-right mt-0 p-2">
    Live 
    <div class="rtmarker" :style="{ backgroundColor:dataind1,marginLeft:6+'px' }" />    
</b-col>

</b-row>

<b-row class="mt-0 pb-5" v-if="loading">
 
    <b-col class="col-12 mt-3 p-5 text-center">
        <b-button variant="success" block disabled>GETTING ROVER DATA</b-button>
    </b-col>

</b-row> 


<b-row class="mt-0 pb-5" v-if="!connected && !loading">
 
    <b-col class="col-12 mt-3 p-5 text-center">
        <b-button variant="danger" block disabled>ROVER NOT CONNECTED</b-button>
    </b-col>

    <b-col class="col-12 col-lg-6 mt-3" @click="history()">
        <CasterRoverLast5Card  :id="roverid" />
    </b-col>

</b-row>  

<b-row class="mt-0 pb-5" v-if="connected">
 
    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterRoverLastEventsCard :logs="roversession.logs" v-if="mapshow"/>
    </b-col>   
    
    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterShowLeafletRoverSession :mapdata="roversession" :id="roverid" :border="true" v-if="mapshow" v-on:fullscreen="fullScreen" />
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterRoverStatusCard3  :rover="rover" />
    </b-col> 
 
    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterRoverStatusCard :rover='rover'/>
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2" @click="history()">
        <CasterRoverLast5Card  :id="roverid" />
    </b-col>

    <b-col class="col-12 col-md-6 mt-1 p-2">
        <CasterRoverStatusCard2   :rover='rover'/>
    </b-col> 

</b-row>
</b-container>

<b-modal ref="full-screen" size="xl"  title="SESSION TRACK"  @show="waitSetWidth" @hide="closeMap" hide-footer centered content-class="darkmodal2">
    <div ref="fullscreendiv" :style="{width:100+'%',height:500+'px'}">
        <CasterShowLeafletRoverSession v-if="modalmapwidth != 0" :mapdata="roversession" :fsmapheight="modalmapheight" :fsmapwidth="modalmapwidth" :border="false" :id="roverid"/>   
    </div>
</b-modal>

<b-modal ref="reset-rover"  :title="modaltitle"  hide-footer centered content-class="darkmodal">
   <CasterRoverSessionControl :rover="rover"/>
</b-modal>

<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<script>
    import CasterRoverLastEventsCard from './CasterRoverLastEventsCard'
    import CasterShowLeafletRoverSession from './CasterShowLeafletRoverSession'
    import CasterRoverStatusCard from './CasterRoverStatusCard'
    import CasterRoverStatusCard2 from './CasterRoverStatusCard2'
    import CasterRoverStatusCard3 from './CasterRoverStatusCard3'
    import CasterRoverLast5Card from './CasterRoverLast5Card'
    import CasterRoverSessionControl from './CasterRoverSessionControl'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    export default {
        name:'CasterRover',
        components:{
            CasterShowLeafletRoverSession,
            CasterRoverLastEventsCard,
            CasterRoverStatusCard,
            CasterRoverStatusCard2,
            CasterRoverStatusCard3,
            CasterRoverLast5Card,
            CasterRoverSessionControl,
            ErrorLogging
        }, 
        data(){
            return {
                roverid:0,
                title:'',
                sessiondata:{},
                roversession:{},
                rover:{},
                connected:false,
                timeout:false,
                loading:true,
                display:false,
                mapshow:false,
                showmap:false,
                modalmapwidth:0,
                modalmapheight:485,
                modaltitle:'',
                poll:false,
                dataind1:'#708675',
                notauth:false,
                errorresponse:{},
                userrole:999,
                };
                
                },

        created(){
            this.$store.commit('setprevroute',this.$route.name)
            this.$store.commit('setroversdatapoll',0)
            this.userrole = this.$store.getters.user.role
            this.roverid = this.$route.params.id
            this.getRoverSession()
        },

        mounted(){
            setTimeout(this.showMap,150)
        },

        beforeDestroy () {
            window.removeEventListener("resize",this.setModalWidth)
            clearInterval(this.timeout)
        },

         watch: {
            roversession: function() {
                if(this.connected){this.dataind1 = '#53C16B'} else this.dataind1 = '#FF0000'
                setTimeout(this.flashData1,500)
            }
        },

        methods: {

            showMap(){
                    this.showmap = true
            },

            getRoverSessionOld(){ 

                axios.get('/api/castaroversessionnew',{
                        headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                        params:{
                            userid:this.$store.getters.user.id,
                            token:this.$store.getters.user.token, 
                            rover: this.roverid,
                            // reseller:this.$store.getters.resellerid,
                            // dealer:this.$store.getters.dealerid,
                            session:0
                        }
                })
                .then((response) => {
                  
                    if(response.data.code == 999){
                        this.errorresponse = response.data
                      } else {
                               // this.loading = false
                                this.roversession = response.data
                            
                                if(this.roversession.error == 'no data'){
                                    this.notauth = false
                                    this.loading = false
                                    this.connected = false
                                    this.display = true
                               
                                } else  {
                                    this.notauth = false
                                    this.loading = false
                                    this.connected = true
                                    this.display = true
                                    this.rover = this.roversession.session[0].rover[0]
                                    this.title = this.rover.text
                                    this.rover.connection_time = this.roversession.session[0].connection_time
                                    this.rover.bytessent = this.roversession.session[0].bytessent
                                    this.rover.bytesrcvd = this.roversession.session[0].bytesrcvd
                                    this.rover.basestationid = this.roversession.session[0].basestation_id
                                    this.rover.time_to_fix = this.roversession.session[0].time_to_fix
                                    this.mapshow = true
                                } 

                                }

                            if(!this.timeout){this.timeout = setInterval(this.getRoverSession,5000) }

                })

              }, 

            getRoverSession(){ 
                axios.get('/api/castaroversessionnew',{
                    headers: {"Authorization" : "Bearer " + this.$store.getters.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token, 
                        rover: this.roverid,
                        // reseller:this.$store.getters.resellerid,
                        // dealer:this.$store.getters.dealerid,
                        session:0
                    }
                })
                .then((response) => {
                  
                    if(response.data.code == 999){
                        //this.errorresponse = response.data //TODO: enable
                    } else {
                        // this.loading = false
                        this.roversession = response.data
                    
                        if(this.roversession.error == 'no data'){
                            this.notauth = false
                            this.loading = false
                            this.connected = false
                            this.display = true
                        } else  {
                            this.notauth = false
                            this.loading = false
                            this.connected = true
                            this.display = true
                            this.rover = this.roversession.session[0].rover[0]
                            this.title = this.rover.text
                            this.rover.connection_time = this.roversession.session[0].connection_time
                            this.rover.bytessent = this.roversession.session[0].bytessent
                            this.rover.bytesrcvd = this.roversession.session[0].bytesrcvd
                            this.rover.basestationid = this.roversession.session[0].basestation_id
                            this.rover.time_to_fix = this.roversession.session[0].time_to_fix
                            this.mapshow = true
                        } 
                    }

                    if(!this.timeout){this.timeout = setInterval(this.getRoverSession,5000) }
                })
              }, 


            showHistory(){
               this.$router.push({ name: 'casterroverhistory', params: {id:this.roverid} }).catch(()=>{})
           },

           manageRover(){
                this.$router.push({ name: 'casterrovermanage', params: {id:this.roverid} }).catch(()=>{})
           },

            flashData1(){
               this.dataind1 = '#708675';
           },

           setSessionData(event){
                this.sessiondata = event[0]
                this.roverdata = event[0]['rover'][0]
                //this.roverdata.speed = this.sessiondata.speed
                this.roverdata.time_to_fix = this.sessiondata.time_to_fix
                this.roverdata.basestationid = this.sessiondata.basestation_id
                this.sessiondata.connected = this.roverdata.connected
                this.sessiondata.rtk_status = this.roverdata.rtk_status
                this.sessiondata.rtk_message = this.roverdata.rtk_message
                this.sessiondata.color = this.roverdata.color
                this.sessiondata.distance = this.roverdata.distance
                this.title = this.roverdata.text
           },

           resetRover(){
                this.modaltitle = this.rover.text
                this.$refs['reset-rover'].show()
           }, 
          
            fullScreen(){
                this.modalmapwidth = 0
                this.$refs['full-screen'].show()
            },

            waitSetWidth(){
                setTimeout(this.setModalWidth,150)
            },

            setModalWidth(){
                window.addEventListener("resize", this.setModalWidth)
                this.modalmapwidth = this.$refs['fullscreendiv'].clientWidth -20
            },

            closeMap(){
                window.removeEventListener("resize", this.setModalWidth)
                this.$refs['full-screen'].hide()
            },

            back(){
                history.back()
            },

        }       
    
    };

</script>