<template>
<div>
    
<b-row class="mt-3 mb-3">

        <b-col class="col-12 col-sm-3">
            <label>Country</label>
				<b-form-select
                    v-model="countrysel"
                    :options="countries"
                    class="darkinput2"
                    size="sm"
                    :disabled="denmarkonly"  
                    />
        </b-col>

        <b-col class="col-12 col-sm-3 offset-sm-6 mt-4">
            <b-button variant="outline-success" block size="sm" @click.stop="addBase()">ADD BASE</b-button>

        </b-col>
</b-row>

<b-row>
        <b-col class="col-12 offset-sm-9 col-sm-3 text-right" v-if="bases != undefined">{{ totalrows }} Result<span v-if="totalrows != 1">s</span></b-col>
        <b-col class="col-12 offset-sm-9 col-sm-3 text-right" v-else>0 Results</b-col>
    
</b-row>

<b-row>        
        <b-col class="col-12 mt-3">
             <b-table
                class="rovers" 
                responsive 
                hover 
                :items="bases" 
                :fields="fields"
                tbody-tr-class="rowClass"
                thead-tr-class="rowHClass"
                sort-icon-left
                :busy="isBusy"
                :sort-by.sync="sortBy"
                @row-clicked="editBase($event)"
                dark
                >

                <template #table-busy>
                    <div class="text-center text-warning my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong style="margin-left:15px"> Loading data</strong>
                    </div>
                </template>

                </b-table>
       </b-col>

</b-row>

<b-modal ref="edit-base"  :title="modaltitle" size="md" hide-footer centered content-class="darkmodal">
   <CasterBaseEdit
    :id="basesel"
    :country="countrysel" 
    v-on:closemodal="closemodal()"
    v-on:updatemodal="updatemodal()"
    v-on:updatecompany="updatemodal()"
    />
</b-modal>


<ErrorLogging :errorresponse="errorresponse" :component="this.$options.name"/>

</div> 
</template>

<style>
.rovers .table-dark td{
    word-break: unset !important;
}
</style>

<script>
    import CasterBaseEdit from './CasterBaseEdit'
    import ErrorLogging from './ErrorLogging'
    import axios from 'axios'
    import moment from 'moment'
	export default {
        props:['stext'],
        name:'CasterBasesTable',
        components:{
            CasterBaseEdit,
            ErrorLogging
        }, 
        data(){
        return {
                id:0,
                token:'',
                bases:[],
                basesel:0,
                countrysel:0,
                sortBy:'text',
                isBusy: true,
                modaltitle:'',
                display:false,
                totalrows:0,
                errorresponse:{},
                timeout:false,
                denmarkonly:false,
                fields:[
                    { key: 'text', label: 'Base', sortable: true  },    
                    { key: 'mount', label: 'Mount', sortable: true  },
                    { key: 'countrytext', label: 'Country', sortable: true  },
                    { key: 'station_id', label: 'Station ID', sortable: true  },
                    { key: 'avail', label: 'Avail %', sortable: true  },   
                    { key: 'connection_time', label: 'Connected', formatter: 'calculateTime', sortable: true  },
                    { key: 'statustext', label: 'Status', sortable: true  },
                    ],
                    countries:[
                    { value:0,text:'ALL'},    
                    { value:1,text:'UK'},
                    { value:2,text:'DENMARK'},
                ],    
                };
                
                },

		created(){
            this.token = this.$store.getters.token
            if(this.$store.getters.user.business == 11){
                this.denmarkonly = true
                this.countrysel = 2
            }
            this.fetchBases()
        },

           watch: {
                    stext: function () {
                       this.fetchBases()
                    }, 

                    countrysel: function () {
                       this.fetchBases()
                    }, 
            },

            beforeDestroy () {
                clearTimeout(this.timeout)
            }, 
            
            methods: {

            fetchBases(){
               
                let s1 = this.stext
                
                axios.get('/api/basestations',{
					headers: {"Authorization" : "Bearer " + this.token},
                    params:{
                        userid:this.$store.getters.user.id,
                        token:this.$store.getters.user.token,
                        basename:this.stext,
                        country:this.countrysel
                     }
                })
				.then((response) => {

                    if(response.data.code == 999){
                        this.errorresponse = response.data
                        return;
                    }
                    if(s1 == this.stext){
                        this.bases = response.data.basestations
                        this.totalrows = this.bases.length 
                    }
                    this.display = true
                    this.isBusy = false

                    if(!this.timeout){
                        this.timeout = setInterval(this.fetchBases, 5000)
                    } 
                }).catch( err => {
                    console.error("CasterBasesTable, fetchBases :",err)
                })

        
			},

          editBase(item){
               this.basesel = item.value
               this.modaltitle = item.text
               this.$refs['edit-base'].show() 
           },

           addBase(){
                this.basesel = 0
                this.modaltitle = 'Add Base'
                this.$refs['edit-base'].show() 
           },

            updatemodal(){
                this.$refs['edit-base'].hide() 
                this.fetchBases()
            },

           
            calculateTime(start){
                let days = parseInt(start/86400)
                let hours = Math.trunc(parseInt(start - (days * 86400))/3600) 
                let minutes = Math.trunc(parseInt((start - (days * 86400) -(hours * 3600))/60)) 
                let secs = Math.trunc((start - (days * 86400) - (hours * 3600) - (minutes * 60))) 
                let duration = days+'d '+ hours +'h '+ minutes +'m '+ secs+'s'
                return duration
            },    

             formatDate(timedate) {
                if(timedate == null){return '-'}
                return  moment.utc(timedate).local().format('DD-MM-YY HH:mm:ss')
            },

            gotoPage(page){
                this.page = page
                this.fetchBases()
            }

		}	   
	
	};
</script>