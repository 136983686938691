<template>
<div class="imageBorder" style="font-size:0.8em">

    <b-row>
        <b-col class="col-12 col-sm-5">
            <h6>SESSION DETAILS</h6></b-col>
        <b-col class="col-12 col-sm-2 text-center"><span class="livebutton" v-if="setlive">LIVE</span></b-col>    
        <b-col class="col-12 col-sm-5 text-right">USER AGENT {{ session.useragent }}</b-col> 
    </b-row>

    <b-row class="mt-3 p-3">    

        <b-col class="col-12 col-sm-4 border">
            <b-row class="mt-1">
            <b-col class="col-6">QUALITY</b-col>
            <b-col class="col-6"> {{ session.quality }}%</b-col>    
            <b-col class="col-6">SESSION ID</b-col>
            <b-col class="col-6"> {{ session.session_id }}</b-col>    
            <b-col class="col-6">#GPGGAs</b-col>
            <b-col class="col-6"> {{ session.ggas }}</b-col>
            <b-col class="col-6">TIME TO FIX</b-col>
            <b-col class="col-6"> {{ session.time_to_fix }}s</b-col>
            <b-col class="col-6">BASESTATION</b-col>
            <b-col class="col-6"> {{ session.basestation }}</b-col>
            <b-col class="col-6">AVERAGE DISTANCE</b-col>
            <b-col class="col-6"> {{ session.average_distance }} km</b-col>
            </b-row>
        </b-col>


        <b-col class="col-12 col-sm-4 border">
            <b-row class="mt-1">
            <b-col class="col-6">RTK FIX </b-col>
            <b-col class="col-6"> {{ session.fix }}%</b-col>
            <b-col class="col-6">RTK FLOAT </b-col>
            <b-col class="col-6"> {{ session.float }}%</b-col>
            <b-col class="col-6">RTK DGPS </b-col>
            <b-col class="col-6"> {{ session.dgps }}%</b-col>
            <b-col class="col-6">RTK GPS </b-col>
            <b-col class="col-6"> {{ session.stand }}%</b-col>
            <b-col class="col-6">RTK EST </b-col>
            <b-col class="col-6"> {{ session.est }}%</b-col>
        </b-row>
        </b-col>

        
        <b-col class="col-12 col-sm-4 border">

        <b-row class="mt-1">
            <b-col class="col-6">DATA RECEIVED</b-col>
            <b-col class="col-6">
                <div class="rtmarker" :style="{ marginRight:'10px',backgroundColor:dataind1 }" /> {{ bytesrcvd }} kb</b-col>
        </b-row>

        <b-row class="mt-1">
            <b-col class="col-6">DATA SENT</b-col>
            <b-col class="col-6">
                <div  class="rtmarker" :style="{ marginRight:'10px',backgroundColor:dataind2  }" /> {{ bytessent }} kb</b-col>
        </b-row>

        <b-row class="mt-1 mb-3">
            <b-col class="col-6">CONN DURATION</b-col>
            <b-col class="col-6">{{ duration }}</b-col>
            <b-col class="col-6">CONNECTED</b-col>
            <b-col class="col-6">{{ formatTime(session.datetime) }}</b-col>
        </b-row>

        </b-col>

    </b-row>  
    
</div>
</template>

<style>
.data{background-color:'red' !important;}
.nodata{background-color:'yellow' !important;}
.livebutton{background-color:red;color:white;padding:3px;padding-left:6px;padding-right:6px;}
</style>

<script>
    import moment from 'moment'
    export default {
        props:['session','live'],
        data(){
            return {
                online:true,
                duration:0,
                bytessent:0,
                bytesrcvd:0,
                dataind1:'#708675',
                dataind2:'#708675',
                timeout:false,
                start:0,
                setlive:false
                };
                
                },

		mounted(){
            this.setSession()
        },

        beforeDestroy () {
            clearTimeout(this.timeout)
        },    

        watch: {
              session: function () {
                    this.setSession()
              },

              setlive: function () {
                    if(!this.live){clearTimeout(this.timeout)} 
                    else if(!this.timeout){clearTimeout(setInterval(this.connectTime, 1000))}
              },
          },

     
		methods: {

           setSession(){
            this.setlive = this.live
            if(this.session.roverconnected ){this.setlive == true } else this.setlive = false
            this.start = this.session.connection_time
          
            let sentdata = Math.round(this.session.bytessent/1024)
            if(sentdata != this.bytessent){
                this.dataind2 = '#53C16B'
                setTimeout(this.flashData2,500)
                this.bytessent = sentdata
            }

            if(this.session.bytesrcvd != this.bytesrcvd){
                this.dataind1 = '#53C16B'
                setTimeout(this.flashData1,500)
                this.bytesrcvd = this.session.bytesrcvd
            }

            if(this.session.roverconnected == 1 && !this.timeout){
                this.timeout = setInterval(this.connectTime, 1000) 
                } else this.connectTime()

         
           }, 

           flashData1(){
               this.dataind1 = '#708675';
           },
           
           flashData2(){
               this.dataind2 = '#708675';
           }, 

        
            connectTime(){
                    this.start = this.start + 1
                    let days = parseInt(this.start/86400)
                    let hours = Math.trunc(parseInt(this.start - (days * 86400))/3600) 
                    let minutes = Math.trunc(parseInt((this.start - (days * 86400) -(hours * 3600))/60)) 
                    let secs = Math.trunc((this.start - (days * 86400) - (hours * 3600) - (minutes * 60))) 
                    this.duration = days+'d '+ hours +'h '+ minutes +'m '+ secs+'s'
            },

             formatTime(datetime){  // BST Fiddle
                    let time =  moment.utc(datetime).local().format('DD-MM-YY HH:mm:ss')
                    return time
                },

        }	   
	
	};

</script> 