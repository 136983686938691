<template>
<div class="imageBorder"> 

        <b-row>
                <b-col class="col-9 boxtext">RTK STATUS HISTORY</b-col>
                <b-col class="col-3 text-right" >
                <b-icon-fullscreen @click.stop="fullScreen()" v-b-tooltip.hover title="Expand" />
                </b-col>  
        </b-row>

        <b-row  v-if="display">
            <b-col class="col-12 mt-3">
                 <BarChartStackedVertical :chartdata="data" :height="250" />
            </b-col>

        </b-row>

         <b-row class="mt-3" v-else>
                <b-col class="col-12 text-center pt-3 pb-3">
                        <h5 style="color:#48A1BA;">No Data</h5>
                </b-col>   
        </b-row>

<b-modal ref="full-screen" size="lg"  title="RTK STATUS HISTORY" hide-footer centered content-class="darkmodal">

    <b-row style="min-height:300px">
        <b-col class="col-12 mt-3">
             <BarChartStackedVertical :chartdata="data" :height="250" :width="300" />
        </b-col>
    </b-row>

</b-modal>        

</div>	
</template>

<script>
        import BarChartStackedVertical from './BarChartStackedVertical'
        import { mapState } from 'vuex'
        import axios from 'axios'
	export default {
                name:'CasterNetworkRTKChartCard',
                components:{
                        BarChartStackedVertical
                },      
                data(){
		return {
                data:[],
                token:'',
                resellid:0,
                dealerid:0,
                display:false,
                timeout:'',
               };
                
                },

	created(){
                this.token = this.$store.getters.token
                this.resellid = this.$store.getters.resellerid
                this.dealerid = this.$store.getters.dealerid
                this.companyid = this.$store.getters.companyid
                this.getRTKHistory()
        },

        beforeDestroy () {
            clearTimeout(this.timeout)
        }, 

        computed: mapState(['resellerid']),
                
                watch: {
                    
                        resellerid: function () {
                        this.resellid = this.resellerid    
                         this.getRTKHistory()
                        },    
                },    
        
        methods: {

                getRTKHistory(){

                        axios.get('/api/rtkhistory',{
			headers: {"Authorization" : "Bearer " + this.token},
                        params:{
                                userid:this.$store.getters.user.id,
                                token:this.$store.getters.user.token,
                                start:0,
                                end:24,
                                period:3600, // 3600 1 hour 1800 1/2 hour 600 10mins
                                reseller:this.resellid,
                                dealer:this.dealerid,
                                company:this.companyid
                         }
                        })
			.then((response) => {

                           this.data = response.data
                           this.display = true
                           this.timeout = setTimeout(this.getRTKHistory, 300000) // poll every 5 mins
                           
                        })

                },

                fullScreen(){
                      this.$refs['full-screen'].show()
                      this.getRTKHistory()
                },

            }	 

	};
</script> 