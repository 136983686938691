<template>
<div>

<b-container class="mainpagecont">

<b-row>
    <b-col class="col-9 mt-3"><h4>Subscriptions</h4></b-col>
    <b-col class="col-3 text-right mt-3">
        <b-button variant="outline-success" @click="$router.go(-1)" >BACK</b-button>
    </b-col>    
</b-row>

<b-row>
     <b-col class="col-12">
        <CasterSubscriptionsTable :dealerid="dealerid" :companyid="companyid" />
    </b-col>

</b-row>        

</b-container>
	
</div> 
</template>

<script>
    import CasterSubscriptionsTable from './CasterSubscriptionsTable'
	export default {
        name:'CasterCompanySubscriptions',
        components:{
            CasterSubscriptionsTable
        }, 
        data(){
		return {
            dealerid:0,
            };
		
		},
		created(){
            this.token = this.$store.getters.token
            this.dealerid = this.$store.getters.dealerid
            this.companyid = this.$store.getters.companyid
        },
	
}
</script> 